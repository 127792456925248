import React, { useState, useRef, useEffect } from "react";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { setScoreDB, setAScoreDB } from "../Generic/lists";
import {
  ScoreChips,
  ScoreBullet,
  SG,
  SO,
  SR,
} from "../SmallComponents/SmallComponents";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";

import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
  setReduxTopArrow2,
  setReduxLeftArrow2,
} from "../redux/globalTuto";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@material-ui/core/IconButton";

var current_question = [];
// All questions
var ga_questions = [];
var gh_questions = {};

// ids of all green questions
var a_green_ids = [];
// ids of all orange questions
var a_orange_ids = [];
// ids of all red questions
var a_red_ids = [];
// ids of all considered green questions
var a_cons_green_ids = [];
// ids of all considered orange questions
var a_cons_orange_ids = [];
// ids of all considered red questions
var a_cons_red_ids = [];

// ids of questions not considered
var a_left_orange = [];
var a_left_red = [];

var a_super_green_ids = [];
var a_super_green_random = [];
var super_green_index = -1;

function fillConsQuestions() {
  if (a_cons_red_ids.length + a_cons_orange_ids.length < 5) {
    if (a_cons_red_ids.length < 3) {
      if (a_left_red.length > 0) {
        let index = Math.floor(Math.random() * a_left_red.length);
        a_cons_red_ids.push(a_left_red.splice(index, 1)[0]);
      }
    }
    if (a_cons_orange_ids.length < 2) {
      if (a_left_orange.length > 0) {
        let index = Math.floor(Math.random() * a_left_orange.length);
        a_cons_orange_ids.push(a_left_orange.splice(index, 1)[0]);
      }
    }
  }
}

function setScoreEverywhereQuick(id, score_before, score_after) {
  console.log(
    "setScoreEverywhereQuick " + id + " " + score_after + " " + score_before
  );
  if (score_before == score_after) {
    console.log("score_before == score_after");
    return;
  }
  if (score_before == 0) {
    console.log("setScoreEverywhereQuick red before " + a_cons_red_ids);
    a_cons_red_ids.splice(a_cons_red_ids.indexOf(id), 1);
    console.log("setScoreEverywhereQuick red after " + a_cons_red_ids);
    a_red_ids.splice(a_red_ids.indexOf(id), 1);
  }
  if (score_before == 1) {
    console.log("setScoreEverywhereQuick orange before " + a_cons_orange_ids);
    a_cons_orange_ids.splice(a_cons_orange_ids.indexOf(id), 1);
    console.log("setScoreEverywhereQuick orange after " + a_cons_orange_ids);
    a_orange_ids.splice(a_orange_ids.indexOf(id), 1);
  }
  if (score_before == 2) {
    a_cons_green_ids.splice(a_cons_green_ids.indexOf(id), 1);
    a_green_ids.splice(a_green_ids.indexOf(id), 1);
  }
  if (score_before == 3) {
    a_super_green_ids.splice(a_super_green_ids.indexOf(id), 1);
  }

  if (score_after == 0) {
    a_cons_red_ids.push(id);
    a_red_ids.push(id);
  }
  if (score_after == 1) {
    a_cons_orange_ids.push(id);
    a_orange_ids.push(id);
  }
  if (score_after == 2) {
    a_cons_green_ids.push(id);
    a_green_ids.push(id);
  }
  if (score_after == 3) {
    a_super_green_ids.push(id);
  }

  gh_questions[id][6] = score_after;
  ga_questions.forEach((row, index) => {
    if (row[0] == id) {
      row[6] = score_after;
    }
  });
}

function pickAQuestion() {
  if (
    a_super_green_ids.length +
      a_cons_red_ids.length +
      a_cons_orange_ids.length +
      a_cons_green_ids.length ==
    1
  ) {
    let id;
    if (a_super_green_ids.length == 1) {
      id = a_super_green_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_red_ids.length == 1) {
      id = a_cons_red_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_orange_ids.length == 1) {
      id = a_cons_orange_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_green_ids.length == 1) {
      id = a_cons_green_ids[0];
      current_question = gh_questions[id];
    }

    console.log(current_question);
    return;
  }

  const pick = () => {
    let x = Math.random();
    let x2 = Math.random();
    let id;
    if (x2 > 0.7 && a_cons_red_ids.length + a_cons_orange_ids.length == 1) {
      return id;
    }
    if (x2 > 0.85 && a_cons_red_ids.length + a_cons_orange_ids.length == 2) {
      return id;
    }
    if (x < 0.12 && a_cons_green_ids.length > 0) {
      id =
        a_cons_green_ids[Math.floor(Math.random() * a_cons_green_ids.length)];
    } else {
      if (x < 0.56 && a_cons_orange_ids.length > 0) {
        id =
          a_cons_orange_ids[
            Math.floor(Math.random() * a_cons_orange_ids.length)
          ];
      } else {
        if (a_cons_red_ids.length > 0) {
          id =
            a_cons_red_ids[Math.floor(Math.random() * a_cons_red_ids.length)];
        }
      }
    }

    if (typeof id == "undefined") {
      if (x < 0.75 && a_cons_green_ids.length > 0) {
        id =
          a_cons_green_ids[Math.floor(Math.random() * a_cons_green_ids.length)];
      }
    }

    return id;
  };
  let id = pick();

  const feedSuperGreenRandom = () => {
    console.log(a_super_green_ids);
    super_green_index = 0;
    a_super_green_random = Object.keys(a_super_green_ids).sort(function () {
      return 0.5 - Math.random();
    });
    console.log(a_super_green_random);
  };

  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].forEach(() => {
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }
      console.log(a_super_green_random);
      console.log(a_super_green_ids);
      console.log(super_green_index);
      id = a_super_green_ids[a_super_green_random[super_green_index]];
      super_green_index++;
    } else {
      super_green_index = -1;
    }
  });

  // Ugly move to try to avoid twice the same question
  console.log(gh_questions);
  console.log(id);
  console.log(current_question);
  console.log(super_green_index);
  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }

      if (
        typeof a_super_green_ids[a_super_green_random[super_green_index]] !=
        "undefined"
      ) {
        id = a_super_green_ids[a_super_green_random[super_green_index]];
        console.log(
          id,
          a_super_green_ids,
          a_super_green_random,
          super_green_index
        );
        super_green_index++;
      }
    } else {
      super_green_index = -1;
    }
  }

  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  console.log(gh_questions, id, current_question);
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }

      if (
        typeof a_super_green_ids[a_super_green_random[super_green_index]] !=
        "undefined"
      ) {
        id = a_super_green_ids[a_super_green_random[super_green_index]];
        console.log(
          id,
          a_super_green_ids,
          a_super_green_random,
          super_green_index
        );
        super_green_index++;
      }
    } else {
      super_green_index = -1;
    }
  }

  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  console.log(gh_questions, id, current_question);
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }
      if (
        typeof a_super_green_ids[a_super_green_random[super_green_index]] !=
        "undefined"
      ) {
        id = a_super_green_ids[a_super_green_random[super_green_index]];
        console.log(
          id,
          a_super_green_ids,
          a_super_green_random,
          super_green_index
        );
        super_green_index++;
      }
    } else {
      super_green_index = -1;
    }
  }

  current_question = gh_questions[id];
  console.log(current_question);
}

// objective: 2 oranges, 3 reds
function prepareQuestions() {
  a_green_ids = [];
  a_orange_ids = [];
  a_red_ids = [];

  a_cons_green_ids = [];
  a_cons_orange_ids = [];
  a_cons_red_ids = [];

  a_left_orange = [];
  a_left_red = [];

  a_super_green_ids = [];

  ga_questions.forEach((row, x) => {
    let id = row[0];
    let score = row[6];
    if (score == 2) {
      row[6] = 3;
    }
    gh_questions[id] = [...row];
    if (score == 0) {
      console.log("a_red_ids id push " + id);
      console.log(row);
      a_red_ids.push(id);
    }
    if (score == 1) {
      a_orange_ids.push(id);
    }
    if (score == 2 || score == 3) {
      a_super_green_ids.push(id);
    }
  });

  // Big mess to have 5 considered questions, trying to have 2 oranges, 3 reds
  let rn = 3;
  let on = 2;

  if (a_red_ids.length < 3) {
    rn = a_red_ids.length;
    if (a_orange_ids.length > 2) {
      on = Math.min(5, a_orange_ids.length);
    }
  }
  if (a_orange_ids.length < 2) {
    on = a_orange_ids.length;
  }

  let a_shuffled = a_red_ids.sort(() => 0.5 - Math.random());
  a_cons_red_ids = a_shuffled.slice(0, rn);
  a_left_red = a_shuffled.slice(rn, a_shuffled.length);
  // Put the latest added question if red
  if (ga_questions[0][6] == "0" && rn >= 1) {
    if (a_left_red.indexOf(ga_questions[0][0]) != -1) {
      a_left_red.splice(a_left_red.indexOf(ga_questions[0][0]), 1);
      a_cons_red_ids.push(ga_questions[0][0]);
    }
  }

  a_shuffled = a_orange_ids.sort(() => 0.5 - Math.random());
  a_cons_orange_ids = a_shuffled.slice(0, on);
  a_left_orange = a_shuffled.slice(on, a_shuffled.length);
}

function computeScore(isTrue) {
  let score_before = current_question[6];
  let score_after;
  let id = current_question[0];
  if (isTrue) {
    score_after = score_before - 0 + 1;
  } else {
    score_after = 0;
  }

  if (score_after == -1) {
    score_after = 0;
  }
  if (score_after == 4) {
    score_after = 3;
  }

  gh_questions[id][6] = score_after;
  console.log("new score");
  console.log(score_after);
  setScoreEverywhereQuick(id, score_before, score_after);
  return score_after;
}

export default function LTrain({
  a_questions,
  setScore,
  setCurrentId,
  mode = "u",
  is_logged = "1",
  is_focused = false,
  saveFunction = () => {
    console.log("saveFunction");
  },
}) {
  const [formAnswer, setFormAnswer] = useState("");
  const [realAnswer, setRealAnswer] = useState("");
  const [temp_realAnswer, setTempRealAnswer] = useState("");
  const [statement, setStatement] = useState("");
  const [media, setMedia] = useState("0");
  const [media_800, setMedia800] = useState("0");
  const [open_img_dial, setOpenImgDial] = useState(false);
  const [is_oops, setIsOops] = useState(false);
  const [green_score, setGreenScore] = useState("");
  const [orange_score, setOrangeScore] = useState("");
  const [red_score, setRedScore] = useState("");
  const [scoreBullet, setScoreBullet] = useState();
  const inputAnswer = useRef(null);

  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });
  const is1100 = useMediaQuery({ query: "(max-width: 1100px)" });

  const [is_focused_inside, setIsFocusedInside] = useState(is_focused);

  useEffect(() => {
    setIsFocusedInside(is_focused);
  }, [is_focused]);

  const dispatch = useDispatch();
  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  const manageTutoStep = (int_step) => {
    if (int_step > h_global_tuto_step["tuto_step"]) {
      if ((int_step == 8) | (int_step == 10)) {
        let dom_comp = document.getElementById("id_train_ans");
        let rect = dom_comp.getBoundingClientRect();
        console.log(rect.top);
        console.log(rect.left);
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if ((int_step == 9) | (int_step == 11)) {
        let dom_comp = document.getElementById("id_train_val");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if (int_step == 12) {
        let dom_comp = document.getElementById("id_cont_code");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
        dom_comp = document.getElementById("id_tf_cont_code");
        rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow2({ top_arrow2: rect.top }));
        dispatch(setReduxLeftArrow2({ left_arrow2: rect.left }));
      }

      dispatch(setReduxTutoStep({ tuto_step: int_step }));
      //setTutoStep(int_step);
    }
  };

  if (typeof a_questions != "undefined") {
    if (typeof a_questions[0] != "undefined") {
      if (
        ga_questions.length != a_questions.length &&
        a_questions.length != 0
      ) {
        let do_rerender = false;
        if (a_questions.length == 1) {
          if (a_questions[0][0] != -1 && a_questions[0][0] != -2) {
            do_rerender = true;
          }
        }
        if (a_questions.length > 1) {
          if (
            a_questions[0][0] != -1 &&
            a_questions[0][0] != -2 &&
            a_questions[1][0] != -1 &&
            a_questions[1][0] != -2
          ) {
            do_rerender = true;
          }
        }
        if (do_rerender) {
          console.log("render a_questions");
          console.log(a_questions);
          ga_questions = [...a_questions];
          prepareQuestions();
          if (current_question.length == 0) {
            pickAQuestion();
          }
          console.log(current_question);
          setStatement(current_question[3]);
          setMedia(current_question[8]);
          if (mode == "u") {
            setMedia800(current_question[9]);
          }
          if (mode == "a") {
            setMedia800(current_question[10]);
          }
          setRealAnswer(current_question[4]);
          setCurrentId(current_question[0]);
          setGreenScore(a_green_ids.length + a_super_green_ids.length);
          setOrangeScore(a_orange_ids.length);
          setRedScore(a_red_ids.length);
          setScoreBullet(Math.min(current_question[6], 2));
          console.log("a_red_ids");
          console.log(a_red_ids);
          console.log("a_orange_ids");
          console.log(a_orange_ids);
          console.log("a_green_ids");
          console.log(a_green_ids);
          console.log("a_super_green_ids");
          console.log(a_super_green_ids);
          console.log("a_cons_red_ids");
          console.log(a_cons_red_ids);
          console.log("a_cons_orange_ids");
          console.log(a_cons_orange_ids);
          console.log("a_cons_green_ids");
          console.log(a_cons_green_ids);
          console.log("a_left_red");
          console.log(a_left_red);
          console.log("a_left_orange");
          console.log(a_left_orange);
        }
      }
    }
  }

  const processAnswer = (is_logged, mode) => {
    console.log(realAnswer == formAnswer);
    console.log(realAnswer);
    console.log(formAnswer);
    let pross_realAnswer = realAnswer;
    let is_correct = true;
    
    pross_realAnswer = pross_realAnswer.replace(/œ/g, "oe");
    pross_realAnswer = pross_realAnswer.replace(/\t/g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/ $/g, "");
    pross_realAnswer = pross_realAnswer.replace(/^ /g, "");
    pross_realAnswer = pross_realAnswer.replace(/, /g, ",");

    pross_realAnswer = pross_realAnswer.toLowerCase();
    let pross_formAnswer = formAnswer;
    pross_formAnswer = pross_formAnswer.replace(/œ/g, "oe");
    pross_formAnswer = pross_formAnswer.replace(/\t/g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/ $/g, "");
    pross_formAnswer = pross_formAnswer.replace(/^ /g, "");
    pross_formAnswer = pross_formAnswer.replace(/, /g, ",");
    pross_formAnswer = pross_formAnswer.toLowerCase();

    let a_split_re = pross_realAnswer.split(",");
    let a_split_fo = pross_formAnswer.split(",");
    console.log(pross_realAnswer);
    console.log(pross_formAnswer);
    console.log(a_split_re);
    console.log(a_split_fo);

    a_split_fo.map((v) => {
      console.log(v);
      console.log(a_split_fo.indexOf(v));
      if (a_split_re.indexOf(v) == -1) {
        console.log("what ??");
        is_correct = false;
      }
    });

    setScore(computeScore(is_correct));
    console.log(is_logged);
    if (is_logged == "1") {
      console.log(mode);
      if (mode == "u") {
        setScoreDB(current_question[0], Math.min(current_question[6], 2));
      }
      if (mode == "a") {
        setAScoreDB(current_question[0], Math.min(current_question[6], 2));
      }
    }
    pickAQuestion();
    if (typeof current_question == "undefined") {
      window.location.reload();
    }
    console.log(current_question);
    setStatement(current_question[3]);
    setScoreBullet(Math.min(current_question[6], 2));
    setMedia(current_question[8]);
    if (mode == "u") {
      setMedia800(current_question[9]);
    }
    if (mode == "a") {
      setMedia800(current_question[10]);
    }
    setRealAnswer(current_question[4]);
    setCurrentId(current_question[0]);
    inputAnswer.current.value = "";
    setFormAnswer("");
    fillConsQuestions();
    setGreenScore(a_green_ids.length + a_super_green_ids.length);
    setOrangeScore(a_orange_ids.length);
    setRedScore(a_red_ids.length);
    console.log("a_red_ids");
    console.log(a_red_ids);
    console.log("a_orange_ids");
    console.log(a_orange_ids);
    console.log("a_green_ids");
    console.log(a_green_ids);
    console.log("a_super_green_ids");
    console.log(a_super_green_ids);
    console.log("a_cons_red_ids");
    console.log(a_cons_red_ids);
    console.log("a_cons_orange_ids");
    console.log(a_cons_orange_ids);
    console.log("a_cons_green_ids");
    console.log(a_cons_green_ids);
    console.log("a_left_red");
    console.log(a_left_red);
    console.log("a_left_orange");
    console.log(a_left_orange);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (h_global_tuto_step["tuto_step"] == 9) {
      manageTutoStep(10);
    }
    if (h_global_tuto_step["tuto_step"] == 11) {
      manageTutoStep(12);
    }
    let pross_realAnswer = realAnswer;
    let is_correct = true;

    pross_realAnswer = pross_realAnswer.replace(/œ/g, "oe");
    pross_realAnswer = pross_realAnswer.replace(/\t/g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/ $/g, "");
    pross_realAnswer = pross_realAnswer.replace(/^ /g, "");
    pross_realAnswer = pross_realAnswer.replace(/, /g, ",");

    pross_realAnswer = pross_realAnswer.toLowerCase();

    let pross_formAnswer = formAnswer;
    pross_formAnswer = pross_formAnswer.replace(/œ/g, "oe");
    pross_formAnswer = pross_formAnswer.replace(/\t/g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/ $/g, "");
    pross_formAnswer = pross_formAnswer.replace(/^ /g, "");
    pross_formAnswer = pross_formAnswer.replace(/, /g, ",");

    pross_formAnswer = pross_formAnswer.toLowerCase();

    let a_split_re = pross_realAnswer.split(",");
    let a_split_fo = pross_formAnswer.split(",");

    console.log(a_split_re);
    console.log(a_split_fo);

    a_split_fo.map((v) => {
      if (a_split_re.indexOf(v) == -1) {
        is_correct = false;
      }
    });
    if (is_correct == false) {
      setTempRealAnswer(realAnswer);
      setIsOops(true);
    } else {
      processAnswer(is_logged, mode);
    }
  };

  return (
    <Paper
      style={
        typeof h_global_tuto_step != "undefined" &&
        h_global_tuto_step["tuto_step"] >= 8 &&
        h_global_tuto_step["tuto_step"] < 12
          ? isPhoneV
            ? {
                margin: "1em",
                width: "100%",
                paddingBottom: "1em",
                zIndex: 505,
              }
            : {
                margin: "1em",
                minWidth: "746px",
                paddingBottom: "1em",
                zIndex: 505,
              }
          : isPhoneV
          ? {
              margin: "1em",
              width: mode == "p" || mode == "v" ? "auto" : "100%",
              paddingBottom: "1em",
            }
          : {
              margin: "1em",
              minWidth: "746px",
              paddingBottom: "1em",
            }
      }
      elevation={3}
    >
      {is1100 && !isPhoneV && (mode == "p" || mode == "v") && (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            height: "1px",
          }}
        >
          <div style={{ transform: "scale(1.6)", margin: "1em" }}>
            <IconButton color="secondary" onClick={() => saveFunction()}>
              <SaveIcon></SaveIcon>
            </IconButton>
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ width: "100%" }}>
          <div
            style={
              isPhoneV
                ? {}
                : { display: "flex", justifyContent: "space-around" }
            }
          >
            <div
              style={
                mode == "p" || mode == "v"
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isPhoneV ? "space-between" : "flex-start",
                    }
                  : {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isPhoneV ? "center" : "flex-start",
                    }
              }
            >
              {isPhoneV && (mode == "p" || mode == "v") && (
                <div style={{ width: "96px" }}></div>
              )}
              {typeof scoreBullet != "undefined" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: isPhoneV ? "1em" : 0,
                    transform: "scale(2)",
                  }}
                >
                  <ScoreBullet score={scoreBullet}></ScoreBullet>
                </div>
              )}
              {isPhoneV && (mode == "p" || mode == "v") && (
                <div>
                  <IconButton
                    style={{ transform: "scale(1.6)", margin: "1em" }}
                    color="secondary"
                    onClick={() => saveFunction()}
                  >
                    <SaveIcon></SaveIcon>
                  </IconButton>
                </div>
              )}
            </div>
            <div
              style={
                isPhoneV
                  ? {
                      padding: "1em",
                      textAlign: "center",
                      wordWrap: "break-word",
                    }
                  : {
                      width: "300px",
                      padding: "1em",
                      textAlign: "center",
                      wordWrap: "break-word",
                    }
              }
            >
              <div
                style={{
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  {media != "0" && (
                    <>
                      {statement.length < 14 && (
                        <div
                          style={{
                            height: "100px",
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "xx-large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                      {statement.length >= 14 && statement.length <= 62 && (
                        <div
                          style={{
                            height: "100px",
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "x-large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                      {statement.length > 62 && (
                        <div
                          style={{
                            height: "100px",
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                    </>
                  )}
                  {media == "0" && (
                    <>
                      {statement.length < 14 && (
                        <div
                          style={{
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "xxx-large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                      {statement.length >= 14 && statement.length < 50 && (
                        <div
                          style={{
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "xx-large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                      {statement.length >= 50 && (
                        <div
                          style={{
                            overflow: "hidden",
                            marginBottom: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /* wordBreak: "break-all", */
                            overflow: "auto",
                            fontSize: "x-large",
                          }}
                        >
                          <br></br>
                          {statement}
                        </div>
                      )}
                    </>
                  )}
                  {media != "0" && (
                    <>
                      <img
                        src={media}
                        style={{
                          cursor: "pointer",
                          maxHeight: "200px",
                          maxWidth: "150px",
                          minHeight: "120px",
                          minWidth: "120px",
                        }}
                        onClick={() => {
                          setOpenImgDial(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {!isPhoneV && (
              <div
                style={{
                  width: "200px",
                  padding: "1em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <form
                  id="id_answer_form"
                  onSubmit={(event) => {
                    formSubmit(event);
                  }}
                >
                  <TextField
                    id="id_train_ans"
                    label={global.lang == "en" ? "Answer" : "Réponse"}
                    variant="outlined"
                    ref={inputAnswer}
                    onChange={(event) => {
                      setFormAnswer(event.target.value);
                      console.log(event.target.value);
                      if (h_global_tuto_step["tuto_step"] == 8) {
                        manageTutoStep(9);
                      }
                      if (h_global_tuto_step["tuto_step"] == 10) {
                        manageTutoStep(11);
                      }
                    }}
                    value={formAnswer}
                    autoFocus={is_focused_inside}
                    inputProps={{
                      autoCapitalize: "none",
                    }}
                    style={{ fontWeight: 400 }}
                  />
                </form>
              </div>
            )}
            {isPhoneV && <div></div>}
          </div>

          {isPhoneV && (
            <div
              style={{
                padding: "1em",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <form
                id="id_answer_form"
                onSubmit={(event) => {
                  formSubmit(event);
                }}
              >
                <TextField
                  id="id_train_ans"
                  label={global.lang == "en" ? "Answer" : "Réponse"}
                  variant="outlined"
                  ref={inputAnswer}
                  onChange={(event) => {
                    setFormAnswer(event.target.value);
                    console.log(event.target.value);
                    if (h_global_tuto_step["tuto_step"] == 8) {
                      manageTutoStep(9);
                    }
                    if (h_global_tuto_step["tuto_step"] == 10) {
                      manageTutoStep(11);
                    }
                  }}
                  value={formAnswer}
                  autoFocus={is_focused_inside}
                  style={{ fontWeight: 400 }}
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                />
              </form>
            </div>
          )}
          <Divider></Divider>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "1em",
            }}
          >
            <Button
              id="id_train_val"
              style={{ width: "140px", marginBottom: "1em" }}
              color="primary"
              variant="contained"
              onClick={() => {
                if (realAnswer != formAnswer) {
                  setTempRealAnswer(realAnswer);
                  setIsOops(true);
                } else {
                  processAnswer(is_logged, mode);
                }
                if (h_global_tuto_step["tuto_step"] == 9) {
                  manageTutoStep(10);
                }
                if (h_global_tuto_step["tuto_step"] == 11) {
                  manageTutoStep(12);
                }
                setIsFocusedInside(true);
              }}
            >
              ok
            </Button>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span
              style={{
                display: "flex",
                width: "140px",
                justifyContent: "space-around",
              }}
            >
              <SG score={green_score}></SG>
              <SO score={orange_score}></SO>
              <SR score={red_score}></SR>
            </span>
            {/* <ScoreChips
              green_score={green_score}
              orange_score={orange_score}
              red_score={red_score}
            ></ScoreChips> */}
          </div>
        </div>
      </div>
      <Dialog
        open={is_oops}
        onClose={() => {
          setIsOops(false);
          processAnswer(is_logged, mode);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        minWidth={"400px"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: 36 }}>{"OOPS "}&#x1F62C;</Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                {global.lang == "fr" ? "Mauvaise réponse" : "Wrong answer"}{" "}
                <del style={{ color: "red" }}>&nbsp;{formAnswer}&nbsp;</del>
              </Typography>

              <Typography style={{ fontSize: 24 }}>
                {global.lang == "fr" ? "Réponse correcte" : "Correct answer"}{" "}
                <font style={{ color: "green" }}>{temp_realAnswer}</font>
              </Typography>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOops(false);
              processAnswer(is_logged, mode);
            }}
            color="primary"
            autoFocus
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open_img_dial}
        onClose={() => {
          setOpenImgDial(false);
        }}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography style={{ fontSize: 24 }}>{statement}</Typography>
          </div>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2em",
          }}
        >
          <img src={media_800}></img>
        </div>
      </Dialog>
    </Paper>
  );
}
