export const signInGoogle = (e, callback_ok, callback_nok) => {
    console.log(e);
    console.log(e.getAuthResponse().id_token);
    const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
    fetch(server_url+"api/sign_in_jwt", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ credential: e.getAuthResponse().id_token }),
    })
      .then((res) => res.json())
      .then((res) => {
          console.log(res);
          if (res['signInJWT'] == "1"){
              callback_ok();
          }
          if (res['signInJWT'] == "0"){
            console.log(res['email'], res['pseudo'], res['picture']);
            callback_nok(res['email'], res['pseudo'], res['picture']);
          }
      });
}