import React, { useState, useEffect, useRef } from "react";
import { setPublicDB } from "../Generic/lists";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  addListToPageDB,
  removeListFromPageDB,
  setPrevVersionDB,
  publishPageDB,
  unpublishPageDB,
  setPageDescrDB,
  insertPageFileDB,
  deletePageFileDB,
} from "../Generic/lists";
import { addLinkDB, removeLinkDB } from "../Generic/lists";
import CHeader from "../MainComponents/CHeader";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LinkIcon from "@material-ui/icons/Link";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import axios from "axios";
import MMenu from "../MonoPages/MMenu";
import { Typography } from "@material-ui/core";
import { unixToShortHuman, unixToDelay } from "../Generic/generic";
import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GroupIcon from "@material-ui/icons/Group";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

export default function BPage({
  id_user,
  id_page,
  url_pseudo,
  url_name,
  pseudo,
  name,
}) {
  const [a_lists, setLists] = useState([]);
  const [a_a_lists, setALists] = useState([]);

  const [a_links, setLinks] = useState([]);
  const [a_page_lists, setPageLists] = useState([-1]);
  const [menuStatus, setMenuStatus] = useState("1");
  const [page_meta, setPageMeta] = useState([]);
  const [files_meta, setFilesMeta] = useState([]);
  const [is_edit_descr, setIsEditDescr] = useState(false);
  const [file, setFile] = useState("");
  const [temp_descr, setTempDescr] = useState("");

  const setToUpdate = () => {
    let a_a = [...page_meta];
    a_a[8] = 0;
    setPageMeta(a_a);
  };

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";

  var public_url = server_url + "c/" + url_pseudo + "/" + url_name;
  var preview_url = server_url + "f/" + url_pseudo + "/" + url_name;

  useEffect(() => {
    fetch("/api/get_lists")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLists(data);
      });
    fetch(server_url + "/api/get_a_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setALists(res);
      });

    const data = { id_page: id_page };
    console.log(data);
    fetch("/api/get_page_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setPageMeta(res);
      });

    fetch("/api/get_page_files_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_page: id_page }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setFilesMeta(res);
      });

    fetch("/api/get_page_lists", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        let a_a = [];
        res.forEach((e) => {
          a_a.push(e[0]);
        });
        setPageLists(a_a);
        console.log("get_page_lists");
        console.log(res);
        console.log(a_a);
      });

    fetch("/api/get_page_links", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLinks(res);
      });
  }, []);

  const addLink = (name, url, descr) => {
    addLinkDB(id_page, name, url, descr, a_links, setLinks);
  };
  const deleteLink = (id_link) => {
    removeLinkDB(id_page, id_link, a_links, setLinks);
  };
  const setDescr = () => {
    setPageDescrDB(id_page, temp_descr);
    let a_a = [...page_meta];
    a_a[6] = temp_descr;
    a_a[8] = 0;
    setPageMeta(a_a);
    setIsEditDescr(false);
  };
  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };

  const actionPageListDom = (id_list, do_add) => {
    if (do_add) {
      let clone_a_page_lists = [...a_page_lists];
      clone_a_page_lists.push(id_list);
      setPageLists(clone_a_page_lists);
    } else {
      let clone_a_page_lists = [];
      a_page_lists.forEach((e) => {
        if (e != id_list) {
          clone_a_page_lists.push(e);
        }
      });
      setPageLists(clone_a_page_lists);
    }
  };

  const publicPageListDom = (id_list, set_pub) => {
    let clone_a_page_lists = [];
    a_lists.forEach((value) => {
      if (value[0] == id_list) {
        if (set_pub) {
          value[5] = "0";
        } else {
          value[5] = "1";
        }
        clone_a_page_lists.push(value);
      } else {
        clone_a_page_lists.push(value);
      }
    });
    setLists(clone_a_page_lists);
  };

  const uploadFile = (file_rand_url, url_name, fileToUpload) => {
    fetch(server_url + "/api/page_file_put_presign_url", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        file_name: file_rand_url,
        url_name: url_name,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("uploadPic");
        console.log(JSON.stringify(res));
        axios({
          method: "PUT",
          url: res["presigned_url"],
          data: fileToUpload,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => {
            console.log("succeessss file upload");
            let s3_name = url_pseudo + "/" + url_name + "/" + file_rand_url;
            insertPageFileDB(id_page, fileToUpload.name, s3_name);
            setToUpdate();
            fetch("/api/get_page_files_meta", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
              body: JSON.stringify({ id_page: id_page }),
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                setFilesMeta(res);
              });
          })
          .catch((err) => {
            console.log(
              "oh no something wen't wrong in the file uploading process"
            );
          });
      });
  };

  const deleteFile = (s3_name) => {
    let a_files_copy = [];
    files_meta.map((value, index) => {
      if (value[1] != s3_name) {
        a_files_copy.push(value);
      }
    });
    setFilesMeta(a_files_copy);
    deletePageFileDB(id_page, s3_name);
    setToUpdate();
  };

  return (
    <>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={true}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      <div
        style={{
          display: "flex",
          backgroundColor: "rgb(246,248,250)",
        }}
      >
        {menuStatus == "1" && <MMenu></MMenu>}
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ padding: "1em", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "1300px",
              }}
            >
              <div style={{ width: "100%", padding: "1em" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    paddingTop: "1em",
                    paddingBottom: "2em",
                  }}
                >
                  <Typography variant="h3" color="textSecondary">
                    {page_meta[2]}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h4"
                    style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                  >
                    Description
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      color="primary"
                      aria-label={global.lang == "en" ? "edit" : "modifier"}
                      onClick={() => setIsEditDescr(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
                {is_edit_descr && (
                  <div
                    style={{
                      paddingLeft: "1em",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      rowsMin={10}
                      multiline
                      defaultValue={page_meta[6]}
                      onChange={(event) => setTempDescr(event.target.value)}
                      style={{ margin: "1em" }}
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDescr()}
                      style={{ margin: "1em" }}
                    >
                      {global.lang == "en" ? "save" : "sauvegarder"}
                    </Button>
                  </div>
                )}
                {!is_edit_descr && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{ whiteSpace: "pre-line", paddingLeft: "2em" }}
                  >
                    {page_meta[6]}
                  </Typography>
                )}
                <br></br>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ minWidth: "350px" }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: "gray",
                        fontWeight: "300",
                        padding: "1em",
                      }}
                    >
                      {global.lang == "en" ? "Files" : "Pièces jointes"}
                    </Typography>
                    <div style={{ paddingLeft: "2em" }}>
                      <ul>
                        {files_meta.map((value, index) => {
                          return (
                            <li>
                              <Link target="_blank" href={value[5]}>
                                {value[2]}
                              </Link>{" "}
                              <IconButton
                                aria-label={
                                  global.lang == "en"
                                    ? "delete file"
                                    : "supprimer le fichier"
                                }
                                style={{
                                  marginLeft: "2em",
                                  transform: "scale(0.7)",
                                }}
                              >
                                <DeleteIcon
                                  onClick={() => {
                                    deleteFile(value[1]);
                                  }}
                                />
                              </IconButton>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<AttachFileIcon />}
                        color="primary"
                        style={{ margin: "0.5em" }}
                        onClick={() => browseFiles()}
                      >
                        {global.lang == "en"
                          ? "add a file"
                          : "ajouter un fichier"}
                      </Button>
                    </div>
                  </div>
                  <div style={{ minWidth: "350px" }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: "gray",
                        fontWeight: "300",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                      }}
                    >
                      {global.lang == "en"
                        ? "External links"
                        : "Liens externes"}
                    </Typography>

                    <ul>
                      {a_links.map((value, index) => {
                        return (
                          <li>
                            <OneLink
                              id_link={value[0]}
                              name={value[2]}
                              url={value[3]}
                              descr={value[5]}
                              deleteLink={(e) => deleteLink(e)}
                              setToUpdate={() => setToUpdate()}
                            ></OneLink>
                          </li>
                        );
                      })}
                    </ul>

                    <AddLink
                      AddLink={(a, b, c) => addLink(a, b, c)}
                      setToUpdate={() => setToUpdate()}
                    ></AddLink>
                  </div>
                </div>
              </div>

              <div>
                <Paper
                  style={{
                    padding: "1em",
                    paddingTop: "2em",
                    maxWidth: "300px",
                  }}
                >
                  {(page_meta[8] == 0 || page_meta[5] == 0) && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginBottom: "1em" }}
                        >
                          <Link target="_blank" href={preview_url}>
                            {global.lang == "en" ? "preview" : "prévisualiser"}
                          </Link>
                        </Typography>
                      </div>

                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          publishPageDB(id_page, true);
                        }}
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        {global.lang == "en" ? "Publish" : "Publier"}
                      </Button>
                    </div>
                  )}
                  {page_meta[8] == 0 && page_meta[5] == 1 && (
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setPrevVersionDB(id_page, true);
                        }}
                        fullWidth
                        style={{ marginTop: "1em" }}
                      >
                        {global.lang == "en"
                          ? "back to previous version"
                          : "revenir à la version précédente"}
                      </Button>
                    </div>
                  )}
                  {page_meta[8] == 1 && page_meta[5] == 1 && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {global.lang == "en" ? "preview" : "prévisualiser"}
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled
                        fullWidth
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        {global.lang == "en" ? "Publish" : "Publier"}
                      </Button>

                      <Button
                        variant="contained"
                        disabled
                        fullWidth
                        style={{ marginTop: "1em" }}
                      >
                        {global.lang == "en"
                          ? "back to previous version"
                          : "revenir à la version précédente"}
                      </Button>
                    </div>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ marginTop: "1em" }}
                    onClick={() => {
                      unpublishPageDB(id_page, true);
                    }}
                  >
                    {global.lang == "en" ? "unpublish" : "dépublier"}
                  </Button>

                  <div style={{ marginTop: "2em" }}>
                    <div>
                      {page_meta[5] == 1 && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Typography variant="h5" color="textSecondary" style={{marginBottom:"1em",marginTop:"1em"}}>
                              {global.lang == "en" ? "your " : "votre "}
                              <Link target="_blank" href={public_url}>
                                page
                              </Link>{" "}
                              {global.lang == "en"
                                ? " is public"
                                : " est publique"}
                            </Typography>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              marginLeft: "1em",
                            }}
                          >
                            <TextField
                              id="id_public_url"
                              value={public_url}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LinkIcon />
                                  </InputAdornment>
                                ),
                              }}
                              style={{ width: "300px" }}
                            />
                            <IconButton
                              aria-label={
                                global.lang == "en"
                                  ? "copy to clipboard"
                                  : "copier dans le clipboard"
                              }
                              onClick={() => copyURL()}
                              style={{ transform: "rotate(90deg)" }}
                            >
                              <FilterNoneIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              </div>
            </div>

            <div>
              <Typography
                variant="h4"
                style={{ color: "gray", fontWeight: "300", padding: "1em" }}
              >
                {global.lang == "en"
                  ? "Select the lists"
                  : "Selection des listes"}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  maxWidth: "1300px",
                }}
              >
                <div style={{ width: "450px" }}>
                  <ul>
                    {a_lists.map((value, index) => {
                      if (
                        a_page_lists.indexOf(-1) == -1 &&
                        a_page_lists.indexOf(value[0]) != -1
                      ) {
                        const link_url = server_url+"u/"+url_pseudo+"/"+value[9];
                        console.log("a_lists_loop");
                        console.log(value[0]);
                        console.log(a_page_lists);
                        console.log(a_page_lists.indexOf(value[0]));
                        return (
                          <OneList
                            id_list={value[0]}
                            id_page={id_page}
                            title={value[2]}
                            descr={value[8]}
                            isPublic={value[5] != "1"}
                            setPublic={(isPublic, id_list) =>
                              setPublicDB(isPublic, id_list)
                            }
                            link_url={link_url}
                            is_owner={value[10] == 1}
                            is_in_page={a_page_lists.indexOf(value[0]) != -1}
                            setToUpdate={() => setToUpdate()}
                            crea_date={value[3]}
                            views={value[17]}
                            followers={value[18]}
                            type={"mine"}
                            actionPageListDom={actionPageListDom}
                          ></OneList>
                        );
                      }
                    })}
                  </ul>

                  <ul style={{ marginTop: "2em" }}>
                    {a_a_lists.map((value, index) => {
                      if (
                        a_page_lists.indexOf(-1) == -1 &&
                        a_page_lists.indexOf(value[0]) != -1
                      ) {
                        const link_url = server_url+"a/"+value[22]+"/"+value[9];
                        console.log("a_lists_loop");
                        console.log(value);
                        return (
                          <OneList
                            id_list={value[0]}
                            id_page={id_page}
                            title={value[2]}
                            descr={value[8]}
                            isPublic={value[5] != "1"}
                            setPublic={(isPublic, id_list) =>
                              setPublicDB(isPublic, id_list)
                            }
                            link_url={link_url}
                            is_owner={value[10] == 1}
                            is_in_page={a_page_lists.indexOf(value[0]) != -1}
                            setToUpdate={() => setToUpdate()}
                            crea_date={value[3]}
                            views={value[17]}
                            followers={value[18]}
                            type={"following"}
                            actionPageListDom={actionPageListDom}
                          ></OneList>
                        );
                      }
                    })}
                  </ul>
                </div>

                <div>
                  <ul>
                    {a_lists.map((value, index) => {
                      if (
                        a_page_lists.indexOf(-1) == -1 &&
                        a_page_lists.indexOf(value[0]) == -1
                      ) {
                        const link_url = server_url+"u/"+url_pseudo+"/"+value[9];
                        console.log("a_lists_loop");
                        console.log(value[0]);
                        console.log(a_page_lists);
                        console.log(a_page_lists.indexOf(value[0]));
                        return (
                          <OneList
                            id_list={value[0]}
                            id_page={id_page}
                            title={value[2]}
                            descr={value[8]}
                            isPublic={value[5] != "1"}
                            setPublic={(isPublic, id_list) =>
                              setPublicDB(isPublic, id_list)
                            }
                            link_url={link_url}
                            is_owner={value[10] == 1}
                            is_in_page={a_page_lists.indexOf(value[0]) != -1}
                            setToUpdate={() => setToUpdate()}
                            crea_date={value[3]}
                            views={value[17]}
                            followers={value[18]}
                            type={"mine"}
                            actionPageListDom={actionPageListDom}
                            publicPageListDom={publicPageListDom}
                          ></OneList>
                        );
                      }
                    })}
                  </ul>

                  <ul style={{ marginTop: "2em" }}>
                    {a_a_lists.map((value, index) => {
                      if (
                        a_page_lists.indexOf(-1) == -1 &&
                        a_page_lists.indexOf(value[0]) == -1
                      ) {
                        const link_url = server_url+"a/"+value[22]+"/"+value[9];
                        console.log(value);
                        return (
                          <OneList
                            id_list={value[0]}
                            id_page={id_page}
                            title={value[2]}
                            descr={value[8]}
                            isPublic={value[5] != "1"}
                            setPublic={(isPublic, id_list) =>
                              setPublicDB(isPublic, id_list)
                            }
                            link_url={link_url}
                            is_owner={value[10] == 1}
                            is_in_page={a_page_lists.indexOf(value[0]) != -1}
                            setToUpdate={() => setToUpdate()}
                            crea_date={value[3]}
                            views={value[17]}
                            followers={value[18]}
                            type={"following"}
                            actionPageListDom={actionPageListDom}
                          ></OneList>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <input
        id="id_hidden_file_browser"
        type="file"
        onChange={(e) => {
          console.log(e.target.files[0]);
          let s3_name = Math.random() + "";
          setFile({
            fileToUpload: e.target.files[0],
            s3_name: s3_name,
          });
          uploadFile(s3_name, url_name, e.target.files[0]);
        }}
        style={{ display: "none" }}
      ></input>
    </>
  );
}

function OneLink({ id_link, name, url, descr, deleteLink, setToUpdate }) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link target="_blank" href={url}>
          {name}
        </Link>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label={global.lang == "en" ? "delete link" : "supprimer le lien"}
          style={{ marginLeft: "2em", transform: "scale(0.7)" }}
        >
          <DeleteIcon
            onClick={() => {
              deleteLink(id_link);
              setToUpdate();
            }}
          />
        </IconButton>
      </div>
    </div>
  );
}

function AddLink({ AddLink, setToUpdate }) {
  const [name, setName] = useState();
  const [link, setLink] = useState();

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          AddLink(name, link, "");
          setName("");
          setLink("");
          setToUpdate();
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            onChange={(event) => setName(event.target.value)}
            placeholder={
              global.lang == "en" ? "Name of the link" : "Nom du lien"
            }
          />
          <TextField
            onChange={(event) => setLink(event.target.value)}
            placeholder={
              global.lang == "en"
                ? "https://my_link.com"
                : "https://mon_lien.com"
            }
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "0.5em" }}
            type="submit"
          >
            {global.lang == "en" ? "add" : "ajouter"}
          </Button>
        </div>
      </form>
    </>
  );
}

function OneList({
  id_list,
  id_page,
  title,
  descr,
  isPublic,
  setPublic,
  link_url,
  is_owner,
  is_in_page,
  setToUpdate,
  crea_date,
  views,
  followers,
  type = "mine",
  actionPageListDom,
  publicPageListDom,
}) {
  const [isPublicIn, setIsPublicIn] = useState(isPublic);
  const [is_in_page_state, setIsInPage] = useState(is_in_page);
  const [open_priv_dial, setOpenPrivDial] = useState(false);
  const [open_pub_dial, setOpenPubDial] = useState(false);
  const [open_pub_issue_dial, setOpenPubIssueDial] = useState(false);

  return (
    <Paper style={{ width: "450px", padding: "1em", margin: "0.5em" }}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Link target="_blank" href={link_url}>
              {title}
            </Link>
            <div>
              {type == "mine" && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "0.2em",
                    color: "rgb(156,158,160)",
                  }}
                >
                  <EventIcon fontSize="small"></EventIcon>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {global.lang == "en" && "Created "}
                    {global.lang == "fr" && "Créée le "}
                    {unixToShortHuman(crea_date)}
                  </div>
                  {isPublicIn && (
                    <div style={{ display: "flex" }}>
                      <div style={{ paddingLeft: "0.6em" }}>
                        <VisibilityIcon fontSize="small"></VisibilityIcon>
                      </div>
                      <div
                        style={{
                          paddingLeft: "0.4em",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        {" "}
                        {views}
                      </div>
                      <div style={{ paddingLeft: "0.6em" }}>
                        <GroupIcon fontSize="small"></GroupIcon>
                      </div>
                      <div
                        style={{
                          paddingLeft: "0.4em",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        {" "}
                        {followers}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {type == "following" && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "0.2em",
                    color: "rgb(156,158,160)",
                  }}
                >
                  <EventIcon fontSize="small"></EventIcon>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {global.lang == "en" && "Followed since "}
                    {global.lang == "fr" && "Suivie depuis "}
                    {unixToShortHuman(crea_date)}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            {!isPublicIn && is_owner == 1 && (
              <>
                <IconButton
                  color="secondary"
                  aria-label={
                    global.lang == "en" ? "set private" : "rendre privé"
                  }
                >
                  <LockIcon />
                </IconButton>
                <IconButton
                  aria-label={
                    global.lang == "en" ? "set public" : "rendre public"
                  }
                  onClick={() => {
                    setOpenPubDial(true);
                    /* setIsPublicIn(true);
                    setPublic(true, id_list); */
                  }}
                >
                  <PublicIcon />
                </IconButton>
              </>
            )}

            {isPublicIn && is_owner == 1 && (
              <>
                <IconButton
                  aria-label={
                    global.lang == "en" ? "set private" : "rendre privé"
                  }
                  onClick={() => {
                    if (!is_in_page_state){
                    setOpenPrivDial(true);
                    }
                    /* setIsPublicIn(false);
                    setPublic(false, id_list); */
                  }}
                >
                  <LockIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label={
                    global.lang == "en" ? "set public" : "rendre public"
                  }
                >
                  <PublicIcon />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="textSecondary">
            {descr}
          </Typography>
        </div>
        <div>
          {isPublicIn && !is_in_page_state && (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "0.5em", width: "160px" }}
                onClick={() => {
                  addListToPageDB(id_page, id_list);
                  /* setIsInPage(true); */
                  actionPageListDom(id_list, true);
                  setToUpdate();
                }}
              >
                {global.lang == "en" ? "add to the page" : "ajouter à la page"}
              </Button>
            </>
          )}
          {!isPublicIn && !is_in_page_state && (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "0.5em", width: "160px" }}
                onClick={() => setOpenPubIssueDial(true)}
              >
                {global.lang == "en" ? "add to the page" : "ajouter à la page"}
              </Button>
            </>
          )}
          {is_in_page_state && (
            <>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "0.5em", width: "160px" }}
                onClick={() => {
                  removeListFromPageDB(id_page, id_list);
                  /* setIsInPage(false); */
                  actionPageListDom(id_list, false);
                  setToUpdate();
                }}
              >
                {global.lang == "en"
                  ? "remove from the page"
                  : "retirer de la page"}
              </Button>
            </>
          )}
        </div>
      </div>

      <Dialog
        open={open_pub_issue_dial}
        onClose={() => setOpenPubIssueDial(false)}
      >
        <DialogTitle>
          {global.lang == "en" &&
            "Your list have to be public to add it on the page."}
          {global.lang == "fr" &&
            "Votre liste doit être publique pour l'ajouter à une page."}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPubIssueDial(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsPublicIn(true);
              setPublic(true, id_list);
              addListToPageDB(id_page, id_list);
              /* setIsInPage(true); */
              publicPageListDom(id_list, true);
              actionPageListDom(id_list, true);
              setToUpdate();
              setOpenPubIssueDial(false);
            }}
            color="primary"
            style={{ maxWidth: "200px" }}
          >
            {global.lang == "en" && "set list public and add it to the page"}
            {global.lang == "fr" &&
              "rendre la liste publique et l'ajouter à la page"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenPubIssueDial(false);
            }}
            color="secondary"
          >
            {global.lang == "en" && "do nothing"}
            {global.lang == "fr" && "ne rien faire"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open_pub_dial}
        onClose={() => setOpenPubDial(false)}
        aria-labelledby="pub-dialog-title"
      >
        <DialogTitle id="pub-dialog-title">
          {global.lang == "en" && "Set public access to your list?"}
          {global.lang == "fr" && "Rendre votre liste publique?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPubDial(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsPublicIn(true);
              setPublic(true, id_list);
              publicPageListDom(id_list,true);
              setOpenPubDial(false);
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "ok"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open_priv_dial}
        onClose={() => setOpenPrivDial(false)}
        aria-labelledby="pub-dialog-title"
      >
        <DialogTitle id="pub-dialog-title">
          {global.lang == "en" && "Public won't have access to your list"}
          {global.lang == "fr" && "Le public n'aura plus accès à la liste"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPrivDial(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsPublicIn(false);
              setPublic(false, id_list);
              publicPageListDom(id_list,false);
              setOpenPrivDial(false);
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
