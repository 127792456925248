import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import ImageIcon from "@material-ui/icons/Image";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleLogin } from "react-google-login";
import GoogleIcon from "@mui/icons-material/Google";
import { signInGoogle } from "../Generic/google_signin";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { setReduxDefaultDisplay } from "../redux/globalDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(3),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

export default function HomeNoSession() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            justify="center"
            alignItems="center"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "1em",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "im/logo_A.svg"}
                /*  style={{ borderRadius: "100px", border: "1px gray dashed" }} */
                style={{ width: "300px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingBottom: "1em",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                alpha version
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            justify="center"
            alignItems="center"
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <SignIn />
            </div>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            justify="center"
            alignItems="center"
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <SignUp id="id_signup" />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export function SignIn({ callbackType = "reload", callback, propose_other }) {
  const [passwordIn, setPasswordIn] = useState("");
  const [emailIn, setEmailIn] = useState("");
  const [isPC, setIsPC] = useState(
    useMediaQuery({
      query: "(min-width: 700px)",
    })
  );

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  const doLog = () => {
    console.log("doLog");
    const data = { email: emailIn, password: passwordIn };
    fetch(server_url + "/api/auth_user", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res["count_record"] == 1) {
          if (callbackType == "home") {
            window.location.href = server_url;
          }
          if (callbackType == "reload") {
            if (window.location.href.split("#").length >= 2 ){
              if (window.location.href.split("#")[1] == "id_landingpage_main"){
                window.location.href = window.location.href.split("#")[0]; 
              } else {
                window.location.reload();
              }
            } else {
              window.location.reload();
            }
          }
          if (callbackType == "savelist") {
            callback();
          }
          if (callbackType == "follow") {
            console.log(callbackType);
            callback();
            console.log(callbackType);
          }
        }
      });
  };


  // To handle in production
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const handleGoogleButtonSignUp = (e) => {
    signInGoogle(
      e,
      () => window.location.reload(),
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };
  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Paper className={classes.paper} style={{ width: "300px", padding: "2em" }}>
      <Typography
        variant="h3"
        color="textPrimary"
        style={{ marginBottom: "1.5em" }}
      >
        {global.lang == "en" ? "Sign in" : "Se connecter"}
      </Typography>
      
       <GoogleLogin
            clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
            onSuccess={(e) => handleGoogleButtonSignUp(e)}
            onFailure={() => {
              alert(
                "Problem with the sign in method, please choose another one"
              );
            }}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                style={
                  isPC
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "0.4em",
                        marginRight: "0.4em",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "1.5em",
                        marginRight: "1.5em",
                      }
                }
                startIcon={<GoogleIcon />}
                endIcon={<></>}
                variant="outlined"
              >
                {global.lang == "fr"
                  ? "Se connecter avec Google"
                  : "Login with Google"}
              </Button>
            )}
            cookiePolicy={"single_host_origin"}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "2em",
              marginBottom: "1.7em",
            }}
          >
            <div
              style={{ height: "1px", width: "100px" }}
            >
              <hr></hr>
            </div>
            {global.lang == "fr" ? "ou" : "or"}
            <div
              style={{ height: "1px", width: "100px" }}
            >
              <hr></hr>
            </div>
          </div>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          doLog();
        }}
      >
        <TextField
          required
          id="id_log_email"
          label="Email"
          type="email"
          variant="filled"
          placeholder="xxx@yyy.com"
          onChange={(event) => setEmailIn(event.target.value)}
        />
        <TextField
          required
          id="id_log_pw"
          label={global.lang == "en" ? "Password" : "Mot de passe"}
          variant="filled"
          type="password"
          placeholder="******"
          onChange={(event) => setPasswordIn(event.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          style={{ marginTop: "2em" }}
        >
          {global.lang == "en" ? "Sign in" : "Se connecter"}
        </Button>
      </form>
      {propose_other && (
        <Link
          onClick={() =>
            dispatch(setReduxDefaultDisplay({ default_display: "signup" }))
          }
          style={{ marginTop: "1.5em", cursor: "pointer" }}
        >
          {global.lang == "fr"
            ? "Première visite? Créez un compte!"
            : "First visit? Create an account!"}
        </Link>
      )}
    </Paper>
  );
}

export function SignUp({ callbackType = "reload", callback }) {
  const [password1Up, setPassword1Up] = useState("");
  const [password2Up, setPassword2Up] = useState("");
  const [emailUp, setEmailUp] = useState("");
  const [pseudoUp, setPseudoUp] = useState("");
  const [pic_url, setPicUrl] = useState("");
  const [pic_number, setPicNumber] = useState(-1);
  const [image_file, setImageFile] = useState("");
  const [captcha_url, setCaptchaUrl] = useState("");
  const [cap_value, setCapValue] = useState("");

  const [is_pass_error, setIsPassError] = useState(false);
  const [is_email_error, setIsEmailError] = useState(false);
  const [is_captcha_error, setIsCaptchaError] = useState(false);

  const [email_exists, setEmailExists] = useState(false);
  const [pseudo_exists, setPseudoExists] = useState(false);

  const [cookies, setCookie] = useCookies(["tuto2"]);

  const [isPC, setIsPC] = useState(
    useMediaQuery({
      query: "(min-width: 700px)",
    })
  );

  const classes = useStyles();

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const randProfilePic = () => {
    fetch(server_url + "/api/get_rand_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPicNumber(res["rand_int"]);
        setPicUrl(res["url"]);
        console.log("randProfilePic " + res["rand_int"] + " " + res["url"]);
      });
    setImageFile("");
  };

  const randCaptcha = () => {
    fetch(server_url + "/api/get_captcha")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCaptchaUrl(res["url"]);
      });
  };

  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    randProfilePic();
    randCaptcha();
  }, []);

  const doReg = () => {
    var file_name = "";
    if (pic_number == -1) {
      file_name = image_file.fileToUpload.name;
    }

    if (password1Up != password2Up) {
      setIsPassError(true);
      setPassword1Up("");
      setPassword2Up("");
    }

    if (!isValidEmail(emailUp)) {
      setIsEmailError(true);
    }

    const data = { email: emailUp, pseudo: pseudoUp };
    console.log(data);

    fetch(server_url + "/api/email_pseudo_available", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res["count_email"] + "" == "1") {
          setEmailExists(true);
        }
        if (res["count_pseudo"] + "" == "1") {
          setPseudoExists(true);
        }

        const data_cap = { cap_value: cap_value };
        console.log(data);
        fetch(server_url + "/api/captcha_ok", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data_cap),
        })
          .then((res2) => res2.json())
          .then((res2) => {
            console.log(res2);
            if (res2["captcha_ok"] + "" == "0") {
              setIsCaptchaError(true);
              randCaptcha();
            }

            if (
              res2["captcha_ok"] + "" == "1" &&
              res["count_pseudo"] + "" == "0" &&
              res["count_email"] + "" == "0" &&
              password1Up == password2Up &&
              isValidEmail(emailUp)
            ) {
              const data_reg = {
                email: emailUp,
                pseudo: pseudoUp,
                password: password1Up,
                pic_number: pic_number,
                file_name: file_name,
                cap_value: cap_value.toLowerCase(),
                lang: global.lang,
              };
              console.log(data_reg);

              fetch("/api/insert_user", {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(data_reg),
              })
                .then((res3) => res3.json())
                .then((res3) => {
                  console.log(res3);
                  if (pic_number == -1) {
                    axios({
                      method: "PUT",
                      url: res3["presigned_url"],
                      data: image_file.fileToUpload,
                      headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                      },
                    })
                      .then((res4) => {
                        console.log(res4);
                        const data_auth = {
                          email: emailUp,
                          password: password1Up,
                        };

                        fetch(server_url + "/api/auth_user", {
                          method: "POST",
                          headers: {
                            "Content-type": "application/json",
                          },
                          body: JSON.stringify(data_auth),
                        })
                          .then((res5) => res5.json())
                          .then((res5) => {
                            if (res5["count_record"] == 1) {
                              console.log(cookies);
                              setCookie("tuto2", "new_user", { path: "/" });
                              if (callbackType == "home") {
                                window.location.href = server_url;
                              }
                              if (callbackType == "reload") {
                                if (window.location.href.split("#").length >= 2 ){
                                  if (window.location.href.split("#")[1] == "id_landingpage_main"){
                                    window.location.href = window.location.href.split("#")[0]; 
                                  } else {
                                    window.location.reload();
                                  }
                                } else {
                                  window.location.reload();
                                }
                              }
                              if (callbackType == "savelist") {
                                callback();
                              }
                              if (callbackType == "follow") {
                                callback();
                              }
                            }
                          });
                      })
                      .catch((err) => {
                        console.log(
                          "oh no something wen't wrong in the file uploading process"
                        );
                      });
                  } else {
                    const data_auth = { email: emailUp, password: password1Up };
                    fetch(server_url + "/api/auth_user", {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify(data_auth),
                    })
                      .then((res5) => res5.json())
                      .then((res5) => {
                        console.log(cookies);
                        setCookie("tuto2", "new_user", { path: "/" });
                        if (res5["count_record"] == 1) {
                          if (callbackType == "home") {
                            window.location.href = server_url;
                          }
                          if (callbackType == "reload") {
                            if (window.location.href.split("#").length >= 2 ){
                              if (window.location.href.split("#")[1] == "id_landingpage_main"){
                                window.location.href = window.location.href.split("#")[0]; 
                              } else {
                                window.location.reload();
                              }
                            } else {
                              window.location.reload();
                            }
                          }
                          if (callbackType == "savelist") {
                            callback();
                          }
                          if (callbackType == "follow") {
                            callback();
                          }
                        }
                      });
                  }
                });
            }
          });
      });
  };

  return (
    <Paper
      className={classes.paper}
      style={isPC ? { padding: "2em", maxWidth: "950px" } : {}}
    >
      <Typography
        variant={isPC ? "h3" : "h5"}
        color="textPrimary"
        style={
          isPC
            ? { marginBottom: "2em" }
            : { marginBottom: "1em", marginTop: "1em" }
        }
      >
        {global.lang == "en" ? "Sign up" : "Créer un compte"}
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          doReg();
        }}
      >
        <div
          style={
            isPC
              ? { display: "flex", justifyContent: "space-around" }
              : {
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }
          }
        >
          <div style={isPC ? { padding: "1em", paddingRight: "2em" } : {}}>
            {pic_url != "" && (
              <img
                src={
                  image_file == ""
                    ? pic_url
                    : URL.createObjectURL(image_file.fileToUpload)
                }
                style={
                  isPC
                    ? {
                        border: "dashed rgba(0,0,0,0.12)",
                        borderRadius: "128px",
                        maxWidth: "256px",
                        maxHeight: "256px",
                        fontSize: "large",
                      }
                    : {
                        border: "dashed rgba(0,0,0,0.12)",
                        borderRadius: "128px",
                        maxWidth: "256px",
                        maxHeight: "256px",
                        fontSize: "large",
                        transform: "scale(0.8)",
                      }
                }
              ></img>
            )}

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>
                    {global.lang == "en"
                      ? "Add a profile picture"
                      : "Ajouter une photo de profil"}
                  </Typography>
                  <IconButton aria-label="icon" onClick={() => browseFiles()}>
                    <ImageIcon></ImageIcon>
                  </IconButton>

                  <input
                    id="id_hidden_file_browser"
                    type="file"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setImageFile({
                        fileToUpload: e.target.files[0],
                      });
                      setPicNumber(-1);
                    }}
                    style={{ display: "none" }}
                  ></input>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>
                    {global.lang == "en" ? "Random pick" : "Choix aléatoire"}
                  </Typography>
                  <IconButton
                    aria-label="refresh icon"
                    onClick={() => randProfilePic()}
                  >
                    <RefreshIcon></RefreshIcon>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={isPC ? { display: "flex" } : {}}>
                <TextField
                  required
                  id="id_reg_email"
                  type="email"
                  label="Email"
                  variant="filled"
                  style={{ width: "256px" }}
                  inputProps={{ maxLength: 80 }}
                  placeholder="xxx@yyy.com"
                  helperText={
                    is_email_error ? (
                      <>
                        {global.lang == "en"
                          ? "Email not valid"
                          : "Adresse non valide"}
                      </>
                    ) : (
                      <>
                        {email_exists ? (
                          <>
                            {global.lang == "en"
                              ? "An account with this email already exists"
                              : "Un compte avec cette adresse existe déjà"}
                          </>
                        ) : (
                          <>
                            {global.lang == "en"
                              ? "Only for identifation, won't be public"
                              : "Votre adresse n'est pas rendue publique"}
                          </>
                        )}
                      </>
                    )
                  }
                  onChange={(event) => {
                    setEmailExists(false);
                    setEmailUp(event.target.value);
                    setIsEmailError(false);
                  }}
                  error={is_email_error || email_exists}
                />
                <TextField
                  required
                  id="id_reg_pseudo"
                  label="Pseudo"
                  variant="filled"
                  placeholder="gerard"
                  style={{ width: "256px" }}
                  inputProps={{ maxLength: 50 }}
                  helperText={
                    pseudo_exists ? (
                      <>
                        {" "}
                        {global.lang == "en"
                          ? "An account with this pseudo already exists"
                          : "Un compte avec ce pseudo existe déjà"}
                      </>
                    ) : (
                      <>
                        {global.lang == "en"
                          ? "Your pseudo is public"
                          : "Votre pseudo est public"}
                      </>
                    )
                  }
                  onChange={(event) => {
                    setPseudoExists(false);
                    setPseudoUp(event.target.value);
                  }}
                  error={pseudo_exists}
                />
              </div>
              <div style={isPC ? { display: "flex" } : {}}>
                <TextField
                  required
                  id="id_reg_pw1"
                  label={global.lang == "en" ? "Password" : "Mot de passe"}
                  variant="filled"
                  type="password"
                  placeholder="******"
                  style={{ width: "256px" }}
                  inputProps={{ maxLength: 30 }}
                  onChange={(event) => {
                    setIsPassError(false);
                    setPassword1Up(event.target.value);
                  }}
                  error={is_pass_error}
                  helperText={
                    is_pass_error ? (
                      <>
                        {global.lang == "en"
                          ? "Passwords don't match"
                          : "Les mots de passe ne sont pas identiques"}{" "}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <TextField
                  required
                  id="id_reg_pw2"
                  label={
                    global.lang == "en"
                      ? "Confirm password"
                      : "Confirmer le mot de passe"
                  }
                  variant="filled"
                  type="password"
                  placeholder="******"
                  style={{ width: "256px" }}
                  inputProps={{ maxLength: 30 }}
                  onChange={(event) => {
                    setIsPassError(false);
                    setPassword2Up(event.target.value);
                  }}
                  error={is_pass_error}
                />
              </div>
            </div>
            {captcha_url != "" && (
              <div style={{ paddingTop: "1em" }}>
                <Typography variant="body2" color="textSecondary">
                  {global.lang == "en"
                    ? "Type the characters seen in the image below"
                    : "Êtes-vous un humain ?"}
                </Typography>
                <div
                  style={
                    isPC
                      ? {
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }
                      : {
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          flexDirection: "column",
                        }
                  }
                >
                  <div>
                    <img src={captcha_url}></img>
                  </div>
                  <div>
                    <IconButton
                      aria-label="refresh icon"
                      onClick={() => randCaptcha()}
                    >
                      <RefreshIcon></RefreshIcon>
                    </IconButton>
                  </div>
                  <TextField
                    required
                    id="id_captcha"
                    label="Captcha"
                    variant="filled"
                    placeholder="xh6hjd"
                    helperText={
                      is_captcha_error ? (
                        <>
                          {global.lang == "en"
                            ? "wrong captcha, please try again"
                            : "Erreur de captcha, veuillez réessayer"}
                        </>
                      ) : (
                        ""
                      )
                    }
                    error={is_captcha_error}
                    onChange={(event) => {
                      setIsCaptchaError(false);
                      setCapValue(event.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          fullWidth
          type="submit"
          style={{ marginTop: "2em" }}
        >
          {global.lang == "en" ? "Sign up" : "Créer mon compte"}
        </Button>
      </form>
    </Paper>
  );
}

export function SignUpSmall({
  callbackType = "reload",
  callback,
  propose_other,
}) {
  const [password1Up, setPassword1Up] = useState("");
  const [password2Up, setPassword2Up] = useState("");
  const [emailUp, setEmailUp] = useState("");
  const [pseudoUp, setPseudoUp] = useState("");
  const [pic_url, setPicUrl] = useState("");
  const [pic_number, setPicNumber] = useState(-1);
  const [image_file, setImageFile] = useState("");
  const [captcha_url, setCaptchaUrl] = useState("");
  const [cap_value, setCapValue] = useState("");

  const [is_pass_error, setIsPassError] = useState(false);
  const [is_email_error, setIsEmailError] = useState(false);
  const [is_captcha_error, setIsCaptchaError] = useState(false);

  const [email_exists, setEmailExists] = useState(false);
  const [pseudo_exists, setPseudoExists] = useState(false);

  const [cookies, setCookie] = useCookies(["tuto2"]);

  const [is_google_up, setIsGoogleUp] = useState(false);

  const [isPC, setIsPC] = useState(
    useMediaQuery({
      query: "(min-width: 700px)",
    })
  );

  const [g_picture, setGPicture] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const randProfilePic = () => {
    fetch(server_url + "/api/get_rand_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPicNumber(res["rand_int"]);
        setPicUrl(res["url"]);
        console.log("randProfilePic " + res["rand_int"] + " " + res["url"]);
      });
    setImageFile("");
  };

  const randCaptcha = () => {
    fetch(server_url + "/api/get_captcha")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCaptchaUrl(res["url"]);
      });
  };

  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    randProfilePic();
    randCaptcha();
  }, []);

  const doReg = () => {
    var file_name = "";
    if (pic_number == -1) {
      file_name = image_file.fileToUpload.name;
    }

    if (password1Up != password2Up) {
      setIsPassError(true);
      setPassword1Up("");
      setPassword2Up("");
    }

    if (!isValidEmail(emailUp)) {
      setIsEmailError(true);
    }

    const data = { email: emailUp, pseudo: pseudoUp };
    console.log(data);

    fetch(server_url + "/api/email_pseudo_available", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res["count_email"] + "" == "1") {
          setEmailExists(true);
        }
        if (res["count_pseudo"] + "" == "1") {
          setPseudoExists(true);
        }

        const data_cap = { cap_value: cap_value };
        console.log(data);
        fetch(server_url + "/api/captcha_ok", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data_cap),
        })
          .then((res2) => res2.json())
          .then((res2) => {
            console.log(res2);
            if (res2["captcha_ok"] + "" == "0") {
              setIsCaptchaError(true);
              randCaptcha();
            }

            if (
              res2["captcha_ok"] + "" == "1" &&
              res["count_pseudo"] + "" == "0" &&
              res["count_email"] + "" == "0" &&
              password1Up == password2Up &&
              isValidEmail(emailUp)
            ) {
              const data_reg = {
                email: emailUp,
                pseudo: pseudoUp,
                password: password1Up,
                pic_number: pic_number,
                file_name: file_name,
                cap_value: cap_value.toLowerCase(),
                lang: global.lang,
              };
              console.log(data_reg);

              fetch("/api/insert_user", {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(data_reg),
              })
                .then((res3) => res3.json())
                .then((res3) => {
                  console.log(res3);
                  if (pic_number == -1) {
                    axios({
                      method: "PUT",
                      url: res3["presigned_url"],
                      data: image_file.fileToUpload,
                      headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                      },
                    })
                      .then((res4) => {
                        console.log(res4);
                        const data_auth = {
                          email: emailUp,
                          password: password1Up,
                        };

                        fetch(server_url + "/api/auth_user", {
                          method: "POST",
                          headers: {
                            "Content-type": "application/json",
                          },
                          body: JSON.stringify(data_auth),
                        })
                          .then((res5) => res5.json())
                          .then((res5) => {
                            if (res5["count_record"] == 1) {
                              console.log(cookies);
                              setCookie("tuto2", "new_user", { path: "/" });
                              if (callbackType == "home") {
                                window.location.href = server_url;
                              }
                              if (callbackType == "reload") {
                                if (window.location.href.split("#").length >= 2 ){
                                  if (window.location.href.split("#")[1] == "id_landingpage_main"){
                                    window.location.href = window.location.href.split("#")[0]; 
                                  } else {
                                    window.location.reload();
                                  }
                                } else {
                                  window.location.reload();
                                }
                              }
                              if (callbackType == "savelist") {
                                callback();
                              }
                              if (callbackType == "follow") {
                                callback();
                              }
                            }
                          });
                      })
                      .catch((err) => {
                        console.log(
                          "oh no something wen't wrong in the file uploading process"
                        );
                      });
                  } else {
                    const data_auth = { email: emailUp, password: password1Up };
                    fetch(server_url + "/api/auth_user", {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify(data_auth),
                    })
                      .then((res5) => res5.json())
                      .then((res5) => {
                        console.log(cookies);
                        setCookie("tuto2", "new_user", { path: "/" });
                        if (res5["count_record"] == 1) {
                          if (callbackType == "home") {
                            window.location.href = server_url;
                          }
                          if (callbackType == "reload") {
                            if (window.location.href.split("#").length >= 2 ){
                              if (window.location.href.split("#")[1] == "id_landingpage_main"){
                                window.location.href = window.location.href.split("#")[0]; 
                              } else {
                                window.location.reload();
                              }
                            } else {
                              window.location.reload();
                            }
                          }
                          if (callbackType == "savelist") {
                            callback();
                          }
                          if (callbackType == "follow") {
                            callback();
                          }
                        }
                      });
                  }
                });
            }
          });
      });
  };

  const doRegG = () => {
    const data = { email: "useless", pseudo: pseudoUp };
    console.log(data);

    fetch(server_url + "/api/email_pseudo_available", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res["count_pseudo"] + "" == "1") {
          setPseudoExists(true);
        }

        if (res["count_pseudo"] + "" == "0") {
          var int_is_google_picture = "1";
          const data_reg = {
            pseudo: pseudoUp,
            pic_number: pic_number,
            file_name: "file_name",
            google_profile_pic: g_picture,
            is_google_picture: int_is_google_picture,
            lang: global.lang,
          };
          console.log(data_reg);

          fetch("/api/insert_google_user", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(data_reg),
          })
            .then((res3) => res3.json())
            .then((res3) => {
              console.log(res3);
              if (pic_number == -1) {
                axios({
                  method: "PUT",
                  url: res3["presigned_url"],
                  data: image_file.fileToUpload,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                  },
                })
                  .then((res4) => {
                    console.log(cookies);
                    setCookie("tuto2", "new_user", { path: "/" });
                    console.log(res4);
                    if (callbackType == "home") {
                      window.location.href = server_url;
                    }
                    if (callbackType == "reload") {
                      if (window.location.href.split("#").length >= 2 ){
                        if (window.location.href.split("#")[1] == "id_landingpage_main"){
                          window.location.href = window.location.href.split("#")[0]; 
                        } else {
                          window.location.reload();
                        }
                      } else {
                        window.location.reload();
                      }
                    }
                    if (callbackType == "savelist") {
                      callback();
                    }
                    if (callbackType == "follow") {
                      callback();
                    }
                  })
                  .catch((err) => {
                    console.log(
                      "oh no something wen't wrong in the file uploading process"
                    );
                  });
              } else {
                console.log(cookies);
                setCookie("tuto2", "new_user", { path: "/" });
                if (callbackType == "home") {
                  window.location.href = server_url;
                }
                if (callbackType == "reload") {
                  if (window.location.href.split("#").length >= 2 ){
                    if (window.location.href.split("#")[1] == "id_landingpage_main"){
                      window.location.href = window.location.href.split("#")[0]; 
                    } else {
                      window.location.reload();
                    }
                  } else {
                    window.location.reload();
                  }
                }
                if (callbackType == "savelist") {
                  callback();
                }
                if (callbackType == "follow") {
                  callback();
                }
              }
            });
        }
      });
  };

  const handleGoogleButtonSignUp = (e) => {
    signInGoogle(
      e,
      () => window.location.reload(),
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };

  const handleSignUpG = (email, pseudo, picture) => {
    setEmailUp(email);
    setPseudoUp(pseudo);
    setGPicture(picture);
    setIsGoogleUp(true);
  };

  return (
    <Paper
      className={classes.paper}
      style={
        isPC
          ? {
              padding: "1em 2em 2em 2em",
              maxWidth: "950px",
              position: "absolute",
              top: "0px",
            }
          : {}
      }
    >
      <Typography
        variant={isPC ? "h3" : "h3"}
        color="textPrimary"
        style={
          isPC
            ? { marginBottom: "1em" }
            : propose_other
            ? { marginBottom: "2em", marginTop: "0.5em" }
            : { marginBottom: "1em", marginTop: "1em" }
        }
      >
        {global.lang == "en" ? "Sign up" : "Créer un compte"}
      </Typography>

      {!is_google_up && (
        <>
          <GoogleLogin
            clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
            onSuccess={(e) => handleGoogleButtonSignUp(e)}
            onFailure={() => {
              alert(
                "Problem with the sign in method, please choose another one"
              );
            }}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                style={
                  isPC
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "0.4em",
                        marginRight: "0.4em",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "1.5em",
                        marginRight: "1.5em",
                      }
                }
                startIcon={<GoogleIcon />}
                endIcon={<></>}
                variant="outlined"
              >
                {global.lang == "fr"
                  ? "Se connecter avec Google"
                  : "Login with Google"}
              </Button>
            )}
            cookiePolicy={"single_host_origin"}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "2em",
              marginBottom: "1.7em",
            }}
          >
            <div
              style={
                isPC
                  ? { height: "1px", width: "190px" }
                  : { height: "1px", width: "120px" }
              }
            >
              <hr></hr>
            </div>
            {global.lang == "fr" ? "ou" : "or"}
            <div
              style={
                isPC
                  ? { height: "1px", width: "190px" }
                  : { height: "1px", width: "120px" }
              }
            >
              <hr></hr>
            </div>
          </div>
        </>
      )}

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          if (is_google_up) {
            doRegG();
          } else {
            doReg();
          }
        }}
      >
        <div
          style={
            isPC
              ? { display: "flex", justifyContent: "space-around" }
              : {
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={isPC ? { display: "flex" } : {}}>
                {!is_google_up ? (
                  <TextField
                    required
                    id="id_reg_email"
                    type="email"
                    label="Email"
                    variant="filled"
                    style={{ width: "256px" }}
                    inputProps={{ maxLength: 80 }}
                    placeholder="xxx@yyy.com"
                    helperText={
                      is_email_error ? (
                        <>
                          {global.lang == "en"
                            ? "Email not valid"
                            : "Adresse non valide"}
                        </>
                      ) : (
                        <>
                          {email_exists ? (
                            <>
                              {global.lang == "en"
                                ? "An account with this email already exists"
                                : "Un compte avec cette adresse existe déjà"}
                            </>
                          ) : (
                            <>
                              {global.lang == "en"
                                ? "Only for identifation, won't be public"
                                : "Votre adresse n'est pas rendue publique"}
                            </>
                          )}
                        </>
                      )
                    }
                    onChange={(event) => {
                      setEmailExists(false);
                      setEmailUp(event.target.value);
                      setIsEmailError(false);
                    }}
                    error={is_email_error || email_exists}
                  />
                ) : (
                  <TextField
                    required={is_google_up ? false : true}
                    id="id_reg_email"
                    type="email"
                    label={is_google_up ? "Email" : "Email"}
                    variant="filled"
                    style={{ width: "256px" }}
                    inputProps={{ maxLength: 80 }}
                    value={emailUp}
                    helperText={
                      global.lang == "en"
                        ? "Only for identifation, won't be public"
                        : "Votre adresse n'est pas rendue publique"
                    }
                    disabled
                    focused
                  />
                )}
                {!is_google_up ? (
                  <TextField
                    required
                    id="id_reg_pseudo"
                    label="Pseudo"
                    variant="filled"
                    placeholder="gerard"
                    style={{ width: "256px" }}
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      pseudo_exists ? (
                        <>
                          {" "}
                          {global.lang == "en"
                            ? "An account with this pseudo already exists"
                            : "Un compte avec ce pseudo existe déjà"}
                        </>
                      ) : (
                        <>
                          {global.lang == "en"
                            ? "Your pseudo is public"
                            : "Votre pseudo est public"}
                        </>
                      )
                    }
                    onChange={(event) => {
                      setPseudoExists(false);
                      setPseudoUp(event.target.value);
                    }}
                    error={pseudo_exists}
                  />
                ) : (
                  <TextField
                    required
                    id="id_reg_pseudo"
                    label="Pseudo"
                    variant="filled"
                    value={pseudoUp}
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      pseudo_exists ? (
                        <>
                          {" "}
                          {global.lang == "en"
                            ? "An account with this pseudo already exists"
                            : "Un compte avec ce pseudo existe déjà"}
                        </>
                      ) : (
                        <>
                          {global.lang == "en"
                            ? "Your pseudo is public"
                            : "Votre pseudo est public"}
                        </>
                      )
                    }
                    onChange={(event) => {
                      setPseudoExists(false);
                      setPseudoUp(event.target.value);
                    }}
                    error={pseudo_exists}
                    focused
                  />
                )}
              </div>
              {!is_google_up && (
                <div style={isPC ? { display: "flex" } : {}}>
                  <TextField
                    required
                    id="id_reg_pw1"
                    label={global.lang == "en" ? "Password" : "Mot de passe"}
                    variant="filled"
                    type="password"
                    placeholder="******"
                    style={{ width: "256px" }}
                    inputProps={{ maxLength: 30 }}
                    onChange={(event) => {
                      setIsPassError(false);
                      setPassword1Up(event.target.value);
                    }}
                    error={is_pass_error}
                    helperText={
                      is_pass_error ? (
                        <>
                          {global.lang == "en"
                            ? "Passwords don't match"
                            : "Les mots de passe ne sont pas identiques"}{" "}
                        </>
                      ) : (
                        ""
                      )
                    }
                  />
                  <TextField
                    required
                    id="id_reg_pw2"
                    label={
                      global.lang == "en"
                        ? "Confirm password"
                        : "Confirmer le mot de passe"
                    }
                    variant="filled"
                    type="password"
                    placeholder="******"
                    style={{ width: "256px" }}
                    inputProps={{ maxLength: 30 }}
                    onChange={(event) => {
                      setIsPassError(false);
                      setPassword2Up(event.target.value);
                    }}
                    error={is_pass_error}
                  />
                </div>
              )}
            </div>
            {captcha_url != "" && !is_google_up && (
              <div style={{ paddingTop: "1em" }}>
                <Typography variant="body2" color="textSecondary">
                  {global.lang == "en"
                    ? "Type the characters seen in the image below"
                    : "Êtes-vous un humain ?"}
                </Typography>
                <div
                  style={
                    isPC
                      ? {
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }
                      : {
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          flexDirection: "column",
                        }
                  }
                >
                  <div>
                    <img src={captcha_url}></img>
                  </div>
                  <div>
                    <IconButton
                      aria-label="refresh icon"
                      onClick={() => randCaptcha()}
                    >
                      <RefreshIcon></RefreshIcon>
                    </IconButton>
                  </div>
                  <TextField
                    required
                    id="id_captcha"
                    label="Captcha"
                    variant="filled"
                    placeholder="xh6hjd"
                    helperText={
                      is_captcha_error ? (
                        <>
                          {global.lang == "en"
                            ? "wrong captcha, please try again"
                            : "Erreur de captcha, veuillez réessayer"}
                        </>
                      ) : (
                        ""
                      )
                    }
                    error={is_captcha_error}
                    onChange={(event) => {
                      setIsCaptchaError(false);
                      setCapValue(event.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          fullWidth
          type="submit"
          style={{
            marginTop: "2em",
            marginLeft: "0.4em",
            marginRight: "0.4em",
          }}
        >
          {global.lang == "en" ? "Sign up" : "Créer mon compte"}
        </Button>
      </form>
      {propose_other && (
        <Link
          onClick={() =>
            dispatch(setReduxDefaultDisplay({ default_display: "signin" }))
          }
          style={{ marginTop: "2em", cursor: "pointer" }}
        >
          {global.lang == "fr"
            ? "J'ai déjà un compte"
            : "I already have an account"}
        </Link>
      )}
    </Paper>
  );
}

export function SignUpSmallFull({
  is_open = true,
  setClose,
  propose_other = true,
  default_display = "signup",
  callbackType = "reload",
  callback,
}) {
  /*   const [sign_show, setSignShow] = useState("");

  useEffect(() => {
    setSignShow(default_display);
  }, []); */

  const h_global_dialog = useSelector((state) => state.global_dialog);
  const dispatch = useDispatch();

  useEffect(() => {
    if (h_global_dialog["default_display"] !== default_display) {
      dispatch(setReduxDefaultDisplay({ default_display: default_display }));
    }
  }, []);

  return (
    <Slide direction="up" in={is_open} mountOnEnter unmountOnExit>
      <div>
      
        <div
          style={{
            display: "block",
            position: "fixed",
            width: "100%",
            top: "0",
            left: "0",
            height: 2000 + "px",
            backgroundColor: "white",
            zIndex: 510,
          }}
        ></div>
        <div
          style={{
            display: "block",
            position: "absolute",
            width: "100%",
            top: "0",
            left: "0",
            height: 1400 + "px",
            backgroundColor: "white",
            zIndex: 511,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseIcon
              onClick={() => setClose()}
              style={{
                width: "48px",
                height: "48px",
                padding: "0.3em",
                cursor: "pointer",
              }}
            ></CloseIcon>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {h_global_dialog["default_display"] == "signup" && (
              <SignUpSmall
                propose_other={propose_other}
                callbackType={callbackType}
                callback={callback}
              ></SignUpSmall>
            )}
            {h_global_dialog["default_display"] == "signin" && (
              <SignIn
                propose_other={propose_other}
                callbackType={callbackType}
                callback={callback}
              ></SignIn>
            )}
          </div>
        </div>
      </div>
    </Slide>
  );
}

export function SignUpG({
  callbackType = "reload",
  callback,
  email,
  pseudo,
  google_profile_pic,
}) {
  const [pseudoUp, setPseudoUp] = useState("");
  const [pic_url, setPicUrl] = useState("");
  const [pic_number, setPicNumber] = useState(-1);
  const [image_file, setImageFile] = useState("");
  const [is_google_picture, setIsGooglePicture] = useState(false);

  const [pseudo_exists, setPseudoExists] = useState(false);

  const [cookies, setCookie] = useCookies(["tuto2"]);

  const classes = useStyles();

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const randProfilePic = () => {
    fetch(server_url + "/api/get_rand_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPicNumber(res["rand_int"]);
        setPicUrl(res["url"]);
        console.log("randProfilePic " + res["rand_int"] + " " + res["url"]);
      });
    setImageFile("");
  };

  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };

  useEffect(() => {
    setPseudoUp(pseudo);
    randProfilePic();
  }, []);

  const doReg = () => {
    var file_name = "";
    if (pic_number == -1) {
      file_name = image_file.fileToUpload.name;
    }

    const data = { email: "useless", pseudo: pseudoUp };
    console.log(data);

    fetch(server_url + "/api/email_pseudo_available", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res["count_pseudo"] + "" == "1") {
          setPseudoExists(true);
        }

        if (res["count_pseudo"] + "" == "0") {
          var int_is_google_picture = "0";
          if (is_google_picture) {
            int_is_google_picture = "1";
          }
          const data_reg = {
            pseudo: pseudoUp,
            pic_number: pic_number,
            file_name: file_name,
            google_profile_pic: google_profile_pic,
            is_google_picture: int_is_google_picture,
            lang: global.lang,
          };
          console.log(data_reg);

          fetch("/api/insert_google_user", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(data_reg),
          })
            .then((res3) => res3.json())
            .then((res3) => {
              console.log(res3);
              if (pic_number == -1) {
                axios({
                  method: "PUT",
                  url: res3["presigned_url"],
                  data: image_file.fileToUpload,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                  },
                })
                  .then((res4) => {
                    console.log(cookies);
                    setCookie("tuto2", "new_user", { path: "/" });
                    console.log(res4);
                    if (callbackType == "home") {
                      window.location.href = server_url;
                    }
                    if (callbackType == "reload") {
                      if (window.location.href.split("#").length >= 2 ){
                        if (window.location.href.split("#")[1] == "id_landingpage_main"){
                          window.location.href = window.location.href.split("#")[0]; 
                        } else {
                          window.location.reload();
                        }
                      } else {
                        window.location.reload();
                      }
                    }
                    if (callbackType == "savelist") {
                      callback();
                    }
                    if (callbackType == "follow") {
                      callback();
                    }
                  })
                  .catch((err) => {
                    console.log(
                      "oh no something wen't wrong in the file uploading process"
                    );
                  });
              } else {
                console.log(cookies);
                setCookie("tuto2", "new_user", { path: "/" });
                if (callbackType == "home") {
                  window.location.href = server_url;
                }
                if (callbackType == "reload") {
                  if (window.location.href.split("#").length >= 2 ){
                    if (window.location.href.split("#")[1] == "id_landingpage_main"){
                      window.location.href = window.location.href.split("#")[0]; 
                    } else {
                      window.location.reload();
                    }
                  } else {
                    window.location.reload();
                  }
                }
                if (callbackType == "savelist") {
                  callback();
                }
                if (callbackType == "follow") {
                  callback();
                }
              }
            });
        }
      });
  };

  return (
    <Paper
      className={classes.paper}
      style={{ padding: "2em", maxWidth: "950px" }}
    >
      <Typography
        variant="h3"
        color="textPrimary"
        style={{ marginBottom: "2em" }}
      >
        {global.lang == "en" ? "Sign up" : "Créer un compte"}
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          doReg();
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ padding: "1em", paddingRight: "2em" }}>
            {pic_url != "" && (
              <img
                src={
                  is_google_picture
                    ? google_profile_pic
                    : image_file == ""
                    ? pic_url
                    : URL.createObjectURL(image_file.fileToUpload)
                }
                style={{
                  border: "dashed rgba(0,0,0,0.12)",
                  borderRadius: "128px",
                  maxWidth: "256px",
                  maxHeight: "256px",
                  fontSize: "large",
                }}
              ></img>
            )}

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {global.lang == "en"
                        ? "Google account picture"
                        : "Photo de compte Google"}
                    </Typography>
                    <IconButton aria-label="google profile picture">
                      <Switch
                        onChange={(e) => setIsGooglePicture(e.target.checked)}
                        checked={is_google_picture}
                      ></Switch>
                    </IconButton>
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {global.lang == "en"
                        ? "Add a profile picture"
                        : "Ajouter une photo de profil"}
                    </Typography>
                    <IconButton
                      aria-label="icon"
                      onClick={
                        is_google_picture
                          ? () => console.log("can't click")
                          : () => browseFiles()
                      }
                      disabled={is_google_picture}
                    >
                      <ImageIcon></ImageIcon>
                    </IconButton>

                    <input
                      id="id_hidden_file_browser"
                      type="file"
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        setImageFile({
                          fileToUpload: e.target.files[0],
                        });
                        setPicNumber(-1);
                      }}
                      style={{ display: "none" }}
                    ></input>
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {global.lang == "en" ? "Random pick" : "Choix aléatoire"}
                    </Typography>
                    <IconButton
                      aria-label="refresh icon"
                      onClick={
                        is_google_picture
                          ? () => console.log("can't click")
                          : () => randProfilePic()
                      }
                      disabled={is_google_picture}
                    >
                      <RefreshIcon></RefreshIcon>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",

              alignItems: "center",
            }}
          >
            <div>
              <div>
                <TextField
                  required
                  id="id_reg_email"
                  type="email"
                  label="Email"
                  variant="filled"
                  value={email}
                  disabled
                />
              </div>
              <div>
                <TextField
                  required
                  id="id_reg_pseudo"
                  label="Pseudo"
                  variant="filled"
                  placeholder="gerard"
                  defaultValue={pseudo}
                  inputProps={{ maxLength: 50 }}
                  helperText={
                    pseudo_exists ? (
                      <>
                        {" "}
                        {global.lang == "en"
                          ? "An account with this pseudo already exists"
                          : "Un compte avec ce pseudo existe déjà"}
                      </>
                    ) : (
                      <>
                        {global.lang == "en"
                          ? "Your pseudo is public"
                          : "Votre pseudo est public"}
                      </>
                    )
                  }
                  onChange={(event) => {
                    setPseudoExists(false);
                    setPseudoUp(event.target.value);
                  }}
                  error={pseudo_exists}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          fullWidth
          type="submit"
          style={{ marginTop: "2em" }}
        >
          {global.lang == "en" ? "Sign up" : "Créer mon compte"}
        </Button>
      </form>
    </Paper>
  );
}
