import React, { useState, useEffect } from "react";
import CHeader from "../MainComponents/CHeader";
import LAdd from "./LAdd";
import LTrain from "./LTrain";
import TestGen from "../Gen/TestGen";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {
  deleteQuestionDB,
  deleteQuestionsDB,
  setListDescrDB,
} from "../Generic/lists";
import LinkIcon from "@material-ui/icons/Link";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";
import { setPublicDB } from "../Generic/lists";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { ScoreBullet } from "../SmallComponents/SmallComponents";
import { useDispatch } from "react-redux";
import { setReduxListsMeta } from "../redux/globalLists";
import {
  setReduxSelectedQuestion,
  setReduxAllSelectedQuestions,
} from "../redux/globalQuestions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import {
  cloneQuestionsToListDB,
  moveQuestionsToListDB,
  setQuestionStatementDB,
  setQuestionAnswerDB,
} from "../Generic/lists";
import { unixToShortHuman } from "../Generic/generic";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import CMenu from "../MainComponents/CMenu";
import { Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GroupIcon from "@material-ui/icons/Group";
import { Paper } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import { CreaListDialog, CreaPageDialog } from "../SmallComponents/CDialog";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import { unfollowAListDB } from "../Generic/lists";

import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
} from "../redux/globalTuto";

import ConstructionIcon from "@mui/icons-material/Construction";
import ToggleButton from "@mui/material/ToggleButton";

import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useCookies } from "react-cookie";

const ColorButton = withStyles((theme) => ({
  root: {
    /* color: theme.palette.getContrastText(green[500]), */
    color: "white",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  editableDiv: {
    "&:hover": {
      border: "1px solid lightgray",
    },
    border: "1px solid rgb(246,248,250)",
  },
  editableDivStatementPic: {
    "&:hover": {
      border: "1px solid lightgray",
    },
    border: "1px solid rgb(246,248,250)",
    width: "200px",
    maxHeight: "150px",
    display: "flex",
    alignItems: "center",
    /* wordBreak: "break-all", */
    overflow: "auto",
  },
  editableDivStatementNoPic: {
    "&:hover": {
      border: "1px solid lightgray",
    },
    border: "1px solid rgb(246,248,250)",
    width: "400px",
    maxHeight: "150px",
    display: "flex",
    alignItems: "center",
    /* wordBreak: "break-all", */
    overflow: "auto",
  },
}));

export default function UList({ id_user, id_list, url_pseudo }) {
  const [menuStatus, setMenuStatus] = useState("0");
  const [a_questions, setQuestions] = useState([]);
  const [current_id_question, setCurrentIdQuestion] = useState(-1);
  const [list_name, setListName] = useState("");
  const [url_list_name, setUrlListName] = useState("");
  const [list_descr, setListDescr] = useState("");
  const [is_public, setIsPublic] = useState(true);
  const [get_list_ok, setGetListsOk] = useState(false);
  const [crea_date, setCreaDate] = useState();
  const [views, setViews] = useState();
  const [followers, setFollowers] = useState();

  const [open_img_dial, setOpenImgDial] = useState(false);
  const [media_800, setMedia800] = useState();

  const [do_print_1, setDoPrint1] = useState(false);
  const [do_print_2, setDoPrint2] = useState(false);
  const [do_print_3, setDoPrint3] = useState(false);

  const [open_test_gen, setOpenTestGen] = useState(false);

  const [arg_a_questions, setArgAQuestions] = useState([]);

  const [cont_code, setContCode] = useState("");

  const [open_tools, setOpenTools] = useState(false);
  const [is_title_exp, setTitleExp] = useState(false);

  const [cookies, setCookie] = useCookies(["tuto2"]);

  const isPCS = useMediaQuery({ query: "(max-width: 1300px)" });
  const is1100 = useMediaQuery({ query: "(max-width: 1100px)" });
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const deleteQuestionsDom = (id_questions) => {
    const a_upd_questions = [];
    a_questions.forEach((row, index) => {
      if (id_questions.indexOf(row[0] + "") == -1) {
        a_upd_questions.push(row);
      }
    });
    setQuestions(a_upd_questions);
  };

  const dispatch = useDispatch();

  const setQuestionsForTestGen = () => {
    var temp_a_questions = [];
    a_questions.forEach((row) => {
      console.log(row);
      temp_a_questions.push(row[3]);
    });
    setArgAQuestions(temp_a_questions);
  };

  const setCurrentScore = (score) => {
    let a_questions_copy = [...a_questions];
    a_questions_copy.forEach((row, index) => {
      if (row[0] == current_id_question) {
        a_questions_copy[index][6] = score;
      }
    });
    setQuestions(a_questions_copy);
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("LList id_list ", id_list);

  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  useEffect(() => {
    document.getElementById("id_sub_root").style.backgroundColor =
      "rgb(255,244,221)";
    fetch(server_url + "/api/get_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        dispatch(
          setReduxListsMeta({
            a_lists_meta: res,
          })
        );
        setGetListsOk(true);
        setDoPrint1(true);
      });
    let start = new Date();
    fetch(server_url + "/api/get_questions", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_list: id_list }),
    })
      .then((res) => res.json())
      .then((data) => {
        let end1 = new Date();
        console.log(data);
        setQuestions(data);
        let end2 = new Date();
        console.log(end1 - start, end2 - start);
        setDoPrint2(true);
      });
    fetch(server_url + "/api/get_list_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_list: id_list }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get_list_meta");
        console.log(data);

        setListName(data["row_list"][2]);
        setListDescr(data["row_list"][8]);
        setUrlListName(data["row_list"][9]);
        setCreaDate(data["row_list"][3]);
        if (data["row_list"][5] == "1") {
          setIsPublic(false);
        } else {
          setIsPublic(true);
          setViews(data["row_list"][17]);
          setFollowers(data["row_list"][18]);
        }
        setDoPrint3(true);
      });

    fetch(server_url + "/api/get_cont_code", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_content: id_list, content_type: "l" }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setContCode(res["id_code"]);
      });

  }, []);

  const deleteQuestion = (id_question) => {
    deleteQuestionDB(id_question);
    console.log("deleteQuestion");
  };

  const deleteQuestionDom = (id_question) => {
    console.log(id_question);
    const a_upd_questions = [];
    a_questions.forEach((row, index) => {
      console.log(row);
      if (row[0] != id_question && row[0] + "" != id_question + "") {
        a_upd_questions.push(row);
      }
    });
    setQuestions(a_upd_questions);
  };

  return (
    <div>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={true}
        log_out_url={server_url}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      {/* {do_print_1 && do_print_2 && do_print_3 ? ( */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {menuStatus == "1" && <CMenu></CMenu>}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div style={isPhoneV ? { width: "100%" } : { maxWidth: "1650px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: isPhoneV ? "100%" : "auto",
              }}
            >
              <div
                style={{ display: "flex", width: isPhoneV ? "100%" : "auto" }}
              >
                {!isPCS && (
                  <div>
                    {url_list_name != "" && (
                      <LPresentation
                        list_name={list_name}
                        url_list_name={url_list_name}
                        url_pseudo={url_pseudo}
                        list_descr={list_descr}
                        is_public={is_public}
                        setIsPublic={(e) => setIsPublic(e)}
                        id_list={id_list}
                        crea_date={crea_date}
                        views={views}
                        followers={followers}
                        is_UList={true}
                      ></LPresentation>
                    )}
                    <div style={{ paddingLeft: "2em", paddingTop: "2em" }}>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setReduxTutoStep({ tuto_step: 1 }));
                        }}
                      >
                        {global.lang == "fr"
                          ? "lancer le tutoriel"
                          : "launch tutorial"}
                      </Link>
                    </div>
                  </div>
                )}
                <div style={{ width: isPhoneV ? "100%" : "auto" }}>
                  {isPCS && (
                    <div style={{ margin: "1em" }}>
                      <div
                        style={
                          isPhoneV
                            ? {
                                display: "flex",
                                justifyContent: "space-between",
                              }
                            : {
                                display: "flex",
                                justifyContent: "space-between",
                                width: "746px",
                              }
                        }
                      >
                        <div></div>
                        <div style={{ maxWidth: "500px" }}>
                          <Typography variant="h3">{list_name}</Typography>
                        </div>

                        {is_title_exp ? (
                          <div>
                            <IconButton>
                              <ExpandLessIcon
                                onClick={() => setTitleExp(false)}
                              />
                            </IconButton>
                          </div>
                        ) : (
                          <div>
                            <IconButton>
                              <ExpandMoreIcon
                                onClick={() => setTitleExp(true)}
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                      {is_title_exp && (
                        <>
                          <div style={{ padding: "0.5em", marginTop: "0.5em" }}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: "large" }}
                            >
                              {list_descr}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <IconButton disabled>
                                <EventIcon
                                  style={{ color: "gray" }}
                                ></EventIcon>
                              </IconButton>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingRight: "1em",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {global.lang == "en" && "Created "}
                                  {global.lang == "fr" && "Créée "}
                                  {unixToShortHuman(crea_date)}
                                </Typography>
                              </div>
                            </div>

                            {is_public && (
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <IconButton disabled>
                                    <VisibilityIcon
                                      style={{ color: "gray" }}
                                    ></VisibilityIcon>
                                  </IconButton>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      style={{
                                        paddingLeft: "0.4em",
                                        paddingTop: "0.17em",
                                      }}
                                    >
                                      {views}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    paddingLeft: "1em",
                                  }}
                                >
                                  <IconButton disabled>
                                    <GroupIcon
                                      style={{ color: "gray" }}
                                    ></GroupIcon>
                                  </IconButton>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      style={{
                                        paddingLeft: "0.4em",
                                        paddingTop: "0.17em",
                                      }}
                                    >
                                      {followers}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {is1100 && (
                            <>
                              <RShare
                                url_list_name={url_list_name}
                                url_pseudo={url_pseudo}
                                list_descr={list_descr}
                                is_public={is_public}
                                setIsPublic={(e) => setIsPublic(e)}
                                id_list={id_list}
                                cont_code={cont_code}
                                mode_repr={"short_hor"}
                              ></RShare>
                              <ColorButton
                                id="id_but_eval"
                                variant="contained"
                                style={
                                  h_global_tuto_step["tuto_step"] != 14
                                    ? {
                                        marginTop: "1em",
                                        width: "100%",
                                        marginTop: "2em",
                                        borderRadius: "20px",
                                      }
                                    : {
                                        marginTop: "1em",
                                        width: "100%",
                                        marginTop: "2em",
                                        zIndex: 507,
                                        borderRadius: "20px",
                                      }
                                }
                                disabled={
                                  a_questions.length >= 10 ? false : true
                                }
                                onClick={() => {
                                  setQuestionsForTestGen();
                                  setOpenTestGen(true);
                                }}
                              >
                                {global.lang == "en"
                                  ? "paper test"
                                  : "interrogation écrite"}
                              </ColorButton>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <div
                    style={
                      isPhoneV
                        ? {
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                          }
                        : { display: "flex" }
                    }
                  >
                    <LTrain
                      a_questions={a_questions}
                      setScore={(x) => setCurrentScore(x)}
                      setCurrentId={setCurrentIdQuestion}
                      is_focused={true}
                    ></LTrain>
                    {!is1100 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          margin: "1em 1em 1em 1em",
                          /* flexDirection: "column", */
                        }}
                      >
                        <div style={{ maxWidth: "220px" }}>
                          <RShare
                            url_list_name={url_list_name}
                            url_pseudo={url_pseudo}
                            list_descr={list_descr}
                            is_public={is_public}
                            setIsPublic={(e) => setIsPublic(e)}
                            id_list={id_list}
                            cont_code={cont_code}
                          ></RShare>

                          <ColorButton
                            id="id_but_eval"
                            variant="contained"
                            style={
                              h_global_tuto_step["tuto_step"] != 14
                                ? {
                                    marginTop: "1em",
                                    width: "100%",
                                    marginTop: "2em",
                                    borderRadius: "20px",
                                  }
                                : {
                                    marginTop: "1em",
                                    width: "100%",
                                    marginTop: "2em",
                                    zIndex: 507,
                                    borderRadius: "20px",
                                  }
                            }
                            disabled={a_questions.length >= 10 ? false : true}
                            onClick={() => {
                              setQuestionsForTestGen();
                              setOpenTestGen(true);
                            }}
                          >
                            {global.lang == "en"
                              ? "paper test"
                              : "interrogation écrite"}
                          </ColorButton>

                          {a_questions.length < 10 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                maxWidth: "230px",
                                paddingRight: "0.5em",
                                paddingLeft: "0.5em",
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                align="center"
                                style={{ fontWeight: 400 }}
                              >
                                {global.lang == "en" ? (
                                  <>
                                    {10 - a_questions.length} more to activate
                                    this feature
                                  </>
                                ) : (
                                  <>
                                    {10 - a_questions.length} de plus pour
                                    activer cette fonctionnalité
                                  </>
                                )}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={
                isPhoneV
                  ? {
                      display: "flex",
                      justifyContent: "space-around",
                    }
                  : {
                      display: "flex",
                      justifyContent: "space-around",
                      minWidth: "1192px",
                    }
              }
            >
              <div style={isPhoneV ? { width: "100%", margin: "1em" } : {}}>
                {!isPhoneV && (
                  <div
                    style={{
                      position: "relative",
                      top: "50px",
                      left: "64px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <ToggleButton
                      selected={open_tools}
                      onClick={() => setOpenTools(!open_tools)}
                      style={
                        open_tools
                          ? { backgroundColor: "rgb(240,240,240)" }
                          : { backgroundColor: "white" }
                      }
                    >
                      <ConstructionIcon />
                    </ToggleButton>
                  </div>
                )}
                {isPhoneV && (
                  <LAdd
                    id_list={id_list}
                    a_questions={a_questions}
                    setQuestions={setQuestions}
                    open_tools={open_tools}
                  ></LAdd>
                )}
                <Paper
                  style={{
                    border: isPhoneV ? "inherit" : "solid 1px lightgray",
                    backgroundColor: isPhoneV ? "rgb(255,244,221)" : "white",
                    marginBottom: "6em"
                  }}
                  elevation={0}
                >
                  {get_list_ok ? (
                    <div>
                      {open_tools && (
                        <>
                          <LToolBar
                            deleteQuestionsDom={deleteQuestionsDom}
                          ></LToolBar>
                          <Divider></Divider>
                        </>
                      )}

                      {!isPhoneV && (
                        <LAdd
                          id_list={id_list}
                          a_questions={a_questions}
                          setQuestions={setQuestions}
                          open_tools={open_tools}
                        ></LAdd>
                      )}
                      <Divider></Divider>
                      {a_questions.map((value, index) => {
                        console.log(value);
                        return (
                          <LQuestion
                            id_question={value[0]}
                            score={value[6]}
                            statement={value[3]}
                            answer={
                              current_id_question == value[0]
                                ? "1f9e"
                                : value[4]
                            }
                            deleteQuestion={(x) => deleteQuestion(x)}
                            a_questions={a_questions}
                            setQuestions={(x) => setQuestions(x)}
                            has_pic={value[2] == "i"}
                            pic_url={value[2] == "i" ? value[8] : ""}
                            pic_url_800={value[9]}
                            deleteQuestionDom={deleteQuestionDom}
                            setOpenImgDial={setOpenImgDial}
                            setMedia800={setMedia800}
                            open_tools={open_tools}
                          ></LQuestion>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <CircularProgress></CircularProgress>
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <CircularProgress></CircularProgress>
        </div>
      )} */}
      <Dialog
        open={open_img_dial}
        onClose={() => {
          setOpenImgDial(false);
        }}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2em",
          }}
        >
          <img src={media_800}></img>
        </div>
      </Dialog>
      <Dialog
        open={open_test_gen}
        onClose={() => {
          setOpenTestGen(false);
        }}
        maxWidth="xl"
        /* fullWidth={true}  */
      >
        <div>
          <TestGen
            arg_a_questions={arg_a_questions}
            url_pseudo={url_pseudo}
            url_name={url_list_name}
            id_list={id_list}
          ></TestGen>
        </div>
      </Dialog>
    </div>
  );
}

export function LPresentation({
  list_name,
  url_list_name,
  url_pseudo,
  list_descr,
  is_public,
  id_list,
  setIsPublic,
  crea_date,
  views,
  followers,
  added_date,
  // mode="owner" or "visitor"
  mode = "owner",
  width_supperp = 1350,
  is_UList = false,
  max_width = 350,
}) {
  const [state_descr, setStateDescr] = useState();
  const [temp_descr, setTempDescr] = useState();
  const [open_descr_dialog, setOpenDescrDialog] = useState(false);
  const [is_supperp, SetIsSupperp] = useState(false);
  const [open_unfollow_dial, setUnfollowDial] = useState(false);

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const url = server_url + "p/" + url_pseudo + "/" + url_list_name;

  useEffect(() => {
    setStateDescr(list_descr);
    if (window.innerWidth < width_supperp) {
      SetIsSupperp(true);
    } else {
      SetIsSupperp(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < width_supperp) {
        SetIsSupperp(true);
      } else {
        SetIsSupperp(false);
      }
    });
  }, []);

  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const classes = useStyles();
  console.log(crea_date, "k", views, "m", followers, state_descr, list_descr);

  return (
    <div
      style={
        !is_supperp
          ? {
              marginTop: "1em",
              padding: "1em",
              marginLeft: "1em",
              minWidth: max_width - 30 + "px",
              maxWidth: max_width + "px",
              borderRadius: "10px",
              backgroundColor: "white",
            }
          : {
              marginTop: "1em",
              padding: "1em",
              marginLeft: "1em",
              minWidth: "150px",
              maxWidth: max_width + "px",
              borderRadius: "10px",
              backgroundColor: "white",
            }
      }
    >
      <Typography variant={mode == "owner" ? "h4" : "h4"} color="textPrimary">
        {list_name}
      </Typography>

      {typeof state_descr != "undefined" && (
        <>
          {mode == "owner" ? (
            <div
              className={classes.editableDiv}
              style={{ padding: "0.5em", marginTop: "0.5em" }}
              onClick={() => {
                setOpenDescrDialog(true);
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: "large" }}
              >
                {state_descr}
              </Typography>
            </div>
          ) : (
            <div style={{ padding: "0.5em", marginTop: "0.5em" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: "large" }}
              >
                {list_descr}
              </Typography>
            </div>
          )}
        </>
      )}
      {!is_UList && (
        <>
          {!is_public && mode == "owner" && (
            <div style={{ paddingTop: "2em" }}>
              <IconButton color="secondary" aria-label="set private">
                <LockIcon />
              </IconButton>
              <IconButton
                aria-label={
                  global.lang == "en" ? "set public" : "rendre public"
                }
                onClick={() => {
                  setIsPublic(true);
                  setPublicDB(true, id_list);
                }}
                style={{ color: "gray" }}
              >
                <PublicIcon />
              </IconButton>
            </div>
          )}

          {is_public && mode == "owner" && (
            <div style={{ paddingTop: "2em", display: "flex" }}>
              <Grid container>
                <Grid item md={12} lg={4}>
                  <IconButton
                    aria-label={
                      global.lang == "en" ? "set private" : "rendre privé"
                    }
                    onClick={() => {
                      setIsPublic(false);
                      setPublicDB(false, id_list);
                    }}
                    style={{ color: "gray" }}
                  >
                    <LockIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label={
                      global.lang == "en" ? "set public" : "rendre public"
                    }
                  >
                    <PublicIcon />
                  </IconButton>
                </Grid>

                <Grid item md={12} lg={8}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "1em",
                      transformOrigin: "left",
                      transform: "scale(0.7)",
                      width: "120%",
                    }}
                  >
                    <TextField
                      id="id_public_url"
                      label="URL"
                      value={url}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkIcon />
                          </InputAdornment>
                        ),
                      }}
                      style={{ width: "300px" }}
                    />
                    <IconButton
                      aria-label={
                        global.lang == "en"
                          ? "copy to clipboard"
                          : "copier dans le clipboard"
                      }
                      onClick={() => copyURL()}
                      style={{ transform: "rotate(90deg)" }}
                    >
                      <FilterNoneIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}

      <div
        style={
          is_supperp
            ? {
                display: "flex",
                flexDirection: "column",
              }
            : {
                display: "flex",
                flexDirection: "column",
              }
        }
      >
        <div style={{ display: "flex" }}>
          <IconButton disabled>
            <EventIcon style={{ color: "gray" }}></EventIcon>
          </IconButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "1em",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {mode == "visitor" && (
                <>
                  {global.lang == "en" && "Created "}
                  {global.lang == "fr" && "Créée "}
                </>
              )}
              {unixToShortHuman(crea_date)}
            </Typography>
          </div>
        </div>

        {is_public && (
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ display: "flex" }}>
              <IconButton disabled>
                <VisibilityIcon style={{ color: "gray" }}></VisibilityIcon>
              </IconButton>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingLeft: "0.4em", paddingTop: "0.17em" }}
                >
                  {views}
                </Typography>
              </div>
            </div>

            <div style={{ display: "flex", paddingLeft: "1em" }}>
              <IconButton disabled>
                <GroupIcon style={{ color: "gray" }}></GroupIcon>
              </IconButton>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingLeft: "0.4em", paddingTop: "0.17em" }}
                >
                  {followers}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>

      {typeof added_date != "undefined" && (
        <>
          <div style={{ display: "flex" }}>
            <IconButton disabled>
              <EventIcon style={{ color: "gray" }}></EventIcon>
            </IconButton>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="textSecondary">
                {global.lang == "en" && "Followed since "}
                {global.lang == "fr" && "Suivie depuis "}
                {unixToShortHuman(added_date)}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Link
              variant="contained"
              onClick={() => {
                setUnfollowDial(true);
                console.log("unfollowAListDB " + id_list);
              }}
              style={{ cursor: "pointer", fontSize: "14px" }}
            >
              {global.lang == "en"
                ? "Unfollow this list"
                : "Ne plus suivre cette liste"}
            </Link>
          </div>
        </>
      )}

      <Dialog
        open={open_descr_dialog}
        onClose={() => setOpenDescrDialog(false)}
      >
        <div
          style={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2em",
            }}
          >
            <TextareaAutosize
              rowsMax={8}
              defaultValue={state_descr}
              style={{ width: "400px" }}
              onChange={(event) => {
                console.log(event.currentTarget.value);
                setTempDescr(event.currentTarget.value);
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "400px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDescrDialog(false);
                }}
                style={{ width: "150px" }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "150px" }}
                onClick={() => {
                  setStateDescr(temp_descr);
                  setListDescrDB(id_list, temp_descr);
                  setOpenDescrDialog(false);
                }}
              >
                save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={open_unfollow_dial} onClose={() => setUnfollowDial(false)}>
        <DialogTitle>
          {global.lang == "en" && "Confirm you want to unfollow this list"}
          {global.lang == "fr" && "Ne plus suivre cette liste"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setUnfollowDial(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              unfollowAListDB(id_list);
              window.location.href = url;
              /* setUnfollowDial(false); */
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "Je confirme"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function RShare({
  url_list_name,
  url_pseudo,
  is_public,
  id_list,
  setIsPublic,
  cont_code,
  // mode="owner" or "visitor"
  mode = "owner",
  mode_repr = "default",
}) {
  const [open_snack, setOpenSnack] = useState(false);
  const [snack_mess, setSnackMess] = useState("");
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const url = server_url + "p/" + url_pseudo + "/" + url_list_name;

  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  //const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {mode_repr == "short_hor" && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Typography id="id_cont_code" variant="h3" color="textSecondary">
            #{cont_code.toUpperCase()}
          </Typography>
        </div>
      )}
      <div
        style={
          mode_repr == "short_hor"
            ? isPhoneV
              ? {
                  backgroundColor: "white",
                  borderRadius: "10px",
                }
              : {
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                }
            : { backgroundColor: "white", borderRadius: "10px" }
        }
      >
        {is_public && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "1em",
              }}
            >
              <Link href={url} underline="hover" target="_blank">
                {global.lang == "fr"
                  ? "voir version publique"
                  : "go to public version"}
              </Link>
            </div>
            {mode_repr != "short_hor" && (
              <div
                style={
                  h_global_tuto_step["tuto_step"] == 12
                    ? {
                        display: "flex",
                        justifyContent: "space-around",
                        position: "relative",
                        zIndex: 507,
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-around",
                      }
                }
              >
                <Typography
                  id="id_cont_code"
                  variant="h3"
                  color="textSecondary"
                >
                  #{cont_code.toUpperCase()}
                </Typography>
              </div>
            )}
          </>
        )}

        {is_public && mode == "owner" && (
          <div
            style={
              h_global_tuto_step["tuto_step"] == 13
                ? {
                    display: "flex",
                    justifyContent: "space-around",
                    zIndex: 507,
                    position: "relative",
                    backgroundColor: "white",
                  }
                : { display: "flex", justifyContent: "space-around" }
            }
          >
            <div
              id="id_url_tocopy"
              style={{
                display: "flex",
                marginLeft: "1em",
                transformOrigin: "center",
                transform: "scale(0.7)",
                width: "120%",
              }}
            >
              <TextField
                id="id_public_url"
                label="URL"
                value={url}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ width: "300px", fontWeight: 400 }}
              />
              <IconButton
                aria-label={
                  global.lang == "en"
                    ? "copy to clipboard"
                    : "copier dans le clipboard"
                }
                onClick={() => copyURL()}
                style={{ transform: "rotate(90deg)" }}
              >
                <FilterNoneIcon />
              </IconButton>
            </div>
          </div>
        )}

        {!is_public && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ marginBottom: "0.2em", marginTop: "0.4em" }}
            >
              {global.lang == "fr" ? "Liste privée" : "Private list"}
            </Typography>
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {!is_public && mode == "owner" && (
            <div>
              <IconButton color="secondary" aria-label="set private">
                <LockIcon />
              </IconButton>
              <IconButton
                aria-label={
                  global.lang == "en" ? "set public" : "rendre public"
                }
                onClick={() => {
                  setIsPublic(true);
                  setPublicDB(true, id_list);
                  if (global.lang == "fr") {
                    setSnackMess("Votre liste est publique");
                    setOpenSnack(true);
                  }
                  if (global.lang == "en") {
                    setSnackMess("Your list is public");
                    setOpenSnack(true);
                  }
                }}
                style={{ color: "gray" }}
              >
                <PublicIcon />
              </IconButton>
            </div>
          )}
          {is_public && mode == "owner" && (
            <div style={{ display: "flex" }}>
              <IconButton
                aria-label={
                  global.lang == "en" ? "set private" : "rendre privé"
                }
                onClick={() => {
                  setIsPublic(false);
                  setPublicDB(false, id_list);
                  if (global.lang == "fr") {
                    setSnackMess("Votre liste est privée");
                    setOpenSnack(true);
                  }
                  if (global.lang == "en") {
                    setSnackMess("Your list is private");
                    setOpenSnack(true);
                  }
                }}
                style={{ color: "gray" }}
              >
                <LockIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label={
                  global.lang == "en" ? "set public" : "rendre public"
                }
              >
                <PublicIcon />
              </IconButton>
            </div>
          )}
        </div>
        <Snackbar
          open={open_snack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snack_mess}
          action={action}
        />
        {h_global_tuto_step["tuto_step"] == 12 && (
          <>
            <div
              style={{
                animation: "mymove 1s infinite",
                animationDirection: "alternate",
                position: "absolute",
                zIndex: 507,
                top: h_global_tuto_step["top_arrow"] + 10 + "px",
                left: h_global_tuto_step["left_arrow"] - 20 + "px",
                width: "10px",
                height: "10px",
              }}
            >
              <img src="../../im/red_arrow.png"></img>
            </div>
            <div
              style={{
                animation: "mymove 1s infinite",
                animationDirection: "alternate",
                position: "absolute",
                zIndex: 507,
                top: h_global_tuto_step["top_arrow2"] + 10 + "px",
                left: h_global_tuto_step["left_arrow2"] - 20 + "px",
                width: "10px",
                height: "10px",
              }}
            >
              <img src="../../im/red_arrow.png"></img>
            </div>
          </>
        )}
        {h_global_tuto_step["tuto_step"] == 13 && (
          <>
            <div
              style={{
                animation: "mymove 1s infinite",
                animationDirection: "alternate",
                position: "absolute",
                zIndex: 507,
                top: h_global_tuto_step["top_arrow"] + 6 + "px",
                left: h_global_tuto_step["left_arrow"] - 20 + "px",
                width: "10px",
                height: "10px",
              }}
            >
              <img src="../../im/red_arrow.png"></img>
            </div>
          </>
        )}
        {h_global_tuto_step["tuto_step"] == 14 && (
          <>
            <div
              style={{
                animation: "mymove 1s infinite",
                animationDirection: "alternate",
                position: "absolute",
                zIndex: 508,
                top: h_global_tuto_step["top_arrow"] + 6 + "px",
                left: h_global_tuto_step["left_arrow"] - 20 + "px",
                width: "10px",
                height: "10px",
              }}
            >
              <img src="../../im/red_arrow.png"></img>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function LToolBar({ deleteQuestionsDom }) {
  const [id_selected_list, setIdSelectedList] = useState(-1);
  const [open_snack, setOpenSnack] = useState(false);
  const [snack_message, setSnackMessage] = useState("");
  const [is_checkbox_checked, setIsCheckboxChecked] = useState(false);
  const [selected_number, setSelectedNumber] = useState();
  const [open_del_diag, setOpenDelDiag] = useState(false);

  const h_redux_my_lists = useSelector((state) => state.global_lists);
  const h_redux_my_questions = useSelector((state) => state.global_questions);
  console.log(h_redux_my_lists);

  const dispatch = useDispatch();

  useEffect(() => {
    setIdSelectedList(h_redux_my_lists["a_own_lists"][0][0]);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "rgb(246,248,250)",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <Checkbox
          color="primary"
          onChange={(event) => {
            setIsCheckboxChecked(event.target.checked);
            dispatch(
              setReduxAllSelectedQuestions({
                is_selected: event.target.checked,
              })
            );
            console.log(event.target.checked);
          }}
          checked={is_checkbox_checked}
        ></Checkbox>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Button
            size="small"
            style={{
              border: "1px solid lightgray",
              transform: "scale(0.7)",
              color: "#4D5156",
            }}
            color="textSecondary"
            onClick={() => {
              let a_temp_selected_questions = [];
              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                }
              );
              cloneQuestionsToListDB(
                a_temp_selected_questions,
                id_selected_list
              );
              let list_name = "";
              h_redux_my_lists["a_own_lists"].map((row) => {
                if (row[0] == id_selected_list) {
                  list_name = row[2];
                }
              });
              setSnackMessage("Questions copied to " + list_name);
              setOpenSnack(true);
              setIsCheckboxChecked(false);
              dispatch(
                setReduxAllSelectedQuestions({
                  is_selected: false,
                })
              );
            }}
          >
            {global.lang == "en"
              ? "copy selected questions to"
              : "copier les questions dans"}
          </Button>
          {/* <Button
            style={{
              border: "1px solid lightgray",
              transform: "scale(0.7)",
              color: "#4D5156",
            }}
            size="small"
            onClick={() => {
              let a_temp_selected_questions = [];
              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                }
              );
              moveQuestionsToListDB(
                a_temp_selected_questions,
                id_selected_list
              );
              let list_name = "";
              h_redux_my_lists["a_own_lists"].map((row) => {
                if (row[0] == id_selected_list) {
                  list_name = row[2];
                }
              });
              setSnackMessage("Questions moved to " + list_name);
              setOpenSnack(true);

              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    deleteQuestionsDom(k);
                  }
                }
              );
              setIsCheckboxChecked(false);
              dispatch(
                setReduxAllSelectedQuestions({
                  is_selected: false,
                })
              );
            }}
          >
            {global.lang == "en"
              ? "move selected questions to"
              : "déplacer les questions dans"}
          </Button> */}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            value={id_selected_list}
            onChange={(event) => {
              setIdSelectedList(event.target.value);
            }}
            style={{
              width: "150px",
              color: "#4D5156",
              transform: "scale(0.7)",
              transformOrigin: "left",
            }}
          >
            {h_redux_my_lists["a_own_lists"].map((row) => {
              return <MenuItem value={row[0]}>{row[2]}</MenuItem>;
            })}
          </Select>
        </div>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", paddingRight: "1em" }}
      >
        <Button
          size="small"
          style={{
            border: "1px solid lightgray",
            transform: "scale(0.7)",
            color: "#4D5156",
            transformOrigin: "right",
          }}
          color="textSecondary"
          onClick={() => {
            let a_temp_selected_questions = [];
            Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
              (k) => {
                let v = h_redux_my_questions["h_selected_questions"][k];
                if (v) {
                  a_temp_selected_questions.push(k);
                }
              }
            );
            setSelectedNumber(a_temp_selected_questions.length);
            setOpenDelDiag(true);
          }}
        >
          {global.lang == "en"
            ? "delete selected questions"
            : "supprimer la selection"}
        </Button>
      </div>

      <Snackbar
        open={open_snack}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnack(false);
        }}
        message={snack_message}
      ></Snackbar>

      <Dialog
        open={open_del_diag}
        onClose={() => setOpenDelDiag(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle>
          {global.lang == "en" &&
            "Confirm you want to delete these " +
              selected_number +
              " questions"}
          {global.lang == "fr" &&
            "Confirmer la suppression de " + selected_number + " questions"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDelDiag(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              let a_temp_selected_questions = [];
              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                }
              );
              console.log(a_temp_selected_questions);
              deleteQuestionsDB(a_temp_selected_questions);
              deleteQuestionsDom(a_temp_selected_questions);
              dispatch(
                setReduxAllSelectedQuestions({
                  is_selected: false,
                })
              );
              setSnackMessage(
                a_temp_selected_questions.length +
                  " questions removed from list"
              );
              setOpenDelDiag(false);
              setIsCheckboxChecked(false);
              setOpenSnack(true);
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "Je confirme"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function LQuestion({
  id_question,
  score,
  statement,
  answer,
  deleteQuestion,
  a_questions,
  setQuestions,
  has_pic,
  pic_url,
  pic_url_800,
  deleteQuestionDom,
  setOpenImgDial,
  setMedia800,
  open_tools = true,
}) {
  const [id_selected_list, setIdSelectedList] = useState(-1);
  const [open_snack, setOpenSnack] = useState(false);
  const [snack_message, setSnackMessage] = useState("");
  const [open_statement_dialog, setOpenStatementDialog] = useState(false);
  const [open_answer_dialog, setOpenAnswerDialog] = useState(false);
  const [temp_statement, setTempStatement] = useState();
  const [temp_answer, setTempAnswer] = useState();
  const [disp_img_loading, setDispImgLoading] = useState(false);
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const [open_del_diag, setOpenDelDiag] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const h_redux_my_lists = useSelector((state) => state.global_lists);
  console.log(h_redux_my_lists);
  const h_redux_my_questions = useSelector((state) => state.global_questions);
  console.log(h_redux_my_questions);

  useEffect(() => {
    setIdSelectedList(h_redux_my_lists["a_own_lists"][0][0]);
    dispatch(
      setReduxSelectedQuestion({
        is_selected: false,
        id_question: id_question,
      })
    );
  }, []);

  console.log(h_redux_my_questions["h_selected_questions"]);
  console.log(h_redux_my_questions["h_selected_questions"][id_question]);
  console.log(id_question);

  return (
    <div>
      <div
        style={
          isPhoneV
            ? {
                display: "flex",
                flexDirection: "column",
                marginBottom: "1em",
                backgroundColor: "white",
                border: "1px solid lightgray",
              }
            : { display: "flex" }
        }
      >
        {open_tools && (
          <Checkbox
            color="primary"
            onChange={(event) => {
              dispatch(
                setReduxSelectedQuestion({
                  is_selected: event.target.checked,
                  id_question: id_question,
                })
              );
            }}
            checked={
              h_redux_my_questions["h_selected_questions"][id_question]
                ? true
                : false
            }
          ></Checkbox>
        )}
        <div
          style={
            isPhoneV
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }
              : { display: "flex", alignItems: "center" }
          }
        >
          <ScoreBullet score={Math.min(score, 2)}></ScoreBullet>
        </div>

        <div
          style={{
            display: "flex",
            width: isPhoneV ? "auto" : "400px",
            padding: "12px",
            justifyContent: "space-around",
          }}
        >
          <div
            className={
              has_pic
                ? classes.editableDivStatementPic
                : classes.editableDivStatementNoPic
            }
            onClick={() => {
              setOpenStatementDialog(true);
            }}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {statement.length < 17 && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={
                  isPhoneV
                    ? { fontSize: "xx-large" }
                    : { fontSize: "xx-large", marginRight: "0.5em" }
                }
              >
                {statement}
              </Typography>
            )}
            {statement.length >= 17 && statement.length <= 50 && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={
                  isPhoneV
                    ? { fontSize: has_pic ? "x-large" : "xx-large" }
                    : {
                        fontSize: has_pic ? "x-large" : "xx-large",
                        marginRight: "0.5em",
                      }
                }
              >
                {statement}
              </Typography>
            )}
            {statement.length > 50 && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={
                  isPhoneV
                    ? { fontSize: "large" }
                    : { fontSize: "large", marginRight: "0.5em" }
                }
              >
                {statement}
              </Typography>
            )}
          </div>
          {has_pic && (
            <>
              {!disp_img_loading ? (
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    src={pic_url}
                    style={{
                      maxWidth: "300px",
                      maxHeight: "200px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMedia800(pic_url_800);
                      setOpenImgDial(true);
                    }}
                    onError={() => {
                      console.log("error on image");
                      setDispImgLoading(true);
                    }}
                  ></img>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                  }}
                >
                  <Typography variant="body2">
                    {global.lang == "en"
                      ? "picture processing"
                      : "image en traitement"}
                  </Typography>
                  <CircularProgress></CircularProgress>
                </div>
              )}
            </>
          )}
        </div>
        <div
          style={
            isPhoneV ? { padding: "12px" } : { width: "280px", padding: "12px" }
          }
        >
          {answer === "1f9e" ? (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography
                variant="body2"
                style={{ fontSize: "XXx-large", color: "red" }}
              >
                ?
              </Typography>
            </div>
          ) : (
            <div
              className={classes.editableDiv}
              onClick={() => {
                setOpenAnswerDialog(true);
              }}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {answer.length < 17 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "xx-large" }}
                >
                  {answer}
                </Typography>
              )}
              {answer.length >= 17 && answer.length <= 28 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "x-large" }}
                >
                  {answer}
                </Typography>
              )}
              {answer.length > 28 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "large" }}
                >
                  {answer}
                </Typography>
              )}
            </div>
          )}
        </div>

        <div
          style={
            isPhoneV
              ? {
                  backgroundColor: "rgb(246,248,250)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1em",
                }
              : {
                  backgroundColor: "rgb(246,248,250)",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <div
            style={
              open_tools
                ? {
                    width: "340px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
                : {
                    width: "170px",
                    display: "flex",
                    justifyContent: "space-around",
                  }
            }
          >
            {open_tools && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "64px",
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      if (id_selected_list != -1) {
                        cloneQuestionsToListDB([id_question], id_selected_list);
                        let list_name = "";
                        h_redux_my_lists["a_own_lists"].map((row) => {
                          if (row[0] == id_selected_list) {
                            list_name = row[2];
                          }
                        });
                        setSnackMessage("Question added to " + list_name);
                        setOpenSnack(true);
                      }
                    }}
                    style={{
                      border: "1px solid lightgray",
                      width: "150px",
                      transform: "scale(0.7)",
                      color: "#4D5156",
                    }}
                  >
                    {global.lang == "en" ? "copy to" : "copier dans"}
                  </Button>
                  {/*                 <Button
                  size="small"
                  onClick={() => {
                    if (id_selected_list != -1) {
                      moveQuestionsToListDB([id_question], id_selected_list);
                      deleteQuestionDom(id_question);
                      let list_name = "";
                      h_redux_my_lists["a_own_lists"].map((row) => {
                        if (row[0] == id_selected_list) {
                          list_name = row[2];
                        }
                      });
                      setSnackMessage("Question moved to " + list_name);
                      setOpenSnack(true);
                    }
                  }}
                  style={{
                    border: "1px solid lightgray",
                    width: "150px",
                    transform: "scale(0.7)",
                    color: "#4D5156",
                  }}
                >
                  {global.lang == "en" ? "move to" : "déplacer dans"}
                </Button> */}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    value={id_selected_list}
                    onChange={(event) => {
                      setIdSelectedList(event.target.value);
                    }}
                    style={{
                      width: "150px",
                      transform: "scale(0.7)",
                      transformOrigin: "left",
                      color: "#4D5156",
                    }}
                  >
                    {h_redux_my_lists["a_own_lists"].map((row) => {
                      return <MenuItem value={row[0]}>{row[2]}</MenuItem>;
                    })}
                  </Select>
                </div>
              </div>
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label={
                  global.lang == "en"
                    ? "delete question"
                    : "supprimer la question"
                }
                style={isPhoneV ? {} : { transform: "scale(0.7)" }}
                color="gray"
              >
                <DeleteIcon
                  onClick={() => {
                    setOpenDelDiag(true);
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open_snack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
        message={snack_message}
      ></Snackbar>
      <Divider></Divider>
      <Dialog
        open={open_statement_dialog}
        onClose={() => setOpenStatementDialog(false)}
      >
        <div
          style={{
            width: isPhoneV ? "200px" : "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: isPhoneV ? "1em" : "2em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2em",
              marginTop: isPhoneV ? "2em" : "0",
            }}
          >
            <TextareaAutosize
              rowsMax={4}
              defaultValue={statement}
              style={{ width: isPhoneV ? "100%" : "400px" }}
              onChange={(event) => {
                console.log(event.currentTarget.value);
                setTempStatement(event.currentTarget.value);
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: isPhoneV ? "100%" : "400px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={
                  isPhoneV
                    ? {
                        width: "100%",
                        fontSize: "x-large",
                        marginBottom: "1em",
                      }
                    : { width: "150px" }
                }
                onClick={() => {
                  if (typeof temp_statement != "undefined") {
                    let a_temp_questions = [...a_questions];
                    a_temp_questions.forEach((row) => {
                      if (row[0] == id_question) {
                        row[3] = temp_statement;
                      }
                    });
                    setQuestions(a_temp_questions);
                    setQuestionStatementDB(id_question, temp_statement);
                    setTempStatement();
                  }
                  setOpenStatementDialog(false);
                }}
              >
                save
              </Button>
              {!isPhoneV && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenStatementDialog(false);
                  }}
                  style={{ width: "150px" }}
                >
                  cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={open_answer_dialog}
        onClose={() => setOpenAnswerDialog(false)}
      >
        <div
          style={{
            width: isPhoneV ? "200px" : "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: isPhoneV ? "1em" : "2em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2em",
              marginTop: isPhoneV ? "2em" : "0",
            }}
          >
            <TextareaAutosize
              rowsMax={4}
              defaultValue={answer}
              style={{ width: isPhoneV ? "100%" : "400px" }}
              onChange={(event) => {
                console.log(event.currentTarget.value);
                setTempAnswer(event.currentTarget.value);
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: isPhoneV ? "100%" : "400px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={
                  isPhoneV
                    ? {
                        width: "100%",
                        fontSize: "x-large",
                        marginBottom: "1em",
                      }
                    : { width: "150px" }
                }
                onClick={() => {
                  if (typeof temp_answer != "undefined") {
                    let a_temp_questions = [...a_questions];
                    a_temp_questions.forEach((row) => {
                      if (row[0] == id_question) {
                        row[4] = temp_answer;
                      }
                    });
                    setQuestions(a_temp_questions);
                    setQuestionAnswerDB(id_question, temp_answer);
                    setTempAnswer();
                  }
                  setOpenAnswerDialog(false);
                }}
              >
                save
              </Button>
              {!isPhoneV && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenAnswerDialog(false);
                  }}
                  style={{ width: "150px" }}
                >
                  cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={open_del_diag} onClose={() => setOpenDelDiag(false)}>
        <Typography variant="h4" style={{ padding: "1em 1em 0 1em" }}>
          {global.lang == "en" && "Confirm you want to delete this item"}
          {global.lang == "fr" &&
            "Voulez-vous vraiment supprimer cette entrée?"}
        </Typography>
        <DialogActions style={{ padding: "2em" }}>
          <Button
            onClick={() => setOpenDelDiag(false)}
            color="primary"
            style={{ marginRight: "2em" }}
          >
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Non"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteQuestion(id_question);
              deleteQuestionDom(id_question);
              setOpenDelDiag(false);
            }}
            color="primary"
            style={{ marginRight: "1em" }}
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "Oui"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
