export const unixToHuman = (unix_time) => {
  console.log(unix_time);
  let date = new Date(unix_time * 1000);
  let month = date.getMonth();
  let day = date.getDay();
  let day_of_month = date.getDate();
  day_of_month = ("0" + day_of_month).slice(-2);

  let year = date.getFullYear();
  let today = new Date();

  let a_en_months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let a_fr_months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  let a_fr_days = [
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
    "dimanche",
  ];
  let a_en_days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  if (global.lang == "fr") {
    let result =
      a_fr_days[day] + " " + day_of_month + " " + a_fr_months[month] + " UTC";
    if (today.getFullYear() != year) {
      result += " " + year;
    }
    return result;
  }

  let result =
    a_en_days[day] + ", " + a_en_months[month] + " " + day_of_month + " UTC";
  if (today.getFullYear() != year) {
    result += " " + year;
  }
  return result;
};

export const unixToShortHuman = (unix_time) => {
  console.log(unix_time);
  let date = new Date(unix_time * 1000);
  let month = date.getMonth();
  let day_of_month = date.getDate();
  day_of_month = ("0" + day_of_month).slice(-2);

  let year = date.getFullYear();
  let today = new Date();

  let a_en_months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let a_fr_months = [
    "Jan",
    "Fév",
    "Mars",
    "Avr",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];

  if (global.lang == "fr") {
    let result = day_of_month + " " + a_fr_months[month];
    if (today.getFullYear() != year) {
      result += " " + year;
    }
    return result;
  }

  let result = a_en_months[month] + " " + day_of_month;
  if (today.getFullYear() != year) {
    result += " " + year;
  }
  return result;
};

export const unixToVeryShortHuman = (unix_time) => {
  console.log(unix_time);
  let date = new Date(unix_time * 1000);
  let month = date.getMonth();
  let year = date.getFullYear();
  let today = new Date();

  let a_en_months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let a_fr_months = [
    "Jan",
    "Fév",
    "Mars",
    "Avr",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];

  if (global.lang == "fr") {
    let result = a_fr_months[month]+ " " + year;
    return result;
  }

  let result = a_en_months[month] + " " + year;
  return result;
};

export const unixToDelay = (seconds) => {
  console.log(seconds);
  let result;
  if (seconds < 60) {
    if (global.lang == "fr") {
      return "< 1 min";
    }
    if (global.lang == "en") {
      return "< 1 min";
    }
  }
  if (seconds < 60 * 60) {
    result = Math.floor(seconds / 60);
    if (global.lang == "fr") {
      return result + " min";
    }
    if (global.lang == "en") {
      return result + " min";
    }
  }
  if (seconds < 60 * 60 * 24) {
    result = Math.floor(seconds / (60 * 60));
    if (global.lang == "fr") {
      if (result == 1) {
        return result + " hour";
      } else {
        return result + " hours";
      }
    }
    if (global.lang == "en") {
      if (result == 1) {
        return result + " hour";
      } else {
        return result + " hours";
      }
    }
  }
  if (seconds < 60 * 60 * 24 * 7) {
    result = Math.floor(seconds / (60 * 60 * 24));
    if (global.lang == "fr") {
      if (result == 1) {
        return result + " jour";
      } else {
        return result + " jours";
      }
    }
    if (global.lang == "en") {
      if (result == 1) {
        return result + " day";
      } else {
        return result + " days";
      }
    }
  }
  if (seconds < 60 * 60 * 24 * 30) {
    result = Math.floor(seconds / (60 * 60 * 24 * 7));
    if (global.lang == "fr") {
      return result + " sem";
    }
    if (global.lang == "en") {
      if (result == 1) {
        return result + " week";
      } else {
        return result + " weeks";
      }
    }
  }
  if (seconds < 60 * 60 * 24 * 365) {
    result = Math.floor(seconds / (60 * 60 * 24 * 30));
    if (global.lang == "fr") {
      return result + " mois";
    }
    if (global.lang == "en") {
      if (result == 1) {
        return result + " month";
      } else {
        return result + " months";
      }
    }
  }
  if (seconds < 60 * 60 * 24 * 365) {
    result = Math.floor(seconds / (60 * 60 * 24 * 365));
    if (global.lang == "fr") {
      if (result == 1) {
        return result + " an";
      } else {
        return result + " ans";
      }
    }
    if (global.lang == "en") {
      if (result == 1) {
        return result + " year";
      } else {
        return result + " years";
      }
    }
  }
  return result;
};
