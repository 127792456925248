import React, { useState, useRef, useEffect } from "react";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { setScoreDB, setAScoreDB, setWallTrainListDB } from "../Generic/lists";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { ScoreChips, ScoreBullet, SG, SO, SR } from "../SmallComponents/SmallComponents";
import { useDispatch } from "react-redux";
import { setReduxScore, setReduxShortStats } from "../redux/globalScores";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

var current_question = [];
// All questions
var ga_questions = [];
var gh_questions = {};
// ids of all green questions
var a_green_ids = [];
// ids of all orange questions
var a_orange_ids = [];
// ids of all red questions
var a_red_ids = [];
// ids of all considered green questions
var a_cons_green_ids = [];
// ids of all considered orange questions
var a_cons_orange_ids = [];
// ids of all considered red questions
var a_cons_red_ids = [];

// ids of questions not considered
var a_left_orange = [];
var a_left_red = [];

var a_super_green_ids = [];

var a_super_green_random = [];
var super_green_index = -1;

function fillConsQuestions() {
  if (a_cons_red_ids.length + a_cons_orange_ids.length < 5) {
    if (a_cons_red_ids.length < 3) {
      if (a_left_red.length > 0) {
        let index = Math.floor(Math.random() * a_left_red.length);
        a_cons_red_ids.push(a_left_red.splice(index, 1)[0]);
      }
    }
    if (a_cons_orange_ids.length < 2) {
      if (a_left_orange.length > 0) {
        let index = Math.floor(Math.random() * a_left_orange.length);
        a_cons_orange_ids.push(a_left_orange.splice(index, 1)[0]);
      }
    }
  }
}

function setScoreEverywhereQuick(id, score_before, score_after) {
  console.log(
    "setScoreEverywhereQuick " + id + " " + score_after + " " + score_before
  );
  if (score_before == score_after) {
    console.log("score_before == score_after");
    return;
  }
  if (score_before == 0) {
    console.log("setScoreEverywhereQuick red before " + a_cons_red_ids);
    a_cons_red_ids.splice(a_cons_red_ids.indexOf(id), 1);
    console.log("setScoreEverywhereQuick red after " + a_cons_red_ids);
    a_red_ids.splice(a_red_ids.indexOf(id), 1);
  }
  if (score_before == 1) {
    console.log("setScoreEverywhereQuick orange before " + a_cons_orange_ids);
    a_cons_orange_ids.splice(a_cons_orange_ids.indexOf(id), 1);
    console.log("setScoreEverywhereQuick orange after " + a_cons_orange_ids);
    a_orange_ids.splice(a_orange_ids.indexOf(id), 1);
  }
  if (score_before == 2) {
    a_cons_green_ids.splice(a_cons_green_ids.indexOf(id), 1);
    a_green_ids.splice(a_green_ids.indexOf(id), 1);
  }
  if (score_before == 3) {
    a_super_green_ids.splice(a_super_green_ids.indexOf(id), 1);
  }

  if (score_after == 0) {
    a_cons_red_ids.push(id);
    a_red_ids.push(id);
  }
  if (score_after == 1) {
    a_cons_orange_ids.push(id);
    a_orange_ids.push(id);
  }
  if (score_after == 2) {
    a_cons_green_ids.push(id);
    a_green_ids.push(id);
  }
  if (score_after == 3) {
    a_super_green_ids.push(id);
  }

  gh_questions[id][6] = score_after;
  ga_questions.forEach((row, index) => {
    if (row[0] == id) {
      row[6] = score_after;
    }
  });
}

function pickAQuestion() {
  if (
    a_super_green_ids.length +
      a_cons_red_ids.length +
      a_cons_orange_ids.length +
      a_cons_green_ids.length ==
    1
  ) {
    let id;
    if (a_super_green_ids.length == 1) {
      id = a_super_green_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_red_ids.length == 1) {
      id = a_cons_red_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_orange_ids.length == 1) {
      id = a_cons_orange_ids[0];
      current_question = gh_questions[id];
    }
    if (a_cons_green_ids.length == 1) {
      id = a_cons_green_ids[0];
      current_question = gh_questions[id];
    }

    console.log(current_question);
    return;
  }

  const pick = () => {
    let x = Math.random();
    let x2 = Math.random();
    let id;
    if (x2 > 0.33 && a_cons_red_ids.length + a_cons_orange_ids.length == 1) {
      return id;
    }
    if (x2 > 0.67 && a_cons_red_ids.length + a_cons_orange_ids.length == 2) {
      return id;
    }
    if (x < 0.12 && a_cons_green_ids.length > 0) {
      id =
        a_cons_green_ids[Math.floor(Math.random() * a_cons_green_ids.length)];
    } else {
      if (x < 0.56 && a_cons_orange_ids.length > 0) {
        id =
          a_cons_orange_ids[
            Math.floor(Math.random() * a_cons_orange_ids.length)
          ];
      } else {
        if (a_cons_red_ids.length > 0) {
          id =
            a_cons_red_ids[Math.floor(Math.random() * a_cons_red_ids.length)];
        }
      }
    }

    if (typeof id == "undefined") {
      if (x < 0.75 && a_cons_green_ids.length > 0) {
        id =
          a_cons_green_ids[Math.floor(Math.random() * a_cons_green_ids.length)];
      }
    }

    return id;
  };

  let id = pick();

  const feedSuperGreenRandom = () => {
    console.log(a_super_green_ids);
    super_green_index = 0;
    a_super_green_random = Object.keys(a_super_green_ids).sort(function () {
      return 0.5 - Math.random();
    });
    console.log(a_super_green_random);
  };

  if (typeof id == "undefined") {
    if (
      super_green_index == -1 ||
      super_green_index >= a_super_green_random.length ||
      a_super_green_random.length != a_super_green_ids.length
    ) {
      feedSuperGreenRandom();
    }
    console.log(a_super_green_random);
    console.log(a_super_green_ids);
    console.log(super_green_index);
    id = a_super_green_ids[a_super_green_random[super_green_index]];
    super_green_index++;
  } else {
    super_green_index = -1;
  }

  // Ugly move to try to avoid twice the same question
  console.log(gh_questions);
  console.log(id);
  console.log(current_question);
  console.log(super_green_index);
  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }

      id = a_super_green_ids[a_super_green_random[super_green_index]];
      super_green_index++;
    } else {
      super_green_index = -1;
    }
  }

  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }

      id = a_super_green_ids[a_super_green_random[super_green_index]];
      super_green_index++;
    } else {
      super_green_index = -1;
    }
  }

  if (typeof id == "undefined") {
    console.log("pick_new");
    pickAQuestion();
    return;
  }
  if (current_question[0] == gh_questions[id][0]) {
    id = pick();
    if (typeof id == "undefined") {
      if (
        super_green_index == -1 ||
        super_green_index >= a_super_green_random.length ||
        a_super_green_random.length != a_super_green_ids.length
      ) {
        feedSuperGreenRandom();
      }

      id = a_super_green_ids[a_super_green_random[super_green_index]];
      super_green_index++;
    } else {
      super_green_index = -1;
    }
  }

  current_question = gh_questions[id];
  console.log(current_question);
}

// objective: 2 oranges, 3 reds
function prepareQuestions() {
  a_green_ids = [];
  a_orange_ids = [];
  a_red_ids = [];

  a_cons_green_ids = [];
  a_cons_orange_ids = [];
  a_cons_red_ids = [];

  a_left_orange = [];
  a_left_red = [];

  a_super_green_ids = [];

  ga_questions.forEach((row, x) => {
    let id = row[0];
    let score = row[6];
    if (score == 2) {
      row[6] = 3;
    }
    gh_questions[id] = [...row];
    if (score == 0) {
      console.log("a_red_ids id push " + id);
      console.log(row);
      a_red_ids.push(id);
    }
    if (score == 1) {
      a_orange_ids.push(id);
    }
    if (score == 2) {
      a_super_green_ids.push(id);
    }
  });

  // Big mess to have 5 considered questions, trying to have 2 oranges, 3 reds
  let rn = 3;
  let on = 2;

  if (a_red_ids.length < 3) {
    rn = a_red_ids.length;
    if (a_orange_ids.length > 2) {
      on = Math.min(5, a_orange_ids.length);
    }
  }
  if (a_orange_ids.length < 2) {
    on = a_orange_ids.length;
  }

  let a_shuffled = a_red_ids.sort(() => 0.5 - Math.random());
  a_cons_red_ids = a_shuffled.slice(0, rn);
  a_left_red = a_shuffled.slice(rn, a_shuffled.length);
  // Put the latest added question if red
  if (ga_questions[0][6] == "0" && rn >= 1) {
    if (a_left_red.indexOf(ga_questions[0][0]) != -1) {
      a_left_red.splice(a_left_red.indexOf(ga_questions[0][0]), 1);
      a_cons_red_ids.push(ga_questions[0][0]);
    }
  }

  a_shuffled = a_orange_ids.sort(() => 0.5 - Math.random());
  a_cons_orange_ids = a_shuffled.slice(0, on);
  a_left_orange = a_shuffled.slice(on, a_shuffled.length);
}

function computeScore(isTrue) {
  let score_before = current_question[6];
  let score_after;
  let id = current_question[0];
  if (isTrue) {
    score_after = score_before - 0 + 1;
  } else {
    score_after = 0;
  }

  if (score_after == 4) {
    score_after = 3;
  }

  gh_questions[id][6] = score_after;
  console.log("new score");
  console.log(score_after);
  setScoreEverywhereQuick(id, score_before, score_after);
  return score_after;
}

const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function WTrain({ url_pseudo = "" }) {
  const [formAnswer, setFormAnswer] = useState("");
  const [realAnswer, setRealAnswer] = useState("");
  const [temp_realAnswer, setTempRealAnswer] = useState("");
  const [statement, setStatement] = useState("");
  const [media, setMedia] = useState("0");
  const [media_800, setMedia800] = useState("0");
  const [open_img_dial, setOpenImgDial] = useState(false);
  const [is_oops, setIsOops] = useState(false);
  const [green_score, setGreenScore] = useState("");
  const [orange_score, setOrangeScore] = useState("");
  const [red_score, setRedScore] = useState("");
  const [scoreBullet, setScoreBullet] = useState();
  const inputAnswer = useRef(null);

  const [a_questions, setQuestions] = useState();
  const [list_meta, setListMeta] = useState([-1]);
  const [a_lists, setLists] = useState([]);
  const [a_a_lists, setALists] = useState([]);
  const [mode, setMode] = useState("u");

  const [do_rerender, setDoRerender] = useState(false);

  const [is_toggled, setIsToggled] = useState(false);
  const [a_url_pseudo, setAURLPseudo] = useState("");

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const dispatch = useDispatch();
  const h_redux_scores = useSelector((state) => state.global_scores);

  const classes = useStyle();

  useEffect(() => {
    fetch(server_url + "/api/get_short_stats")
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          setReduxShortStats({
            mine_green: res["mine_ind_green"],
            mine_orange: res["mine_ind_orange"],
            mine_red: res["mine_ind_red"],
            other_green: res["other_ind_green"],
            other_orange: res["other_ind_orange"],
            other_red: res["other_ind_red"],
            pages_visits: res["pages_visits"],
            lists_visits: res["lists_visits"],
            following_lists: res["following_lists"],
            following_me: res["following_me"],
          })
        );
      });
    fetch(server_url + "/api/get_wall_list_and_questions")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setQuestions(res["a_questions"]);
        setListMeta(res["list_meta"]);
        setLists(res["a_lists"]);
        setALists(res["a_a_lists"]);
        setMode(res["mode"]);
        setDoRerender(true);
        setAURLPseudo(res["url_pseudo"]);
      });
  }, []);

  console.log(a_questions);
  if (typeof a_questions != "undefined") {
    if (typeof a_questions[0] != "undefined") {
      console.log(do_rerender);

      if (do_rerender) {
        console.log("render a_questions");
        console.log(a_questions);
        ga_questions = [...a_questions];
        prepareQuestions();
        pickAQuestion();

        console.log(current_question);
        setStatement(current_question[3]);
        setMedia(current_question[8]);
        if (mode == "u") {
          setMedia800(current_question[9]);
        }
        if (mode == "a") {
          setMedia800(current_question[9]);
        }
        setRealAnswer(current_question[4]);
        setGreenScore(a_green_ids.length + a_super_green_ids.length);
        console.log(a_green_ids.length + a_super_green_ids.length);
        setOrangeScore(a_orange_ids.length);
        setRedScore(a_red_ids.length);
        setScoreBullet(Math.min(current_question[6], 2));
        console.log("a_red_ids");
        console.log(a_red_ids);
        console.log("a_orange_ids");
        console.log(a_orange_ids);
        console.log("a_green_ids");
        console.log(a_green_ids);
        console.log("a_super_green_ids");
        console.log(a_super_green_ids);
        console.log("a_cons_red_ids");
        console.log(a_cons_red_ids);
        console.log("a_cons_orange_ids");
        console.log(a_cons_orange_ids);
        console.log("a_cons_green_ids");
        console.log(a_cons_green_ids);
        console.log("a_left_red");
        console.log(a_left_red);
        console.log("a_left_orange");
        console.log(a_left_orange);
        setDoRerender(false);
      }
    }
  }

  const processAnswer = () => {
    console.log(realAnswer == formAnswer);
    console.log(realAnswer);
    console.log(formAnswer);
    let pross_realAnswer = realAnswer;
    let is_correct = true;
    pross_realAnswer = pross_realAnswer.replace(/\t/g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/  /g, " ");
    pross_realAnswer = pross_realAnswer.replace(/ $/g, "");
    pross_realAnswer = pross_realAnswer.replace(/^ /g, "");
    pross_realAnswer = pross_realAnswer.replace(/, /g, ",");

    pross_realAnswer = pross_realAnswer.toLowerCase();
    let pross_formAnswer = formAnswer;
    pross_formAnswer = pross_formAnswer.replace(/\t/g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/  /g, " ");
    pross_formAnswer = pross_formAnswer.replace(/ $/g, "");
    pross_formAnswer = pross_formAnswer.replace(/^ /g, "");
    pross_formAnswer = pross_formAnswer.replace(/, /g, ",");
    pross_formAnswer = pross_formAnswer.toLowerCase();

    let a_split_re = pross_realAnswer.split(",");
    let a_split_fo = pross_formAnswer.split(",");

    a_split_fo.map((v) => {
      if (a_split_re.indexOf(v) == -1) {
        is_correct = false;
      }
    });
    computeScore(is_correct);

    // be carefull setScoreDB transforms score=3 to score=2 !!!!!!!!!!!
    if (mode == "u") {
      setScoreDB(current_question[0], Math.min(current_question[6], 2));
    }
    if (mode == "a") {
      setAScoreDB(current_question[0], Math.min(current_question[6], 2));
    }

    pickAQuestion();
    if (typeof current_question == "undefined") {
      window.location.reload();
    }
    setStatement(current_question[3]);
    setScoreBullet(Math.min(current_question[6], 2));
    setMedia(current_question[8]);
    if (mode == "u") {
      setMedia800(current_question[9]);
    }
    if (mode == "a") {
      setMedia800(current_question[9]);
    }
    setRealAnswer(current_question[4]);
    inputAnswer.current.value = "";
    setFormAnswer("");
    fillConsQuestions();

    let new_green_score = a_green_ids.length + a_super_green_ids.length;
    console.log(new_green_score);
    let new_orange_score = a_orange_ids.length;
    let new_red_score = a_red_ids.length;

    // compute the score for ShortStats
    console.log("bef list_meta[10] == 1");
    if (mode == "u") {
      // mine
      let old_mine_green = h_redux_scores["mine_green"];
      let old_mine_orange = h_redux_scores["mine_orange"];
      let old_mine_red = h_redux_scores["mine_red"];
      console.log(old_mine_green);

      dispatch(
        setReduxScore({
          score: old_mine_green + new_green_score - green_score,
          which_score: "mine_green",
        })
      );

      dispatch(
        setReduxScore({
          score: old_mine_orange + new_orange_score - orange_score,
          which_score: "mine_orange",
        })
      );

      dispatch(
        setReduxScore({
          score: old_mine_red + new_red_score - red_score,
          which_score: "mine_red",
        })
      );
    } else {
      // other
      let old_other_green = h_redux_scores["other_green"];
      let old_other_orange = h_redux_scores["other_orange"];
      let old_other_red = h_redux_scores["other_red"];

      dispatch(
        setReduxScore({
          score: old_other_green + new_green_score - green_score,
          which_score: "other_green",
        })
      );

      dispatch(
        setReduxScore({
          score: old_other_orange + new_orange_score - orange_score,
          which_score: "other_orange",
        })
      );

      dispatch(
        setReduxScore({
          score: old_other_red + new_red_score - red_score,
          which_score: "other_red",
        })
      );
    }

    //done

    setGreenScore(new_green_score);
    setOrangeScore(new_orange_score);
    setRedScore(new_red_score);
    console.log("a_red_ids");
    console.log(a_red_ids);
    console.log("a_orange_ids");
    console.log(a_orange_ids);
    console.log("a_green_ids");
    console.log(a_green_ids);
    console.log("a_super_green_ids");
    console.log(a_super_green_ids);
    console.log("a_cons_red_ids");
    console.log(a_cons_red_ids);
    console.log("a_cons_orange_ids");
    console.log(a_cons_orange_ids);
    console.log("a_cons_green_ids");
    console.log(a_cons_green_ids);
    console.log("a_left_red");
    console.log(a_left_red);
    console.log("a_left_orange");
    console.log(a_left_orange);
  };

  const setNewList = (id_new_list) => {
    const callback = () => {
      fetch(server_url + "/api/get_wall_list_and_questions")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setQuestions(res["a_questions"]);
          setListMeta(res["list_meta"]);
          setLists(res["a_lists"]);
          setMode(res["mode"]);
          setAURLPseudo(res["url_pseudo"]);
          setDoRerender(true);
          console.log("setNewList callback");
        });
    };
    setWallTrainListDB(id_new_list, callback);
  };

  return (
    <div>
      {typeof a_lists != "undefined" && (
        <Paper
          style={{ margin: "1em", width: "858px", paddingBottom: "1em" }}
          elevation={3}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ width: "100%" }}>
              {is_toggled ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                    /* backgroundColor: "lightGray", */
                    borderBottom: "solid 1px lightgray",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsToggled(false)}
                >
                  <KeyboardArrowDownIcon
                    style={{ marginLeft: "0.3em" }}
                  ></KeyboardArrowDownIcon>
                  <Typography variant="h4" style={{ marginLeft: "1em" }}>
                    {(global.lang == "fr" ? "S'entraîner" : "Train")}
                  </Typography>
                  <div style={{ width: "170px" }}></div>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                    /* backgroundColor: "lightGray", */
                    borderBottom: "solid 1px lightgray",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => setIsToggled(true)}
                  >
                    <KeyboardArrowDownIcon
                      style={{
                        marginLeft: "0.3em",
                        transform: "rotate(180deg)",
                      }}
                    ></KeyboardArrowDownIcon>
                    <Typography variant="h5" style={{ marginLeft: "1em" }}>
                      {(global.lang == "fr" ? "S'entraîner" : "Train")}
                    </Typography>
                  </div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      id="demo-simple-select-outlined"
                      value={list_meta[0]}
                      onChange={(e) => setNewList(e.target.value)}
                      /* label="List" */
                    >
                      {a_lists.map((row) => {
                        return (
                          <MenuItem
                            value={row[0]}
                            selected={row[0] == list_meta[0]}
                          >
                            {row[2]}
                          </MenuItem>
                        );
                      })}
                      {a_lists.length > 0 && a_a_lists.length > 0 && (
                        <Divider></Divider>
                      )}
                      {a_a_lists.map((row) => {
                        return (
                          <MenuItem
                            value={row[0]}
                            selected={row[0] == list_meta[0]}
                          >
                            {row[2]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    href=""
                    style={{ marginRight: "1em" }}
                    onClick={() => {
                      if (mode == "a") {
                        window.location.href =
                          server_url +
                          mode +
                          "/" +
                          a_url_pseudo +
                          "/" +
                          list_meta[9];
                      } else {
                        window.location.href =
                          server_url +
                          mode +
                          "/" +
                          url_pseudo +
                          "/" +
                          list_meta[9];
                      }
                    }}
                  >
                    {(global.lang == "fr" ? "voir la liste" : "see the list")}
                  </Button>
                </div>
              )}

              {!is_toggled && (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {typeof scoreBullet != "undefined" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ScoreBullet score={scoreBullet}></ScoreBullet>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "300px",
                        padding: "1em",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      <div
                        style={{
                          height: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <div>
                          <div
                            style={
                              media != "0"
                                ? {
                                    height: "100px",
                                    overflow: "hidden",
                                    marginBottom: "1em",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    /* wordBreak: "break-all", */
                                    overflow: "auto",
                                  }
                                : {
                                    overflow: "hidden",
                                    marginBottom: "1em",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    /* wordBreak: "break-all", */
                                    overflow: "auto",
                                  }
                            }
                          >
                            <br></br>
                            {statement}
                          </div>
                          {media != "0" && (
                            <img
                              src={media}
                              style={{
                                cursor: "pointer",
                                maxHeight: "200px",
                                maxWidth: "150px",
                                minHeight: "120px",
                                minWidth: "120px",
                              }}
                              onClick={() => {
                                setOpenImgDial(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "200px",
                        padding: "1em",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <form
                        id="id_answer_form"
                        onSubmit={(event) => {
                          event.preventDefault();
                          let pross_realAnswer = realAnswer;
                          let is_correct = true;
                          pross_realAnswer = pross_realAnswer.replace(
                            /\t/g,
                            " "
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            / $/g,
                            ""
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            /^ /g,
                            ""
                          );
                          pross_realAnswer = pross_realAnswer.replace(
                            /, /g,
                            ","
                          );

                          pross_realAnswer = pross_realAnswer.toLowerCase();
                          let pross_formAnswer = formAnswer;
                          pross_formAnswer = pross_formAnswer.replace(
                            /\t/g,
                            " "
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            /  /g,
                            " "
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            / $/g,
                            ""
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            /^ /g,
                            ""
                          );
                          pross_formAnswer = pross_formAnswer.replace(
                            /, /g,
                            ","
                          );
                          pross_formAnswer = pross_formAnswer.toLowerCase();

                          let a_split_re = pross_realAnswer.split(",");
                          let a_split_fo = pross_formAnswer.split(",");

                          a_split_fo.map((v) => {
                            if (a_split_re.indexOf(v) == -1) {
                              is_correct = false;
                            }
                          });
                          if (is_correct == false) {
                            setTempRealAnswer(realAnswer);
                            setIsOops(true);
                          } else {
                            processAnswer();
                          }
                        }}
                      >
                        <TextField
                          label={global.lang == "en" ? "Answer" : "Réponse"}
                          variant="outlined"
                          ref={inputAnswer}
                          onChange={(event) => {
                            setFormAnswer(event.target.value);
                            console.log(event.target.value);
                          }}
                          value={formAnswer}
                        />
                      </form>
                    </div>
                  </div>
                  <Divider></Divider>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "1em",
                    }}
                  >
                    <Button
                      style={{ width: "140px", marginBottom: "1em" }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (realAnswer != formAnswer) {
                          setTempRealAnswer(realAnswer);
                          setIsOops(true);
                        } else {
                          processAnswer();
                        }
                      }}
                    >
                      ok
                    </Button>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                                <span
              style={{
                display: "flex",
                width: "140px",
                justifyContent: "space-around",
              }}
            >
              <SG score={green_score}></SG>
              <SO score={orange_score}></SO>
              <SR score={red_score}></SR>
            </span>
                   {/*  <ScoreChips
                      green_score={green_score}
                      orange_score={orange_score}
                      red_score={red_score}
                    ></ScoreChips> */}
                  </div>
                </>
              )}
            </div>
          </div>
          <Dialog
            open={is_oops}
            onClose={() => {
              setIsOops(false);
              processAnswer();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            minWidth={"400px"}
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-title">
              <Typography style={{ fontSize: 36 }}>
                {"OOPS "}&#x1F62C;
              </Typography>
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText id="alert-dialog-description">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography style={{ fontSize: 24 }}>
                    {global.lang == "fr" ? "Mauvaise réponse" : "Wrong answer"}{" "}
                    <del style={{ color: "red" }}>&nbsp;{formAnswer}&nbsp;</del>
                  </Typography>

                  <Typography style={{ fontSize: 24 }}>
                    {global.lang == "fr"
                      ? "Réponse correcte"
                      : "Correct answer"}{" "}
                    <font style={{ color: "green" }}>{temp_realAnswer}</font>
                  </Typography>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsOops(false);
                  processAnswer();
                }}
                color="primary"
                autoFocus
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open_img_dial}
            onClose={() => {
              setOpenImgDial(false);
            }}
            maxWidth={"lg"}
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-title">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography style={{ fontSize: 24 }}>{statement}</Typography>
              </div>
            </DialogTitle>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "2em",
              }}
            >
              <img src={media_800}></img>
            </div>
          </Dialog>
        </Paper>
      )}
    </div>
  );
}
