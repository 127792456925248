import React, { useState, useEffect, useRef } from "react";
import ImageIcon from "@material-ui/icons/Image";
import { Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

export default function LDropZone({ image_file, setImageFile, image_url, setImageUrl, setDispImgLoadUrl}) {


  console.log("image_file typeof");
  console.log(typeof image_file);
  console.log(image_file);



  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };

  const getPicURL = (image_file, image_url) => {
    let src = "";
    if (image_file != ""){
    try {
      src = URL.createObjectURL(image_file.fileToUpload);
    } catch (error) {
      console.log(error);
    }
  }
  if (image_url != ""){
    src = image_url;
  }

    return src;
  };



  return (
    <div
      style={{
        border: "dashed rgba(0,0,0,0.12)",
        borderRadius: "8px",
        padding: "1em",
        textAlign: "center",
        marginLeft: "1em",
      }}
    >
      
        <span>
          <Typography variant="body2" color="textSecondary">
            {global.lang == "en" ? "Add a picture" : "ajouter une image"}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <IconButton>
              <AttachFileIcon onClick={() => browseFiles()}></AttachFileIcon>
            </IconButton>

            <IconButton>
              <LinkIcon onClick={() => setDispImgLoadUrl(true)}></LinkIcon>
            </IconButton>
          </div>
        </span>
      
      {(image_file != "" || image_url != "") && (
        <img
          src={getPicURL(image_file, image_url)}
          style={{ maxWidth: "200px", maxHeight: "100px" }}
        ></img>
      )}
      <input
        id="id_hidden_file_browser"
        type="file"
        onChange={(e) => {
          console.log(e.target.files[0]);
          setImageFile({
            fileToUpload: e.target.files[0],
          });
          setImageUrl("");
        }}
        style={{ display: "none" }}
      ></input>
    </div>
  );
}
