import {
  insertListDB,
  insertPageDB,
  addListDB,
  insertSOTDB,
} from "../Generic/lists";
import { useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useCookies } from "react-cookie";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { goFromCode } from "../Generic/lists";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setListSup, setListMetaSup } from "../redux/globalAddList";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
} from "../redux/globalTuto";

export function CreaListDialog({ open_crea_list, setOpenCreaList }) {
  const inputTitle = useRef(null);
  const [button_ok, setButtonOk] = useState(false);
  const [cookies, setCookie] = useCookies(["tuto2"]);
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <Dialog
      open={open_crea_list}
      onClose={() => setOpenCreaList(false)}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={isPhoneV ? { padding: "1em" } : { padding: "3em 3em 1em 3em" }}
      >
        <DialogTitle>
          <Typography
            variant={isPhoneV ? "h3" : "h2"}
            color="textPrimary"
            style={{ marginBottom: "0.5em" }}
          >
            {global.lang == "en" ? "Create a list" : "Créer une liste"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                transform: "scale(1.5)",
                transformOrigin: "left bottom",
                width: "66%",
                marginTop: "1em",
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                id="id_crea_list_title"
                label={
                  global.lang == "en" ? "List title *" : "Nom de la liste *"
                }
                fullWidth
                inputRef={inputTitle}
                onChange={() => {
                  setButtonOk(true);
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography color="textSecondary">
                {global.lang == "en"
                  ? "Choose a name for your list."
                  : "Choisir un nom pour la liste."}
              </Typography>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "2em 0 0 0",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  console.log(inputTitle.current.value);
                  console.log(cookies);
                  if (cookies.tuto2 === "new_user") {
                    console.log(cookies);
                    setCookie("tuto2", "ulist", { path: "/" });
                  }
                  insertListDB(inputTitle.current.value, "");
                  setOpenCreaList(false);
                }}
                color="primary"
                autoFocus
                disabled={!button_ok}
                style={
                  isPhoneV
                    ? {
                        width: "100%",
                        fontSize: "x-large",
                        marginBottom: "0.5em",
                        marginTop: "1em",
                      }
                    : { margin: "1em 0 1em 1em" }
                }
              >
                {global.lang == "en" ? "Create" : "créer"}
              </Button>
              {!isPhoneV && (
                <Button
                  onClick={() => setOpenCreaList(false)}
                  color="primary"
                  style={{ margin: "1em" }}
                >
                  {global.lang == "en" ? "Cancel" : "Annuler"}
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
export function AddListDialog({ open_add_list, setOpenAddList }) {
  const inputTitle = useRef(null);
  const inputDescr = useRef(null);
  const [button_ok, setButtonOk] = useState(false);
  const [cookies, setCookie] = useCookies(["tuto2"]);
  const [id_code, setIdCode] = useState("");

  const dispatch = useDispatch();
  const h_redux_add_list = useSelector((state) => state.global_add_list);
  const addListMeta = (id_a_list) => {
    const server_url =
      "https://" + window.location.href.split(/[/?#]/)[2] + "/";

    console.log("addListMeta");
    const data = { id_a_list: id_a_list };

    let setListMetaSupGen = () => {
      return 0;
    };

    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] != 0 &&
      h_redux_add_list["id_list_sup3"] != 0
    ) {
      window.location.reload();
    }

    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] != 0 &&
      h_redux_add_list["id_list_sup3"] == 0
    ) {
      dispatch(
        setListSup({
          list_number: 3,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 3,
            a_list_meta: res,
          })
        );
      };
    }
    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] == 0
    ) {
      dispatch(
        setListSup({
          list_number: 2,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 2,
            a_list_meta: res,
          })
        );
      };
    }
    if (h_redux_add_list["id_list_sup1"] == 0) {
      dispatch(
        setListSup({
          list_number: 1,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 1,
            a_list_meta: res,
          })
        );
      };
    }

    fetch(server_url + "/api/get_a_list_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("inside addListMeta");
        console.log(res);
        setListMetaSupGen(res);
      });
  };

  return (
    <Dialog open={open_add_list} onClose={() => setOpenAddList(false)}>
      <div style={{ padding: "3em 3em 1em 3em" }}>
        <DialogTitle>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: "0.5em" }}
          >
            {global.lang == "en" ? "Add a list" : "Ajouter une liste"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "scale(1.5)",
                margin: "2em 0 0 0",
                transformOrigin: "left bottom",
                width: "66%",
              }}
            >
              <TextField
                id="id_tf_cont_code"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                }}
                placeholder="x4w2k"
                style={{ width: "148px" }}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                onChange={(e) => setIdCode(e.target.value)}
              ></TextField>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenAddList(false);
                  addListDB(id_code, addListMeta);
                }}
                type="submit"
                style={{ height: "54px", borderRadius: "10" }}
              >
                GO
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography color="textSecondary">
                {global.lang == "en"
                  ? "Enter the code of the list you want to add."
                  : "Entrer le code de la liste à ajouter."}
              </Typography>
            </div>
          </div>

          {/*         <TextField
          margin="dense"
          id="id_crea_list_descr"
          label={
            global.lang == "en" ? "List description" : "Description de la liste"
          }
          fullWidth
          inputRef={inputDescr}
        /> */}
        </DialogContent>
      </div>
      <div style={{ margin: "0 0 1em 2em" }}>
        <Button onClick={() => setOpenAddList(false)} color="primary">
          {global.lang == "en" ? "Cancel" : "Annuler"}
        </Button>
      </div>
    </Dialog>
  );
}

const diagStyle = makeStyles((theme) => ({
  white_hover: {
    backgroundColor: "white",
    cursor: "pointer",
    "&:hover": {
      background: "whitesmoke",
    },
  },
}));

export function ChoiceQCodeDialog({ open_cqc, setOpenCQC, id_code }) {
  const classes = diagStyle();

  const dispatch = useDispatch();
  const h_redux_add_list = useSelector((state) => state.global_add_list);
  const addListMeta = (id_a_list) => {
    const server_url =
      "https://" + window.location.href.split(/[/?#]/)[2] + "/";

    console.log("addListMeta");
    const data = { id_a_list: id_a_list };

    let setListMetaSupGen = () => {
      return 0;
    };

    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] != 0 &&
      h_redux_add_list["id_list_sup3"] != 0
    ) {
      window.location.reload();
    }

    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] != 0 &&
      h_redux_add_list["id_list_sup3"] == 0
    ) {
      dispatch(
        setListSup({
          list_number: 3,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 3,
            a_list_meta: res,
          })
        );
      };
    }
    if (
      h_redux_add_list["id_list_sup1"] != 0 &&
      h_redux_add_list["id_list_sup2"] == 0
    ) {
      dispatch(
        setListSup({
          list_number: 2,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 2,
            a_list_meta: res,
          })
        );
      };
    }
    if (h_redux_add_list["id_list_sup1"] == 0) {
      dispatch(
        setListSup({
          list_number: 1,
          id_a_list: id_a_list,
        })
      );

      setListMetaSupGen = (res) => {
        dispatch(
          setListMetaSup({
            list_number: 1,
            a_list_meta: res,
          })
        );
      };
    }

    fetch(server_url + "/api/get_a_list_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("inside addListMeta");
        console.log(res);
        setListMetaSupGen(res);
      });
  };

  return (
    <Dialog open={open_cqc} onClose={() => setOpenCQC(false)} maxWidth={false}>
      <div
        style={{
          padding: "5em 3em",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            padding: "1em",
            display: "flex",
            alignItems: "center",
            width: "400px",
            borderRadius: "16px",
            marginRight: "2em",
          }}
          className={classes.white_hover}
          onClick={() => goFromCode(id_code)}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography variant="h2">🚣</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography variant="h2" align="center">
                {global.lang == "fr" ? "Simple visite" : "Just visiting"}
              </Typography>
            </div>
          </div>
        </div>

        <div style={{ width: "1px", borderLeft: "1px solid gray" }}></div>

        <div
          style={{
            padding: "1em",
            display: "flex",
            alignItems: "center",
            width: "400px",
            borderRadius: "16px",
            marginLeft: "2em",
          }}
          className={classes.white_hover}
          onClick={() => {
            addListDB(id_code, addListMeta);
            setOpenCQC(false);
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography variant="h2">🏫</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography variant="h2" align="center">
                {global.lang == "fr" ? "Ajouter dans" : "Add to"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Typography
                variant="h2"
                align="center"
                style={{ fontWeight: "400" }}
              >
                {global.lang == "fr" ? "listes de l'école" : "school lists"}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export function CreaPageDialog({ open_crea_page, setOpenCreaPage }) {
  const inputTitle = useRef(null);
  const inputDescr = useRef(null);
  const [button_ok, setButtonOk] = useState(false);

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  return (
    <div>
      <Dialog
        open={open_crea_page}
        onClose={() => setOpenCreaPage(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          {global.lang == "en" ? "Create a page" : "Créer une page"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {global.lang == "en"
              ? "Choose a name and a description for your page."
              : "Choisissez un nom et une description pour votre page."}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="id_crea_list_title"
            label={global.lang == "en" ? "Page title *" : "Nom de la page *"}
            fullWidth
            inputRef={inputTitle}
            onChange={() => {
              setButtonOk(true);
            }}
          />
          <TextField
            margin="dense"
            id="id_crea_list_descr"
            label={
              global.lang == "en"
                ? "Page description"
                : "Description de la page"
            }
            fullWidth
            inputRef={inputDescr}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreaPage(false)} color="primary">
            {global.lang == "en" ? "Cancel" : "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log(inputTitle.current.value);
              console.log(inputDescr.current.value);
              insertPageDB(inputTitle.current.value, inputDescr.current.value);
              setOpenCreaPage(false);
            }}
            color="primary"
            disabled={!button_ok}
          >
            {global.lang == "en" ? "Create" : "créer"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Are you a student or teacher ?
export function ChoiceTutoDialog({ open_sot, setOpenSOT }) {
  const [cookies, setCookie] = useCookies(["sot"]);

  const classes = diagStyle();

  const dispatch = useDispatch();
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  return (
    <Dialog open={open_sot} onClose={() => setOpenSOT(false)} maxWidth={false}>
      <div style={{ padding: "0 3em 1em 3em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "2em 0",
          }}
        >
          <div>
            <Typography variant="h2" align="center">
              {global.lang == "fr"
                ? "Bienvenue sur Cerbrain 😀"
                : "Welcome to Cerbrain 😀"}
            </Typography>
            <Typography variant="h2" align="center">
              {global.lang == "fr" ? "Je suis?" : "I am?"}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              padding: "1em",
              display: "flex",
              alignItems: "center",
              width: "400px",
              borderRadius: "16px",
              marginRight: "2em",

              border: "1px solid lightgray",
            }}
            className={classes.white_hover}
            onClick={() => {
              console.log("student");
              insertSOTDB("student");
              setCookie("sot", "student", { path: "/" });
              
              let dom_comp = document.getElementById("id_add_list_2");
              let rect = dom_comp.getBoundingClientRect();
              dispatch(setReduxTopArrow({ top_arrow: rect.top }));
              dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
              dispatch(setReduxTutoStep({ tuto_step: 100.5 }));
              setOpenSOT(false)
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography variant="h2">🧒</Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ textTransform: "uppercase", marginTop: "0.4em" }}
                >
                  {global.lang == "fr" ? "élève" : "student"}
                </Typography>
              </div>
            </div>
          </div>

          <div style={{ width: "1px", borderLeft: "1px solid white" }}></div>

          <div
            style={{
              padding: "1em",
              display: "flex",
              alignItems: "center",
              width: "400px",
              borderRadius: "16px",
              marginLeft: "2em",

              border: "1px solid lightgray",
            }}
            className={classes.white_hover}
            onClick={() => {
              console.log("prof");
              insertSOTDB("prof");
              setCookie("sot", "prof", { path: "/" });
              console.log("after prof");

              let dom_comp = document.getElementById("id_crea_list_2");
              let rect = dom_comp.getBoundingClientRect();
              dispatch(setReduxTopArrow({ top_arrow: rect.top }));
              dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
              dispatch(setReduxTutoStep({ tuto_step: 0.5 }));
              setOpenSOT(false)
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography variant="h2">👩‍🏫</Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ textTransform: "uppercase", marginTop: "0.4em" }}
                >
                  {global.lang == "fr" ? "prof" : "teacher"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
