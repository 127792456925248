import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import {
  SignIn,
  SignUp,
  SignUpG,
  SignUpSmall,
  SignUpSmallFull,
} from "../HomeNoSession/HomeNoSession";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { GoogleLogin } from "react-google-login";
import { signInGoogle } from "../Generic/google_signin";
import { useSelector, useDispatch } from "react-redux";
import { setReduxRegister } from "../redux/globalDialog";
import { useMediaQuery } from "react-responsive";
import Divider from '@mui/material/Divider';

const avatStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: "pointer",
    marginRight: "1em",
    marginLeft: "1em",
    top: "16px",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow:
        "0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function CBulProf({
  url_pseudo,
  pseudo,
  is_logged = "0",
  log_out_url = "",
}) {
  console.log(is_logged);
  console.log(log_out_url);
  return (
    <span>
      {is_logged == "1" ? (
        <Bul
          url_pseudo={url_pseudo}
          pseudo={pseudo}
          log_out_url={log_out_url}
        ></Bul>
      ) : (
        <>{/* <HAuth></HAuth> */}</>
      )}
    </span>
  );
}

function HAuth() {
  const [openInDialog, setOpenInDialog] = useState(false);
  const [openUpDialog, setOpenUpDialog] = useState(false);
  const [openUpGDialog, setOpenUpGDialog] = useState(false);

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");

  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => window.location.reload(),
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };

  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(setReduxRegister({ register: true }));
        }}
        style={{ margin: "1em" }}
      >
        {global.lang == "en" ? "Sign up" : "créer un compte"}
      </Button>

      <Button
        variant="contained"
        onClick={() => setOpenInDialog(true)}
        style={{ margin: "1em" }}
      >
        {global.lang == "en" ? "Log in" : "se connecter"}
      </Button>

      {/*        <div
            id="g_id_onload"
            data-client_id="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
            
            data-context="signin"
            data-auto_prompt="false"
            data-callback={"handleSignin"}
    ></div>
      <div
        class="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        style={{margin:"1em", marginRight:"2em"}}
      ></div> */}
      <span style={{ marginLeft: "1em", marginRight: "2em" }}>
        <GoogleLogin
          clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={handleGoogleButton}
          onFailure={() => {
            alert("Problem with the sign in method, please choose another one");
          }}
          cookiePolicy={"single_host_origin"}
        />
      </span>

      <Dialog
        onClose={() => setOpenInDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openInDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignIn></SignIn>
        </div>
        <Button
          autoFocus
          onClick={() => setOpenInDialog(false)}
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog>
      {/* <SignUpSmallFull
        is_open={openUpDialog}
        setClose={()=>setOpenUpDialog(false)}
      ></SignUpSmallFull> */}
      {/* <Dialog
        onClose={() => setOpenUpDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openUpDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignUpSmall></SignUpSmall>
        </div>
        <Button
          autoFocus
          onClick={() => setOpenUpDialog(false)}
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog> */}

      <Dialog
        onClose={() => setOpenUpGDialog(false)}
        open={openUpGDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignUpG
            email={g_email}
            pseudo={g_pseudo}
            google_profile_pic={g_picture}
          ></SignUpG>
        </div>
        <Button
          autoFocus
          onClick={() => setOpenUpGDialog(false)}
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog>
    </div>
  );
}

function Bul({ url_pseudo, pseudo, log_out_url = "" }) {
  const [pic_url, setPicUrl] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const isPhone = useMediaQuery({ query: "(max-width: 1000px)" });

  const classes = avatStyle();

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  useEffect(() => {
    fetch(server_url + "/api/get_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: "session" }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPicUrl(res["url"]);
        console.log(res);
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    fetch(server_url + "/api/log_out")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (log_out_url == "") {
          console.log('log_out_url==""');
          window.location.reload();
        } else {
          console.log('log_out_url!=""');
          window.location.href = log_out_url;
        }
      });
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        {!isPhone && (
          <div style={{ top: "20px", position: "relative", color: "#535A60" }}>
            {pseudo}
          </div>
        )}
        {pic_url != "" && (
          <Avatar
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
            alt="Remy Sharp"
            src={pic_url}
            className={classes.avatar}
          ></Avatar>
        )}
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem
              style={{fontStyle:"italic", fontWeight: 300, marginBottom:"0.2em", marginTop:"0.2em"}}
            >
              <ListItemText primary={pseudo} />
            </MenuItem>
            <Divider />
          {typeof url_pseudo != "undefined" && (
            <MenuItem
              onClick={() => (window.location.href = server_url + "profile")}
            >
              <ListItemText primary="my profile" />
            </MenuItem>
          )}
          <MenuItem onClick={logOut}>
            <ListItemText primary="log out" />
          </MenuItem>
        </StyledMenu>
      </div>
    </div>
  );
}
