import React, { useState, useEffect } from "react";
import CBulProf from "./CBulProf";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { setLastLgDB, goFromCode } from "../Generic/lists";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import { setReduxIsContFooterOpen } from "../redux/globalDialog";
import { CreaListDialog, ChoiceQCodeDialog } from "../SmallComponents/CDialog";
import { SignIn, SignUp, SignUpG } from "../HomeNoSession/HomeNoSession";
import { signInGoogle } from "../Generic/google_signin";
import { GoogleLogin } from "react-google-login";
import Dialog from "@material-ui/core/Dialog";
import { useMediaQuery } from "react-responsive";
import { isMobile } from "react-device-detect";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";

export default function CHeader({
  menuStatus,
  setMenuStatus,
  is_logged = "0",
  log_out_url = "",
  show_crea_but = true,
}) {
  const [lang, setLang] = useState(global.lang);
  const [id_code, setIdCode] = useState("");
  const [open_crea_list, setOpenCreaList] = useState("0");
  const [url_pseudo, setUrlPseudo] = useState();
  const [pseudo, setPseudo] = useState();
  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const [openUpGListCreaDialog, setOpenUpGListCreaDialog] = useState(false);

  const [open_cqc, setOpenCQC] = useState(false);
  const [is_cqc_error, setIsCqcError] = useState(false);

  const isPhone = useMediaQuery({ query: "(max-width: 1000px)" });
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  console.log(global.lang);
  console.log(log_out_url);

  const handleGoogleButtonCreaList = (e) => {
    signInGoogle(
      e,
      () => (window.location.href = server_url),
      (email, pseudo, picture) => handleSignUpGListCrea(email, pseudo, picture)
    );
  };

  const handleSignUpGListCrea = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGListCreaDialog(true);
  };

  const redirectLang = (lang) => {
    if (is_logged == "1") {
      setLastLgDB(lang);
    }
    const a_url = window.location.href.split("?");
    window.location.href = a_url[0] + "?" + lang;
  };

  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  const dispatch = useDispatch();
  const h_global_dialog = useSelector((state) => state.global_dialog);

  useEffect(() => {
    const a_url = window.location.href.split("?");
    console.log(a_url);
    if (a_url[0].split(".").indexOf("fr") != -1) {
      setLang("fr");
      setLastLgDB("fr");
    }
    if (a_url[0].split(".").indexOf("en") != -1) {
      setLang("en");
      setLastLgDB("en");
    }
    fetch(server_url + "/api/get_user_data")
      .then((res) => res.json())
      .then((res) => {
        setUrlPseudo(res["url_pseudo"]);
        setPseudo(res["pseudo"]);
        console.log(res);
      });
  }, []);

  return (
    <div
      style={
        h_global_tuto_step["tuto_step"] == 12 ||
        h_global_tuto_step["tuto_step"] == 0.5
          ? {
              height: "65px",
              position: "fixed",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              top: 0,
              boxShadow: "0px 3px 3px rgb(240,240,240);",
              backgroundColor: "white",
              zIndex: 506,
            }
          : isMobile
          ? {
              height: "65px",
              position: "fixed",
              display: "flex",
              justifyContent: "space-between",
              width: window.screen.width + "px",
              top: 0,
              boxShadow: "0px 3px 3px rgb(240,240,240);",
              backgroundColor: "white",
              zIndex: 500,
            }
          : {
              height: "65px",
              position: "fixed",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              top: 0,
              boxShadow: "0px 3px 3px rgb(240,240,240);",
              backgroundColor: "white",
              zIndex: 500,
            }
      }
    >
      <div style={{ display: "flex" }}>
        <a id="id_invisible_link" href="#id_landingpage_main"></a>
        {menuStatus == "1" && is_logged == "1" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => setMenuStatus("0")}>
              <MenuIcon></MenuIcon>
            </IconButton>
          </div>
        )}
        {menuStatus == "0" && is_logged == "1" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => setMenuStatus("1")}>
              <MenuIcon></MenuIcon>
            </IconButton>
          </div>
        )}

        {/*         {isPhone && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => (window.location.href = server_url)}
          >
            <img
              src={server_url + "im/logo_A_no_text.svg"}
              style={{ height: "56px" }}
            ></img>
          </div>
        )} */}

        <div
          style={
            is_logged == "1"
              ? {
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: "2em",
                }
          }
          onClick={() => (window.location.href = server_url)}
        >
          <Typography
            variant="h5"
            style={isPhoneV && is_logged == "0" ?{
              fontFamily: "Fredoka One",
              /* color: "rgb(0, 61, 255)", */
              /* color: "rgb(25,25,70)", */
              color: "rgb(0,80,171)",
              fontSize: "xxx-large"
            }:{
              fontFamily: "Fredoka One",
              /* color: "rgb(0, 61, 255)", */
              /* color: "rgb(25,25,70)", */
              color: "rgb(0,80,171)",
            }}
            display="inline"
          >
            {isPhoneV && is_logged == "0" ? "c": "cerbrain"}
          </Typography>
        </div>
      </div>
      <div
        style={
          isPhoneV && is_logged == "1" 
            ? {
                position: "fixed",
                bottom: 0,
                right: 0,
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                padding: "0.5em 1em 0.5em 2em",
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px",
                borderBottomLeftRadius: "48px",
                borderTopLeftRadius: "48px",
                transition: "all 300ms ease-in-out",
              }
            : { display: "flex", alignItems: "center" }
        }
      >
        {h_global_dialog["is_cont_footer_open"] == 1 ? (
          <>
            <form
              /*           onSubmit={
            h_global_tuto_step["tuto_step"] != 0
              ? (e) => {
                  e.preventDefault();
                  goFromCode(id_code, "_blank");
                }
              : (e) => {
                  e.preventDefault();
                  goFromCode(id_code);
                }
          } */
              onSubmit={(e) => {
                e.preventDefault();
                if (id_code.length == 5 || id_code.length == 6) {
                  setOpenCQC(true);
                } else {
                  setIsCqcError(true);
                }
              }}
              style={isPhoneV ? { margin: "0.5em 2em" } : {}}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  id="id_tf_cont_code"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                  }}
                  error={
                    id_code.length == 5 || id_code.length == 6 || !is_cqc_error
                      ? false
                      : true
                  }
                  placeholder="x4w2k"
                  onChange={(e) => setIdCode(e.target.value)}
                  /*               onChange={
                h_global_tuto_step["tuto_step"] != 0
                  ? (e) => {
                      goFromCode(e.target.value, "_blank");
                    }
                  : (e) => {
                      goFromCode(e.target.value);
                    }
              } */
                  style={{ width: "148px" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                ></TextField>
                <Button
                  variant="contained"
                  color="primary"
                  /* onClick={() => goFromCode(id_code)} */
                  type="submit"
                  style={{ height: "54px", borderRadius: "10" }}
                >
                  GO
                </Button>
              </div>
            </form>
            {isPhoneV && is_logged == "1" &&(
              <IconButton
                aria-label="close"
                onClick={() =>
                  dispatch(setReduxIsContFooterOpen({ is_cont_footer_open: 0 }))
                }
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        ) : (
          <IconButton
            aria-label="expand"
            onClick={() =>
              dispatch(setReduxIsContFooterOpen({ is_cont_footer_open: 1 }))
            }
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </div>
      <div style={{ display: "flex" }}>
        {!isPhone ? (
          <>
            {" "}
            {show_crea_but && (
              <div
                style={{
                  marginRight: "3em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  id="id_crea_list_1"
                  variant="contained"
                  color="secondary"
                  style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    width: "100%",
                    fontFamily:"Nunito",
                    fontWeight: 900
                  }}
                  onClick={() => {
                    if (is_logged == "1") {
                      setOpenCreaList("open_crea_logged");
                    } else {
                      setOpenCreaList("open_crea_not_logged");
                    }
                  }}
                >
                  {global.lang == "en" ? "create a list" : "créer une liste"}
                </Button>
              </div>
            )}{" "}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: "scale(2)",
            }}
          >
            <IconButton
              color="secondary"
              aria-label="create list"
              onClick={() => {
                if (is_logged == "1") {
                  setOpenCreaList("open_crea_logged");
                } else {
                  setOpenCreaList("open_crea_not_logged");
                }
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
        )}

        {!isPhone && (
          <div style={{ height: "100%" }}>
            <Select
              value={lang}
              style={{ height: "24px", top: "20px", marginRight: "1.7em" }}
              disableUnderline
            >
              <MenuItem value="fr" onClick={() => redirectLang("fr")}>
                fr
              </MenuItem>
              <MenuItem value="en" onClick={() => redirectLang("en")}>
                en
              </MenuItem>
            </Select>
          </div>
        )}
        <CBulProf
          url_pseudo={url_pseudo}
          pseudo={pseudo}
          is_logged={is_logged}
          log_out_url={log_out_url}
        />
      </div>
      <CreaListDialog
        open_crea_list={open_crea_list == "open_crea_logged"}
        setOpenCreaList={setOpenCreaList}
        url_pseudo={url_pseudo}
      ></CreaListDialog>
      <Dialog
        onClose={() => setOpenCreaList("0")}
        aria-labelledby="customized-dialog-title"
        open={open_crea_list == "open_crea_not_logged"}
        maxWidth="lg"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <Typography variant="h3" color="textPrimary">
            Sign In with google
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <GoogleLogin
            clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={(e) => handleGoogleButtonCreaList(e)}
            onFailure={() => {
              alert(
                "Problem with the sign in method, please choose another one"
              );
            }}
            cookiePolicy={"single_host_origin"}
            style={{ width: "400px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <Typography variant="h4" color="textPrimary">
            Or
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <SignIn></SignIn>
          <SignUp></SignUp>
        </div>
      </Dialog>
      <ChoiceQCodeDialog
        open_cqc={open_cqc}
        setOpenCQC={setOpenCQC}
        id_code={id_code}
      ></ChoiceQCodeDialog>
    </div>
  );
}
