import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CHeader from "../MainComponents/CHeader";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { MetPresentation } from "../User/UPresentation";

export default function CUsers() {
  const [a_followers, setFollowers] = useState();
  const [a_following, setFollowing] = useState();
  const [a_visited, setVisited] = useState();
  const [tab, setTab] = useState("followers");
  const [menuStatus, setMenuStatus] = useState("0");

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";

  useEffect(() => {
    fetch(server_url + "/api/get_people_i_follow_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(JSON.stringify(res));
        setFollowing(res);
      });
    fetch(server_url + "/api/get_followers_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(JSON.stringify(res));
        setFollowers(res);
      });
    fetch(server_url + "/api/get_visited_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(JSON.stringify(res));
        setVisited(res);
      });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ height: "69px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "2em",
        }}
      >
        <Typography variant="h3" color="textSecondary">
          {global.lang == "en" ? "Users" : "Membres"}
        </Typography>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          {tab == "followers" ? (
            <Button
              color="primary"
              variant="outlined"
              style={{ margin: "1em" }}
            >
              followers
            </Button>
          ) : (
            <Button
              onClick={() => setTab("followers")}
              variant="outlined"
              style={{ margin: "1em" }}
            >
              followers
            </Button>
          )}
          {tab == "following" ? (
            <Button
              color="primary"
              variant="outlined"
              style={{ margin: "1em" }}
            >
              {global.lang == "en" ? "following" : "comptes que je suis"}
            </Button>
          ) : (
            <Button
              onClick={() => setTab("following")}
              variant="outlined"
              style={{ margin: "1em" }}
            >
              {global.lang == "en" ? "following" : "comptes que je suis"}
            </Button>
          )}
          {tab == "visited" ? (
            <Button
              color="primary"
              variant="outlined"
              style={{ margin: "1em" }}
            >
              {global.lang == "en"
                ? "I visited them"
                : "J'ai visité leur contenu"}
            </Button>
          ) : (
            <Button
              onClick={() => setTab("visited")}
              variant="outlined"
              style={{ margin: "1em" }}
            >
              {global.lang == "en"
                ? "I visited them"
                : "J'ai visité leur contenu"}
            </Button>
          )}
        </div>
      </div>

      {tab == "followers" && typeof a_followers != "undefined" && (
        <UsersList a_users={a_followers}></UsersList>
      )}
      {tab == "following" && typeof a_following != "undefined" && (
        <UsersList a_users={a_following}></UsersList>
      )}
      {tab == "visited" && typeof a_visited != "undefined" && (
        <UsersList a_users={a_visited}></UsersList>
      )}
    </div>
  );
}

function UsersList({ a_users }) {
  console.log(a_users);
  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      {a_users.map((value, index) => {
        let pseudo = value[2];
        let url_pseudo = value[8];
        let descr = value[9];
        let followers_number = value[10];
        let following_number = value[11];

        return (
          <Grid item xl={4} lg={6} sm={12}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <MetPresentation a_meta={value} is_logged="1"></MetPresentation>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
