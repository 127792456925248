import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { addQuestionDBAndDom } from "../Generic/lists";
import Switch from "@material-ui/core/Switch";

import LDropZone from "./LPicComp";
import axios from "axios";

import { createMuiTheme } from "@material-ui/core/styles";
import { ScoreBullet } from "../SmallComponents/SmallComponents";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
} from "../redux/globalTuto";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "react-responsive";

/* function addQuestionDom(
  id_list,
  statement,
  answer,
  a_questions,
  setQuestions,
  is_bw,
  has_pic,
  pic_url
) {
  if (is_bw) {
    setQuestions([
      [-1, id_list, "t", statement, answer, Date.now() / 1000, "0", 1, "0"],
      [-2, id_list, "t", answer, statement, Date.now() / 1000, "0", 1, "0"],
      ...a_questions,
    ]);
  } else {
    if (has_pic) {
      setQuestions([
        [
          -1,
          id_list,
          "i",
          statement,
          answer,
          Date.now() / 1000,
          "0",
          1,
          pic_url,
        ],
        ...a_questions,
      ]);
    } else {
      setQuestions([
        [-1, id_list, "t", statement, answer, Date.now() / 1000, "0", 1, "0"],
        ...a_questions,
      ]);
    }
  }
}
 */

/* const useStyles = makeStyles(() => ({
  dropZone: {
    height: "200px",
    fullWidth: "true",
    fontSize: "small",
  },
  previewContainer: {
    container: "true",
    width: "100%",
    height: "200px",
  },
  preview: {
    //width: '100%',
    //height: '100%',
    //item: 'true',
    xs: "12",
  },
  previewImg: {
    maxHeight: "50px",
    maxWidth: "100px",
  },
  textArea: {
    fontSize: "small",
  },
})); */

const theme = createMuiTheme({});
theme.overrides.MuiDropzoneArea = {
  root: {
    maxHeight: "150px",
    minHeight: "100px",
  },
};

export default function CAddQuestion({
  id_list,
  a_questions,
  setQuestions,
  open_tools = true,
}) {
  const [statement, setStatement] = useState("");
  const [answer, setAnswer] = useState("");
  const [is_bw, setIsBW] = useState(false);
  const inputStatement = useRef(null);
  const inputAnswer = useRef(null);
  const [image_file, setImageFile] = useState("");
  const [image_url, setImageUrl] = useState("");

  const [url_pseudo, setUrlPseudo] = useState("");
  const [url_list, setUrlList] = useState("");
  const [disp_img_load_url, setDispImgLoadUrl] = useState(false);
  const [img_load_url, setImgLoadUrl] = useState("");
  const [new_url_img_name, setNewUrlImgName] = useState("");
  const [circ_progr, setCircProg] = useState(false);
  const [img_error, setImgError] = useState(false);

  const [cookies, setCookie] = useCookies(["tuto2", "sot"]);

  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  //const [tuto_step, setTutoStep] = useState(1);
  //const [top_arrow, setTopArrow] = useState();
  //const [left_arrow, setLeftArrow] = useState();

  const dispatch = useDispatch();
  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  //if ( document.cookie[document.cookie.length - 1] === "u" || document.cookie[document.cookie.length - 1] === "t") {

  /*   useEffect(() => {
    const a_url = window.location.href.split("?")[0].split("/");
    setUrlPseudo(a_url[4]);
    setUrlList(a_url[5]);
  });
 */
  useEffect(() => {
    const a_url = window.location.href.split("?")[0].split("/");
    setUrlPseudo(a_url[4]);
    setUrlList(a_url[5]);

    let dom_comp = document.getElementById("id_statement_sup");
    var rect = dom_comp.getBoundingClientRect();
    dispatch(setReduxTopArrow({ top_arrow: rect.top }));
    dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
    /*     setTopArrow(rect.top);
    setLeftArrow(rect.left); */

    console.log(cookies);
    if (cookies.tuto2 === "ulist" || cookies.tuto2 === "new_user") {
      console.log(cookies);
      setCookie("tuto2", "tuto_done", { path: "/" });
      console.log(cookies);
      dispatch(setReduxTutoStep({ tuto_step: 1 }));
    }
  }, []);

  const manageTutoStep = (int_step) => {
    if (int_step == 0) {
      dispatch(setReduxTutoStep({ tuto_step: int_step }));
    }
    if (
      int_step > h_global_tuto_step["tuto_step"] &&
      h_global_tuto_step["tuto_step"] != 0
    ) {
      if (int_step == 4) {
        let dom_comp = document.getElementById("id_statement_sup");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }
      if ((int_step == 2) | (int_step == 5)) {
        let dom_comp = document.getElementById("id_answer");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if ((int_step == 3) | (int_step == 6)) {
        let dom_comp = document.getElementById("id_add_but");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if ((int_step == 8) | (int_step == 10)) {
        let dom_comp = document.getElementById("id_train_ans");
        let rect = dom_comp.getBoundingClientRect();
        console.log(rect.top);
        console.log(rect.left);
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if ((int_step == 9) | (int_step == 11)) {
        let dom_comp = document.getElementById("id_train_val");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      if (int_step == 13) {
        let dom_comp = document.getElementById("id_url_tocopy");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }
      if (int_step == 14) {
        let dom_comp = document.getElementById("id_but_eval");
        let rect = dom_comp.getBoundingClientRect();
        /*         setTopArrow(rect.top);
        setLeftArrow(rect.left); */
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
      }

      dispatch(setReduxTutoStep({ tuto_step: int_step }));
      //setTutoStep(int_step);
    }
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const uploadPic = (pic_rand_url) => {
    let name_for_bucket =
      pic_rand_url.split("/")[3] +
      "." +
      image_file.fileToUpload.name.split(".").pop();
    let name = image_file.fileToUpload.name;
    fetch(server_url + "/api/presign_url", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id_list: id_list,
        name_hash: name_for_bucket,
        name: name,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("uploadPic");
        console.log(JSON.stringify(res));
        axios({
          method: "PUT",
          url: res["signed_url"],
          data: image_file.fileToUpload,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => {
            console.log("succeessss file upload");
          })
          .catch((err) => {
            console.log(
              "oh no something wen't wrong in the file uploading process"
            );
          });
      });
  };

  const addQuestionForm = () => {
    /*     if (is_bw) {
      addQuestionDB(id_list, "t", answer, statement, "0", setTempIdQuestionBW, a_questions.length);
    } */
    if (image_file == "" && image_url == "") {
      addQuestionDBAndDom(
        id_list,
        "t",
        statement,
        answer,
        "0",
        is_bw,
        a_questions,
        setQuestions
      );
      /*       addQuestionDom(
        id_list,
        statement,
        answer,
        a_questions,
        setQuestions,
        is_bw,
        false,
        "0"
      ); */
    }
    if (image_file != "") {
      console.log(typeof image_file);
      let pic_rand_url = URL.createObjectURL(image_file.fileToUpload);
      let ext = image_file.fileToUpload.name.split(".").pop();
      uploadPic(pic_rand_url);
      addQuestionDBAndDom(
        id_list,
        "i",
        statement,
        answer,
        pic_rand_url.split("/")[3] + "." + ext,
        is_bw,
        a_questions,
        setQuestions,
        pic_rand_url
      );
      /*       addQuestionDom(
        id_list,
        statement,
        answer,
        a_questions,
        setQuestions,
        is_bw,
        true,
        pic_rand_url
      ); */
      setImageFile("");
    } else {
      if (image_url != "") {
        console.log(typeof image_file);

        addQuestionDBAndDom(
          id_list,
          "i",
          statement,
          answer,
          new_url_img_name,
          is_bw,
          a_questions,
          setQuestions,
          image_url
        );
        setImageUrl("");
      }
    }
  };

  /*   const classes = useStyles(); */

  return (
    <div
      style={
        isPhoneV
          ? { display: "flex", margin: "2em 0 2em 0" }
          : { display: "flex" }
      }
    >
      {open_tools && <Checkbox disabled></Checkbox>}
      <form
        id="form_global_add"
        onSubmit={(event) => {
          event.preventDefault();
          addQuestionForm();
          setAnswer("");
          setStatement("");
          document.getElementById("id_statement_sup").focus();
          if (h_global_tuto_step["tuto_step"] == 3) {
            manageTutoStep(4);
          } else {
            manageTutoStep(7);
          }
        }}
        style={isPhoneV ? { width: "100%" } : {}}
      >
        <span
          style={
            isPhoneV
              ? {
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                }
              : { display: "flex" }
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={
                isPhoneV
                  ? { display: "flex", flexDirection: "column" }
                  : { display: "flex" }
              }
            >
              {!isPhoneV && <ScoreBullet score={0}></ScoreBullet>}
              <span
                style={{ width: isPhoneV ? "auto" : "400px", padding: "12px" }}
              >
                <span
                  style={
                    isPhoneV
                      ? { display: "flex" }
                      : {
                          display: "flex",
                          justifyContent: open_tools ? "flex-start" : "center",
                        }
                  }
                >
                  {is_bw ? (
                    <input
                      placeholder={global.lang == "en" ? "Statement" : "Énoncé"}
                      type="text"
                      /* style={{ height: "38px" }} */
                      onChange={(event) => setStatement(event.target.value)}
                      value={statement}
                      id="id_statement"
                      style={{ order: 1 }}
                    />
                  ) : (
                    <TextField
                      id="id_statement_sup"
                      label={global.lang == "en" ? "Statement" : "Énoncé"}
                      variant="outlined"
                      onChange={(event) => {
                        setStatement(event.target.value);
                        console.log(event.target.value);
                        if (h_global_tuto_step["tuto_step"] == 1) {
                          manageTutoStep(2);
                        }
                        if (h_global_tuto_step["tuto_step"] == 4) {
                          manageTutoStep(5);
                        }
                      }}
                      value={statement}
                      multiline
                      rows={3}
                      style={
                        (h_global_tuto_step["tuto_step"] >= 1) &
                        (h_global_tuto_step["tuto_step"] <= 6)
                          ? {
                              zIndex: 502,
                              backgroundColor: "white",
                              width: isPhoneV ? "100%" : "auto",
                            }
                          : { zIndex: 499, width: isPhoneV ? "100%" : "auto" }
                      }
                      inputProps={{
                        autoCapitalize: "none",
                      }}
                    />
                  )}

                  {!is_bw && open_tools && (
                    <LDropZone
                      image_file={image_file}
                      setImageFile={(e) => setImageFile(e)}
                      image_url={image_url}
                      setImageUrl={(e) => setImageUrl(e)}
                      setDispImgLoadUrl={(e) => setDispImgLoadUrl(e)}
                    ></LDropZone>
                  )}
                </span>
              </span>
              <div
                style={
                  isPhoneV
                    ? { padding: "12px" }
                    : { width: "280px", padding: "12px" }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="id_answer"
                    label={global.lang == "en" ? "Answer" : "Réponse"}
                    variant="outlined"
                    ref={inputAnswer}
                    onChange={(event) => {
                      setAnswer(event.target.value);
                      console.log(event.target.value);
                      if (h_global_tuto_step["tuto_step"] == 2) {
                        manageTutoStep(3);
                      }
                      if (h_global_tuto_step["tuto_step"] == 5) {
                        manageTutoStep(6);
                      }
                    }}
                    value={answer}
                    style={
                      (h_global_tuto_step["tuto_step"] == 2) |
                      (h_global_tuto_step["tuto_step"] == 3) |
                      (h_global_tuto_step["tuto_step"] == 5) |
                      (h_global_tuto_step["tuto_step"] == 6)
                        ? isPhoneV
                          ? {
                              zIndex: 502,
                              backgroundColor: "white",
                              width: "100%",
                            }
                          : { zIndex: 502, backgroundColor: "white" }
                        : isPhoneV
                        ? { zIndex: 499, width: "100%" }
                        : { zIndex: 499 }
                    }
                    inputProps={{
                      autoCapitalize: "none",
                    }}
                  />
                </div>
              </div>
            </div>

            {is_bw && (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    backgroundColor: "#FF92A7",
                    display: "inline-block",
                    borderRadius: "50%",
                    width: "8px",
                    height: "8px",
                    margin: "20px",
                  }}
                ></span>
                <span style={{ width: "400px", padding: "12px" }}>
                  <input type="text" value={answer} disabled />
                </span>
                <span style={{ width: "280", padding: "12px" }}>
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input type="text" value={statement} disabled />
                  </span>
                </span>
                <span></span>
              </div>
            )}
          </div>

          <div
            style={
              open_tools
                ? {
                    backgroundColor: "rgb(246,248,250)",
                    width: "340px",
                    display: "flex",
                    justifyContent: "space-around",
                  }
                : isPhoneV
                ? {
                    backgroundColor: "rgb(246,248,250)",
                    padding: "1em",
                    display: "flex",
                    justifyContent: "space-around",
                  }
                : {
                    backgroundColor: "rgb(246,248,250)",
                    width: "170px",
                    display: "flex",
                    justifyContent: "space-around",
                  }
            }
          >
            {open_tools && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" color="textSecondary">
                      2X
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Switch
                      onChange={(event) => {
                        setImageFile("");
                        setImageUrl("");
                        setIsBW(event.target.checked);
                      }}
                      color="primary"
                    ></Switch>
                  </div>
                </div>
              </div>
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                id="id_add_but"
                variant="contained"
                color="primary"
                form="form_global_add"
                type="submit"
                style={
                  (h_global_tuto_step["tuto_step"] == 3) |
                  (h_global_tuto_step["tuto_step"] == 6)
                    ? { zIndex: 502 }
                    : { zIndex: 499 }
                }
              >
                {global.lang == "en" ? "add" : "ajouter"}
              </Button>
            </div>
          </div>
        </span>
      </form>

      <Dialog
        open={disp_img_load_url}
        onClose={() => {
          setDispImgLoadUrl(false);
          setCircProg(false);
        }}
      >
        <form
          id="form_pic_url"
          onSubmit={(e) => {
            e.preventDefault();
            console.log(img_load_url);
            setCircProg(true);
            setImgError(false);
            fetch(
              "https://y0u46n4a88.execute-api.eu-west-3.amazonaws.com/picFromURL/ressourcepicfromurl",
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  todel: "hihi",
                  url: img_load_url,
                  url_pseudo: url_pseudo,
                  url_list: url_list,
                }),
              }
            )
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                setCircProg(false);
                if (res["name"] != "oops") {
                  setNewUrlImgName(res["name"]);
                  setImageFile("");
                  setImageUrl(img_load_url);
                  setDispImgLoadUrl(false);
                } else {
                  setImgError(true);
                }
              });
          }}
        >
          <div
            style={{
              width: "600px",
              heigh: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingTop: "2em",
              paddingBottom: "2em",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2em",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="https://adress.of/my_pic.png"
                style={{ width: "400px" }}
                onChange={(event) => {
                  console.log(event.currentTarget.value);
                  setImgLoadUrl(event.currentTarget.value);
                }}
                inputProps={{
                  autoCapitalize: "none",
                }}
              />
            </div>
            {circ_progr && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "1.2em",
                }}
              >
                <CircularProgress></CircularProgress>
              </div>
            )}
            {img_error && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "1.2em",
                }}
              >
                <Typography variant="h3" color="red">
                  Error with the picture, try with another one
                </Typography>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "400px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "150px" }}
                  form="form_pic_url"
                  type="submit"
                >
                  load
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDispImgLoadUrl(false);
                  }}
                  style={{ width: "150px" }}
                >
                  cancel
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
      {typeof h_global_tuto_step != "undefined" && (
        <>
          {(h_global_tuto_step["tuto_step"] > 0) &
          (h_global_tuto_step["tuto_step"] <= 7) ? (
            <>
              <div
                id="id_0and12"
                style={{
                  zIndex: 501,
                  position: "absolute",
                  width: "100%",
                  height: "200%",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    maxWidth: "700px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      padding: "2em",
                      maxHeight: "460px",
                      marginTop: "1em",
                    }}
                  >
                    {(h_global_tuto_step["tuto_step"] >= 1) &
                    (h_global_tuto_step["tuto_step"] <= 3) ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{ margin: "1em" }}
                            align="center"
                          >
                            {global.lang == "en"
                              ? "Congratulations you created your first knowledge list !"
                              : cookies.sot === "student"
                              ? "Félicitations tu as créé ta première liste de connaissances !"
                              : "Félicitations vous avez créé votre première liste de connaissances !"}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "48px",
                              marginRight: "0.5em",
                            }}
                          >
                            &#128077;
                          </div>
                        </div>

                        <div style={{ margin: "1em" }}>
                          {global.lang == "fr" ? (
                            <Typography
                              variant="h5"
                              color="textSecondary"
                              style={{ display: "inline-block" }}
                              align="justify"
                            >
                              {/* Chaque connaissance se compose d'un */}
                              {cookies.sot === "student"
                                ? "Ajoute un mot et sa traduction"
                                : "Ajouter un énoncé et une réponse"}
                            </Typography>
                          ) : null}
                          {global.lang == "en" ? (
                            <Typography
                              variant="h5"
                              color="textSecondary"
                              style={{ display: "inline-block" }}
                            >
                              {/*   Each knowledge is composed of a */}
                              {cookies.sot === "student"
                                ? "Add a word and its translation"
                                : "Add a statement and an answer"}
                            </Typography>
                          ) : null}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <img src="../../im/tuto/tuto_150.gif"></img>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: "1em",
                          }}
                        >
                          <Link
                            onClick={() =>
                              dispatch(setReduxTutoStep({ tuto_step: 0 }))
                            }
                            style={{ cursor: "pointer", color: "gray" }}
                          >
                            {global.lang == "en"
                              ? "dismiss"
                              : "quitter le tutorial"}
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    {(h_global_tuto_step["tuto_step"] >= 4) &
                    (h_global_tuto_step["tuto_step"] <= 6) ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot === "student" && (
                            <>
                              {global.lang == "en"
                                ? "Add another word before next step."
                                : "Ajoute un deuxième mot pour passer à la suite."}
                            </>
                          )}

                          {cookies.sot === "prof" && (
                            <>
                              {global.lang == "en"
                                ? "Add another knowledge before next step."
                                : "Ajouter une deuxième connaissance pour passer à la suite."}
                            </>
                          )}
                        </Typography>
                        <Link
                          onClick={() =>
                            dispatch(setReduxTutoStep({ tuto_step: 0 }))
                          }
                          style={{ cursor: "pointer", color: "gray" }}
                        >
                          {global.lang == "en"
                            ? "dismiss"
                            : "quitter le tutorial"}
                        </Link>
                      </div>
                    ) : null}
                    {h_global_tuto_step["tuto_step"] == 7 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot === "student" && (
                            <>
                              {global.lang == "en" ? (
                                <>
                                  Your list contains {a_questions.length} words
                                </>
                              ) : (
                                <>
                                  Ta liste comporte {a_questions.length} mots de
                                  vocabulaire
                                </>
                              )}
                            </>
                          )}
                          {cookies.sot === "prof" && (
                            <>
                              {global.lang == "en" ? (
                                <>
                                  Your list contains {a_questions.length}{" "}
                                  knowledge
                                </>
                              ) : (
                                <>
                                  Votre liste comporte {a_questions.length}{" "}
                                  connaissances
                                </>
                              )}
                            </>
                          )}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "WhiteSmoke",
                            padding: "2em",
                          }}
                        >
                          {a_questions.length > 0 && (
                            <div style={{ display: "flex" }}>
                              <Typography variant="h5" color="textSecondary">
                                {a_questions[0][3]}
                              </Typography>
                              <Typography variant="h5" color="textPrimary">
                                {": "}
                                &nbsp;
                              </Typography>
                              <Typography variant="h5" color="textSecondary">
                                {a_questions[0][4]}
                              </Typography>
                            </div>
                          )}
                          {a_questions.length > 1 && (
                            <div style={{ display: "flex" }}>
                              <Typography variant="h5" color="textSecondary">
                                {a_questions[1][3]}
                              </Typography>
                              <Typography variant="h5" color="textPrimary">
                                {": "}
                                &nbsp;
                              </Typography>
                              <Typography variant="h5" color="textSecondary">
                                {a_questions[1][4]}
                              </Typography>
                            </div>
                          )}
                          {a_questions.length > 2 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <Typography variant="h5" color="textSecondary">
                                ...
                              </Typography>
                            </div>
                          )}
                          {/* {a_questions.map((row) => {
                            console.log(row);
                            let state = row[3];
                            let answ = row[4];

                            return (
                              <div style={{ display: "flex" }}>
                                <Typography variant="h5" color="textSecondary">
                                  {state}
                                </Typography>
                                <Typography variant="h5" color="textPrimary">
                                  {": "}
                                  &nbsp;
                                </Typography>
                                <Typography variant="h5" color="textSecondary">
                                  {answ}
                                </Typography>
                              </div>
                            );
                          })} */}
                        </div>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {global.lang == "en"
                            ? "Let's memorize these knowledge"
                            : "Mémorisons ces connaissances"}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "2em",
                          }}
                        >
                          <Link
                            onClick={() =>
                              dispatch(setReduxTutoStep({ tuto_step: 0 }))
                            }
                            style={{ cursor: "pointer", color: "gray" }}
                          >
                            {global.lang == "en"
                              ? "dismiss"
                              : "quitter le tutorial"}
                          </Link>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginBottom: "2em", marginRight: "1em" }}
                            onClick={() => manageTutoStep(8)}
                          >
                            {global.lang == "en" ? "memorize" : "mémoriser"}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {(h_global_tuto_step["tuto_step"] != 0) &
              (h_global_tuto_step["tuto_step"] < 7) ? (
                <div
                  style={
                    (h_global_tuto_step["tuto_step"] == 1) |
                    (h_global_tuto_step["tuto_step"] == 4)
                      ? {
                          animation: "mymove 1s infinite",
                          animationDirection: "alternate",
                          position: "absolute",
                          zIndex: 503,
                          top: h_global_tuto_step["top_arrow"] + "px",
                          left: h_global_tuto_step["left_arrow"] - 40 + "px",
                          width: "10px",
                          height: "10px",
                        }
                      : {
                          animation: "mymove 1s infinite",
                          animationDirection: "alternate",
                          position: "absolute",
                          zIndex: 503,
                          top: h_global_tuto_step["top_arrow"] + "px",
                          left: h_global_tuto_step["left_arrow"] - 20 + "px",
                          width: "10px",
                          height: "10px",
                        }
                  }
                >
                  <img src="../../im/red_arrow.png"></img>
                </div>
              ) : null}
            </>
          ) : null}
          {h_global_tuto_step["tuto_step"] > 7 ? (
            <>
              <div
                id="id_sup7"
                style={{
                  zIndex: 501,
                  position: "absolute",
                  width: "100%",
                  height: "200%",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {h_global_tuto_step["tuto_step"] == 12 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      maxWidth: "700px",
                      position: "absolute",
                      left: "250px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: "2em",
                        maxHeight: "380px",
                        marginTop: "10em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            fontSize: "96px",
                          }}
                        >
                          💡
                        </div>

                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot == "student" && (
                            <>
                              {global.lang == "en"
                                ? "Share the list with your friends with this code ..."
                                : "Partage la liste avec tes amis grâce au code d'identification ..."}
                            </>
                          )}
                          {cookies.sot == "prof" && (
                            <>
                              {global.lang == "en"
                                ? "Students can access the list with this code ..."
                                : "Partagez la liste avec vos élèves grâce au code d'identification ..."}
                            </>
                          )}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "2em",
                          }}
                        >
                          <Link
                            onClick={() =>
                              dispatch(setReduxTutoStep({ tuto_step: 0 }))
                            }
                            style={{ cursor: "pointer", color: "gray" }}
                          >
                            {global.lang == "en"
                              ? "dismiss"
                              : "quitter le tutorial"}
                          </Link>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginBottom: "2em", marginRight: "1em" }}
                            onClick={() => manageTutoStep(13)}
                          >
                            {global.lang == "en" ? "next" : "suivant"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {h_global_tuto_step["tuto_step"] == 13 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      maxWidth: "700px",
                      position: "absolute",
                      left: "250px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: "2em",
                        maxHeight: "380px",
                        marginTop: "10em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            fontSize: "96px",
                          }}
                        >
                          💡
                        </div>

                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot == "student" && (
                            <>
                              {global.lang == "en"
                                ? "... or directly with the public url of the list"
                                : "... ou directement avec l'adresse web de la liste"}
                            </>
                          )}

                          {cookies.sot == "prof" && (
                            <>
                              {global.lang == "en"
                                ? "... or directly with the adress of the page"
                                : "... ou directement avec l'adresse web de la liste"}
                            </>
                          )}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "2em",
                          }}
                        >
                          <Link
                            onClick={() =>
                              dispatch(setReduxTutoStep({ tuto_step: 0 }))
                            }
                            style={{ cursor: "pointer", color: "gray" }}
                          >
                            {global.lang == "en"
                              ? "dismiss"
                              : "quitter le tutorial"}
                          </Link>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginBottom: "2em", marginRight: "1em" }}
                            onClick={() => manageTutoStep(14)}
                          >
                            {global.lang == "en" ? "next" : "suivant"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {h_global_tuto_step["tuto_step"] == 14 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      maxWidth: "700px",
                      position: "absolute",
                      left: "250px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: "2em",
                        maxHeight: "600px",
                        marginTop: "2em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {/*                         <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            fontSize: "96px",
                          }}
                        >
                          📜
                        </div> */}

                        <Typography
                          variant="h5"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot == "student" && (
                            <>
                              {global.lang == "en"
                                ? "When the list has more than 10 knowledge you can print a paper test, like in school!"
                                : "Quand ta liste aura plus de 10 mots de vocabulaire tu pourras imprimer une interrogation écrite pour t'entraîner, comme en classe!"}
                            </>
                          )}

                          {cookies.sot == "prof" && (
                            <>
                              {global.lang == "en"
                                ? "A feature is enabled when the list has more than 10 knowledge."
                                : "Une fonctionnalité permet à chacun d'imprimer une interrogation écrite à partir de la liste."}
                            </>
                          )}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot == "prof" && (
                            <>
                              {global.lang == "en"
                                ? "You and the students can then download or print an paper evaluation."
                                : "Elle s'active automatiquement lorsque la liste comporte plus de 10 connaissances."}
                            </>
                          )}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {global.lang == "fr" ? (
                            <img
                              width="300px"
                              src="../../im/tuto/tuto_eval_fr.png"
                            ></img>
                          ) : (
                            <img
                              width="300px"
                              src="../../im/tuto/tuto_eval_en.png"
                            ></img>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "2em",
                          }}
                        >
                          <Link
                            onClick={() =>
                              dispatch(setReduxTutoStep({ tuto_step: 0 }))
                            }
                            style={{ cursor: "pointer", color: "gray" }}
                          >
                            {global.lang == "en"
                              ? "dismiss"
                              : "quitter le tutorial"}
                          </Link>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginBottom: "2em", marginRight: "1em" }}
                            onClick={() => manageTutoStep(15)}
                          >
                            {global.lang == "en" ? "next" : "suivant"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {h_global_tuto_step["tuto_step"] == 15 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      maxWidth: "700px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: "2em",
                        maxHeight: "500px",
                        marginTop: "10em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            fontSize: "192px",
                          }}
                        >
                          😀
                        </div>

                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: "1em" }}
                          align="center"
                        >
                          {cookies.sot == "student" && (<>
                          {global.lang == "en"
                            ? "Congratulations, you've made it through the whole tutorial!"
                            : "Félicitations tu as fini le tutoriel! Tu es désormais expert en liste de connaissances Cerbrain"}
                            </>)}

                            {cookies.sot == "prof" && (<>
                          {global.lang == "en"
                            ? "Congratulations, you've made it through the whole tutorial!"
                            : "Vous avez fini le tutoriel, vous êtes désormais expert en liste de connaissances Cerbrain"}
                            </>)}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            marginTop: "2em",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              marginBottom: "4em",
                              marginRight: "1em",
                              transform: "scale(2)",
                            }}
                            onClick={() => manageTutoStep(0)}
                          >
                            {global.lang == "en" ? "end" : "fin"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {h_global_tuto_step["tuto_step"] < 12 ? (
                <div
                  style={
                    (h_global_tuto_step["tuto_step"] == 8) |
                    (h_global_tuto_step["tuto_step"] == 10)
                      ? {
                          animation: "mymove 1s infinite",
                          animationDirection: "alternate",
                          position: "absolute",
                          zIndex: 507,
                          top: h_global_tuto_step["top_arrow"] + 10 + "px",
                          left: h_global_tuto_step["left_arrow"] - 20 + "px",
                          width: "10px",
                          height: "10px",
                        }
                      : {
                          animation: "mymove 1s infinite",
                          animationDirection: "alternate",
                          position: "absolute",
                          zIndex: 507,
                          top: h_global_tuto_step["top_arrow"] + "px",
                          left: h_global_tuto_step["left_arrow"] - 20 + "px",
                          width: "10px",
                          height: "10px",
                        }
                  }
                >
                  <img src="../../im/red_arrow.png"></img>
                </div>
              ) : null}
              {h_global_tuto_step["tuto_step"] == 10 ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 507,
                    top: h_global_tuto_step["top_arrow"] - 70 + "px",
                    left: h_global_tuto_step["left_arrow"] - 80 + "px",
                    width: "260px",
                    height: "40px",
                  }}
                >
                  {global.lang == "fr" ? (
                    <Typography variant="h4" style={{ color: "red" }}>
                      Encore une fois
                    </Typography>
                  ) : (
                    <Typography variant="h4" color="red">
                      One more time
                    </Typography>
                  )}
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
