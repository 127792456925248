import React, { useState, useEffect } from "react";
import CHeader from "../MainComponents/CHeader";
import TestGen from "../Gen/TestGen";
import LTrain from "../List/LTrain";
import { addOneVisitDB } from "../Generic/lists";
import { RMetPresentation } from "../User/UPresentation";
import { LPresentation } from "../List/UList";
import Button from "@material-ui/core/Button";
import { ScoreBullet } from "../SmallComponents/SmallComponents";
import { CreaListDialog } from "../SmallComponents/CDialog";
import { useDispatch } from "react-redux";
import {
  setReduxSelectedQuestion,
  setReduxAllSelectedQuestions,
} from "../redux/globalQuestions";
import { setReduxListsMeta } from "../redux/globalLists";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { unixToShortHuman } from "../Generic/generic";
import {
  cloneQuestionsToListDB,
  keepThemDB,
  copyQuestionsToAQuestionsDB,
  deleteAQuestionsDB,
} from "../Generic/lists";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import FiberNewOutlinedIcon from "@material-ui/icons/FiberNewOutlined";
import { Link, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { unfollowAListDB } from "../Generic/lists";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LinkIcon from "@material-ui/icons/Link";

import ConstructionIcon from "@mui/icons-material/Construction";
import ToggleButton from "@mui/material/ToggleButton";

import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import Switch from "@mui/material/Switch";

import CMenu from "../MainComponents/CMenu";

const ColorButton = withStyles((theme) => ({
  root: {
    /* color: theme.palette.getContrastText(green[500]), */
    color: "white",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

export default function AList({
  id_user,
  id_list,
  url_pseudo,
  url_name,
  id_a_list,
}) {
  const [menuStatus, setMenuStatus] = useState("0");
  const [a_questions, setQuestions] = useState([]);
  const [user_pseudo, setUserPseudo] = useState("");
  const [current_id_question, setCurrentIdQuestion] = useState(-1);
  const [list_name, setListName] = useState("");
  const [list_descr, setListDescr] = useState("");
  const [list_views, setListViews] = useState("");
  const [list_crea_date, setListCreaDate] = useState("");
  const [list_added_date, setListAddedDate] = useState("");
  const [list_followers, setListFollowers] = useState("");

  const [open_img_dial, setOpenImgDial] = useState(false);
  const [media_800, setMedia800] = useState();

  const [user_meta, setUserMeta] = useState();
  const [session_url_pseudo, setSessionUrlPseudo] = useState("");
  const [owner_meta_ok, setOwnerMetaOk] = useState(false);
  const [do_disp_keep_it, doDispKeepIt] = useState(false);

  const [open_crea_list, setOpenCreaList] = useState(false);

  const [get_list_ok, setGetListsOk] = useState(false);

  const [open_test_gen, setOpenTestGen] = useState(false);
  const [arg_a_questions, setArgAQuestions] = useState([]);

  const [cont_code, setContCode] = useState("");

  const [open_unfollow_dial, setUnfollowDial] = useState(false);

  const [open_tools, setOpenTools] = useState(false);
  const [is_title_exp, setTitleExp] = useState(false);
  const [display_answers, setDisplayAnswers] = useState(false);

  const isPCS = useMediaQuery({ query: "(max-width: 1300px)" });
  const is1100 = useMediaQuery({ query: "(max-width: 1100px)" });
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const setQuestionsForTestGen = () => {
    var temp_a_questions = [];
    a_questions.forEach((row) => {
      console.log(row);
      temp_a_questions.push(row[3]);
    });
    setArgAQuestions(temp_a_questions);
  };

  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const dispatch = useDispatch();

  const setCurrentScore = (score) => {
    let a_questions_copy = [...a_questions];
    a_questions_copy.forEach((row, index) => {
      if (row[0] == current_id_question) {
        a_questions_copy[index][6] = score;
      }
    });
    setQuestions(a_questions_copy);
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const url = server_url + "p/" + url_pseudo + "/" + url_name;

  console.log("AList id_list ", id_list);
  console.log("AList id_a_list ", id_a_list);
  useEffect(() => {
    fetch(server_url + "/api/get_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        dispatch(
          setReduxListsMeta({
            a_lists_meta: res,
          })
        );
        setGetListsOk(true);
      });
    console.log("AList id_a_list ", id_a_list);
    fetch(server_url + "/api/get_pseudo_from_id", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: id_user }),
    })
      .then((res) => res.json())
      .then((data) => setUserPseudo(data["user_pseudo"]));

    fetch(server_url + "/api/get_a_list_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_a_list: id_a_list }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setListName(res[2]);
        setListDescr(res[8]);
        setListViews(res[17]);
        setListCreaDate(res[3]);
        setListAddedDate(res[18]);
        setListFollowers(res[19]);
      });

    fetch(server_url + "/api/get_a_questions", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_a_list: id_a_list }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setQuestions(res);
        let a_temp = [];
        res.map((row) => {
          if (row[9] == "u") {
            a_temp.push(row[0]);
          }
          if (row[9] == "u" || row[9] == "v") {
            doDispKeepIt(true);
          }
        });
        console.log(a_temp, id_a_list);
        if (a_temp.length > 0) {
          copyQuestionsToAQuestionsDB(a_temp, id_a_list);
        }
      });

    fetch(server_url + "/api/get_user_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: id_user }),
    })
      .then((res) => res.json())
      .then((res) => {
        setUserMeta(res);
        setOwnerMetaOk(true);
        console.log(res);
      });
    fetch(server_url + "/api/get_user_data")
      .then((res) => res.json())
      .then((res) => {
        setSessionUrlPseudo(res["url_pseudo"]);
      });

    fetch(server_url + "/api/get_cont_code", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_content: id_list, content_type: "l" }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setContCode(res["id_code"]);
      });
    addOneVisitDB(id_list);
  }, []);

  const deleteQuestionsDom = (id_questions) => {
    const a_upd_questions = [];
    a_questions.forEach((row, index) => {
      if (id_questions.indexOf(row[0] + "") == -1) {
        a_upd_questions.push(row);
      }
    });
    setQuestions(a_upd_questions);
  };

  const keepThemDom = (id_questions) => {
    console.log(id_questions);
    const a_upd_questions = [];
    a_questions.forEach((row, index) => {
      if (id_questions.indexOf(row[0] + "") != -1) {
        row[9] = "a";
      }
      if (id_questions.indexOf(row[0]) != -1) {
        row[9] = "a";
      }
      a_upd_questions.push(row);
    });
    setQuestions(a_upd_questions);
  };

  return (
    <div>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={true}
        log_out_url={server_url}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {menuStatus == "1" && <CMenu></CMenu>}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: isPhoneV ? "none" : "1650px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: isPhoneV ? "100%" : "auto",
                }}
              >
                {!isPCS && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <LPresentation
                      list_name={list_name}
                      url_list_name={url_name}
                      list_descr={list_descr}
                      crea_date={list_crea_date}
                      views={list_views}
                      followers={list_followers}
                      /* added_date={list_added_date} */
                      mode={"visitor"}
                      is_public={true}
                      max_width={250}
                    ></LPresentation>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1em",
                      }}
                    >
                      {owner_meta_ok && (
                        <RMetPresentation
                          a_meta={user_meta}
                          is_logged={"1"}
                        ></RMetPresentation>
                      )}
                    </div>
                  </div>
                )}
                <div style={{ width: isPhoneV ? "100%" : "auto" }}>
                  {isPCS && (
                    <div style={{ margin: "1em" }}>
                      <div
                        style={
                          isPhoneV
                            ? {
                                display: "flex",
                                justifyContent: "space-between",
                              }
                            : {
                                display: "flex",
                                justifyContent: "space-between",
                                width: "746px",
                              }
                        }
                      >
                        <div></div>
                        <div style={{ maxWidth: "500px" }}>
                          <Typography variant="h3">{list_name}</Typography>
                        </div>

                        {is_title_exp ? (
                          <div>
                            <IconButton>
                              <ExpandLessIcon
                                onClick={() => setTitleExp(false)}
                              />
                            </IconButton>
                          </div>
                        ) : (
                          <div>
                            <IconButton>
                              <ExpandMoreIcon
                                onClick={() => setTitleExp(true)}
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                      {is_title_exp && (
                        <>
                          <div style={{ padding: "0.5em", marginTop: "0.5em" }}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: "large" }}
                            >
                              {list_descr}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <IconButton disabled>
                                <EventIcon
                                  style={{ color: "gray" }}
                                ></EventIcon>
                              </IconButton>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingRight: "1em",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {global.lang == "en" && "Created "}
                                  {global.lang == "fr" && "Créée "}
                                  {unixToShortHuman(list_crea_date)}
                                </Typography>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              marginTop: "1em",
                            }}
                          >
                            {owner_meta_ok && (
                              <RMetPresentation
                                a_meta={user_meta}
                                is_logged={"1"}
                              ></RMetPresentation>
                            )}
                          </div>

                          {is1100 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBottom: "2em",
                                  marginTop: "1em",
                                  /* maxWidth: "220px", */
                                }}
                              >
                                <div
                                  style={
                                    isPhoneV
                                      ? {
                                          backgroundColor: "white",
                                          borderRadius: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "column",
                                        }
                                      : {
                                          backgroundColor: "white",
                                          borderRadius: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      margin: "0.5em 2em",
                                    }}
                                  >
                                    <Typography
                                      variant="h3"
                                      color="textSecondary"
                                    >
                                      #{cont_code.toUpperCase()}
                                    </Typography>
                                  </div>

                                  <div
                                    id="id_url_tocopy"
                                    style={{
                                      display: "flex",
                                      marginLeft: "-1em",
                                      transformOrigin: "center",
                                      transform: "scale(0.7)",
                                      width: "120%",
                                      paddingBottom: "1.2em",
                                    }}
                                  >
                                    <TextField
                                      id="id_public_url"
                                      label="URL"
                                      value={url}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <LinkIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                      style={{
                                        width: "300px",
                                        fontWeight: 400,
                                      }}
                                    />
                                    <IconButton
                                      aria-label={
                                        global.lang == "en"
                                          ? "copy to clipboard"
                                          : "copier dans le clipboard"
                                      }
                                      onClick={() => copyURL()}
                                      style={{ transform: "rotate(90deg)" }}
                                    >
                                      <FilterNoneIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton disabled>
                                    <EventIcon
                                      style={{ color: "gray" }}
                                    ></EventIcon>
                                  </IconButton>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {global.lang == "en" && "Followed since "}
                                      {global.lang == "fr" && "Suivie depuis "}
                                      {unixToShortHuman(list_added_date)}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Link
                                    variant="contained"
                                    onClick={() => {
                                      setUnfollowDial(true);
                                      console.log("unfollowAListDB " + id_list);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {global.lang == "en"
                                      ? "Unfollow this list"
                                      : "Ne plus suivre cette liste"}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <div
                    style={
                      isPhoneV
                        ? {
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                          }
                        : { display: "flex" }
                    }
                  >
                    <LTrain
                      a_questions={a_questions}
                      setScore={(x) => setCurrentScore(x)}
                      setCurrentId={setCurrentIdQuestion}
                      mode="a"
                      is_focused={true}
                    ></LTrain>
                    {!is1100 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: "2em",
                            marginTop: "1em",
                            maxWidth: "220px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "10px",
                            }}
                          >
                            {window.innerWidth < 1400 ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  margin: "0.5em 0",
                                }}
                              >
                                <Typography variant="h5" color="textSecondary">
                                  #{cont_code.toUpperCase()}
                                </Typography>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  margin: "0.5em 0",
                                }}
                              >
                                <Typography variant="h3" color="textSecondary">
                                  #{cont_code.toUpperCase()}
                                </Typography>
                              </div>
                            )}
                            <div
                              id="id_url_tocopy"
                              style={{
                                display: "flex",
                                marginLeft: "-1em",
                                transformOrigin: "center",
                                transform: "scale(0.7)",
                                width: "120%",
                                paddingBottom: "1.2em",
                              }}
                            >
                              <TextField
                                id="id_public_url"
                                label="URL"
                                value={url}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LinkIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                style={{ width: "300px", fontWeight: 400 }}
                              />
                              <IconButton
                                aria-label={
                                  global.lang == "en"
                                    ? "copy to clipboard"
                                    : "copier dans le clipboard"
                                }
                                onClick={() => copyURL()}
                                style={{ transform: "rotate(90deg)" }}
                              >
                                <FilterNoneIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>
                            <IconButton disabled>
                              <EventIcon style={{ color: "gray" }}></EventIcon>
                            </IconButton>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {global.lang == "en" && "Followed since "}
                                {global.lang == "fr" && "Suivie depuis "}
                                {unixToShortHuman(list_added_date)}
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Link
                              variant="contained"
                              onClick={() => {
                                setUnfollowDial(true);
                                console.log("unfollowAListDB " + id_list);
                              }}
                              style={{ cursor: "pointer", fontSize: "14px" }}
                            >
                              {global.lang == "en"
                                ? "Unfollow this list"
                                : "Ne plus suivre cette liste"}
                            </Link>
                          </div>
                        </div>

                        <ColorButton
                          variant="contained"
                          style={{
                            margin: "2em 0 0 0",
                            maxWidth: "230px",
                            borderRadius: "20px",
                          }}
                          disabled={a_questions.length >= 10 ? false : true}
                          onClick={() => {
                            setQuestionsForTestGen();
                            setOpenTestGen(true);
                          }}
                        >
                          {global.lang == "en"
                            ? "paper test"
                            : "interrogation écrite"}
                        </ColorButton>
                        {a_questions.length < 10 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              maxWidth: "230px",
                              paddingRight: "0.5em",
                              paddingLeft: "0.5em",
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              align="center"
                              style={{ fontWeight: 400 }}
                            >
                              {global.lang == "en" ? (
                                <>
                                  {10 - a_questions.length} more to activate
                                  this feature
                                </>
                              ) : (
                                <>
                                  {10 - a_questions.length} de plus pour activer
                                  cette fonctionnalité
                                </>
                              )}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2em",
                }}
              >
                <Typography style={{ fontSize: "x-large" }}>
                  {global.lang === "fr"
                    ? "Afficher les réponses"
                    : "Display answers"}
                </Typography>
                <span style={{ transform: "scale(1.6)", marginLeft: "1em" }}>
                  <Switch
                    onChange={(e) => setDisplayAnswers(e.target.checked)}
                  ></Switch>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  /* maxWidth: "1650px", */
                  backgroundColor: "rgb(246,248,250)",
                  minWidth: is1100 ? 0 : "1192px",
                }}
              >
                <div style={isPhoneV ? { margin: "1em" } : {}}>
                  {!is1100 && (
                    <div
                      style={{
                        position: "relative",
                        top: "50px",
                        left: "64px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <ToggleButton
                        selected={open_tools}
                        onClick={() => setOpenTools(!open_tools)}
                        style={
                          open_tools
                            ? { backgroundColor: "rgb(240,240,240)" }
                            : { backgroundColor: "white" }
                        }
                      >
                        <ConstructionIcon />
                      </ToggleButton>
                    </div>
                  )}
                  <Paper
                    style={{
                      border: isPhoneV ? "inherit" : "solid 1px lightgray",
                      backgroundColor: isPhoneV ? "rgb(246,248,250)" : "white",
                      marginBottom: "6em",
                    }}
                    elevation={0}
                  >
                    {get_list_ok ? (
                      <div>
                        {open_tools && (
                          <>
                            <LToolBar
                              deleteQuestionsDom={(x) => deleteQuestionsDom(x)}
                              keepThemDom={(x) => keepThemDom(x)}
                              do_disp_keep_it={do_disp_keep_it}
                            ></LToolBar>
                            <Divider></Divider>
                          </>
                        )}

                        {a_questions.map((value, index) => {
                          console.log(value);
                          return (
                            <LQuestion
                              id_question={value[0]}
                              score={value[6]}
                              statement={value[3]}
                              answer={
                                current_id_question == value[0]
                                  ? "1f9e"
                                  : value[4]
                              }
                              a_questions={a_questions}
                              setQuestions={(x) => setQuestions(x)}
                              has_pic={value[2] == "i"}
                              pic_url={value[2] == "i" ? value[8] : ""}
                              pic_url_800={value[10]}
                              status={value[9]}
                              deleteQuestionsDom={(x) => deleteQuestionsDom(x)}
                              keepThemDom={(x) => keepThemDom(x)}
                              do_disp_keep_it={do_disp_keep_it}
                              setOpenImgDial={setOpenImgDial}
                              setMedia800={setMedia800}
                              open_tools={open_tools}
                              display_answers={display_answers}
                            ></LQuestion>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          backgroundColor: "rgb(246,248,250)",
                        }}
                      >
                        <CircularProgress></CircularProgress>
                      </div>
                    )}
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open_img_dial}
        onClose={() => {
          setOpenImgDial(false);
        }}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2em",
          }}
        >
          <img src={media_800}></img>
        </div>
      </Dialog>

      <CreaListDialog
        open_crea_list={open_crea_list}
        setOpenCreaList={setOpenCreaList}
        url_pseudo={session_url_pseudo}
      ></CreaListDialog>
      <Dialog
        open={open_test_gen}
        onClose={() => {
          setOpenTestGen(false);
        }}
        maxWidth="xl"
      >
        <div>
          <TestGen
            arg_a_questions={arg_a_questions}
            url_pseudo={url_pseudo}
            url_name={url_name}
            id_list={id_list}
          ></TestGen>
        </div>
      </Dialog>

      <Dialog open={open_unfollow_dial} onClose={() => setUnfollowDial(false)}>
        <div style={{ padding: "2em" }}>
          <Typography variant="h4" style={{ marginBottom: "1.4em" }}>
            {global.lang == "en" && "Confirm you want to unfollow this list"}
            {global.lang == "fr" && "Ne plus suivre cette liste"}
          </Typography>
          <DialogActions>
            <Button
              onClick={() => setUnfollowDial(false)}
              color="primary"
              style={{ marginBottom: "-1em" }}
            >
              {global.lang == "en" && "Cancel"}
              {global.lang == "fr" && "Annuler"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                unfollowAListDB(id_list);
                window.location.href = server_url;
                /* setUnfollowDial(false); */
              }}
              color="primary"
              style={{ marginRight: "1em", marginBottom: "-1em" }}
            >
              {global.lang == "en" && "I confirm"}
              {global.lang == "fr" && "Je confirme"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

function LToolBar({ deleteQuestionsDom, keepThemDom, do_disp_keep_it }) {
  const [id_selected_list, setIdSelectedList] = useState(-1);
  const [open_snack, setOpenSnack] = useState(false);
  const [snack_message, setSnackMessage] = useState("");
  const [is_checkbox_checked, setIsCheckboxChecked] = useState(false);

  const [selected_number, setSelectedNumber] = useState();
  const [open_del_diag, setOpenDelDiag] = useState(false);

  const h_redux_my_lists = useSelector((state) => state.global_lists);
  const h_redux_my_questions = useSelector((state) => state.global_questions);
  console.log(h_redux_my_lists);

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof h_redux_my_lists["a_own_lists"][0] !== "undefined") {
      setIdSelectedList(h_redux_my_lists["a_own_lists"][0][0]);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "rgb(246,248,250)",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <Checkbox
          color="primary"
          onChange={(event) => {
            setIsCheckboxChecked(event.target.checked);
            dispatch(
              setReduxAllSelectedQuestions({
                is_selected: event.target.checked,
              })
            );
            console.log(event.target.checked);
          }}
          checked={is_checkbox_checked}
        ></Checkbox>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Button
            size="small"
            style={{
              border: "1px solid lightgray",
              transform: "scale(0.7)",
              color: "#4D5156",
            }}
            color="textSecondary"
            onClick={() => {
              let a_temp_selected_questions = [];
              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                }
              );
              cloneQuestionsToListDB(
                a_temp_selected_questions,
                id_selected_list
              );
              let list_name = "";
              h_redux_my_lists["a_own_lists"].map((row) => {
                if (row[0] == id_selected_list) {
                  list_name = row[2];
                }
              });
              dispatch(
                setReduxAllSelectedQuestions({
                  is_selected: false,
                })
              );
              setSnackMessage(
                a_temp_selected_questions.length +
                  " questions copied to " +
                  list_name
              );
              setOpenSnack(true);
              setIsCheckboxChecked(false);
            }}
          >
            {global.lang == "en"
              ? "copy selected questions to"
              : "copier les questions dans"}
          </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            value={id_selected_list}
            onChange={(event) => {
              setIdSelectedList(event.target.value);
            }}
            style={{
              width: "150px",
              color: "#4D5156",
              transform: "scale(0.7)",
              transformOrigin: "left",
            }}
          >
            {h_redux_my_lists["a_own_lists"].map((row) => {
              return <MenuItem value={row[0]}>{row[2]}</MenuItem>;
            })}
          </Select>
        </div>

        <div
          style={
            do_disp_keep_it
              ? { display: "flex", alignItems: "center" }
              : { display: "flex", alignItems: "center", opacity: "0" }
          }
        >
          <Button
            size="small"
            style={{
              border: "1px solid lightgray",
              transform: "scale(0.7)",
              color: "#4D5156",
            }}
            color="textSecondary"
            onClick={() => {
              let a_temp_selected_questions = [];
              Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
                (k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                }
              );
              console.log(a_temp_selected_questions);
              keepThemDB(a_temp_selected_questions);
              keepThemDom(a_temp_selected_questions);
              dispatch(
                setReduxAllSelectedQuestions({
                  is_selected: false,
                })
              );
              setSnackMessage(
                a_temp_selected_questions.length +
                  " questions kept into the list"
              );
              setOpenSnack(true);
              setIsCheckboxChecked(false);
            }}
          >
            keep selected questions
          </Button>
        </div>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", paddingRight: "1em" }}
      >
        {/*         <Button
          size="small"
          style={{
            border: "1px solid lightgray",
            transform: "scale(0.7)",
            color: "#4D5156",
            transformOrigin: "right",
          }}
          color="textSecondary"
          onClick={() => {
            let a_temp_selected_questions = [];
            Object.keys(h_redux_my_questions["h_selected_questions"]).forEach(
              (k) => {
                let v = h_redux_my_questions["h_selected_questions"][k];
                if (v) {
                  a_temp_selected_questions.push(k);
                }
              }
            );
            setSelectedNumber(a_temp_selected_questions.length);
            setOpenDelDiag(true);
          }}
        >
          {global.lang == "en"
            ? "delete selected questions"
            : "supprimer la selection"}
        </Button> */}
      </div>
      <Snackbar
        open={open_snack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
        message={snack_message}
      ></Snackbar>

      <Dialog
        open={open_del_diag}
        onClose={() => setOpenDelDiag(false)}
        aria-labelledby="alert-dialog-title"
      >
        <div style={{ padding: "2em" }}>
          <Typography variant="h4" style={{ marginBottom: "1.4em" }}>
            {global.lang == "en" &&
              "Confirm you want to delete these " +
                selected_number +
                " questions"}
            {global.lang == "fr" &&
              "Confirmer la suppression de " + selected_number + " questions"}
          </Typography>
          <DialogActions>
            <Button
              onClick={() => setOpenDelDiag(false)}
              color="primary"
              style={{ marginBottom: "-1em" }}
            >
              {global.lang == "en" && "Cancel"}
              {global.lang == "fr" && "Annuler"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                let a_temp_selected_questions = [];
                Object.keys(
                  h_redux_my_questions["h_selected_questions"]
                ).forEach((k) => {
                  let v = h_redux_my_questions["h_selected_questions"][k];
                  if (v) {
                    a_temp_selected_questions.push(k);
                  }
                });
                console.log(a_temp_selected_questions);
                deleteAQuestionsDB(a_temp_selected_questions);
                deleteQuestionsDom(a_temp_selected_questions);
                dispatch(
                  setReduxAllSelectedQuestions({
                    is_selected: false,
                  })
                );
                setSnackMessage(
                  a_temp_selected_questions.length +
                    " questions removed from list"
                );
                setOpenDelDiag(false);
                setOpenSnack(true);
                setIsCheckboxChecked(false);
              }}
              color="primary"
              style={{ marginRight: "1em", marginBottom: "-1em" }}
            >
              {global.lang == "en" && "I confirm"}
              {global.lang == "fr" && "Je confirme"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

function LQuestion({
  id_question,
  score,
  statement,
  answer,
  a_questions,
  setQuestions,
  status,
  has_pic,
  pic_url,
  pic_url_800,
  deleteQuestionsDom,
  keepThemDom,
  do_disp_keep_it,
  setOpenImgDial,
  setMedia800,
  open_tools,
  display_answers,
}) {
  const [id_selected_list, setIdSelectedList] = useState(-1);
  const [open_snack, setOpenSnack] = useState(false);
  const [snack_message, setSnackMessage] = useState("");
  const [show_answer, setShowAnswer] = useState(false);
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const dispatch = useDispatch();

  const h_redux_my_lists = useSelector((state) => state.global_lists);
  console.log(h_redux_my_lists);
  const h_redux_my_questions = useSelector((state) => state.global_questions);
  console.log(h_redux_my_questions);

  useEffect(() => {
    setShowAnswer(display_answers);
  }, [display_answers]);

  useEffect(() => {
    if ( typeof h_redux_my_lists["a_own_lists"][0] !== "undefined"){
      setIdSelectedList(h_redux_my_lists["a_own_lists"][0][0]);
    }
    dispatch(
      setReduxSelectedQuestion({
        is_selected: false,
        id_question: id_question,
      })
    );
  }, []);

  console.log(h_redux_my_questions["h_selected_questions"]);
  console.log(h_redux_my_questions["h_selected_questions"][id_question]);
  console.log(id_question);

  return (
    <div
    /*       style={
        status != "a"
          ? { backgroundColor: "rgb(246,248,250)" }
          : { backgroundColor: "white" }
      } */
    >
      <div
        style={
          isPhoneV
            ? {
                display: "flex",
                flexDirection: "column",
                marginBottom: "1em",
                backgroundColor: "white",
                border: "1px solid lightgray",
              }
            : { display: "flex", justifyContent: "space-between" }
        }
      >
        {open_tools && (
          <Checkbox
            color="primary"
            onChange={(event) => {
              dispatch(
                setReduxSelectedQuestion({
                  is_selected: event.target.checked,
                  id_question: id_question,
                })
              );
            }}
            checked={
              h_redux_my_questions["h_selected_questions"][id_question]
                ? true
                : false
            }
          ></Checkbox>
        )}
        <div
          style={
            isPhoneV
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }
              : { display: "flex", alignItems: "center" }
          }
        >
          <ScoreBullet score={Math.min(score, 2)}></ScoreBullet>
        </div>

        <div
          style={
            isPhoneV
              ? {
                  padding: "12px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  width: "400px",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-around",
                }
          }
        >
          {has_pic ? (
            <div
              style={{
                /*                 width: "200px",
                maxHeight: "150px", */
                display: "flex",
                alignItems: "center",
                /* wordBreak: "break-all", */
                overflow: "auto",
                textAlign: "center",
              }}
            >
              {status == "u" && (
                <FiberNewOutlinedIcon color="secondary"></FiberNewOutlinedIcon>
              )}

              {statement.length < 17 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length >= 17 && statement.length <= 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "x-large" }
                      : { fontSize: "x-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length > 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "large" }
                      : { fontSize: "large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
            </div>
          ) : (
            <div
              style={{
                width: isPhoneV ? "auto" : "400px",
                /* maxHeight: "150px", */
                display: "flex",
                alignItems: "center",
                /* wordBreak: "break-all", */
                overflow: "auto",
                textAlign: "center",
              }}
            >
              {status == "u" && (
                <FiberNewOutlinedIcon color="secondary"></FiberNewOutlinedIcon>
              )}

              {statement.length < 17 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length >= 17 && statement.length <= 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length > 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "x-large" }
                      : { fontSize: "x-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
            </div>
          )}
          {has_pic && (
            <img
              src={pic_url}
              style={{
                maxWidth: "300px",
                maxHeight: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                setMedia800(pic_url_800);
                setOpenImgDial(true);
              }}
            ></img>
          )}
        </div>
        {!isPhoneV && (
          <>
            {show_answer ? (
              <div
                style={{
                  width: "280px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  cursor: "pointer",
                  zIndex: 10
                }}
                onClick={() => {
                  setShowAnswer(false);
                }}
              >
                {answer === "1f9e" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontSize: "XXx-large", color: "red" }}
                    >
                      ?
                    </Typography>
                  </div>
                ) : (
                  <>
                    {answer.length < 17 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "xx-large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                    {answer.length >= 17 && answer.length <= 28 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "x-large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                    {answer.length > 28 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "280px",
                  zIndex: 10
                }}
                onClick={() => {
                  setShowAnswer(true);
                }}
              >
                <div></div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "xx-large" }}
                >
                  {global.lang == "fr" ? "réponse" : "answer"}
                </Typography>
                <TouchAppIcon
                  style={{ color: "white", marginRight: "1em" }}
                  fontSize="large"
                ></TouchAppIcon>
              </div>
            )}
          </>
        )}
        {/* <Button onClick={()=>{console.log(display_answers); setShowAnswer(display_answers);}}>click me</Button> */}
        {isPhoneV && (
          <>
            {show_answer ? (
              <>
                <div
                  style={
                    isPhoneV
                      ? {
                          padding: "12px",
                          textAlign: "center",
                          cursor: "pointer",
                          zIndex: 10
                        }
                      : {
                          width: "280px",
                          padding: "12px",
                          display: "flex",
                          justifyContent: "space-around",
                          cursor: "pointer",
                          zIndex: 10
                        }
                  }
                  onClick={() => {
                    setShowAnswer(false);
                  }}
                >
                  {answer === "1f9e" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ fontSize: "XXx-large", color: "red" }}
                      >
                        ?
                      </Typography>
                    </div>
                  ) : (
                    <>
                      {answer.length < 17 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "xx-large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                      {answer.length >= 17 && answer.length <= 28 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "x-large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                      {answer.length > 28 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  padding: "12px",
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: 10
                }}
                onClick={() => {
                  setShowAnswer(true);
                }}
              >
                <div></div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "xx-large" }}
                >
                  {global.lang == "fr" ? "réponse" : "answer"}
                </Typography>
                <TouchAppIcon
                  style={{ color: "white", marginRight: "1em" }}
                  fontSize="large"
                ></TouchAppIcon>
              </div>
            )}
          </>
        )}

        {open_tools && (
          <div
            style={
              do_disp_keep_it
                ? {}
                : {
                    backgroundColor: "rgb(246,248,250)",
                    width: "280px",
                  }
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {do_disp_keep_it && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      console.log([id_question]);
                      keepThemDB([id_question]);
                      keepThemDom([id_question]);
                    }}
                    style={
                      status != "a"
                        ? { transform: "scale(0.7)", width: "120px" }
                        : {
                            transform: "scale(0.7)",
                            width: "120px",
                            opacity: 0,
                          }
                    }
                  >
                    keep it
                  </Button>
                </div>
              )}

              <div
                style={
                  do_disp_keep_it
                    ? {
                        display: "flex",
                        transform: "scale(0.7)",
                        transformOrigin: "left",
                        paddingLeft: "1em",
                      }
                    : {
                        display: "flex",
                        transform: "scale(0.7)",
                        transformOrigin: "left",
                        paddingLeft: "1em",
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      if (id_selected_list != -1) {
                        cloneQuestionsToListDB([id_question], id_selected_list);
                        let list_name = "";
                        h_redux_my_lists["a_own_lists"].map((row) => {
                          if (row[0] == id_selected_list) {
                            list_name = row[2];
                          }
                        });
                        setSnackMessage("Question added to " + list_name);
                        setOpenSnack(true);
                      }
                    }}
                    style={{
                      border: "1px solid lightgray",
                      width: "120px",
                      color: "#4D5156",
                    }}
                  >
                    {global.lang == "en" ? "copy to" : "copier dans"}
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "1em",
                  }}
                >
                  <Select
                    value={id_selected_list}
                    onChange={(event) => {
                      setIdSelectedList(event.target.value);
                    }}
                    style={{
                      width: "150px",

                      color: "#4D5156",
                    }}
                  >
                    {h_redux_my_lists["a_own_lists"].map((row) => {
                      return <MenuItem value={row[0]}>{row[2]}</MenuItem>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={open_snack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
        message={snack_message}
      ></Snackbar>
      <Divider></Divider>
    </div>
  );
}
