import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import GroupIcon from "@material-ui/icons/Group";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import { CreaListDialog, CreaPageDialog } from "../SmallComponents/CDialog";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#EFF0F1",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0C0D0E",
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
    top: "69px",
    position: "fixed",
    bottom: "0px",

    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  menuGhost: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
  },
}));

export default function CMenu({ setPage, page }) {
  const classes = useStyles();
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  const [openCreaListDialog, setOpenCreaListDialog] = useState(false);
  const [openCreaPageDialog, setOpenCreaPageDialog] = useState(false);

  console.log(page);

  const logOut = () => {
    fetch(server_url + "/api/log_out")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        window.location.reload();
      });
  };

  return (
    <div style={{ backgroundColor: "rgb(246,248,250)" }}>
      <div className={classes.menuGhost}></div>
      <div className={classes.menu}>
        <br></br>
        <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url;
          }}
          selected={page == "wall"}
          style={page == "wall" ? { borderRight: "solid 4px #1976d2" } : {}}
        >
          <ListItemIcon
            style={page == "wall" ? { color: "#0C0D0E" } : { color: "#535A60" }}
          >
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              page == "wall" ? (
                <Typography style={{ fontWeight: "bold", color: "#0C0D0E" }}>
                  {global.lang == "en" ? "Home" : "Accueil"}
                </Typography>
              ) : (
                <Typography style={{ color: "#535A60" }}>
                  {global.lang == "en" ? "Home" : "Accueil"}
                </Typography>
              )
            }
          />
        </StyledMenuItem>
        <br></br>
        {/*         <br></br>
        <br></br>
        <StyledMenuItem button onClick={() => {setPage("lists");window.history.pushState('', 'Cerbrain', '/lists');}}  selected={page=="lists"} style={page=="lists"?{borderRight:"solid 4px #1976d2"}:{}}>
          <ListItemIcon style={page=="lists"?{color:"#0C0D0E"}:{color:"#535A60"}}>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={page=="lists"?<Typography style={{ fontWeight: 'bold', color:"#0C0D0E" }}>{global.lang=='en'?"Lists":"Listes"}</Typography>:<Typography style={{ color:"#535A60" }}>{global.lang=='en'?"Lists":"Listes"}</Typography>} />
        </StyledMenuItem>
        <StyledMenuItem button onClick={() => {setPage("pages");window.history.pushState('', 'Cerbrain', '/pages');}}  selected={page=="pages"} style={page=="pages"?{borderRight:"solid 4px #1976d2"}:{}}>
          <ListItemIcon style={page=="pages"?{color:"#0C0D0E"}:{color:"#535A60"}}>
            <LibraryBooksIcon/>
          </ListItemIcon>
          <ListItemText primary={page=="pages"?<Typography style={{ fontWeight: 'bold', color:"#0C0D0E" }}>Pages</Typography>:<Typography style={{ color:"#535A60" }}>Pages</Typography>}/>
        </StyledMenuItem>
        <br></br> */}
        <br></br>
        {/*         <StyledMenuItem button onClick={() => {setPage("users");window.history.pushState('', 'Cerbrain', '/users');}}  selected={page=="users"} style={page=="users"?{borderRight:"solid 4px #1976d2"}:{}}>
          <ListItemIcon style={page=="users"?{color:"#0C0D0E"}:{color:"#535A60"}}>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={page=="users"?<Typography style={{ fontWeight: 'bold', color:"#0C0D0E" }}>{global.lang=='en'?"Users":"Utilisateurs"}</Typography>:<Typography style={{ color:"#535A60" }}>{global.lang=='en'?"Users":"Membres"}</Typography>} />
        </StyledMenuItem> */}

        <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url + "profile";
          }}
          selected={page == "profile"}
          style={page == "profile" ? { borderRight: "solid 4px #1976d2" } : {}}
        >
          <ListItemIcon
            style={
              page == "profile" ? { color: "#0C0D0E" } : { color: "#535A60" }
            }
          >
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              page == "profile" ? (
                <Typography style={{ fontWeight: "bold", color: "#0C0D0E" }}>
                  {global.lang == "en" ? "Profile" : "Profil"}
                </Typography>
              ) : (
                <Typography style={{ color: "#535A60" }}>
                  {global.lang == "en" ? "Profile" : "Profil"}
                </Typography>
              )
            }
          />
        </StyledMenuItem>

        <br></br>
        <br></br>

        <StyledMenuItem
          button
          onClick={logOut}
          selected={false}
        >
          <ListItemIcon
            style={{ color: "#535A60" }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>
                {global.lang == "en" ? "Log out" : "Se déconnecter"}
              </Typography>
            }
          />
        </StyledMenuItem>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "2em",
          }}
        >
          <CCreateList
            setOpenCreaListDialog={(x) => setOpenCreaListDialog(x)}
          />
        </div>
        {/*       <div style={{ display: "flex", justifyContent: "space-around" }}>
        <CCreatePage setOpenCreaPageDialog={(x) => setOpenCreaPageDialog(x)} />
      </div> */}
      </div>
      <CreaListDialog
        open_crea_list={openCreaListDialog}
        setOpenCreaList={setOpenCreaListDialog}
        style={{ marginTop: "4em" }}
      ></CreaListDialog>
      <CreaPageDialog
        open_crea_page={openCreaPageDialog}
        setOpenCreaPage={setOpenCreaPageDialog}
      ></CreaPageDialog>
    </div>
  );
}

function CCreatePage({ setOpenCreaPageDialog }) {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleIcon />}
      color="primary"
      style={{ margin: "1em" }}
      onClick={() => setOpenCreaPageDialog(true)}
    >
      {global.lang == "en" ? "create a page" : "créer une page"}
    </Button>
  );
}

function CCreateList({ setOpenCreaListDialog }) {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleIcon />}
      color="secondary"
      style={{ margin: "1em", zIndex: 502 }}
      onClick={() => setOpenCreaListDialog(true)}
      id="id_crea_list_1"
    >
      {global.lang == "en" ? "create a list" : "créer une liste"}
    </Button>
  );
}
