import { createSlice } from "@reduxjs/toolkit";

export const tutoSlice = createSlice({
  name: "global_tuto",
  initialState: {
  tuto_step: 0,
  top_arrow: 0,
  left_arrow: 0,
  top_arrow2: 0,
  left_arrow2: 0,
  },
  reducers: {
    setReduxTutoStep: (state, action) => {
      state['tuto_step'] = action.payload.tuto_step;
    },
    setReduxTopArrow: (state, action) => {
        state['top_arrow'] = action.payload.top_arrow;
    },
    setReduxLeftArrow: (state, action) => {
        state['left_arrow'] = action.payload.left_arrow;
    },
    setReduxTopArrow2: (state, action) => {
      state['top_arrow2'] = action.payload.top_arrow2;
  },
  setReduxLeftArrow2: (state, action) => {
      state['left_arrow2'] = action.payload.left_arrow2;
  }
  },
});

export const { setReduxTutoStep, setReduxTopArrow, setReduxLeftArrow, setReduxTopArrow2, setReduxLeftArrow2 } = tutoSlice.actions;

export default tutoSlice.reducer;