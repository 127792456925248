import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CHeader from "./CHeader";
import CMenu from "./CMenu.js";
import CRPan from "./CRPan";
import CWall from "./CWall";
import CPages from "../Page/CPages";
import CProfile from "../User/CProfile";
import CUsers from "../User/CUsers";
import CLists from "./CLists";
import { Hidden } from "@material-ui/core";


export default function WallPage({ doLogOut }) {
  // menuStatus = "1" when displaid in large screen
  // menuStatus = "0" when hidden in small screen
  const [menuStatus, setMenuStatus] = useState("0");
  // page = 'wall'
  // page = 'lists'
  // page = 'pages'
  const [page, setPage] = useState("lists");


  const a_url = window.location.href.split("/");
  const str_page = a_url[3].split("?")[0];

  useEffect(() => {
    if (str_page == "lists") {
      setPage("lists");
      setMenuStatus("1");
    }
    if (str_page == "pages") {
      setPage("pages");
      setMenuStatus("1");
    }
    if (str_page == "profile") {
      setPage("profile");
      setMenuStatus("1");
    }
    if (str_page == "users") {
      setPage("users");
      setMenuStatus("1");
    }
  }, []);

  return (
    <div>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={true}
        show_crea_but={page === "lists" ? false: true}
      ></CHeader>

      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Hidden smDown>
          {menuStatus == 1 && (
            <CMenu setPage={(x) => setPage(x)} page={page}></CMenu>
          )}
        </Hidden>
        <Hidden mdUp>
          {menuStatus == 1 && (
            <CMenu setPage={(x) => setPage(x)} page={page}></CMenu>
          )}
        </Hidden>

        <div style={{display:"flex", justifyContent:"space-around", width:"100%"}}>
          {page === "wall" && <CWall></CWall>}
          {page === "lists" && <CLists></CLists>}
          {/* {page === "pages" && <CPages></CPages>} */}
          {page === "profile" && <CProfile></CProfile>}
          {page === "users" && <CUsers></CUsers>}
  {/*         {page != "wall" && (
            <Hidden mdDown>
              <CRPan/>
            </Hidden>
          )} */}
        </div>
      </div>
    </div>
  );
}
