import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { GoogleLogin } from "react-google-login";
import { signInGoogle } from "../Generic/google_signin";
import { SignIn, SignUpG } from "../HomeNoSession/HomeNoSession";
import Dialog from "@material-ui/core/Dialog";
import { goFromCode } from "../Generic/lists";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import {
  setReduxRegister,
  setReduxDefaultDisplay,
} from "../redux/globalDialog";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import GoogleIcon from "@mui/icons-material/Google";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "whitesmoke",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "whitesmoke",
    },
  },
});

function LandingPage() {
  const [openInDialog, setOpenInDialog] = useState(false);
  const [openUpDialog, setOpenUpDialog] = useState(false);
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const [lang, setLang] = useState(global.lang);

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");

  const [id_code, setIdCode] = useState("");

  const isPC = useMediaQuery({ query: "(min-width: 1000px)", });
  const isPhone = useMediaQuery({ query: "(max-width: 500px)" });
  
  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => window.location.reload(),
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };

  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  const redirectLang = (lang) => {
    const a_url = window.location.href.split("?");
    window.location.href = a_url[0] + "?" + lang;
  };
  const classes = useStyles();

  const dispatch = useDispatch();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPosition2, setScrollPosition2] = useState(0);
  const [scrollPosition3, setScrollPosition3] = useState(0);
  const [scrollPosition4, setScrollPosition4] = useState(0);
  const [scrollPosition5, setScrollPosition5] = useState(0);

  const [scrollFade1, setScrollFade1] = useState(0);
  const [scrollFade2, setScrollFade2] = useState(0);

  const handleScroll = () => {
    setScrollPosition(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow1").offsetTop
    );
    setScrollPosition2(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow2").offsetTop
    );
    setScrollPosition3(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow3").offsetTop
    );
    setScrollPosition4(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow4").offsetTop
    );
    setScrollPosition5(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow5").offsetTop
    );
    setScrollFade1(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_fade1").offsetTop
    );
    setScrollFade2(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_fade2").offsetTop
    );
  };

  useEffect(() => {
    setScrollPosition(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow1").offsetTop
    );
    setScrollPosition2(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow2").offsetTop
    );
    setScrollPosition3(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow3").offsetTop
    );
    setScrollPosition4(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow4").offsetTop
    );
    setScrollPosition5(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_grow5").offsetTop
    );
    setScrollFade1(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_fade1").offsetTop
    );
    setScrollFade2(
      window.scrollY +
        window.innerHeight -
        document.getElementById("id_fade2").offsetTop
    );
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="id_landingpage_main"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "#2B009A",
          alignItems: "center",
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
        }}
      >
        <div
          style={
            isPC
              ? { display: "flex", alignItems: "center" }
              : {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }
          }
        >
          {/*           {!isPC && (
            <>
              <img src={"im/logo_A_no_text.svg"} style={{ width: "84%" }}></img>
              <div style={{ marginTop: "-2em", marginBottom: "2em" }}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "Fredoka One",
                    color: "whitesmoke",
                  }}
                  display="inline"
                >
                  cerbrain
                </Typography>
              </div>
            </>
          )} */}
          <Typography
            variant={isPC ? "h5" : "h4"}
            style={
              isPC
                ? { marginRight: "1em", color: "white" }
                : { margin: "1em", color: "white" }
            }
          >
            {global.lang == "fr" ? "Accès direct" : "Direct access"}{" "}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              goFromCode(id_code);
            }}
          >
            <div
              style={
                isPC
                  ? { display: "flex", alignItems: "center" }
                  : {
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }
              }
            >
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="h6" style={{ color: "white" }}>
                        #
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                placeholder="x4w2k"
                onChange={(e) => setIdCode(e.target.value)}
                style={isPC ? { width: "148px" } : { width: "254px" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center", color: "white" },
                }}
                className={classes.root}
              ></TextField>
              <Button
                variant="contained"
                onClick={() => goFromCode(id_code)}
                type="submit"
                color="primary"
                style={
                  isPC
                    ? {
                        height: "54px",
                        borderRadius: "10",
                        backgroundColor: "rgb(0, 61, 255)",
                      }
                    : {
                        height: "54px",
                        width: "254px",
                        marginTop: "0.5em",
                        marginBottom: "2em",
                      }
                }
              >
                GO
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div
        style={{
          /* backgroundImage: `url(${sall_back})`, */
          backgroundImage: `url("im/lp/Sall_back.svg")`,
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            style={
              isPC
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "1050px",
                    marginTop: "1em",
                  }
                : isPhone
                ? {
                    width: "1050px",
                    marginTop: "1em",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-around",
                    width: "1050px",
                    marginTop: "1em",
                  }
            }
          >
            {isPC && (
              <>
                <div>
                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "Fredoka One",
                      /* color: "rgb(0, 61, 255)", */
                      color: "rgb(25,25,70)",
                    }}
                    display="inline"
                  >
                    cerbrain
                  </Typography>
                </div>
              </>
            )}
            {!isPC && (
              <>
                <div
                  style={
                    isPhone
                      ? {
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "1em",
                        }
                      : {}
                  }
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "Fredoka One",
                      /* color: "rgb(0, 61, 255)", */
                      color: "rgb(25,25,70)",
                    }}
                    display="inline"
                  >
                    cerbrain
                  </Typography>
                </div>
              </>
            )}

            <div style={isPC ? { display: "flex", alignItems: "center" } : {}}>
              <div
                style={
                  isPC
                    ? { display: "flex", justifyContent: "space-around" }
                    : {
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                      }
                }
              >
                <div style={isPC ? {} : isPhone ? {} : { marginRight: "1em" }}>
                  <Button
                    variant="text"
                    onClick={() => {
                      document.getElementById("id_invisible_link").click();
                      dispatch(
                        setReduxDefaultDisplay({ default_display: "signin" })
                      );
                      dispatch(setReduxRegister({ register: true }));
                    }}
                    style={
                      isPC
                        ? { color: "rgb(25,25,70)" }
                        : { color: "rgb(25,25,70)" }
                    }
                  >
                    {global.lang == "fr" ? "Se connecter" : "Log in"}
                  </Button>
                </div>
                <div>
                  <Button
                    /* variant="contained"
                  color="secondary" */
                    variant="text"
                    onClick={() => {
                      document.getElementById("id_invisible_link").click();
                      dispatch(
                        setReduxDefaultDisplay({ default_display: "signup" })
                      );
                      dispatch(setReduxRegister({ register: true }));
                    }}
                    style={
                      isPC
                        ? {
                            color: "rgb(25,25,70)",
                            marginLeft: "2em",
                            marginRight: "2em",
                          }
                        : { color: "rgb(25,25,70)" }
                    }
                  >
                    {global.lang == "fr" ? "créer un compte" : "Register"}
                  </Button>
                </div>

                {!isPC && (
                  <div>
                    <Select
                      value={lang}
                      style={{ marginLeft: "2em", color: "rgb(25,25,70)" }}
                      disableUnderline
                    >
                      <MenuItem value="fr" onClick={() => redirectLang("fr")}>
                        fr
                      </MenuItem>
                      <MenuItem value="en" onClick={() => redirectLang("en")}>
                        en
                      </MenuItem>
                    </Select>
                  </div>
                )}
              </div>
              {isPC && (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <GoogleLogin
                      clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={handleGoogleButton}
                      onFailure={() => {
                        alert(
                          "Problem with the sign in method, please choose another one"
                        );
                      }}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          style={
                            isPC
                              ? {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginLeft: "0.4em",
                                  marginRight: "0.4em",
                                  color: "black",
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginLeft: "1.5em",
                                  marginRight: "1.5em",
                                  color: "black",
                                }
                          }
                          startIcon={<GoogleIcon />}
                          endIcon={<></>}
                          variant="outlined"
                        >
                          {global.lang == "fr" ? "se connecter" : "login"}
                        </Button>
                      )}
                    />
                  </div>

                  <div>
                    <Select
                      value={lang}
                      /* style={{ height: "24px", top: "20px", marginRight: "1.7em" }} */
                      style={{ marginLeft: "2em", color: "rgb(25,25,70)" }}
                      disableUnderline
                    >
                      <MenuItem value="fr" onClick={() => redirectLang("fr")}>
                        fr
                      </MenuItem>
                      <MenuItem value="en" onClick={() => redirectLang("en")}>
                        en
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "4em",
            minHeight: "500px",
          }}
        >
          <div
            style={
              isPC
                ? {
                    margin: "3em",
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    left: "30px",
                    width: "1150px",
                    backgroundImage: 'url("im/lp/S1_pic.svg")',
                    backgroundPosition: "center right",
                    backgroundRepeat: "no-repeat",
                  }
                : {
                    margin: "3em",
                    display: "flex",
                    justifyContent: "space-around",
                    position: "relative",
                    width: "100%",
                  }
            }
          >
            <div
              style={
                isPC
                  ? { width: "600px", margin: "2em" }
                  : { margin: "1em", marginTop: "2em" }
              }
            >
              <Typography
                variant={isPC ? "h3" : "h4"}
                style={{ fontSize: "3.5rem", fontWeight: 800 }}
                display="inline"
              >
                {global.lang == "fr" ? "Création de " : "Create "}
              </Typography>
              <Typography
                variant={isPC ? "h3" : "h4"}
                style={{
                  fontSize: "3.5rem",
                  fontWeight: 800,
                  color: "rgb(240, 120, 0)",
                }}
                display="inline"
              >
                {global.lang == "fr"
                  ? "listes de vocabulaire"
                  : "vocabulary lists"}
              </Typography>
              <Typography
                variant={isPC ? "h3" : "h4"}
                style={{ fontSize: "3.5rem", fontWeight: 800 }}
                display="inline"
              >
                {global.lang == "fr" ? " pour la classe" : " for the class"}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  marginTop: "2em",
                  fontSize: "1.5rem",
                  fontWeight: 400,
                }}
              >
                {global.lang == "fr"
                  ? "Créez des listes de connaissances: vocabulaire, dates, personnages historiques ... " +
                    "Partagez le lien avec vos élèves. " +
                    "Transformez automatiquement ces listes en " +
                    "interrogations écrites."
                  : "Create knowledge lists: vocabulary, dates, historic events ... " +
                    "Share the link with your student. " +
                    "You can even print a paper test for the class !"}
              </Typography>
              <a id="id_invisible_link" href="#id_landingpage_main"></a>
            </div>

            {isPC && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div style={{ margin: "3em 8em" }}>
                  <Fade in={true} timeout={500}>
                    <Button
                      variant="contained"
                      /*                       href={
                        global.lang == "fr"
                          ? "https://cerbrain.fr/p/CerbEnglish/FauxAmis"
                          : "https://cerbrain.com/p/CerbEnglish/FauxAmis"
                      } */
                      style={{
                        transform: "scale(1.3)",
                        backgroundColor: "rgb(0, 61, 255)",
                        width: "80%",
                        marginBottom: "3em",
                        fontFamily: "Nunito",
                        fontWeight: 900
                      }}
                      onClick={() => {
                        if (global.lang === "fr") {
                          window.open(
                            "https://cerbrain.fr/p/CerbEnglish/FauxAmis",
                            "_blank"
                          );
                        } else {
                          window.open(
                            "https://cerbrain.com/p/CerbEnglish/FauxAmis",
                            "_blank"
                          );
                        }
                      }}
                    >
                      {global.lang == "fr"
                        ? "voir un exemple"
                        : "see an example"}
                    </Button>
                  </Fade>
                  <Fade in={true} timeout={1000}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        document.getElementById("id_invisible_link").click();
                        dispatch(
                          setReduxDefaultDisplay({ default_display: "signup" })
                        );
                        dispatch(setReduxRegister({ register: true }));
                      }}
                      style={{
                        transform: "scale(1.3)",
                        backgroundColor: "rgb(240, 120, 0)",
                        width: "80%",
                        fontFamily: "Nunito",
                        fontWeight: 900
                      }}
                    >
                      {global.lang == "fr"
                        ? "commencer maintenant"
                        : "get started"}
                    </Button>
                  </Fade>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isPC && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ margin: "3em 8em" }}>
              <Button
                variant="contained"
                href={
                  global.lang == "fr"
                    ? "https://cerbrain.fr/p/CerbEnglish/FauxAmis"
                    : "https://cerbrain.com/p/CerbEnglish/FauxAmis"
                }
                style={{
                  transform: "scale(1.3)",
                  backgroundColor: "rgb(0, 61, 255)",
                  width: "100%",
                  marginBottom: "3em",
                  fontFamily: "Nunito",
                        fontWeight: 900
                }}
                /*  onClick={()=>{
                        if ( global.lang ==="fr" ){
                          window.open("https://cerbrain.fr/p/CerbEnglish/FauxAmis", "_blank");
                        } else {
                          window.open("https://cerbrain.com/p/CerbEnglish/FauxAmis", "_blank");
                        }}} */
              >
                {global.lang == "fr" ? "voir un exemple" : "see an example"}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  /* window.scrollTo({ top: -1000, left: 0 }); */
                  /* dispatch(setReduxRegister({ register: true })); */
                  /* window.scrollTo({ top: 1, left: 1 }); */
                  document.getElementById("id_invisible_link").click();
                  dispatch(
                    setReduxDefaultDisplay({ default_display: "signup" })
                  );
                  dispatch(setReduxRegister({ register: true }));
                }}
                style={{
                  transform: "scale(1.3)",
                  backgroundColor: "rgb(240, 120, 0)",
                  width: "100%",
                  fontFamily: "Nunito",
                  fontWeight: 900
                }}
              >
                {global.lang == "fr" ? "commencer maintenant" : "get started"}
              </Button>
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "5em",
          }}
        >
          <div style={isPC ? { width: "1000px" } : {}}>
            <Typography
              variant={isPC ? "h3" : "h4"}
              style={
                isPC
                  ? { fontWeight: "900" }
                  : { fontWeight: "900", marginBottom: "1em" }
              }
              align={isPC ? "inherit" : "center"}
            >
              {global.lang === "fr" ? "Cas pratique #1" : "Use case #1"}
            </Typography>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            id="id_grow1"
            style={
              isPC
                ? {
                    width: "1000px",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5em",
                  }
                : {}
            }
          >
            <Grow
              timeout={scrollPosition > 20 ? "auto" : 2000}
              in={scrollPosition > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      1
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "Vous créez une liste de vocabulaire"
                    : "You create a vocabulary list"}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "1.6em",
                  }}
                >
                  <img
                    src={"im/lp/S2_pic1.svg"}
                    style={{ width: "80px" }}
                  ></img>
                </div>
              </div>
            </Grow>

            <div style={{ width: "3em" }}></div>

            <Grow timeout={1000} in={scrollPosition > 20}>
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      2
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "Partagez le lien avec vos élèves qui mémorisent la liste grâce aux outils de cerbrain"
                    : "Share the link with your students who memorize the list thanks to cerbrain's tools"}
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src={"im/lp/S2_pic2.svg"}
                    style={{ width: "80px", marginTop: "1em" }}
                  ></img>
                </div>
              </div>
            </Grow>

            <div style={{ width: "3em" }}></div>

            <Grow
              timeout={scrollPosition > 20 ? 2000 : "auto"}
              in={scrollPosition > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      3
                    </Typography>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ fontWeight: 800, display: "inline-block" }}
                  >
                    {global.lang == "fr" ? "Imprimez une " : "Print "}

                    <Typography
                      variant="h5"
                      align="center"
                      style={{ color: "rgb(240, 120, 0)", fontWeight: 800 }}
                    >
                      {global.lang == "fr"
                        ? "interrogation écrite"
                        : "a paper test"}
                    </Typography>
                    <Typography
                      variant="h5"
                      align="center"
                      style={{ fontWeight: 800 }}
                    >
                      {global.lang == "fr" ? "" : " for the class"}
                    </Typography>
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src={"im/lp/S2_pic3.svg"}
                    style={{ width: "80px", marginTop: "1em" }}
                  ></img>
                </div>
              </div>
            </Grow>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "6em",
          }}
        >
          <div style={isPC ? { width: "1000px" } : {}}>
            <Typography
              variant={isPC ? "h3" : "h4"}
              style={
                isPC
                  ? { fontWeight: "900" }
                  : { fontWeight: "900", marginBottom: "1em" }
              }
              align={isPC ? "inherit" : "center"}
            >
              {global.lang === "fr" ? "Cas pratique #2" : "Use case #2"}
            </Typography>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            id="id_grow2"
            style={
              isPC
                ? {
                    width: "1000px",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5em",
                  }
                : {}
            }
          >
            <Grow
              timeout={scrollPosition2 > 20 ? "auto" : 2000}
              in={scrollPosition2 > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      1
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "Vous créez une liste de dates, de tableaux, ou d'acides aminés"
                    : "You create a list of dates, paintings, or amino acids"}
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src={"im/lp/S2_pic4.svg"}
                    style={{ width: "170px" }}
                  ></img>
                </div>
              </div>
            </Grow>

            <div style={{ width: "3em" }}></div>

            <Grow timeout={1000} in={scrollPosition2 > 20}>
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      2
                    </Typography>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ fontWeight: 800, display: "inline-block" }}
                  >
                    {global.lang == "fr" ? "Communiquez le " : "Give "}

                    <Typography
                      variant="h5"
                      style={{ fontWeight: 800, color: "rgb(240, 120, 0)" }}
                    >
                      {global.lang == "fr" ? "code de la liste" : "the code"}
                    </Typography>
                    <Typography variant="h5" style={{ fontWeight: 800 }}>
                      {global.lang == "fr"
                        ? " à vos élèves"
                        : " to your students"}
                    </Typography>
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    style={{
                      fontFamily: "Roboto",
                      transform: "rotate(-15deg)",
                      color: "gray",
                    }}
                  >
                    #ADIPX
                  </Typography>
                </div>
              </div>
            </Grow>

            <div style={{ width: "3em" }}></div>

            <Grow
              timeout={scrollPosition2 > 20 ? 2000 : "auto"}
              in={scrollPosition2 > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      3
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "Les élèves révisent partout grâce à leur smartphone"
                    : "Students memorize the list with their smartphone"}
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src={"im/lp/S2_pic5.svg"}
                    style={{ width: "50px", marginTop: "2em" }}
                  ></img>
                </div>
              </div>
            </Grow>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "6em",
          }}
        >
          <div style={isPC ? { width: "1000px" } : {}}>
            <Typography
              variant={isPC ? "h3" : "h4"}
              style={
                isPC
                  ? { fontWeight: "900" }
                  : { fontWeight: "900", marginBottom: "1em" }
              }
              align={isPC ? "inherit" : "center"}
            >
              {global.lang === "fr" ? "Cas pratique #3" : "Use case #3"}
            </Typography>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            id="id_grow3"
            style={
              isPC
                ? {
                    width: "1000px",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5em",
                  }
                : {}
            }
          >
            <div style={{ width: "5em" }}></div>
            <Grow
              timeout={scrollPosition3 > 20 ? "auto" : 2000}
              in={scrollPosition3 > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      1
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "L'élève crée sa propre liste de vocabulaire"
                    : "A student creates its own vocabulary list"}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "1.6em",
                  }}
                >
                  <img
                    src={"im/lp/S2_pic1.svg"}
                    style={{ width: "80px" }}
                  ></img>
                </div>
              </div>
            </Grow>

            <div style={{ width: "5em" }}></div>

            <Grow timeout={1000} in={scrollPosition3 > 20}>
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      2
                    </Typography>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ fontWeight: 800, display: "inline-block" }}
                  >
                    {global.lang == "fr"
                      ? "L'élève garde et étoffe sa liste de vocabulaire "
                      : "The student keeps adding vocabulary to its list "}

                    <Typography
                      variant="h5"
                      align="center"
                      display="inline"
                      style={{ fontWeight: 800, color: "rgb(240, 120, 0)" }}
                    >
                      {global.lang == "fr"
                        ? "au fil des années"
                        : "through the years"}
                    </Typography>
                  </Typography>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontFamily: "Roboto",
                      transform: "rotate(-15deg)",
                      color: "gray",
                    }}
                  >
                    2022
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontFamily: "Roboto",
                      transform: "rotate(-15deg)",
                      color: "gray",
                    }}
                  >
                    2023
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontFamily: "Roboto",
                      transform: "rotate(-15deg)",
                      color: "gray",
                    }}
                  >
                    2024
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontFamily: "Roboto",
                      transform: "rotate(-15deg)",
                      color: "gray",
                    }}
                  >
                    ...
                  </Typography>
                </div>
              </div>
            </Grow>
            <div style={{ width: "5em" }}></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "6em",
          }}
        >
          <div style={isPC ? { width: "1000px" } : {}}>
            <Typography
              variant={isPC ? "h3" : "h4"}
              style={
                isPC
                  ? { fontWeight: "900" }
                  : { fontWeight: "900", marginBottom: "1em" }
              }
              align={isPC ? "inherit" : "center"}
            >
              {global.lang === "fr" ? "Cas pratique #4" : "Use case #4"}
            </Typography>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "6em",
          }}
        >
          <div
            id="id_grow4"
            style={
              isPC
                ? {
                    width: "1000px",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5em",
                  }
                : {}
            }
          >
            <div style={{ width: "5em" }}></div>
            <Grow
              timeout={scrollPosition4 > 20 ? "auto" : 2000}
              in={scrollPosition4 > 20}
            >
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      1
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  {global.lang == "fr"
                    ? "L'élève crée une liste de vocabulaire"
                    : "A student creates a vocabulary list"}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "1.6em",
                  }}
                >
                  <img
                    src={"im/lp/S2_pic1.svg"}
                    style={{ width: "80px" }}
                  ></img>
                </div>
              </div>
            </Grow>

            <div style={{ width: "5em" }}></div>

            <Grow timeout={1000} in={scrollPosition4 > 20}>
              <div
                style={
                  isPC
                    ? {
                        height: "200px",
                        padding: "2em",
                        borderRadius: "16px",
                        width: "306px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }
                    : {
                        padding: "2em",
                        margin: "2em",
                        borderRadius: "16px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                        backgroundColor: "white",
                      }
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div
                    style={
                      isPC
                        ? {
                            transform: "scale(0.8)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                        : {
                            transform: "scale(0.7)",
                            marginTop: "-76px",
                            borderRadius: "48px",
                            width: "96px",
                            height: "96px",
                            /* backgroundColor: "rgb(25, 118, 210)", */
                            backgroundColor: "rgb(0, 61, 255)",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                          }
                    }
                  >
                    <Typography
                      variant="h2"
                      style={{ fontWeight: 800, color: "white" }}
                    >
                      2
                    </Typography>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    display="inline"
                    style={{
                      color: "rgb(240, 120, 0)",
                      fontWeight: 800,
                      display: "inline-block",
                    }}
                  >
                    {global.lang == "fr" ? "L'élève" : "The student"}

                    <Typography
                      variant="h5"
                      align="center"
                      display="inline"
                      style={{ color: "black", fontWeight: 800 }}
                    >
                      {global.lang == "fr"
                        ? " imprime une interrogation écrite pour s'entraîner"
                        : " prints a paper test to train"}
                    </Typography>
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src={"im/lp/S2_pic3.svg"}
                    style={{ width: "80px", marginTop: "1em" }}
                  ></img>
                </div>
              </div>
            </Grow>
            <div style={{ width: "5em" }}></div>
          </div>
        </div>

        <div
          style={
            isPC
              ? {
                  width: "100%",
                }
              : {}
          }
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={isPC ? { width: "1200px" } : { width: "100%" }}>
              <div
                style={
                  isPC
                    ? {
                        margin: "3em",
                        display: "flex",
                        justifyContent: "space-between",
                      }
                    : {
                        padding: "1em",
                        display: "flex",
                        justifyContent: "space-between",
                      }
                }
              >
                <Grid container spacing={5}>
                  <Grid item md={12} lg={6}>
                    <div
                      style={
                        isPC
                          ? {
                              width: "500px",
                              margin: "-1em 2em 2em 2em",
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }
                          : {
                              margin: "2em",
                              display: "flex",
                              alignItems: "center",
                            }
                      }
                    >
                      <div>
                        <Typography
                          variant={isPC ? "h3" : "h4"}
                          style={{ fontWeight: 700, display: "inline-block" }}
                          align={isPC ? "inherit" : "center"}
                        >
                          {global.lang == "fr" ? "Outils de mémorisation " : ""}

                          <Typography
                            variant={isPC ? "h3" : "h4"}
                            style={{
                              fontWeight: 700,
                              color: "rgb(240, 120, 0)",
                            }}
                          >
                            {global.lang == "fr"
                              ? "multi supports"
                              : "Multi device"}
                          </Typography>
                          <Typography
                            variant={isPC ? "h3" : "h4"}
                            style={{ fontWeight: 700 }}
                          >
                            {global.lang == "fr" ? "" : " memorizing tool"}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={12} lg={6}>
                    <div style={isPC ? {} : { padding: "1em" }}>
                      <img
                        src={"im/lp/S3_pic1.png"}
                        style={
                          isPC
                            ? { width: "500px", margin: "2em" }
                            : { width: "100%" }
                        }
                      ></img>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          {isPC ? (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ width: "1200px" }}>
                <div
                  style={{
                    margin: "3em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container spacing={5}>
                    <Grid item md={12} lg={6}>
                      <div>
                        <img
                          src={"im/lp/S3_pic2.png"}
                          style={{ width: "500px", margin: "2em" }}
                        ></img>
                      </div>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <div
                        style={{
                          width: "500px",
                          marginLeft: "2em",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="h3"
                          style={{ fontWeight: 700, display: "inline-block" }}
                          display="inline"
                        >
                          {global.lang == "fr"
                            ? "Création automatique "
                            : "Creation of "}

                          <Typography
                            variant="h3"
                            style={{
                              fontWeight: 700,
                              color: "rgb(240, 120, 0)",
                            }}
                            display="inline"
                          >
                            {global.lang == "fr"
                              ? "d'interrogations écrites"
                              : "paper test"}
                          </Typography>
                          <Typography
                            variant="h3"
                            style={{ fontWeight: 700 }}
                            display="inline"
                          >
                            {global.lang == "fr"
                              ? ""
                              : " to print for your class"}
                          </Typography>
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    padding: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container spacing={5}>
                    <Grid item md={12} lg={6}>
                      <div
                        style={{
                          margin: "2em",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="center"
                          style={{ fontWeight: 700, display: "inline-block" }}
                        >
                          {global.lang == "fr"
                            ? "Création automatique "
                            : "Creation of "}

                          <Typography
                            variant="h4"
                            style={{
                              fontWeight: 700,
                              color: "rgb(240, 120, 0)",
                            }}
                          >
                            {global.lang == "fr"
                              ? "d'interrogations écrites"
                              : "paper test"}
                          </Typography>
                          <Typography variant="h4" style={{ fontWeight: 700 }}>
                            {global.lang == "fr"
                              ? ""
                              : " to print for your class"}
                          </Typography>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <div style={{ padding: "1em" }}>
                        <img
                          src={"im/lp/S3_pic2.png"}
                          style={{ width: "100%" }}
                        ></img>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={
                isPC
                  ? {
                      margin: "3em",
                      display: "flex",
                      width: "1200px",
                      justifyContent: "space-between",
                    }
                  : {
                      margin: "1em",
                      display: "flex",
                      flexDirection: "column",
                    }
              }
            >
              <div
                style={
                  isPC
                    ? {
                        width: "500px",
                        margin: "2em",
                        display: "flex",
                        alignItems: "center",
                      }
                    : {
                        margin: "2em",
                        display: "flex",
                        alignItems: "center",
                      }
                }
              >
                <Typography
                  variant={isPC ? "h3" : "h4"}
                  align="center"
                  style={{ fontWeight: 700 }}
                >
                  {global.lang == "fr"
                    ? "Tout type de connaissances"
                    : "All kind of knowledge"}
                </Typography>
              </div>

              {isPC && (
                <div style={{ margin: "2em", marginLeft: "5em" }}>
                  <Grid container spacing={8}>
                    <Grid item md={12} lg={6}>
                      <Paper
                        style={{
                          height: "150px",
                          padding: "2em",
                          borderRadius: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                        elevation={8}
                      >
                        <div>
                          <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 800 }}
                          >
                            {global.lang == "fr"
                              ? "Vocabulaire faux-amis anglais/français"
                              : "French vocabulary 'The Faux-Amis'"}
                          </Typography>

                          <div
                            id="id_fade1"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "1em",
                            }}
                          >
                            <Fade in={scrollFade1 > 20} timeout={1000}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "rgb(0, 61, 255)" }}
                                href={
                                  global.lang == "fr"
                                    ? "https://cerbrain.fr/p/CerbEnglish/FauxAmis"
                                    : "https://cerbrain.com/p/CerbEnglish/FauxAmis"
                                }
                              >
                                {global.lang == "fr"
                                  ? "voir l'exemple"
                                  : "go to the page"}
                              </Button>
                            </Fade>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Paper
                        style={{
                          height: "150px",
                          padding: "2em",
                          borderRadius: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                        elevation={8}
                      >
                        <div>
                          <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 800 }}
                          >
                            {global.lang == "fr"
                              ? "Peintres célèbres"
                              : "Famous painters"}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "3em",
                            }}
                          >
                            <Fade in={scrollFade1 > 20} timeout={2000}>
                              <Button
                                variant="contained"
                                color="primary"
                                href={
                                  global.lang == "fr"
                                    ? "https://cerbrain.fr/p/CerbCulture_FR/Peintres_connus"
                                    : "https://cerbrain.com/p/CerbCulture/Famous_painters"
                                }
                                style={{ backgroundColor: "rgb(0, 61, 255)" }}
                              >
                                {global.lang == "fr"
                                  ? "voir l'exemple"
                                  : "go to the page"}
                              </Button>
                            </Fade>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Paper
                        style={{
                          height: "150px",
                          padding: "2em",
                          borderRadius: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                        elevation={8}
                      >
                        <div>
                          <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 800 }}
                          >
                            {global.lang == "fr"
                              ? "Tables de multiplications"
                              : "Multiplication tables"}
                          </Typography>
                          <div
                            id="id_fade2"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "1em",
                            }}
                          >
                            <Fade in={scrollFade2 > 20} timeout={1000}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "rgb(0, 61, 255)" }}
                                href={
                                  global.lang == "fr"
                                    ? "https://cerbrain.fr/p/CerbScience_FR/Tables_de_multiplications"
                                    : "https://cerbrain.com/p/CerbScience/Multiplication_tables"
                                }
                              >
                                {global.lang == "fr"
                                  ? "voir l'exemple"
                                  : "go to the page"}
                              </Button>
                            </Fade>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Paper
                        style={{
                          height: "150px",
                          padding: "2em",
                          borderRadius: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                        elevation={8}
                      >
                        <div>
                          <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 800 }}
                          >
                            {global.lang == "fr"
                              ? "Acides aminés"
                              : "Amino acids"}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "3em",
                            }}
                          >
                            <Fade in={scrollFade2 > 20} timeout={2000}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "rgb(0, 61, 255)" }}
                                href={
                                  global.lang == "fr"
                                    ? "https://cerbrain.fr/p/CerbScience_FR/Acides_amines"
                                    : "https://cerbrain.com/p/CerbScience/Amino_Acids"
                                }
                              >
                                {global.lang == "fr"
                                  ? "voir l'exemple"
                                  : "go to the page"}
                              </Button>
                            </Fade>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              )}

              {!isPC && (
                <div style={{ margin: "2em" }}>
                  <Paper
                    style={{
                      height: "150px",
                      padding: "2em",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "1em",
                    }}
                    elevation={8}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ fontWeight: 800 }}
                      >
                        {global.lang == "fr"
                          ? "Vocabulaire faux-amis anglais/français"
                          : "French vocabulary 'The Faux-Amis'"}
                      </Typography>

                      <div
                        id="id_fade1"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "1em",
                        }}
                      >
                        <Fade in={scrollFade1 > 20} timeout={1000}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "rgb(0, 61, 255)" }}
                            href={
                              global.lang == "fr"
                                ? "https://cerbrain.fr/p/CerbEnglish/FauxAmis"
                                : "https://cerbrain.com/p/CerbEnglish/FauxAmis"
                            }
                          >
                            {global.lang == "fr"
                              ? "voir l'exemple"
                              : "go to the page"}
                          </Button>
                        </Fade>
                      </div>
                    </div>
                  </Paper>

                  <Paper
                    style={{
                      height: "150px",
                      padding: "2em",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "1em",
                    }}
                    elevation={8}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ fontWeight: 800 }}
                      >
                        {global.lang == "fr"
                          ? "Peintres célèbres"
                          : "Famous painters"}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "3em",
                        }}
                      >
                        <Fade in={scrollFade1 > 20} timeout={2000}>
                          <Button
                            variant="contained"
                            color="primary"
                            href={
                              global.lang == "fr"
                                ? "https://cerbrain.fr/p/CerbCulture_FR/Peintres_connus"
                                : "https://cerbrain.com/p/CerbCulture/Famous_painters"
                            }
                            style={{ backgroundColor: "rgb(0, 61, 255)" }}
                          >
                            {global.lang == "fr"
                              ? "voir l'exemple"
                              : "go to the page"}
                          </Button>
                        </Fade>
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      height: "150px",
                      padding: "2em",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "1em",
                    }}
                    elevation={8}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ fontWeight: 800 }}
                      >
                        {global.lang == "fr"
                          ? "Tables de multiplications"
                          : "Multiplication tables"}
                      </Typography>
                      <div
                        id="id_fade2"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "1em",
                        }}
                      >
                        <Fade in={scrollFade2 > 20} timeout={1000}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "rgb(0, 61, 255)" }}
                            href={
                              global.lang == "fr"
                                ? "https://cerbrain.fr/p/CerbScience_FR/Tables_de_multiplications"
                                : "https://cerbrain.com/p/CerbScience/Multiplication_tables"
                            }
                          >
                            {global.lang == "fr"
                              ? "voir l'exemple"
                              : "go to the page"}
                          </Button>
                        </Fade>
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      height: "150px",
                      padding: "2em",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "1em",
                    }}
                    elevation={8}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ fontWeight: 800 }}
                      >
                        {global.lang == "fr" ? "Acides aminés" : "Amino acids"}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "3em",
                        }}
                      >
                        <Fade in={scrollFade2 > 20} timeout={2000}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "rgb(0, 61, 255)" }}
                            href={
                              global.lang == "fr"
                                ? "https://cerbrain.fr/p/CerbScience_FR/Acides_amines"
                                : "https://cerbrain.com/p/CerbScience/Amino_Acids"
                            }
                          >
                            {global.lang == "fr"
                              ? "voir l'exemple"
                              : "go to the page"}
                          </Button>
                        </Fade>
                      </div>
                    </div>
                  </Paper>
                </div>
              )}
            </div>
          </div>

          <div
            id="id_grow5"
            style={{
              margin: "3em",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Grow timeout={1000} in={scrollPosition5 > 200}>
              <div
                style={
                  isPC
                    ? {
                        maxWidth: "1000px",
                        marginTop: "6em",
                        marginBottom: "12em",
                      }
                    : {
                        marginTop: "5em",
                        marginBottom: "6em",
                      }
                }
              >
                <Typography
                  variant={isPC ? "h2" : "h4"}
                  style={{ fontWeight: 700 }}
                  display="inline"
                >
                  {global.lang == "fr" ? "Avec Cerbrain " : "With Cerbrain "}

                  <Typography
                    variant={isPC ? "h2" : "h4"}
                    style={{ fontWeight: 700, color: "rgb(240, 120, 0)" }}
                    display="inline"
                  >
                    {global.lang == "fr"
                      ? "les élèves aussi créent des listes"
                      : "teachers and students build their kownledge"}
                  </Typography>
                  <Typography
                    variant={isPC ? "h2" : "h4"}
                    style={{ fontWeight: 700 }}
                    display="inline"
                  >
                    {global.lang == "fr"
                      ? " de vocabulaire, et les étoffent au fil des années."
                      : " lists and grow them through the years."}
                  </Typography>
                </Typography>
              </div>
            </Grow>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#6666FF",
          display: "flex",
        }}
      >
        <div
          style={
            isPC
              ? {
                  width: "1200px",
                  display: "flex",
                  padding: "1em",
                  alignItems: "center",
                }
              : {
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "2em",
                  alignItems: "center",
                }
          }
        >
          {isPC && (
            <div>
              <img
                src={"https://cerbrain.fr/im/logo_A_no_text.svg"}
                style={{ height: "124px" }}
              ></img>
            </div>
          )}
          <div style={isPC ? { marginLeft: "6em" } : { marginLeft: "2em" }}>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://cerbrain.fr"
            >
              Français
            </Link>
            <br></br>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://cerbrain.com"
            >
              English
            </Link>
            <br></br>
            <br></br>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://cerbrain.com//static/misc/privacy_policy.pdf"
            >
              Privacy policy
            </Link>
          </div>
          <div style={isPC ? { marginLeft: "8em" } : { marginLeft: "2em" }}>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://www.linkedin.com/company/cerbrain"
            >
              LinkedIn
            </Link>
            <br></br>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://twitter.com/cerbrainen"
            >
              Twitter
            </Link>
            <br></br>
            <br></br>
            <Link
              style={{ color: "white", cursor: "pointer" }}
              href="https://www.linkedin.com/in/ghislainfievet/"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
      {/*       <Dialog
        onClose={() => setOpenInDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openInDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignIn></SignIn>
        </div>
        <Button
          autoFocus
          onClick={() => setOpenInDialog(false)}
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog> */}

      <Dialog
        onClose={() => setOpenUpGDialog(false)}
        open={openUpGDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignUpG
            email={g_email}
            pseudo={g_pseudo}
            google_profile_pic={g_picture}
          ></SignUpG>
        </div>
        <Button
          autoFocus
          onClick={() => setOpenUpGDialog(false)}
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog>
    </>
  );
}

export default LandingPage;
