import { createSlice } from "@reduxjs/toolkit";

export const questionsSlice = createSlice({
  name: "global_questions",
  initialState: {
    h_selected_questions: {},
  },
  reducers: {
    setReduxSelectedQuestion: (state, action) => {
      state['h_selected_questions'][action.payload.id_question] = action.payload.is_selected;
    },
    setReduxAllSelectedQuestions:(state, action) => {
        console.log(state['h_selected_questions']);
        console.log(Object.keys(state['h_selected_questions']));
        Object.keys(state['h_selected_questions']).forEach((k) =>{
            state['h_selected_questions'][k] = action.payload.is_selected;
        }); 
    }
  },
});

export const { setReduxSelectedQuestion, setReduxAllSelectedQuestions } = questionsSlice.actions;

export default questionsSlice.reducer;
