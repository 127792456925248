import Chip from "@material-ui/core/Chip";

export function ScoreChips({ green_score, orange_score, red_score }) {
  return (
    <span
      style={{
        display: "flex",
        width: "140px",
        justifyContent: "space-around",
      }}
    >
      <Chip
        
        style={{
          backgroundColor: "#3CA310",
          color: "white",
          fontWeight: "bold",
          animationName: "chip",
          animationDuration: "0.5s",
          animationDirection: "alternate",
          animationIterationCount: 2
        }}
        size="small"
        label={green_score}
      ></Chip>
      <Chip
        
        style={{
          backgroundColor: "#FE9D00",
          color: "white",
          fontWeight: "bold",
          animationName: "chip",
          animationDuration: "0.5s",
          animationDirection: "alternate",
          animationIterationCount: 2
        }}
        size="small"
        label={orange_score}
      ></Chip>
      <Chip
        
        style={{
          backgroundColor: "#EA1A7F",
          color: "white",
          fontWeight: "bold",
          animationName: "chip",
          animationDuration: "0.5s",
          animationDirection: "alternate",
          animationIterationCount: 2
        }}
        size="small"
        label={red_score}
      ></Chip>
    </span>
  );
}

export function SG({ score }) {
  return (
    <Chip
      key={score}
      style={{
        backgroundColor: "#3CA310",
        color: "white",
        fontWeight: "bold",
        animationName: "chip",
        animationDuration: "0.25s",
        animationDirection: "alternate-reverse"
      }}
      size="small"
      label={score}
    ></Chip>
  );
}

export function SO({ score }) {
  return (
    <Chip
      key={score}
      style={{
        backgroundColor: "#FE9D00",
        color: "white",
        fontWeight: "bold",
        animationName: "chip",
        animationDuration: "0.25s",
        animationDirection: "alternate-reverse"
      }}
      size="small"
      label={score}
    ></Chip>
  );
}
export function SR({ score }) {
  return (
    <Chip
      key={score}
      style={{
        backgroundColor: "#EA1A7F",
        color: "white",
        fontWeight: "bold",
        animationName: "chip",
        animationDuration: "0.25s",
        animationDirection: "alternate-reverse"
      }}
      size="small"
      label={score}
    ></Chip>
  );
}
export function ScoreBullet({ score }) {
  return (
    <span>
      {score == 3 && (
        <span
          style={{
            /* backgroundColor: "#A4FC9B", */
            backgroundColor: "#3CA310",
            display: "inline-block",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            margin: "20px",
          }}
        ></span>
      )}
      {score == 2 && (
        <span
          style={{
            /* backgroundColor: "#A4FC9B", */
            backgroundColor: "#3CA310",
            display: "inline-block",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            margin: "20px",
          }}
        ></span>
      )}
      {score == 1 && (
        <span
          style={{
            /* backgroundColor: "#F9F871", */
            backgroundColor: "#FE9D00",
            display: "inline-block",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            margin: "20px",
          }}
        ></span>
      )}
      {score == 0 && (
        <span
          style={{
            /* backgroundColor: "#FF92A7", */
            backgroundColor: "#EA1A7F",
            display: "inline-block",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            margin: "20px",
          }}
        ></span>
      )}
    </span>
  );
}
