import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./Main";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import Link from "@material-ui/core/Link";
//import GoogleOneTapLogin from 'react-google-one-tap-login';

const server_url =
  "https://" +
  window.location.href.split(/[/?#]/)[2] +
  "/" +
  "/static/misc/privacy_policy.pdf";

ReactDOM.render(
  <div id="id_sub_root" style={{backgroundColor:"rgb(246,248,250)", minHeight:"100%"}}>
    {/* <div style={{backgroundColor:"rgb(246,248,250)"}}> */}
    <React.StrictMode>
      <Provider store={store}>
        <Main></Main>

        {/*       <GoogleOneTapLogin onError={(error) => console.log(error)} onSuccess={(response) => console.log(response)} googleAccountConfigs={{ client_id: "301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com" }} /> */}
      </Provider>
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);
//document.getElementById("root").style.backgroundColor = "rgb(246,248,250)";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
