import { configureStore } from '@reduxjs/toolkit';
import scoresReducer from './globalScores';
import wallReducer from './globalWall';
import listsReducer from './globalLists';
import questionsReducer from './globalQuestions';
import tutoReducer from './globalTuto';
import dialogReducer from './globalDialog';
import addListReducer from './globalAddList'

export default configureStore({
	reducer: {
        global_scores: scoresReducer,
        global_wall: wallReducer,
        global_lists: listsReducer,
        global_questions: questionsReducer,
        global_tuto: tutoReducer,
        global_dialog: dialogReducer,
        global_add_list: addListReducer
	},
});