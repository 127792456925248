import { createSlice } from "@reduxjs/toolkit";

export const dialogSlice = createSlice({
  name: "global_dialog",
  initialState: {
    register: false,
    default_display: "signup",
    is_cffooter_open: 0,
    is_cont_footer_open: 1,
  },
  reducers: {
    setReduxRegister: (state, action) => {
      state['register'] = action.payload.register;
    },
    setReduxDefaultDisplay: (state, action) => {
      state['default_display'] = action.payload.default_display;
    },
    setReduxIsCffooterOpen: (state, action) => {
      state['is_cffooter_open'] = action.payload.is_cffooter_open;
    },
    setReduxIsContFooterOpen: (state, action) => {
      state['is_cont_footer_open'] = action.payload.is_cont_footer_open;
    },
  },
});

export const { setReduxRegister, setReduxDefaultDisplay,
  setReduxIsCffooterOpen, setReduxIsContFooterOpen } = dialogSlice.actions;

export default dialogSlice.reducer;
