import axios from "axios";


export const addQuestionDBAndDom = (
  id_list,
  question_type,
  statement,
  answer,
  media,
  is_bw,
  a_questions,
  setQuestions,
  pic_rand_url
) => {
  const data = {
    id_list: id_list,
    question_type: question_type,
    statement: statement,
    answer: answer,
    media: media,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  if (!is_bw) {
    fetch(server_url + "/api/add_question", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())

      .then((res) => {
        let dom_pic_url = "0";
        if (question_type == "i") {
          dom_pic_url = pic_rand_url;
        }
        let question_type_bis = question_type;
        if (question_type == "u") {
          question_type_bis = "i";
        }
        setQuestions([
          [
            res["id_question"],
            id_list,
            question_type_bis,
            statement,
            answer,
            Date.now() / 1000,
            "0",
            1,
            dom_pic_url,
          ],
          ...a_questions,
        ]);
      });
  } else {
    fetch(server_url + "/api/add_question_bw", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setQuestions([
          [
            res["id_question_2"],
            id_list,
            question_type,
            answer,
            statement,
            Date.now() / 1000,
            "0",
            1,
            media,
          ],
          [
            res["id_question_1"],
            id_list,
            question_type,
            statement,
            answer,
            Date.now() / 1000,
            "0",
            1,
            media,
          ],
          ...a_questions,
        ]);
      });
  }
};

export const deleteQuestionDB = (id_question) => {
  const data = {
    id_question: id_question,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/delete_question", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const deleteQuestionsDB = (id_questions) => {
  const data = {
    id_questions: id_questions,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/delete_questions", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const setScoreDB = (id_question, score) => {
  let new_score = score;
  if (1 * score == 3) {
    new_score = 2;
  }
  const data = {
    id_question: id_question,
    score: new_score,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_score", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const setAScoreDB = (id_question, score) => {
  let new_score = score;
  if (1 * score == 3) {
    new_score = 2;
  }
  const data = {
    id_question: id_question,
    score: new_score,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_a_question_score", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const setIsOwnerDB = (id_list, isOwner) => {
  const data = {
    id_list: id_list,
    is_owner: isOwner,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_is_owner", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const setScoresDB = (a_questions, a_scores, url = "") => {
  const data = {
    question_ids: a_questions,
    scores: a_scores,
  };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_scores", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      console.log(url);
      if (url != "") {
        window.location.href = url;
      }
    });
};

export const setAListScoresDB = (a_questions, a_scores, url = "") => {
  const data = {
    question_ids: a_questions,
    scores: a_scores,
  };
  console.log(a_questions);
  console.log(a_scores);
  console.log(data);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_a_list_scores", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      console.log(url);
      if (url != "") {
        window.location.href = url;
      }
    });
};

export const saveListDB = (id_list, a_scores, url = "") => {
  const data = {
    id_list: id_list,
  };
  console.log("inside saveListDB 28");
  console.log(a_scores);
  console.log(data);
  console.log("inside saveListDB 29");
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/create_a_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("inside saveListDB");
      console.log(res);
      console.log(a_scores);
      setAListScoresDB(res["a_new_questions_id"], a_scores, url);
    });
};

export const setPublicDB = (isPublic, id_list) => {
  console.log("setPublic");
  const data = { id_list: id_list, isPublic: isPublic };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/set_list_public", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const deletePageDB = (id_page) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("deletePageDB");
  console.log(id_page);
  const data = { id_page: id_page };
  fetch(server_url + "/api/delete_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const addListToPageDB = (id_page, id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("addListToPageDB");
  const data = { id_page: id_page, id_list: id_list };
  console.log(data);
  fetch(server_url + "/api/add_list_to_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const removeListFromPageDB = (id_page, id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("removeListFromPageDB");
  const data = { id_page: id_page, id_list: id_list };
  console.log(data);
  fetch(server_url + "/api/remove_list_from_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const addLinkDB = (
  id_page,
  name,
  url,
  descr,
  a_links = [-1],
  setLinks = 0
) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("addLinkDB");
  const data = { id_page: id_page, name: name, url: url, descr: descr };
  console.log(data);
  fetch(server_url + "/api/add_link_to_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      if (a_links.indexOf(-1) == -1) {
        console.log("inside if");
        let a_a = [res["id_link"], id_page, name, url, "t", descr];
        let a_b = [a_a, ...a_links];
        console.log(a_a);
        console.log(a_b);
        setLinks(a_b);
      }
    });
};

export const removeLinkDB = (
  id_page,
  id_link,
  a_links = [-1],
  setLinks = 0
) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  console.log("removeLinkDB");
  const data = { id_page: id_page, id_link: id_link };
  console.log(data);
  fetch(server_url + "/api/remove_link_from_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      if (a_links.indexOf(-1) == -1) {
        let a_a = [];
        a_links.forEach((e) => {
          if (e[0] != id_link) {
            a_a.push(e);
          }
        });
        setLinks(a_a);
      }
    });
};

export const setPrevVersionDB = (id_page, reload = false) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_page: id_page };
  console.log(data);
  fetch(server_url + "/api/set_prev_version", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      if (reload) {
        window.location.reload();
      }
    });
};

export const publishPageDB = (id_page, reload = false) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_page: id_page };
  console.log(data);
  fetch(server_url + "/api/publish_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      if (reload) {
        window.location.reload();
      }
    });
};

export const unpublishPageDB = (id_page, reload = false) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_page: id_page };
  console.log(data);
  fetch(server_url + "/api/unpublish_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
      if (reload) {
        window.location.reload();
      }
    });
};

export const setPageDescrDB = (id_page, descr) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_page: id_page, descr: descr };
  console.log("setPageDescrDB");
  console.log(data);
  fetch(server_url + "/api/set_page_descr", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const setUserDescrDB = (id_user, descr) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_user: id_user, descr: descr };
  console.log("setUserDescrDB");
  console.log(data);
  fetch(server_url + "/api/set_user_descr", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const doFollowDB = (id_user) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_user: id_user };
  console.log("doFollowDB");
  console.log(data);
  fetch(server_url + "/api/do_follow", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const unFollowDB = (id_user) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_user: id_user };
  console.log("unFollowDB");
  console.log(data);
  fetch(server_url + "/api/un_follow", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const updateListsScores = (id_user, id_list, which) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_user: id_user, id_list: id_list, which: which };
  console.log("updateListsScores");
  console.log(data);
  fetch(server_url + "/api/update_lists_scores", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const addOneVisitDB = (id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_list: id_list };
  console.log("addOneVisitDB");
  console.log(data);
  fetch(server_url + "/api/add_one_visit", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(JSON.stringify(res));
    });
};

export const addOnePageVisitDB = (id_page) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = { id_page: id_page };
  console.log("addOnePageVisitDB");
  console.log(data);
  fetch(server_url + "/api/add_one_page_visit", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const insertPageFileDB = (id_page, orig_name, s3_name) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_page: id_page,
    orig_name: orig_name,
    s3_name: s3_name,
  };
  console.log(data);
  fetch(server_url + "/api/insert_page_file", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const deletePageFileDB = (id_page, s3_name) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_page: id_page,
    s3_name: s3_name,
  };
  console.log(data);
  fetch(server_url + "/api/delete_page_file", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const setLastLgDB = (lang) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    lang: lang,
  };
  console.log(data);
  fetch(server_url + "/api/set_last_lg", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const insertListVisitDB = (id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_list: id_list,
  };
  console.log(data);
  fetch(server_url + "/api/insert_list_visit", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const insertPageVisitDB = (id_page) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_page: id_page,
  };
  console.log(data);
  fetch(server_url + "/api/insert_page_visit", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const setWallTrainListDB = (id_list, callback = 0) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_list: id_list,
  };
  console.log(data);
  fetch(server_url + "/api/set_wall_train_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      if (callback != 0) {
        callback();
      }
    });
};

export const cloneQuestionsToListDB = (a_questions, id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    a_questions: a_questions,
    id_list: id_list,
  };
  console.log(data);
  fetch(server_url + "/api/clone_questions_to_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const moveQuestionsToListDB = (a_questions, id_list) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    a_questions: a_questions,
    id_list: id_list,
  };
  console.log(data);
  fetch(server_url + "/api/move_questions_to_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const setQuestionStatementDB = (id_question, statement) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_question: id_question,
    statement: statement,
  };
  console.log(data);
  fetch(server_url + "/api/set_question_statement", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const setQuestionAnswerDB = (id_question, answer) => {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const data = {
    id_question: id_question,
    answer: answer,
  };
  console.log(data);
  fetch(server_url + "/api/set_question_answer", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const unfollowAListDB = (id_list) => {
  console.log("unfollowAListDB");
  const data = { id_list: id_list };
  fetch("/api/unfollow_a_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(JSON.stringify(res)));
};

export const keepThemDB = (id_questions) => {
  console.log("keepThemDB");
  const data = { id_questions: id_questions };
  fetch("/api/keep_them", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
};

export const copyQuestionsToAQuestionsDB = (id_questions, id_a_list) => {
  console.log("copyQuestionsToAQuestionsDB");
  const data = { id_questions: id_questions, id_a_list: id_a_list };
  fetch("/api/copy_questions_to_a_questions", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
};

export const deleteAQuestionsDB = (id_questions) => {
  console.log("deleteAQuestionsDB");
  const data = { id_questions: id_questions };
  fetch("/api/delete_a_questions", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
};

export const setListDescrDB = (id_list, descr) => {
  console.log("setListDescrDB");
  const data = { id_list: id_list, descr: descr };
  console.log(data);
  fetch("/api/set_list_descr", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
};

export const insertListDB = (title, descr, url_pseudo = "") => {
  if (typeof descr == "undefined") {
    descr = "";
  }
  const data = { title: title, descr: descr };
  console.log(title);
  console.log(descr);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/insert_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);

      const url = server_url + "u/" + res["url_pseudo"] + "/" + res["url_name"];
      window.location.href = url;
    });
};

export const insertPageDB = (title, descr, url_pseudo = "") => {
  if (typeof descr == "undefined") {
    descr = "";
  }
  const data = { title: title, descr: descr };
  console.log(title);
  console.log(descr);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/insert_page", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);

      const url = server_url + "b/" + res["url_pseudo"] + "/" + res["url_name"];
      window.location.href = url;
    });
};

export const insertReportUserDB = (id_user, message) => {
  if (typeof message == "undefined") {
    message = "";
  }
  const data = { id_user: id_user, message: message };
  console.log(id_user);
  console.log(message);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/report_user", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};

export const goFromCode = (id_code, target = "_self") => {
  if (id_code[0] == "#") {
    id_code = id_code.substring(1);
  }
  const data = { id_code: id_code.toLowerCase() };
  console.log(data);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "api/url_from_cont_code", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      if (res["content_type"] == "l") {
        if (target == "_self") {
          window.location.href =
            server_url + "p/" + res["url_pseudo"] + "/" + res["url_name"];
        }
        if (target == "_blank") {
          window.open(
            server_url + "p/" + res["url_pseudo"] + "/" + res["url_name"],
            "_blank"
          );
        }
      }
      if (res["content_type"] == "p") {
        if (target == "_self") {
          window.location.href =
            server_url + "c/" + res["url_pseudo"] + "/" + res["url_name"];
        }
        if (target == "_blank") {
          window.open(
            server_url + "c/" + res["url_pseudo"] + "/" + res["url_name"],
            "_blank"
          );
        }
      }
    });
};

export const addListDB = (id_code, callback = 0, url = "", do_redux = true) => {
  if (id_code[0] == "#") {
    id_code = id_code.substring(1);
  }
  id_code = id_code.toLowerCase();

  const data = {
    id_code: id_code,
  };
  console.log("addList");
  console.log(data);

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  
  fetch(server_url + "/api/add_list", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("inside addListDB");
      console.log(res);
      let id_a_list = res["id_a_list"];
      if (callback == 0){
        if (url != "") {
          window.location.href = url;
        } else {
          window.location.reload();
        }
      } else {
        callback(id_a_list);
      }
    });
};

export const insertSOTDB = (sot) => {
  console.log("insertSOTDB");
  const data = { sot: sot };
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  fetch(server_url + "/api/insert_sot", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    });
};
