import React from "react";
import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  pdf,
  Document,
  Image,
  Link,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  createElement,
  PDFRenderer,
} from "@react-pdf/renderer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import QRCode from "qrcode.react";
import { getAllByPlaceholderText } from "@testing-library/dom";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useMediaQuery } from "react-responsive";

import ToggleButton from "@mui/material/ToggleButton";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

var qrCodeDataUri = "";
var count = 0;
var a_questions = [];
var a_questions_long = [];
var g_arg_a_questions = [];
var test_volume = 0;
var test_volume_long = 0;
var url = "";
var a_short = [
  "iench",
  "veuchs",
  "cat",
  "blue",
  "dolphin",
  "marmaid",
  "tuna",
  "fish",
  "poisson",
  "endurer",
  "cheval",
  "fer à",
  "chateau",
  "confiture",
  "serpent",
  "doux",
  "persil",
  "voiture",
  "piscine",
  "tapis",
  "considérer",
  "considérable",
];
var a_medium = [
  "disposition à l’allégresse, à la bonne ",
  "en parlant d’un texte ou d’un autre document, est inauthentique, douteux, attribué à tor.",
  "monte à la tête, enivre facilement, tro",
  "brille intensément, scintille vivement, étincèle, é",
  "Dans son sens le plus courant, la XXX est l’état ",
  "Diligent est un adjectif. Quelqu’un de dili",
  "Ce qui est XXX empêche sans aucun recours.",
  "Une XXX est une odeur, un gaz, une vapeur, un",
  "Ce qui est XXX épuise les f.",
  "Un XXX trouble le repos de l’État, s’oppose au pouvoir en menant u",
  "Ce qui est XXX est d’une couleur entre jaune et",
  "Ce qui est XXX prend en compte une chose dans sa totalité, analyse quelq",
  "Ce qui est XXX est incassable, indest",
  "Ce qui est XXX est incontestable, irréfutable, inatt",
  "Ce qui est XXX a un goût d’œuf pourri ou dégage une",
  "L’XXX est l’endurcissement intérieur, le fait de desséche",
  "Une XXX est un blâme, un violent reproche, une ardente semonce adressée, par exemple, par un avocat à son auditoire. Au pluriel, des objurgations sont des paroles pressantes, insistantes, pour faire changer d’avis, pour éloigner quelqu’un d’une idée.",
  "Être XXX, c’est être poli à l’excès, marquer trop d’égards, porter trop d’attention, se montrer servile, à tel point que l’on soupçonne l’hypocrisie de cette attitude.",
  "Un XXX est un étranger, un voyageur qui est dan",
  "Quelqu’un de XXX a",
  "Une XXX est un ma",
  "La XXX est un concept qui désigne la capacité de l’être humain à faire des trouvailles heureuses, sans avoir planifié auparavant sa découverte. La sérendipité, c’est trouver ce que l’on ne cherchait pas. Ce concept permet de parler de l’existence de découvertes non-anticipées.",
  "veuchs",
  "aquarium",
  "magie",
  "mangue",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
];
var a_long = [
  "disposition à l’allégresse, à la bonne humeur, un état de vigueur et d’entrain.",
  "en parlant d’un texte ou d’un autre document, est inauthentique, douteux, attribué à tort à un auteur ou relatant une histoire dont l’authenticité n’a pas été validée par une autorité.",
  "monte à la tête, enivre facilement, trouble les sens, conduit vers une certaine langueur.",
  "brille intensément, scintille vivement, étincèle, éclate par sa lumière aux yeux de celui qui regarde.",
  "Dans son sens le plus courant, la XXX est l’état de quelque chose qui est divisé en deux contraires.",
  "Diligent est un adjectif. Quelqu’un de diligent effectue une action avec rapidité et efficacité.",
  "Ce qui est XXX empêche sans aucun recours. Un obstacle dirimant est prohibitif.",
  "Une XXX est une odeur, un gaz, une vapeur, un effluve qui émane d’un corps ou qui se dégage d’un lieu.",
  "Ce qui est XXX épuise les forces, épuise quelque chose, est complet.",
  "Un XXX trouble le repos de l’État, s’oppose au pouvoir en menant une action pour le renverser, provoque la révolte, cherche à susciter le désordre.",
  "Ce qui est XXX est d’une couleur entre jaune et or, une sorte de blond, comme les blés arrivés à maturité.",
  "Ce qui est XXX prend en compte une chose dans sa totalité, analyse quelque chose sans le diviser en parties, traite le tout plutôt qu’une section.",
  "Ce qui est XXX est incassable, indestructible, impérissable et, par extension, solide.",
  "Ce qui est XXX est incontestable, irréfutable, inattaquable. On ne peut contredire ce qui est irréfragable.",
  "Ce qui est XXX a un goût d’œuf pourri ou dégage une odeur d’oeuf pourri, d’œufs couvés, de brûlé (Académie).",
  "L’XXX est l’endurcissement intérieur, le fait de dessécher son cœur, de devenir insensible, intraitable, sourd à l’appel du prochain.",
  "Une XXX est un blâme, un violent reproche, une ardente semonce adressée, par exemple, par un avocat à son auditoire. Au pluriel, des objurgations sont des paroles pressantes, insistantes, pour faire changer d’avis, pour éloigner quelqu’un d’une idée.",
  "Être XXX, c’est être poli à l’excès, marquer trop d’égards, porter trop d’attention, se montrer servile, à tel point que l’on soupçonne l’hypocrisie de cette attitude.",
  "Un XXX est un étranger, un voyageur qui est dans un pays dont il ne vient pas, un nomade, un pèlerin.",
  "Quelqu’un de XXX a l’âme timide, manque de courage, de cœur, de fermeté ; un pusillanime est timoré, irrésolu et assez lâche.",
  "Une XXX est un manquement aux devoirs d’une fonction ou d’une charge, une infraction, une faute, une transgression grave. On parle surtout de la prévarication d’un fonctionnaire ou d’un élu.",
  "La XXX est un concept qui désigne la capacité de l’être humain à faire des trouvailles heureuses, sans avoir planifié auparavant sa découverte. La sérendipité, c’est trouver ce que l’on ne cherchait pas. Ce concept permet de parler de l’existence de découvertes non-anticipées.",
];

function ShortTest({ page_number = 1, is_same = true, cont_code }) {
  console.log(url);
  console.log("is_same", is_same);

  return (
    <Document>
      {Array.from(Array(parseInt(page_number)).keys()).map((answer) => {
        console.log("ShortTest ", page_number);
        if (!is_same) {
          a_questions = g_arg_a_questions
            .sort(() => Math.random() - 0.5)
            .slice(0, 10);
        }
        return (
          <Page
            size="A4"
            style={{ backgroundColor: "white" }}
            key={Math.random()}
          >
            <div
              style={{
                marginLeft: 50,
                marginRight: 50,
                marginTop: 50,
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "75%",
                  borderTop: "1px solid darkgray",
                  borderBottom: "1px solid darkgray",
                  borderLeft: "1px solid darkgray",
                  marginRight: -1,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      borderBottom: "1px solid darkgray",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginRight: 200 }}>
                      <div
                        style={
                          test_volume < 249
                            ? { fontSize: 14, marginLeft: 10 }
                            : test_volume < 599
                            ? { fontSize: 13, marginLeft: 10 }
                            : { fontSize: 12, marginLeft: 10 }
                        }
                      >
                        <Text>{global.lang == "fr" ? "Prénom:" : "Name:"}</Text>
                      </div>
                      {test_volume < 249 && (
                        <div
                          style={{
                            fontSize: 14,
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 20,
                          }}
                        >
                          <Text>Date:</Text>
                        </div>
                      )}
                    </div>

                    <div style={{ marginRight: 200 }}>
                      <div
                        style={
                          test_volume < 249
                            ? { fontSize: 14, marginLeft: 10 }
                            : test_volume < 599
                            ? { fontSize: 13, marginLeft: 10 }
                            : { fontSize: 12, marginLeft: 10 }
                        }
                      >
                        <Text>{global.lang == "fr" ? "Nom:" : ""}</Text>
                      </div>
                      {test_volume < 249 && (
                        <div
                          style={{
                            fontSize: 14,
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 20,
                          }}
                        >
                          <Text>
                            {global.lang == "fr" ? "Classe:" : "Class:"}
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={
                        test_volume < 249
                          ? { fontSize: 22, marginTop: 50, marginBottom: 50 }
                          : test_volume < 599
                          ? { fontSize: 20, marginTop: 20, marginBottom: 20 }
                          : { fontSize: 18, marginTop: 15, marginBottom: 15 }
                      }
                    >
                      {global.lang == "fr"
                        ? "Evaluation des connaissances"
                        : "Knowledge test"}
                    </Text>
                  </div>
                  <Text style={{ fontSize: 14, margin: 10 }}>
                    {global.lang == "fr"
                      ? "Liste de référence"
                      : "Reference list"}
                  </Text>
                  <Link
                    src={url}
                    style={{
                      fontSize: 10,
                      marginBottom: 10,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {url}
                  </Link>
                </div>
              </div>
              <div
                style={{
                  width: "25%",
                  border: "1px solid darkgray",
                  marginLeft: -1,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <Image
                    source={{ uri: qrCodeDataUri }}
                    style={{ width: "64px", height: "64px", margin: 2 }}
                  />
                </div>

                <Text>{cont_code}</Text>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {a_questions.map((answer, i) => {
                  // Return the element. Also pass key
                  return (
                    <div key={Math.random()}>
                      {i != a_questions.length - 1 ? (
                        <div
                          style={{
                            marginLeft: 50,
                            marginRight: 50,
                            marginTop: -1,
                            marginBottom: -1,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={
                              test_volume < 249
                                ? {
                                    width: "50%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                                : test_volume < 599
                                ? {
                                    width: "66%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                                : {
                                    width: "75%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                            }
                          >
                            <Text
                              style={
                                test_volume < 249
                                  ? {
                                      fontSize: 14,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                                  : test_volume < 599
                                  ? {
                                      fontSize: 12,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                                  : {
                                      fontSize: 10,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                              }
                            >
                              {answer}
                            </Text>
                          </div>
                          <div
                            style={
                              test_volume < 249
                                ? {
                                    width: "50%",
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                                : test_volume < 599
                                ? {
                                    width: "34%",
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                                : {
                                    width: "25%",
                                    borderTop: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                            }
                          ></div>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginLeft: 50,
                            marginRight: 50,
                            marginTop: -1,
                            marginBottom: -1,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={
                              test_volume < 249
                                ? {
                                    width: "50%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                                : test_volume < 599
                                ? {
                                    width: "66%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                                : {
                                    width: "75%",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    marginRight: -1,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "row",
                                  }
                            }
                          >
                            <Text
                              style={
                                test_volume < 249
                                  ? {
                                      fontSize: 14,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                                  : test_volume < 599
                                  ? {
                                      fontSize: 12,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                                  : {
                                      fontSize: 10,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }
                              }
                            >
                              {answer}
                            </Text>
                          </div>
                          <div
                            style={
                              test_volume < 249
                                ? {
                                    width: "50%",

                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                                : test_volume < 599
                                ? {
                                    width: "34%",

                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                                : {
                                    width: "25%",

                                    borderTop: "1px solid darkgray",
                                    borderBottom: "1px solid darkgray",
                                    borderLeft: "1px solid darkgray",
                                    borderRight: "1px solid darkgray",
                                    marginLeft: -1,
                                  }
                            }
                          ></div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Page>
        );
      })}
    </Document>
  );
}

function HorTest({ page_number = 1, is_same = true, cont_code = "" }) {
  return (
    <Document>
      {Array.from(Array(parseInt(page_number)).keys()).map((answer) => {
        // Return the element. Also pass key
        console.log("HorTest ", page_number);
        return (
          <Page
            size="A4"
            style={{ backgroundColor: "white" }}
            orientation="landscape"
            key={Math.random()}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <OneSmallTest
                page={1}
                is_same={is_same}
                cont_code={cont_code}
              ></OneSmallTest>
              <OneSmallTest
                page={2}
                is_same={is_same}
                cont_code={cont_code}
              ></OneSmallTest>
            </div>
          </Page>
        );
      })}
    </Document>
  );
}

function OneSmallTest({
  mother = "HorTest",
  page = 1,
  is_same = true,
  cont_code = "",
}) {
  /* const[ a_temp_questions, setATempQuestions] = useState([]); */
  console.log("OneSmallTest g_arg_a_questions", g_arg_a_questions);
  console.log("OneSmallTest a_questions_long", a_questions_long);
  console.log("is_same", is_same);
  let a_temp_questions = [];

  if (
    (page == 1 && !is_same) ||
    ((mother == "HorTest" || mother === "MediumTest") && !is_same)
  ) {
    a_questions_long = g_arg_a_questions
      .sort(() => Math.random() - 0.5)
      .slice(0, 40);
    console.log(
      "OneSmallTest a_questions_long inside !is_same",
      a_questions_long
    );
  }

  console.log(mother);
  if (mother == "HorTest" || mother === "MediumTest") {
    console.log("OneSmallTest a_questions_long inside", a_questions_long);
    console.log([].concat(a_questions_long));
    console.log([].concat(a_questions_long).splice(0, 20));
    if (test_volume < 249) {
      a_temp_questions = [].concat(a_questions_long).splice(0, 20);
      console.log("inside inside", a_temp_questions);
    } else {
      if (test_volume < 599) {
        a_temp_questions = [].concat(a_questions_long).splice(0, 15);
      } else {
        a_temp_questions = [].concat(a_questions_long).splice(0, 14);
      }
    }
  }
  if (mother == "HorLongTest") {
    if (page == 1) {
      if (test_volume < 249) {
        a_temp_questions = [].concat(a_questions_long).splice(0, 20);
      } else {
        if (test_volume < 599) {
          a_temp_questions = [].concat(a_questions_long).splice(0, 15);
        } else {
          a_temp_questions = [].concat(a_questions_long).splice(0, 14);
        }
      }
    }
    if (page == 2) {
      if (test_volume < 249) {
        a_temp_questions = [].concat(a_questions_long).splice(20, 20);
      } else {
        if (test_volume < 599) {
          a_temp_questions = [].concat(a_questions_long).splice(15, 15);
        } else {
          a_temp_questions = [].concat(a_questions_long).splice(14, 14);
        }
      }
    }
  }
  console.log("OneSmallTest a_temp_questions", a_temp_questions);

  return (
    <>
      <div
        style={
          mother == "MediumTest"
            ? { width: "70%", marginTop: 50 }
            : page == 1
            ? {
                width: "45%",
                marginTop: 20,
              }
            : mother == "HorTest"
            ? {
                width: "45%",
                marginTop: 20,
              }
            : { width: "45%", marginTop: 30 }
        }
      >
        {(mother === "HorTest" || page === 1 || mother === "MediumTest") && (
          <div
            style={
              page == 1
                ? {
                    marginLeft: 10,
                    marginRight: -5,
                    marginTop: 10,
                    marginBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                  }
                : {
                    marginLeft: -5,
                    marginRight: 5,
                    marginTop: 10,
                    marginBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                  }
            }
          >
            <div
              style={{
                width: "75%",
                borderTop: "1px solid darkgray",
                borderBottom: "1px solid darkgray",
                borderLeft: "1px solid darkgray",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontSize: 10,
                    borderBottom: "1px solid darkgray",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  <Text style={{ marginLeft: 5 }}>
                    {global.lang == "fr" ? "Prénom/Nom:" : "Name:"}
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                    marginBottom: 7,
                    marginTop: 8,
                  }}
                >
                  <Text style={{ fontSize: 12 }}>
                    {global.lang == "fr"
                      ? "Evaluation des connaissances"
                      : "Knowledge test"}
                  </Text>
                </div>
                <Text style={{ fontSize: 9, margin: 5 }}>
                  {global.lang == "fr"
                    ? "Liste de référence"
                    : "Reference list"}
                </Text>
                <Link
                  src={url}
                  style={{ fontSize: 8, marginLeft: 5, marginBottom: 5 }}
                >
                  {url}
                </Link>
              </div>
            </div>
            <div
              style={{
                width: "25%",
                border: "1px solid darkgray",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <div>
                <Image
                  source={{ uri: qrCodeDataUri }}
                  style={{ width: "50px", height: "50px", margin: 2 }}
                />
              </div>

              <Text>{cont_code}</Text>
            </div>
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {a_temp_questions.map((answer, i) => {
              // Return the element. Also pass key
              console.log("coucou");
              return (
                <div key={Math.random()}>
                  {i != a_temp_questions.length - 1 ? (
                    <div
                      style={
                        page == 1
                          ? {
                              marginLeft: 10,
                              marginRight: -5,
                              marginTop: -1,
                              marginBottom: -1,
                              display: "flex",
                              flexDirection: "row",
                              borderTop: "1px solid darkgray",
                              borderLeft: "1px solid darkgray",
                              borderRight: "1px solid darkgray",

                              marginBottom: -1,
                            }
                          : {
                              marginLeft: -5,
                              marginRight: 5,
                              marginTop: -1,
                              marginBottom: -1,
                              display: "flex",
                              flexDirection: "row",
                              borderTop: "1px solid darkgray",
                              borderLeft: "1px solid darkgray",
                              borderRight: "1px solid darkgray",

                              marginBottom: -1,
                            }
                      }
                    >
                      <div
                        style={
                          test_volume < 249
                            ? {
                                width: "50%",
                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                            : test_volume < 599
                            ? {
                                width: "66%",
                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                            : {
                                width: "70%",
                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                        }
                      >
                        <Text
                          style={
                            test_volume < 249
                              ? {
                                  fontSize: 11,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                              : test_volume < 599
                              ? {
                                  fontSize: 10,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                              : {
                                  fontSize: 8,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                          }
                        >
                          {answer}
                        </Text>
                      </div>
                      <div
                        style={
                          test_volume < 249
                            ? {
                                width: "50%",

                                borderLeft: "1px solid darkgray",
                              }
                            : test_volume < 599
                            ? {
                                width: "34%",

                                borderLeft: "1px solid darkgray",
                              }
                            : {
                                width: "30%",

                                borderLeft: "1px solid darkgray",
                              }
                        }
                      ></div>
                    </div>
                  ) : (
                    <div
                      style={
                        page == 1
                          ? {
                              marginLeft: 10,
                              marginRight: -5,
                              marginTop: -1,
                              marginBottom: -1,
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid darkgray",
                            }
                          : {
                              marginLeft: -5,
                              marginRight: 5,
                              marginTop: -1,
                              marginBottom: -1,
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid darkgray",
                            }
                      }
                    >
                      <div
                        style={
                          test_volume < 249
                            ? {
                                width: "50%",

                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                            : test_volume < 599
                            ? {
                                width: "66%",

                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                            : {
                                width: "70%",

                                paddingLeft: 4,
                                paddingRight: 4,

                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }
                        }
                      >
                        <Text
                          style={
                            test_volume < 249
                              ? {
                                  fontSize: 11,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                              : test_volume < 599
                              ? {
                                  fontSize: 10,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                              : {
                                  fontSize: 8,
                                  marginTop: 5,
                                  marginBottom: 5,
                                }
                          }
                        >
                          {answer}
                        </Text>
                      </div>
                      <div
                        style={
                          test_volume < 249
                            ? {
                                width: "50%",

                                borderLeft: "1px solid darkgray",
                              }
                            : test_volume < 599
                            ? {
                                width: "34%",

                                borderLeft: "1px solid darkgray",
                              }
                            : {
                                width: "30%",
                                borderLeft: "1px solid darkgray",
                              }
                        }
                      ></div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function HorLongTest({ page_number = 1, is_same = true, cont_code = "" }) {
  return (
    <Document>
      {Array.from(Array(parseInt(page_number)).keys()).map((answer) => {
        // Return the element. Also pass key
        console.log("HorLongTest ", page_number);

        return (
          <Page
            size="A4"
            style={{ backgroundColor: "white" }}
            orientation="landscape"
            key={Math.random()}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <OneSmallTest
                mother="HorLongTest"
                page={1}
                is_same={is_same}
                cont_code={cont_code}
              ></OneSmallTest>
              <OneSmallTest
                mother="HorLongTest"
                page={2}
                is_same={is_same}
                cont_code={cont_code}
              ></OneSmallTest>
            </div>
          </Page>
        );
      })}
    </Document>
  );
}

function MediumTest({ cont_code = "" }) {
  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "white" }} key={Math.random()}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <OneSmallTest
            mother="MediumTest"
            page={1}
            is_same={true}
            cont_code={cont_code}
          ></OneSmallTest>
        </div>
      </Page>
    </Document>
  );
}

function TestGen({
  arg_a_questions,
  url_pseudo,
  url_name,
  id_list,
  questions_type = "tg",
}) {
  const [a, setA] = useState(7);
  const [lang_inside, setLangInside] = useState(global.lang);
  const [qr_url, setQRURL] = useState(
    "https://" +
      window.location.href.split(/[/?#]/)[2] +
      "/p/" +
      url_pseudo +
      "/" +
      url_name
  );
  const [cont_code, setContCode] = useState("");
  const [is_same, setIsSame] = useState(true);
  const [page_number, setPageNumber] = useState(1);
  const [test_format, setTestFormat] = useState("small");

  const isLaptop = useMediaQuery({ query: "(max-width: 1400px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 1000px)" });

  if (questions_type === "tg") {
    g_arg_a_questions = arg_a_questions;
    console.log("arg_a_questions", arg_a_questions);
    a_questions = arg_a_questions.sort(() => Math.random() - 0.5).slice(0, 10);
    a_questions_long = arg_a_questions
      .sort(() => Math.random() - 0.5)
      .slice(0, 40);
  } else {
    let a_temp_questions = [];
    arg_a_questions.forEach((row) => {
      a_temp_questions.push(row[3]);
    });
    g_arg_a_questions = a_temp_questions;
    a_questions = a_temp_questions.sort(() => Math.random() - 0.5).slice(0, 10);
    a_questions_long = a_temp_questions
      .sort(() => Math.random() - 0.5)
      .slice(0, 40);
  }
  let volume = 0;
  a_questions.forEach((a) => {
    volume = volume + a.length;
  });
  test_volume = volume;

  volume = 0;
  a_questions_long.forEach((a) => {
    volume = volume + a.length;
  });
  test_volume_long = volume;
  console.log(volume);

  console.log(url_pseudo, url_name);

  useEffect(() => {
    if (arg_a_questions.length >= 40) {
      setTestFormat("large");
    } else {
      if (arg_a_questions.length >= 20) {
        setTestFormat("medium");
      }
    }
    const server_url =
      "https://" + window.location.href.split(/[/?#]/)[2] + "/";
    url = server_url + "p/" + url_pseudo + "/" + url_name;
    console.log(url);
    setQRURL(url);

    fetch(server_url + "/api/get_cont_code", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_content: id_list, content_type: "l" }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setContCode("#" + res["id_code"]);
        /* g_cont_code = res["id_code"]; */
      });
    const qrCodeCanvas = document.querySelector("canvas");
    qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
    setA(Math.random());
  }, []);

  const dl_pdf = async (which_doc) => {
    const link = document.createElement("a");
    var blob = 0;
    switch (which_doc) {
      case "short":
        blob = await pdf(
          <ShortTest
            page_number={page_number}
            is_same={is_same}
            cont_code={cont_code}
          />
        ).toBlob();
        break;

      case "hor_short":
        blob = await pdf(
          <MediumTest
            page_number={page_number}
            is_same={is_same}
            cont_code={cont_code}
          />
        ).toBlob();
        break;

      case "hor_long":
        blob = await pdf(
          <HorLongTest
            page_number={page_number}
            is_same={is_same}
            cont_code={cont_code}
          />
        ).toBlob();
        break;
    }

    console.log(blob);

    link.href = window.URL.createObjectURL(blob);
    const d = new Date();
    link.download =
      d.getFullYear() +
      "" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      ("0" + (d.getDate() + 1)).slice(-2) +
      "_" +
      which_doc +
      "_" +
      url_name +
      ".pdf";
    link.click();
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <QRCode id="canvas" value={qr_url} />
      </div>

      <div
        style={
          !isLaptop
            ? {
                display: "flex",
                justifyContent: "space-between",
                minWidth: "900px",
                minHeight: "900px",
              }
            : isPhone
            ? {
                display: "flex",
                justifyContent: "space-between",
              }
            : {
                display: "flex",
                justifyContent: "space-between",
                minWidth: "900px",
                minHeight: "650px",
              }
        }
      >
        {!isPhone && <div></div>}

        {isPhone && (
          <div>
            <Typography
              align="center"
              style={{
                fontSize: "40px",
                marginBottom: "1.5em",
                fontFamily: "nunito",
                fontWeight: 900,
              }}
            >
              {global.lang == "fr" ? "Interrogation écrite" : "Paper test"}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: "20px",
                marginBottom: "0.5em",
                marginTop: "3em",
                fontFamily: "nunito",
                fontWeight: 900,
              }}
            >
              {global.lang == "fr" ? "Format Petit" : "Small format"}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                startIcon={<GetAppIcon />}
                onClick={() => dl_pdf("short")}
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                disabled={arg_a_questions.length < 10}
              >
                {global.lang == "fr" ? "télécharger" : "download"}
              </Button>
            </div>
            <Typography
              align="center"
              style={{
                fontSize: "20px",
                marginBottom: "0.5em",
                marginTop: "3em",
                fontFamily: "nunito",
                fontWeight: 900,
              }}
            >
              {global.lang == "fr" ? "Format Moyen" : "Medium format"}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                startIcon={<GetAppIcon />}
                onClick={() => dl_pdf("medium")}
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                disabled={arg_a_questions.length < 20}
              >
                {global.lang == "fr" ? "télécharger" : "download"}
              </Button>
            </div>
            <Typography
              align="center"
              style={{
                fontSize: "20px",
                marginBottom: "0.5em",
                marginTop: "3em",
                fontFamily: "nunito",
                fontWeight: 900,
              }}
            >
              {global.lang == "fr" ? "Format Grand" : "Large format"}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "3em",
              }}
            >
              <Button
                startIcon={<GetAppIcon />}
                onClick={() => dl_pdf("large")}
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                disabled={arg_a_questions.length < 40}
              >
                {global.lang == "fr" ? "télécharger" : "download"}
              </Button>
            </div>
          </div>
        )}
        {!isPhone && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "2em",
              minWidth: "677px",
            }}
          >
            {test_format === "small" && (
              <div
                style={
                  isLaptop
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        margin: "2em 2em 1em 2em",
                      }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        margin: "3em",
                      }
                }
              >
                <Typography
                  align="center"
                  style={
                    isLaptop
                      ? {
                          fontSize: "40px",
                          marginBottom: "1.5em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                      : {
                          fontSize: "60px",
                          marginBottom: "2em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                  }
                >
                  {/* {global.lang == "fr" ? "Format petit" : "Format S"} */}
                  {global.lang == "fr" ? "Interrogation écrite" : "Paper test"}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={isLaptop ? { width: "244px" } : { width: "292px" }}
                  >
                    <PDFViewer
                      style={
                        isLaptop
                          ? { height: "362px", width: "244px" }
                          : { height: "452px", width: "292px" }
                      }
                    >
                      <ShortTest
                        page_number={page_number}
                        is_same={is_same}
                        cont_code={cont_code}
                      />
                    </PDFViewer>
                    <Button
                      startIcon={<GetAppIcon />}
                      onClick={() => dl_pdf("short")}
                      variant="contained"
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      {global.lang == "fr" ? "télécharger" : "download"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {test_format === "medium" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  /* width: 300, */
                  /* alignSelf: "flex-end", */
                  margin: "3em",
                }}
              >
                <Typography
                  align="center"
                  style={
                    isLaptop
                      ? {
                          fontSize: "40px",
                          marginBottom: "1.5em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                      : {
                          fontSize: "60px",
                          marginBottom: "2em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                  }
                >
                  {/* {global.lang == "fr" ? "Format moyen" : "Format M"} */}
                  {global.lang == "fr" ? "Interrogation écrite" : "Paper test"}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={isLaptop ? { width: "244px" } : { width: "292px" }}
                  >
                    <PDFViewer
                      style={
                        isLaptop
                          ? { height: "362px", width: "244px" }
                          : { height: "452px", width: "292px" }
                      }
                    >
                      <MediumTest cont_code={cont_code} />
                    </PDFViewer>
                    <Button
                      startIcon={<GetAppIcon />}
                      onClick={() => dl_pdf("hor_short")}
                      variant="contained"
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      {global.lang == "fr" ? "télécharger" : "download"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {test_format === "large" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  /* width: 300, */
                  /* alignSelf: "flex-end", */
                  margin: "3em",
                }}
              >
                <Typography
                  align="center"
                  style={
                    isLaptop
                      ? {
                          fontSize: "40px",
                          marginBottom: "1.5em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                      : {
                          fontSize: "60px",
                          marginBottom: "2em",
                          fontFamily: "nunito",
                          fontWeight: 900,
                        }
                  }
                >
                  {/* {global.lang == "fr" ? "Format grand" : "Format L"} */}
                  {global.lang == "fr" ? "Interrogation écrite" : "Paper test"}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "380px" }}>
                    <PDFViewer style={{ height: "327px", width: "380px" }}>
                      <HorLongTest
                        page_number={page_number}
                        is_same={is_same}
                        cont_code={cont_code}
                      />
                    </PDFViewer>
                    <Button
                      startIcon={<GetAppIcon />}
                      onClick={() => dl_pdf("hor_long")}
                      variant="contained"
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      {global.lang == "fr" ? "télécharger" : "download"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {!isPhone && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: "rgb(245,245,245)",
              minWidth: "300px",
            }}
          >
            <div style={{ margin: "3em" }}>
              <div>
                <ToggleButton
                  style={
                    test_format === "small"
                      ? {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                        }
                      : {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                          backgroundColor: "white",
                        }
                  }
                  selected={test_format === "small"}
                  onClick={() => setTestFormat("small")}
                  size="large"
                >
                  {global.lang === "fr" ? "format petit" : "small format"}
                </ToggleButton>
              </div>
              <div>
                <ToggleButton
                  style={
                    test_format === "medium"
                      ? {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                        }
                      : {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                          backgroundColor: "white",
                        }
                  }
                  selected={test_format === "medium"}
                  onClick={() => setTestFormat("medium")}
                  size="large"
                  disabled={arg_a_questions.length < 20}
                >
                  {global.lang === "fr" ? "format moyen" : "medium format"}
                </ToggleButton>
              </div>
              <div>
                <ToggleButton
                  style={
                    test_format === "large"
                      ? {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                        }
                      : {
                          marginBottom: "2em",
                          fontFamily: "Nunito",
                          fontWeight: 900,
                          width: "100%",
                          backgroundColor: "white",
                        }
                  }
                  selected={test_format === "large"}
                  onClick={() => setTestFormat("large")}
                  size="large"
                  disabled={arg_a_questions.length < 40}
                >
                  {global.lang === "fr" ? "format grand" : "large format"}
                </ToggleButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TestGen;
