import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import GroupIcon from "@material-ui/icons/Group";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import Link from "@mui/material/Link";
import { useMediaQuery } from "react-responsive";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#EFF0F1",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0C0D0E",
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
    top: "69px",
    /*   position: "fixed", */
    position: "relative",
    minHeight: "1000px",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  menuGhost: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
  },
}));

export function WLMenu({ url_pseudo }) {
  const classes = useStyles();
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  const [a_lists, setLists] = useState([]);
  const [a_a_lists, setALists] = useState([]);
  const [a_lists_last_visits, setListsLastVisits] = useState([]);
  const [a_pages, setAPages] = useState([]);
  const [a_pages_last_visits, setPagesLastVisits] = useState([]);
  const isLG = useMediaQuery({ query: "(min-width: 1400px)" });

  useEffect(() => {
    console.log("WLMenu useEffect");
    fetch(server_url + "api/get_homepage_lists_pages")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLists(res["lists"]);
        setALists(res["a_lists"]);
        setListsLastVisits(res["lists_last_visit"]);
        setAPages(res["pages"]);
        setPagesLastVisits(res["pages_last_visit"]);
        console.log(res);
      });
  }, []);

  return (
    <div>
      <div className={classes.menuGhost}></div>
      <div className={classes.menu}>
        <br></br>
        <div
          style={{
            display: "flex",
            color: "gray",
            alignItems: "center",
            marginTop: "1em",
            marginBottom: "0.5em",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = server_url + "lists";
          }}
        >
          <StyledMenuItem style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListAltIcon />
            </div>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", marginLeft: "1em" }}
              color="textSecondary"
            >
              {global.lang == "en" ? "My lists" : "Mes listes"}
            </Typography>
          </StyledMenuItem>
        </div>
        {a_lists.map((row) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ textAlign: "center", margin: "0.3em" }}>
                <Link href={server_url + "u/" + url_pseudo + "/" + row[9]}>
                  {row[2]}
                </Link>
                <ScoreBar
                  green={row[11]}
                  orange={row[12]}
                  red={row[13]}
                ></ScoreBar>
              </div>
            </div>
          );
        })}
        {isLG ? (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = server_url + "lists";
            }}
          >
            <StyledMenuItem style={{ width: "100%" }}>
              <div
                style={{ opacity: 0, display: "flex", alignItems: "center" }}
              >
                <ListAltIcon />
              </div>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginLeft: "1em" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Followed lists" : "Listes suivies"}
              </Typography>
            </StyledMenuItem>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
              justifyContent: "space-around",
            }}
            onClick={() => {
              window.location.href = server_url + "lists";
            }}
          >
            <StyledMenuItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Followed lists" : "Listes suivies"}
              </Typography>
            </StyledMenuItem>
          </div>
        )}
        {a_a_lists.map((row) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ textAlign: "center", margin: "0.3em" }}>
                <Link href={server_url + "a/" + row[22] + "/" + row[9]}>
                  {row[2]}
                </Link>
                <ScoreBar
                  green={row[11]}
                  orange={row[12]}
                  red={row[13]}
                ></ScoreBar>
              </div>
            </div>
          );
        })}
        {isLG ? (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = server_url + "lists";
            }}
          >
            <StyledMenuItem style={{ width: "100%" }}>
              <div
                style={{ opacity: 0, display: "flex", alignItems: "center" }}
              >
                <ListAltIcon />
              </div>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginLeft: "1em" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Last visits" : "Dernières visites"}
              </Typography>
            </StyledMenuItem>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
              justifyContent: "space-around",
            }}
            onClick={() => {
              window.location.href = server_url + "lists";
            }}
          >
            <StyledMenuItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Last visits" : "Dernières visites"}
              </Typography>
            </StyledMenuItem>
          </div>
        )}

        {a_lists_last_visits.map((row) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <Link href={server_url + "p/" + row[22] + "/" + row[9]}>
                  {row[2]}
                </Link>
              </div>
            </div>
          );
        })}

        <div
          style={{
            display: "flex",
            color: "gray",
            alignItems: "center",
            marginTop: "1em",
            marginBottom: "0.5em",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = server_url + "pages";
          }}
        >
          <StyledMenuItem style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LibraryBooksIcon />
            </div>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", marginLeft: "1em" }}
              color="textSecondary"
            >
              {global.lang == "en" ? "My Pages" : "Mes pages"}
            </Typography>
          </StyledMenuItem>
        </div>
        {a_pages.map((row) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <Link href={server_url + "b/" + url_pseudo + "/" + row[7]}>
                  {row[2]}
                </Link>
              </div>
            </div>
          );
        })}
        {isLG ? (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = server_url + "pages";
            }}
          >
            <StyledMenuItem style={{ width: "100%" }}>
              <div
                style={{ opacity: 0, display: "flex", alignItems: "center" }}
              >
                <LibraryBooksIcon />
              </div>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginLeft: "1em" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Last visits" : "Dernières visites"}
              </Typography>
            </StyledMenuItem>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              color: "gray",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "0.5em",
              cursor: "pointer",
              justifyContent: "space-around",
            }}
            onClick={() => {
              window.location.href = server_url + "pages";
            }}
          >
            <StyledMenuItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                color="textSecondary"
              >
                {global.lang == "en" ? "Last visits" : "Dernières visites"}
              </Typography>
            </StyledMenuItem>
          </div>
        )}
        {a_pages_last_visits.map((row) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <Link href={server_url + "c/" + row[4] + "/" + row[0][7]}>
                  {row[0][2]}
                </Link>
              </div>
            </div>
          );
        })}
        <div style={{ margin: "2em" }}></div>
      </div>
    </div>
  );
}

function ScoreBar({ green, orange, red }) {
  const isLG = useMediaQuery({ query: "(min-width: 1400px)" });
  const sbar_width = 200;
  const height = "14px";
  const green_width = (sbar_width * green) / (green + orange + red);
  const orange_width = (sbar_width * orange) / (green + orange + red);
  const red_width = sbar_width - green_width - orange_width;

  const md_sbar_width = 150;
  const md_height = "14px";
  const md_green_width = (md_sbar_width * green) / (green + orange + red);
  const md_orange_width = (md_sbar_width * orange) / (green + orange + red);
  const md_red_width = md_sbar_width - md_green_width - md_orange_width;

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div
        style={
          isLG
            ? {
                width: sbar_width + "px",
                backgroundColor: "white",
                display: "flex",
                height: height,
                border: "1px solid lightgray",
              }
            : {
                width: md_sbar_width + "px",
                backgroundColor: "white",
                display: "flex",
                height: height,
                border: "1px solid lightgray",
              }
        }
      >
        <div
          style={
            isLG
              ? {
                  width: green_width + "px",
                  backgroundColor: "#3CA310",
                  height: height,
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
              : {
                  width: md_green_width + "px",
                  backgroundColor: "#3CA310",
                  height: height,
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
          }
        >
          {green_width > 30 && green}
        </div>
        <div
          style={
            isLG
              ? {
                  width: orange_width + "px",
                  backgroundColor: "#FE9D00",
                  height: height,
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
              : {
                  width: md_orange_width + "px",
                  backgroundColor: "#FE9D00",
                  height: height,
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
          }
        >
          {orange_width > 30 && orange}
        </div>
        <div
          style={
            isLG
              ? {
                  width: red_width + "px",
                  backgroundColor: "white",
                  height: height,
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
              : {
                  width: md_red_width + "px",
                  backgroundColor: "white",
                  height: height,
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: "small",
                }
          }
        >
          {red_width > 30 && red}
        </div>
      </div>
    </div>
  );
}
