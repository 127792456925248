import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#EFF0F1",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0C0D0E",
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
    top: "69px",
    position: "fixed",
    bottom: "0px",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  menuGhost: {
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
  },
}));

export default function MMenu() {
  const classes = useStyles();
  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";

  return (
    <div style={{backgroundColor: "white"}}>
      <div className={classes.menuGhost}></div>
      <div className={classes.menu}>
        <br></br>
        <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url;
          }}
        >
          <ListItemIcon style={{ color: "#535A60" }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>
                {global.lang == "en" ? "Home" : "Accueil"}
              </Typography>
            }
          />
        </StyledMenuItem>
        <br></br>
        <br></br>
        <br></br>
{/*         <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url + "lists";
          }}
        >
          <ListItemIcon style={{ color: "#535A60" }}>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>
                {global.lang == "en" ? "Lists" : "Listes"}
              </Typography>
            }
          />
        </StyledMenuItem> */}
{/*         <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url + "pages";
          }}
        >
          <ListItemIcon style={{ color: "#535A60" }}>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>Pages</Typography>
            }
          />
        </StyledMenuItem> */}
        <br></br>
        <br></br>
        <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url + "profile";
          }}
        >
          <ListItemIcon style={{ color: "#535A60" }}>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>Profile</Typography>
            }
          />
        </StyledMenuItem>

        <StyledMenuItem
          button
          onClick={() => {
            window.location.href = server_url + "users";
          }}
        >
          <ListItemIcon style={{ color: "#535A60" }}>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: "#535A60" }}>
                {global.lang == "en" ? "Users" : "Membres"}
              </Typography>
            }
          />
        </StyledMenuItem>
      </div>
    </div>
  );
}
