import React, { useEffect, useState } from "react";
import "../css/animations.css";
import { Paper } from "@material-ui/core";
import WTrain from "../Wall/WTrain";
import CRPan from "./CRPan";
import { Hidden } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setReduxUser,
  setReduxPage,
  setReduxList,
  setReduxQuestion,
} from "../redux/globalWall";
import {
  setReduxListsMeta,
  addReduxListMeta,
  removeReduxListMeta,
} from "../redux/globalLists";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { unixToDelay } from "../Generic/generic";
import { cloneQuestionsToListDB, insertReportUserDB } from "../Generic/lists";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "@material-ui/core/Snackbar";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CreaListDialog, CreaPageDialog } from "../SmallComponents/CDialog";
import { WLMenu } from "../Wall/WLMenu";

import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
} from "../redux/globalTuto";

import {useCookies} from "react-cookie";

export default function CWall() {
  const [a_events, setEvents] = useState([]);
  const [mine_ind_green, setMineIndGreen] = useState(-1);
  const [do_render, setDoRender] = useState(false);
  const [openCreaListDialog, setOpenCreaListDialog] = useState(false);
  const [openCreaPageDialog, setOpenCreaPageDialog] = useState(false);
  const [url_pseudo, setUrlPseudo] = useState("");

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const dispatch = useDispatch();
  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  const [cookies, setCookie] = useCookies(['tuto']);

  const manageTutoStep = (int_step) => {
    if (int_step == 0) {
      dispatch(setReduxTutoStep({ tuto_step: int_step }));
    }
    if (int_step == 0.5) {
        let dom_comp = document.getElementById("id_crea_list_1");
        let rect = dom_comp.getBoundingClientRect();
        dispatch(setReduxTopArrow({ top_arrow: rect.top }));
        dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
    }
    dispatch(setReduxTutoStep({ tuto_step: int_step }));
  };

  useEffect(() => {
    fetch(server_url + "api/get_user_data")
      .then((res) => res.json())
      .then((res) => {
        setUrlPseudo(res["url_user_name"]);
        console.log(res);
      });

    /* fetch(server_url + "/api/get_16_events", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ a_events: [] }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setEvents(res);
      });
    fetch("/api/get_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        dispatch(
          setReduxListsMeta({
            a_lists_meta: res,
          })
        );
        setDoRender(true);
      }); */

    console.log(cookies);
    if ( cookies.tuto === "new_user") {
        console.log(cookies);
        setCookie('tuto', "ulist");
        console.log(cookies);
        manageTutoStep(0.5);
    }

  }, []);

  return (
    <>
      <WLMenu url_pseudo={url_pseudo}></WLMenu>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div>
          <div style={{ height: "69px" }}></div>
          <WTrain url_pseudo={url_pseudo} />
          {/* <WLists setOpenCreaListDialog={setOpenCreaListDialog}></WLists>
          <WPages setOpenCreaPageDialog={setOpenCreaPageDialog}></WPages> */}
        </div>
        <div>
          <div style={{height:"69px"}}></div>
          <div style={{display:"flex", alignItems:"center", height:"500px"}}>
            <Button variant="contained" color="secondary" style={{width:"260px", height:"220px", fontSize:"x-large"}}>
              {global.lang=="fr"? "Créer une liste de vocabulaire": "Create a list"}
            </Button>
          </div>
        </div>
{/*         <Button onClick={() => manageTutoStep(0.5)}> open tuto div</Button>
        <CreaListDialog
          open_crea_list={openCreaListDialog}
          setOpenCreaList={setOpenCreaListDialog}
          url_pseudo={url_pseudo}
        ></CreaListDialog>
        <CreaPageDialog
          open_crea_page={openCreaPageDialog}
          setOpenCreaPage={setOpenCreaPageDialog}
          url_pseudo={url_pseudo}
        ></CreaPageDialog> */}
      </div>

      {h_global_tuto_step["tuto_step"] == 0.5 && (
        <>
          <div
            style={{
              zIndex: 501,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                maxWidth: "760px",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "2em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "3em",
                      marginTop: "2em",
                    }}
                  >
                    <Typography variant="h3" color="textPrimary">
                      {global.lang == "en"
                        ? "Welcome on Cerbrain"
                        : "Bienvenue sur Cerbrain"}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "48px",
                        marginLeft: "0.5em",
                      }}
                    >
                      &#128512;
                    </div>
                  </div>

                  <div style={{ margin: "1em" }}>
                    <Typography variant="h5" color="textSecondary">
                      {global.lang == "en"
                        ? "Let's create a list in 4 steps."
                        : "Créons une liste de connaissances en 4 étapes."}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1em",
                        marginBottom: "1em",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{ display: "inline-block" }}
                      >
                        {global.lang == "en"
                          ? "1) First click on the "
                          : "1) Cliquer sur le bouton "}
                        <Typography
                          variant="h5"
                          color="red"
                          style={{ display: "inline-block" }}
                        >
                          {global.lang == "en"
                            ? "create a list "
                            : "créer une liste "}
                        </Typography>
                        {global.lang == "en" && " button"}
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: "4em",

                      marginTop: "1em",
                    }}
                  >
                    <Link
                      onClick={() => manageTutoStep(0)}
                      style={{ cursor: "pointer" }}
                    >
                      {global.lang == "fr" ? "non merci" : "dismiss"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="id_tototodel"
            style={{
              animation: "mymove 1s infinite",
              animationDirection: "alternate",
              position: "absolute",
              zIndex: 510,
              top: h_global_tuto_step["top_arrow"] + "px",
              left: h_global_tuto_step["left_arrow"] - 120 + "px",
              width: "10px",
              height: "10px",
            }}
          >
            <img src="im/red_arrow.png"></img>
          </div>
        </>
      )}
    </>
  );
}

const avatStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: "0.2em 1em",
    cursor: "pointer",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow:
        "0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));



export function UserReport({ id_user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open_crea_page, setOpenCreaPage] = useState(false);
  const inputMessage = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(id_user);

  return (
    <div>
      <IconButton aria-label="report" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenCreaPage(true);
            console.log("report");
          }}
        >
          <ListItemText primary="report user" />
        </MenuItem>
      </StyledMenu>

      <Dialog
        open={open_crea_page}
        onClose={() => setOpenCreaPage(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {global.lang == "en" ? "Report a user" : "Signaler un utilisateur"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {global.lang == "en"
              ? "Describe the situation, why do you want to report this user ?"
              : "Pourquoi signaler cet utilisateur ?"}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="id_crea_list_title"
            label={global.lang == "en" ? "Message" : "Message"}
            fullWidth
            inputRef={inputMessage}
            multiline
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreaPage(false)} color="primary">
            {global.lang == "en" ? "Cancel" : "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log(inputMessage.current.value);
              insertReportUserDB(id_user, inputMessage.current.value);
              setOpenCreaPage(false);
              handleClose();
            }}
            color="primary"
          >
            {global.lang == "en" ? "Send" : "Envoyer"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
