import React, { useState, useEffect } from "react";
import CHeader from "./MainComponents/CHeader";
import CFFooter from "./MainComponents/CFFooter";
import WallPage from "./MainComponents/WallPage";
import UList from "./List/UList";
import AList from "./List/AList";
import PList from "./List/PList";
import VList from "./List/VList";
import CPages from "./Page/CPages";
import BPage from "./Page/BPage";
import CPage from "./Page/CPage";
import EProfile from "./User/EProfile";
import Link from "@mui/material/Link";
import {
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { SignUpSmallFull } from "./HomeNoSession/HomeNoSession";
import { useSelector, useDispatch } from "react-redux";
import { setReduxRegister } from "./redux/globalDialog";

import LandingPage from "./HomeNoSession/LandingPage";

const theme = createTheme({
  palette: {
    text: {
      primary: "#202124",
      secondary: "#4D5156",
    },
  },
  typography: {
    fontFamily: "Nunito, Roboto, Arial, sans-serif",
    body1: {
      fontWeight: 900,
      color: "rgb(25,25,80)",
    },
    button: {
      fontWeight: 900,
    },
    h3: {
      color: "rgb(25,25,70)",
    },
    h4: {
      color: "rgb(25,25,75)",
    },
  },
});

export default function Main() {
  const [is_logged, setIsLogged] = useState("-1");
  const [main_content, setMainContent] = useState("no");
  const [id_user, setIdUser] = useState(0);
  const [id_content, setIdContent] = useState(0);
  const [id_a_list, setIdAList] = useState(0);
  const [page_name, setPageName] = useState(0);
  const [pseudo, setPseudo] = useState(0);
  const [user_descr, setUserDescr] = useState();
  const [a_user_meta, setUserMeta] = useState([]);
  const [a_list_meta, setListMeta] = useState([]);
  const [lang, setLang] = useState("no");
  const [is_private, setIsPrivate] = useState("no");
  // menuStatus = "1" when displaid
  // menuStatus = "0" when hidden
  const [menuStatus, setMenuStatus] = useState("1");
  // page = 'wall'
  // page = 'lists'
  // page = 'pages'
  const [page, setPage] = useState("wall");
  const isPC = useMediaQuery({ query: "(min-width: 700px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 699px)" });
  const [phone_mode, setPhoneMode] = useState("phone");

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  const a_url = window.location.href.split("?")[0].split("/");
  const user = a_url[4];
  const list = a_url[5];

  const dispatch = useDispatch();
  const h_global_dialog = useSelector((state) => state.global_dialog);

  useEffect(() => {
    console.log(window.location.href.split("?"));
    console.log(a_url[2].split("."));
    console.log(a_url[2].split(".")[a_url[2].split(".").length - 1]);
    if (a_url[2].split(".")[a_url[2].split(".").length - 1] == "fr") {
      global.lang = "fr";
      //setLang("fr");
      console.log(global.lang);
    }
    if (a_url[2].split(".")[a_url[2].split(".").length - 1] == "com") {
      global.lang = "en";
      //setLang("en");
    }
    if (a_url[2].split(".")[a_url[2].split(".").length - 1] == "net") {
      global.lang = "en";
      //setLang("en");
    }
    if (a_url[2].split(".")[a_url[2].split(".").length - 1] == "club") {
      global.lang = "fr";
      //setLang("en");
    }
    if (window.location.href.split("?").indexOf("fr") != -1) {
      global.lang = "fr";
      setLang("fr");
      console.log("fr");
    } else {
      if (window.location.href.split("?").indexOf("en") != -1) {
        console.log("en");
        global.lang = "en";
        setLang("en");
      } else {
        console.log("fetch ???");
        fetch(server_url + "api/get_last_lg")
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            if (res["last_lg"] != "no") {
              global.lang = res["last_lg"];
              console.log(global.lang);
              //setLang(res["last_lg"]);
            }
            console.log(global.lang);
            if (global.lang == "fr") {
              document.title =
                "Cerbrain - création de listes de vocabulaire pour la classe";
            }
            if (global.lang == "en") {
              document.title =
                "Cerbrain: create and memorize your knowledge list, and share it with the world.";
            }
            setLang(global.lang);
          });
      }
    }

    console.log(global.lang);

    fetch(server_url + "api/is_logged")
      .then((res) => res.json())
      .then((res) => {
        console.log("before is_logged");
        setIsLogged(res["is_logged"]);
        console.log(is_logged);
        console.log(is_logged == "0");
      });

    if (["u"].indexOf(a_url[3]) != -1) {
      console.log("upa");
      fetch("/api/ids_from_url", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ url_pseudo: user, url_name: list }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setIdUser(res["id_user"]);
          setIdContent(res["id_list"]);
          setIsPrivate(res["is_private"]);
        });
    }
    if (["a"].indexOf(a_url[3]) != -1) {
      console.log("a");
      fetch("/api/ids_from_url_a_list", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ url_pseudo: user, url_name: list }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setIdUser(res["id_user"]);
          setIdContent(res["id_list"]);
          setIdAList(res["id_a_list"]);
        });
    }
    if (["b", "c", "f"].indexOf(a_url[3]) != -1) {
      fetch("/api/ids_from_url_page", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ url_pseudo: user, url_name: list }),
      })
        .then((res) => res.json())
        .then((res) => {
          setIdUser(res["id_user"]);
          setIdContent(res["id_page"]);
          setPseudo(res["pseudo"]);
          setPageName(res["name"]);
        });
    }
    if (["e"].indexOf(a_url[3]) != -1) {
      const data = { url_pseudo: user };
      console.log(data);
      fetch("/api/meta_from_url_pseudo", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setIdUser(res[0]);
          setUserDescr(res[9]);
          setPseudo(res[2]);
        });
    }

    if (a_url[3] == "u") {
      setMainContent("list");
    }
    if (a_url[3] == "p") {
      let data = { url_name: list };
      console.log(data);
      fetch("/api/is_a_followed_list", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res["count"] == 1) {
            fetch("/api/ids_from_url_a_list", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
              body: JSON.stringify({ url_pseudo: user, url_name: list }),
            })
              .then((res2) => res2.json())
              .then((res2) => {
                console.log(res2);
                setIdUser(res2["id_user"]);
                setIdContent(res2["id_list"]);
                setIdAList(res2["id_a_list"]);
                setMainContent("added-list");
              });
          } else {
            setMainContent("public-list");
          }
        });
    }
    if (a_url[3] == "a") {
      setMainContent("added-list");
    }
    if (a_url[3] == "b") {
      setMainContent("edit-page");
    }
    if (a_url[3] == "c") {
      setMainContent("preview-page");
    }
    if (a_url[3] == "e") {
      setMainContent("public-profile");
    }
    if (a_url[3] == "f") {
      setMainContent("published-page");
    }

    if (["a", "b", "c", "d", "e", "f", "p", "u", "v"].indexOf(a_url[3]) == -1) {
      setMainContent("home");
    }
  }, []);

  function doLogOut() {
    fetch("api/log_out");
    setIsLogged("0");
  }

  return (
    <div style={{ height: "100%" }}>
      {/* <div style={{ backgroundColor: "rgb(246,248,250)", height: "100%" }}> */}
      <ThemeProvider theme={theme}>
        {lang != "no" && (
          <div style={{ height: "100%" }}>
            {main_content == "home" && (
              <div style={{ height: "100%" }}>
                {is_logged == "0" && lang == "en" && (
                  /* <HomeNoSession /> */
                  /* <VList
                    url_pseudo="Cerbrain"
                    url_name="The_Cerbrain_List"
                    is_logged={is_logged}
                  ></VList> */
                  <LandingPage></LandingPage>
                )}
                {is_logged == "0" && lang == "fr" && (
                  /* <HomeNoSession /> */
                  /* <VList
                    url_pseudo="Cerbrain"
                    url_name="La_liste_Cerbrain"
                    is_logged={is_logged}
                  ></VList> */
                  <LandingPage></LandingPage>
                )}
                {is_logged == "1" && <WallPage doLogOut={() => doLogOut()} />}
              </div>
            )}
            {main_content == "list" && id_content != 0 && is_logged == "1" && (
              <UList
                id_user={id_user}
                id_list={id_content}
                url_pseudo={user}
              ></UList>
            )}
            {main_content == "list" && id_content != 0 && is_logged == "0" && (
              <div>
                <CHeader is_logged={is_logged}></CHeader>
                <div style={{ height: "69px" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "1650px",
                  }}
                >
                  <h2 style={{ margin: "2em" }}>
                    {global.lang == "fr" &&
                      "Vous devez être connecté pour accéder à cette liste en mode édition"}
                    {global.lang == "en" &&
                      "You need to log into cerbrain to see this list in edition mode"}
                    <br></br>
                    {is_private == "0" && global.lang == "fr" && (
                      <Link href={"/p/" + user + "/" + list}>
                        Voir la version publique
                      </Link>
                    )}
                    {is_private == "0" && global.lang == "en" && (
                      <Link href={"/p/" + user + "/" + list}>
                        Go to the public version
                      </Link>
                    )}
                  </h2>
                </div>
              </div>
            )}
            {main_content == "pages" && id_content != 0 && <CPages></CPages>}
            {main_content == "public-list" && is_logged == "1" && (

                  <PList
                    url_pseudo={user}
                    url_name={list}
                    is_logged={is_logged}
                  ></PList>

            )}
            {main_content == "public-list" && is_logged == "0" && (

                  <VList
                    url_pseudo={user}
                    url_name={list}
                    is_logged={is_logged}
                  ></VList>

            )}
            {main_content == "added-list" && id_content != 0 && id_a_list != 0 && (
                  <AList
                    id_user={id_user}
                    id_list={id_content}
                    url_pseudo={user}
                    url_name={list}
                    id_a_list={id_a_list}
                  ></AList>
            )}
            {main_content == "edit-page" &&
              id_content != 0 &&
              typeof id_content != "undefined" && (
                <BPage
                  id_user={id_user}
                  id_page={id_content}
                  url_pseudo={user}
                  url_name={list}
                  pseudo={pseudo}
                  page_name={page_name}
                ></BPage>
              )}
            {main_content == "preview-page" &&
              id_content != 0 &&
              typeof id_content != "undefined" && (
                <CPage
                  id_user={id_user}
                  id_page={id_content}
                  url_pseudo={user}
                  url_name={list}
                  pseudo={pseudo}
                  page_name={page_name}
                  is_logged={is_logged}
                  c_or_f="c"
                ></CPage>
              )}
            {main_content == "published-page" &&
              id_content != 0 &&
              typeof id_content != "undefined" && (
                <CPage
                  id_user={id_user}
                  id_page={id_content}
                  url_pseudo={user}
                  url_name={list}
                  pseudo={pseudo}
                  page_name={page_name}
                  is_logged={is_logged}
                  c_or_f="f"
                ></CPage>
              )}
            {main_content == "public-profile" &&
              typeof id_user != "undefined" &&
              typeof user_descr != "undefined" && (
                <EProfile
                  id_user={id_user}
                  url_pseudo={user}
                  pseudo={pseudo}
                  descr={user_descr}
                  is_logged={is_logged}
                ></EProfile>
              )}
          </div>
        )}

        <SignUpSmallFull
          is_open={h_global_dialog["register"]}
          setClose={() => dispatch(setReduxRegister({ register: false }))}
          default_display={h_global_dialog["default_display"]}
        ></SignUpSmallFull>
      </ThemeProvider>

      {h_global_dialog["is_cffooter_open"] != 0 && <CFFooter />}
    </div>
  );
}
