import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { setPublicDB } from "../Generic/lists";
import Badge from "@material-ui/core/Badge";
import { ScoreChips } from "../SmallComponents/SmallComponents";
import { unixToShortHuman, unixToDelay } from "../Generic/generic";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setReduxUser } from "../redux/globalWall";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GroupIcon from "@material-ui/icons/Group";
import { CreaListDialog, AddListDialog } from "../SmallComponents/CDialog";
import { useMediaQuery } from "react-responsive";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useCookies } from "react-cookie";
import {
  setReduxTutoStep,
  setReduxTopArrow,
  setReduxLeftArrow,
} from "../redux/globalTuto";

import { ChoiceTutoDialog } from "../SmallComponents/CDialog";

export default function CLists() {
  const [a_lists, setLists] = useState([]);
  const [a_a_lists, setALists] = useState([]);
  const [a_v_lists, setVisitedLists] = useState([]);
  const [url_user_name, setUrlUserName] = useState();
  const [openCreaListDialog, setOpenCreaListDialog] = useState(false);
  const [openAddListDialog, setOpenAddListDialog] = useState(false);

  const [id_list_sup1, setIdListSup1] = useState(0);
  const [list_meta_sup1, setListMetaSup1] = useState([]);
  const [id_list_sup2, setIdListSup2] = useState(0);
  const [list_meta_sup2, setListMetaSup2] = useState([]);
  const [id_list_sup3, setIdListSup3] = useState(0);
  const [list_meta_sup3, setListMetaSup3] = useState([]);

  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });
  const isPC = useMediaQuery({ query: "(min-width: 1000px)" });
  const is1500 = useMediaQuery({ query: "(min-width: 1500px)" });

  const h_redux_add_list = useSelector((state) => state.global_add_list);

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const dispatch = useDispatch();
  const h_global_tuto_step = useSelector((state) => state.global_tuto);

  const [cookies, setCookie] = useCookies(["tuto2"]);

  const [open_sot, setOpenSOT] = useState(false);

  const manageTutoStep = (int_step) => {
    if (int_step == 0) {
      dispatch(setReduxTutoStep({ tuto_step: int_step }));
    }
    if (int_step == 0.5 || int_step == 100.6) {
      let dom_comp = document.getElementById("id_crea_list_2");
      let rect = dom_comp.getBoundingClientRect();
      dispatch(setReduxTopArrow({ top_arrow: rect.top }));
      dispatch(setReduxLeftArrow({ left_arrow: rect.left }));
    }
    dispatch(setReduxTutoStep({ tuto_step: int_step }));
  };

 

  useEffect(() => {
    fetch(server_url + "/api/get_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLists(res);
      });
    fetch(server_url + "/api/get_a_lists")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setALists(res);
      });

    const data = { number: 6 };
    console.log(data);
    /*     fetch("/api/get_last_visited_lists", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setVisitedLists(res);
      }); */

    fetch(server_url + "api/get_user_data")
      .then((res) => res.json())
      .then((res) => setUrlUserName(res["url_user_name"]));

    console.log(cookies);
    if (cookies.tuto2 === "new_user") {
      console.log(cookies);
      setCookie("tuto2", "ulist", { path: "/" });
      console.log(cookies);
      /* manageTutoStep(0.5); */
      setOpenSOT(true);
    }
  }, []);

  const deleteList = (id_list) => {
    const data = { id_list: id_list };
    fetch("/api/delete_list", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.log(JSON.stringify(res)));
  };

  console.log(global.lang);

  return (
    <div>
      <div style={{ height: "69px" }}></div>
      <div
        style={
          isPC
            ? {
                display: "flex",
                justifyContent: "space-around",
                backgroundColor: "rgb(246,248,250)",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgb(246,248,250)",
              }
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              width: isPhoneV ? "100%" : "550px",
              padding: "1em",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {isPhoneV ? (
                <>
                  {" "}
                  <Typography
                    variant="h3"
                    style={{
                      color: "gray",
                      fontWeight: "300",

                      fontFamily: "Nunito, Roboto",
                      margin: "1em 1em 0em 1em",
                    }}
                    align="center"
                  >
                    {global.lang == "en" ? "🏠" : "🏠"}
                  </Typography>{" "}
                  <Typography
                    variant="h3"
                    style={{
                      color: "gray",
                      fontWeight: "300",
                      fontFamily: "Nunito, Roboto",
                      margin: "0.5em 1em 1em 1em",
                    }}
                    align="center"
                  >
                    {global.lang == "en" ? "Mine" : "Mes listes"}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h3"
                  style={{
                    color: "gray",
                    fontWeight: "300",
                    padding: "1em",
                    fontFamily: "Nunito, Roboto",
                  }}
                >
                  {global.lang == "en" ? "🏠 Mine" : "🏠 Mes listes"}
                </Typography>
              )}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="secondary"
                onClick={() => {setOpenCreaListDialog(true);}}
              >
                <AddCircleOutlineIcon fontSize="large"></AddCircleOutlineIcon>
              </IconButton>
            </div> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2em",
                zIndex: h_global_tuto_step["tuto_step"] == 100.6 || h_global_tuto_step["tuto_step"] == 0.5 ? 512:0,
              }}
            >
              <Button
                id="id_crea_list_2"
                variant="contained"
                color="secondary"
                onClick={() => setOpenCreaListDialog(true)}
              >
                {global.lang == "fr"
                  ? "créer une nouvelle liste"
                  : "create a new list"}
              </Button>
            </div>

            <div style={{ width: isPhoneV ? "100%" : "400px" }}>
              {a_lists.map((value, index) => {
                if (value[10] == 1) {
                  const link_url =
                    server_url + "u/" + url_user_name + "/" + value[9];
                  return (
                    <OneList
                      id_list={value[0]}
                      title={value[2]}
                      descr={value[8]}
                      doDelete={(x) => deleteList(x)}
                      isPublic={value[5] != "1"}
                      setPublic={(isPublic, id_list) =>
                        setPublicDB(isPublic, id_list)
                      }
                      link_url={link_url}
                      is_owner={1}
                      ind_green={value[11]}
                      ind_orange={value[12]}
                      ind_red={value[13]}
                      crea_date={value[3]}
                      type={"mine"}
                      views={value[17]}
                      followers={value[18]}
                      is_yellow={true}
                    ></OneList>
                    /* {a_lists.length - 1 != index && <Divider></Divider>} */
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              width: isPhoneV ? "100%" : "550px",
              padding: "1em",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {isPhoneV ? (
              <>
                <Typography
                  variant="h3"
                  style={{
                    color: "gray",
                    fontWeight: "300",
                    margin: "1em 1em 0 1em",
                    fontFamily: "Nunito, Roboto",
                  }}
                  align="center"
                >
                  {global.lang == "en" ? "🏫" : "🏫"}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    color: "gray",
                    fontWeight: "300",
                    margin: "0.5em 1em 1em 1em",
                    fontFamily: "Nunito, Roboto",
                  }}
                  align="center"
                >
                  {global.lang == "en" ? "I'm following" : "Listes de l'école"}
                </Typography>
              </>
            ) : (
              <Typography
                variant="h3"
                style={{
                  color: "gray",
                  fontWeight: "300",
                  padding: "1em",
                  fontFamily: "Nunito, Roboto",
                }}
              >
                {global.lang == "en"
                  ? "🏫 I'm following"
                  : "🏫 Listes de l'école"}
              </Typography>
            )}
            <div
              style={h_global_tuto_step["tuto_step"] == 100.5 ?{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2em",
                zIndex: 512
              }:{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2em",
              }}
            >
              <Button
                id="id_add_list_2"
                variant="contained"
                color="primary"
                onClick={() => setOpenAddListDialog(true)}
              >
                {global.lang == "fr"
                  ? "ajouter une liste existante"
                  : "add an existing list"}
              </Button>
            </div>

            <div
              style={{
                width: isPhoneV ? "100%" : "400px",
                marginBottom: "4em",
              }}
            >
              {h_redux_add_list["id_list_sup3"] != 0 &&
                (() => {
                  const list_meta_sup = h_redux_add_list["list_meta_sup3"];
                  const link_url =
                    server_url +
                    "a/" +
                    list_meta_sup[20] +
                    "/" +
                    list_meta_sup[9];

                  return (
                    <>
                      {h_redux_add_list["id_list_sup3"].length == 0 ? (
                        <CircularProgress></CircularProgress>
                      ) : (
                        <OneList
                          id_list={list_meta_sup[0]}
                          title={list_meta_sup[2]}
                          descr={list_meta_sup[8]}
                          link_url={link_url}
                          is_owner={0}
                          new_number={list_meta_sup[21]}
                          ind_green={list_meta_sup[11]}
                          ind_orange={list_meta_sup[12]}
                          ind_red={list_meta_sup[13]}
                          crea_date={list_meta_sup[3]}
                          url_pseudo={list_meta_sup[20]}
                          id_creator={list_meta_sup[22]}
                          pseudo={list_meta_sup[23]}
                          type={"following"}
                        ></OneList>
                      )}
                    </>
                  );
                })()}
              {h_redux_add_list["id_list_sup2"] != 0 &&
                (() => {
                  const list_meta_sup = h_redux_add_list["list_meta_sup2"];
                  const link_url =
                    server_url +
                    "a/" +
                    list_meta_sup[20] +
                    "/" +
                    list_meta_sup[9];

                  return (
                    <>
                      {h_redux_add_list["id_list_sup2"].length == 0 ? (
                        <CircularProgress></CircularProgress>
                      ) : (
                        <OneList
                          id_list={list_meta_sup[0]}
                          title={list_meta_sup[2]}
                          descr={list_meta_sup[8]}
                          link_url={link_url}
                          is_owner={0}
                          new_number={list_meta_sup[21]}
                          ind_green={list_meta_sup[11]}
                          ind_orange={list_meta_sup[12]}
                          ind_red={list_meta_sup[13]}
                          crea_date={list_meta_sup[3]}
                          url_pseudo={list_meta_sup[20]}
                          id_creator={list_meta_sup[22]}
                          pseudo={list_meta_sup[23]}
                          type={"following"}
                        ></OneList>
                      )}
                    </>
                  );
                })()}
              {h_redux_add_list["id_list_sup1"] != 0 &&
                (() => {
                  const list_meta_sup = h_redux_add_list["list_meta_sup1"];
                  const link_url =
                    server_url +
                    "a/" +
                    list_meta_sup[20] +
                    "/" +
                    list_meta_sup[9];

                  return (
                    <>
                      {h_redux_add_list["id_list_sup1"].length == 0 ? (
                        <CircularProgress></CircularProgress>
                      ) : (
                        <OneList
                          id_list={list_meta_sup[0]}
                          title={list_meta_sup[2]}
                          descr={list_meta_sup[8]}
                          link_url={link_url}
                          is_owner={0}
                          new_number={list_meta_sup[21]}
                          ind_green={list_meta_sup[11]}
                          ind_orange={list_meta_sup[12]}
                          ind_red={list_meta_sup[13]}
                          crea_date={list_meta_sup[3]}
                          url_pseudo={list_meta_sup[20]}
                          id_creator={list_meta_sup[22]}
                          pseudo={list_meta_sup[23]}
                          type={"following"}
                        ></OneList>
                      )}
                    </>
                  );
                })()}

              {a_a_lists.map((value, index) => {
                const link_url = server_url + "a/" + value[19] + "/" + value[9];
                console.log(value);
                return (
                  <OneList
                    id_list={value[0]}
                    title={value[2]}
                    descr={value[8]}
                    link_url={link_url}
                    is_owner={0}
                    new_number={value[20]}
                    ind_green={value[11]}
                    ind_orange={value[12]}
                    ind_red={value[13]}
                    crea_date={value[3]}
                    url_pseudo={value[19]}
                    id_creator={value[21]}
                    pseudo={value[22]}
                    type={"following"}
                  ></OneList>
                  /*   {a_a_lists.length - 1 != index && <Divider></Divider>} */
                );
              })}
            </div>
          </div>
        </div>
        {/*  UNCOMMENT TO DISPLAY LAST VISITED LISTS
       
       {a_v_lists.length != 0 && (
          <div style={{ width: "400px", padding: "1em" }}>
            <Typography
              variant="h3"
              style={{ color: "gray", fontWeight: "300", padding: "1em" }}
            >
              {global.lang == "en" ? "Last visited" : "Dernières visites"}
            </Typography>
            <Paper style={{ width: "400px" }}>
              {a_v_lists.map((value, index) => {
                const link_url = server_url + "p/" + value[19] + "/" + value[9];
                console.log(value);
                return (
                  <>
                    <OneList
                      id_list={value[0]}
                      title={value[2]}
                      descr={value[8]}
                      link_url={link_url}
                      is_owner={0}
                      url_pseudo={value[19]}
                      id_creator={value[21]}
                      pseudo={value[22]}
                      crea_date={value[23]}
                      type={"visited"}
                    ></OneList>
                    {a_a_lists.length - 1 != index && <Divider></Divider>}
                  </>
                );
              })} 
            </Paper>
          </div>
        )}*/}
      </div>

      <ChoiceTutoDialog
        open_sot={open_sot}
        setOpenSOT={setOpenSOT}
      ></ChoiceTutoDialog>

      <CreaListDialog
        open_crea_list={openCreaListDialog}
        setOpenCreaList={setOpenCreaListDialog}
        /* url_pseudo={url_user_name} */
      ></CreaListDialog>

      <AddListDialog
        open_add_list={openAddListDialog}
        setOpenAddList={setOpenAddListDialog}
        /* addListMeta={(id_a_list)=>{addListMeta(id_a_list)}} */
        /* url_pseudo={url_user_name} */
      ></AddListDialog>
      {h_global_tuto_step["tuto_step"] == 0.5 && isPC && (
        <>
          <div
            style={{
              zIndex: 501,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                maxWidth: "760px",
                marginRight:is1500 ?"4em": "1em"
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "2em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "3em",
                      marginTop: "2em",
                    }}
                  >
                    <Typography variant="h3" color="textPrimary">
                      {global.lang == "en"
                        ? "Let's create a list in 4 steps."
                        : "Créons ensemble une liste de connaissances."}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "48px",
                        marginLeft: "0.5em",
                      }}
                    >
                      🔧
                    </div>
                  </div>

                  <div style={{ margin: "1em 1em 2em 1em" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1em",
                        marginBottom: "1em",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{ display: "inline-block" }}
                      >
                        {global.lang == "en"
                          ? "1) First click on the \"create a list\" button."
                          : "Cliquer sur le bouton \"créer une liste\"."}
                        <Typography
                          variant="h5"
                          color="red"
                          style={{ display: "inline-block" }}
                        >
                          {global.lang == "en"
                            ? "You can then enter a name for the list."
                            : "Vous pourrez alors entrer un nom pour la liste."}
                        </Typography>
                        {global.lang == "en" && " button"}
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: "4em",

                      marginTop: "1em",
                    }}
                  >
                    <Link
                      onClick={() => manageTutoStep(0)}
                      style={{ cursor: "pointer" }}
                    >
                      {global.lang == "fr" ? "non merci" : "dismiss"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="id_tototodel"
            style={{
              animation: "mymove 1s infinite",
              animationDirection: "alternate",
              position: "absolute",
              zIndex: 510,
              top: h_global_tuto_step["top_arrow"] + "px",
              left: h_global_tuto_step["left_arrow"] - 20 + "px",
              width: "10px",
              height: "10px",
            }}
          >
            <img src="im/red_arrow.png"></img>
          </div>
        </>
      )}

      {h_global_tuto_step["tuto_step"] == 100.5 && isPC && (
        <>
          <div
            style={{
              zIndex: 501,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              display: "flex",
              alignItems: "center",
              
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                maxWidth: "760px",
                marginLeft:is1500 ?"4em": "1em"
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "2em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "3em",
                    }}
                  >
                    <Typography variant="h3" color="textPrimary">
                      {global.lang == "en"
                        ? "Here you can add a list with the code the teacher gave you"
                        : "Ici tu peux ajouter une liste avec le code que le ou la professeur t'a donné"}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "48px",
                        margin:"1em",
                        transform:"scale(2)",
                      }}
                    >
                      👩🏽‍🏫
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent:"space-around",
                      marginLeft: "4em",

                      marginTop: "1em",
                    }}
                  >
                    <Link
                      onClick={() => manageTutoStep(0)}
                      style={{ cursor: "pointer" }}
                    >
                      {global.lang == "fr" ? "non merci" : "dismiss"}
                    </Link>
                    <Button
                        onClick={() => manageTutoStep(100.6)}
                        variant="contained"
                        color="secondary"
                        style={{transform:"scale(2"}}
                      >
                        {global.lang == "en" ? "next" : "suivant"}
                      </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="id_tototodel"
            style={{
              animation: "mymove 1s infinite",
              animationDirection: "alternate",
              position: "absolute",
              zIndex: 510,
              top: h_global_tuto_step["top_arrow"] + "px",
              left: h_global_tuto_step["left_arrow"] -20+ "px",
              width: "10px",
              height: "10px",
            }}
          >
            <img src="im/red_arrow.png"></img>
          </div>
        </>
      )}

    {h_global_tuto_step["tuto_step"] == 100.6 && isPC && (
        <>
          <div
            style={{
              zIndex: 501,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                maxWidth: "760px",
                marginRight:is1500 ?"4em": "1em"
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "2em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "3em",
                    }}
                  >
                    <Typography variant="h3" color="textPrimary">
                      {global.lang == "en"
                        ? "Here you can create your own list, maybe for your spanish vocabulary?"
                        : "Ici tu peux créer tes propres listes, par exemple pour ton vocabulaire d'anglais."}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "48px",
                        margin: "1em",
                        transform: "scale(2)",
                      }}
                    >
                      🔧
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent:"space-around",
                      marginLeft: "4em",

                      marginTop: "1em",
                    }}
                  >
                    <Button
                        onClick={() => manageTutoStep(0)}
                        variant="contained"
                        color="secondary"
                        style={{transform:"scale(2"}}
                      >
                        {global.lang == "en" ? "ok got it !" : "ok j'ai compris !"}
                      </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="id_tototodel"
            style={{
              animation: "mymove 1s infinite",
              animationDirection: "alternate",
              position: "absolute",
              zIndex: 510,
              top: h_global_tuto_step["top_arrow"] + "px",
              left: h_global_tuto_step["left_arrow"] -20 + "px",
              width: "10px",
              height: "10px",
            }}
          >
            <img src="im/red_arrow.png"></img>
          </div>
        </>
      )}
    </div>
  );
}

const oneListStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: "0.2em 1em",
    cursor: "pointer",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow:
        "0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
  yellow_hover: {
    backgroundColor: "rgb(255,244,221)",
    cursor: "pointer",
    "&:hover": {
      background: "rgb(247, 234, 205)",
    },
  },
  white_hover: {
    backgroundColor: "white",
    cursor: "pointer",
    "&:hover": {
      background: "whitesmoke",
    },
  },
}));

function OneList({
  id_list,
  title,
  descr,
  doDelete,
  isPublic,
  setPublic,
  link_url,
  is_owner,
  new_number = 0,
  ind_green = -1,
  ind_orange,
  ind_red,
  crea_date = -1,
  id_creator = -1,
  url_pseudo = "",
  pseudo = "",
  // type = "mine", "following", "visited"
  type,
  views,
  followers,
  is_yellow = false,
}) {
  const [isPublicIn, setIsPublicIn] = useState(isPublic);
  const [open_del_diag, setOpenDelDiag] = useState(false);
  const [open_pub_diag, setOpenPubDiag] = useState(false);
  const [open_priv_diag, setOpenPrivDiag] = useState(false);
  const isPC = useMediaQuery({ query: "(min-width: 700px)" });

  const h_redux_wall = useSelector((state) => state.global_wall);
  console.log(h_redux_wall);
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const dispatch = useDispatch();

  let curr_time = Math.round(new Date().getTime() / 1000);

  useEffect(() => {
    if (
      id_creator != -1 &&
      Object.keys(h_redux_wall["h_users"]).indexOf(id_creator) == -1
    ) {
      fetch(server_url + "/api/get_user_meta", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ id_user: id_creator }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetch(server_url + "/api/get_profil_picture", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({ url_pseudo: url_pseudo }),
          })
            .then((res_bis) => res_bis.json())
            .then((res_bis) => {
              res.push(res_bis["url"]);
              /* setUrlAvat(res_bis["url"]); */
              console.log(id_creator);
              console.log(res);
              console.log(res_bis);
              dispatch(
                setReduxUser({
                  id_user: id_creator,
                  row: res,
                })
              );
              console.log(res);
            });
        });
    }
  }, []);

  const classes = oneListStyle();

  return (
    <Paper
      className={type == "mine" ? classes.yellow_hover : classes.white_hover}
      style={{ margin: "1em" }}
    >
      {/* <div className={type == "mine" ? classes.yellow_hover: classes.white_hover} style={{borderRadius:"16px", margin:"1em"}}></div> */}
      <div
        style={
          is_yellow
            ? {
                display: "flex",
                padding: "1em",
                justifyContent: "space-between",
              }
            : {
                display: "flex",
                padding: "1em",
                justifyContent: "space-between",
              }
        }
        onClick={() => (window.location.href = link_url)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: "600", fontSize: "16px" }}>
            <Badge color="secondary" badgeContent={new_number}>
              <Link
                href={link_url}
                /* style={{ marginRight: "0.5em", fontSize: "larger" }} */
                style={
                  isPC ? { fontSize: "x-large" } : { fontSize: "xx-large" }
                }
              >
                {title}
              </Link>
            </Badge>
          </div>
          {type == "mine" && (
            <div
              style={{
                display: "flex",
                marginTop: "0.2em",
                color: "rgb(156,158,160)",
              }}
            >
              <EventIcon fontSize="small"></EventIcon>
              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {global.lang == "en" && "Created "}
                {global.lang == "fr" && "Créée le "}
                {unixToShortHuman(crea_date)}
              </div>
              {isPublicIn && (
                <div style={{ display: "flex" }}>
                  <div style={{ paddingLeft: "0.6em" }}>
                    <VisibilityIcon fontSize="small"></VisibilityIcon>
                  </div>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {views}
                  </div>
                  <div style={{ paddingLeft: "0.6em" }}>
                    <GroupIcon fontSize="small"></GroupIcon>
                  </div>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {followers}
                  </div>
                </div>
              )}
            </div>
          )}
          {type == "following" && (
            <div
              style={{
                display: "flex",
                marginTop: "0.2em",
                color: "rgb(156,158,160)",
              }}
            >
              <EventIcon fontSize="small"></EventIcon>
              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {global.lang == "en" && "Followed since "}
                {global.lang == "fr" && "Suivie depuis "}
                {unixToShortHuman(crea_date)}
              </div>
            </div>
          )}
          {type == "visited" && (
            <div
              style={{
                fontWeight: 600,
                color: "rgb(156,158,160)",
                fontSize: "14px",
              }}
            >
              {global.lang == "fr" && "Il y a "}
              {unixToDelay(curr_time - crea_date)}
              {global.lang == "en" && " Ago"}
            </div>
          )}
        </div>
        <div>
          {/*           {!isPublicIn && is_owner == 1 && (
            <span>
              <IconButton
                color="secondary"
                aria-label={
                  global.lang == "en" ? "set private" : "rendre privé"
                }
              >
                <LockIcon />
              </IconButton>
              <IconButton
                aria-label={
                  global.lang == "en" ? "set public" : "rendre public"
                }
                onClick={() => {
                  setOpenPubDiag(true);
                }}
              >
                <PublicIcon />
              </IconButton>
            </span>
          )}

          {isPublicIn && is_owner == 1 && (
            <span>
              <IconButton
                aria-label={
                  global.lang == "en" ? "set private" : "rendre privé"
                }
                onClick={() => {
                  setOpenPrivDiag(true);
                }}
              >
                <LockIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label={
                  global.lang == "en" ? "set public" : "rendre public"
                }
              >
                <PublicIcon />
              </IconButton>
            </span>
          )} */}
        </div>
      </div>

      <div
        style={
          type == "mine"
            ? {
                display: "flex",
                justifyContent: "space-between",
              }
            : {
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1em",
              }
        }
      >
        <div
          style={{ marginLeft: "1em", display: "flex", alignItems: "center" }}
          onClick={() => (window.location.href = link_url)}
        >
          {ind_green != -1 && (
            <ScoreChips
              green_score={ind_green}
              orange_score={ind_orange}
              red_score={ind_red}
            ></ScoreChips>
          )}
        </div>
        <div
          style={{ width: "100%" }}
          onClick={() => (window.location.href = link_url)}
        ></div>

        <div style={{ marginRight: "1em" }}>
          {type == "mine" && (
            <IconButton
              aria-label={
                global.lang == "en" ? "delete list" : "supprimer la liste"
              }
              style={{ transform: "scale(0.7)" }}
              onClick={() => setOpenDelDiag(true)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {id_creator != -1 &&
            typeof h_redux_wall["h_users"][id_creator] != "undefined" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href =
                    server_url + "e/" + h_redux_wall["h_users"][id_creator][8];
                }}
              >
                <Avatar
                  variant="contained"
                  color="primary"
                  src={h_redux_wall["h_users"][id_creator][14]}
                  className={classes.avatar}
                  /*                   onClick={() => {
                    window.location.href =
                      server_url +
                      "e/" +
                      h_redux_wall["h_users"][id_creator][8];
                  }} */
                  href={
                    server_url + "e/" + h_redux_wall["h_users"][id_creator][8]
                  }
                ></Avatar>
                <div
                  style={{ fontWeight: 600, color: "gray", fontSize: "14px" }}
                >
                  {pseudo}
                </div>
              </div>
            )}
        </div>
      </div>

      <Dialog open={open_del_diag} onClose={() => setOpenDelDiag(false)}>
        <Typography variant="h4" style={{ padding: "1em 1em 0 1em" }}>
          {global.lang == "en" && "Confirm you want to delete this list"}
          {global.lang == "fr" && "Voulez-vous vraiment supprimer cette liste?"}
        </Typography>
        <DialogActions style={{ padding: "2em" }}>
          <Button
            onClick={() => setOpenDelDiag(false)}
            color="primary"
            style={{ marginRight: "2em" }}
          >
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Non"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              doDelete(id_list);
              setOpenDelDiag(false);
              window.location.reload();
            }}
            color="primary"
            style={{ marginRight: "1em" }}
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "Oui"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={open_pub_diag} onClose={() => setOpenPubDiag(false)}>
        <DialogTitle>
          {global.lang == "en" && "Set public access to your list?"}
          {global.lang == "fr" && "Rendre votre liste publique?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPubDiag(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsPublicIn(true);
              setPublic(true, id_list);
              setOpenPubDiag(false);
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "ok"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open_priv_diag} onClose={() => setOpenPrivDiag(false)}>
        <DialogTitle>
          {global.lang == "en" && "Public won't have access to your list"}
          {global.lang == "fr" && "Le public n'aura plus accès à la liste"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPrivDiag(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsPublicIn(false);
              setPublic(false, id_list);
              setOpenPrivDiag(false);
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "ok"}
          </Button>
        </DialogActions>
      </Dialog> */}
    </Paper>
  );
}
