import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { SignIn, SignUp } from "../HomeNoSession/HomeNoSession";
import { doFollowDB, unFollowDB } from "../Generic/lists";
import { unixToVeryShortHuman } from "../Generic/generic";
import { Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import GroupIcon from "@material-ui/icons/Group";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import { Paper } from "@material-ui/core";
import { UserReport } from "../MainComponents/CWall";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { SignUpG } from "../HomeNoSession/HomeNoSession";
import { signInGoogle } from "../Generic/google_signin";
import { GoogleLogin } from "react-google-login";
import { SignUpSmallFull } from "../HomeNoSession/HomeNoSession";

const avatStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    cursor: "pointer",
    marginRight: "1em",
    marginLeft: "1em",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow:
        "0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const shortAvatStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
    marginRight: "0.8em",
    marginLeft: "0.8em",
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow:
        "0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

export function MetPresentation({ a_meta, is_logged }) {
  console.log(a_meta);

  return (
    <UPresentation
      id_user={a_meta[0]}
      user_pseudo={a_meta[2]}
      user_url={a_meta[8]}
      descr={a_meta[9]}
      followers={a_meta[10]}
      i_follow_number={a_meta[11]}
      is_logged={is_logged}
      crea_date={a_meta[6]}
    ></UPresentation>
  );
}

export function RMetPresentation({ a_meta, is_logged }) {
  console.log(a_meta);

  return (
    <RUPresentation
      id_user={a_meta[0]}
      user_pseudo={a_meta[2]}
      user_url={a_meta[8]}
      descr={a_meta[9]}
      followers={a_meta[10]}
      i_follow_number={a_meta[11]}
      is_logged={is_logged}
      crea_date={a_meta[6]}
    ></RUPresentation>
  );
}

export function BigMetPresentation({ a_meta, is_logged }) {
  console.log(a_meta);

  return (
    <BigUPresentation
      id_user={a_meta[0]}
      user_pseudo={a_meta[2]}
      user_url={a_meta[8]}
      descr={a_meta[9]}
      followers={a_meta[10]}
      i_follow_number={a_meta[11]}
      is_logged={is_logged}
      crea_date={a_meta[6]}
    ></BigUPresentation>
  );
}

export default function UPresentation({
  id_user,
  user_pseudo,
  user_url,
  descr,
  followers,
  i_follow_number,
  crea_date,
  is_logged,
  width_break = 1420,
}) {
  const [is_a_i_follow, setIFollow] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [a_networks, setNetworks] = useState([]);
  const [pic_url, setPicUrl] = useState();

  const [is_expended, setIsExpended] = useState(false);

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => {
        window.location.reload();
      },
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };
  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  useEffect(() => {
    fetch(server_url + "/api/is_a_i_follow", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: id_user }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setIFollow(res["count"] == 1);
      });

    const data = { id_user: id_user };
    console.log(data);
    fetch(server_url + "/api/get_user_networks", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setNetworks(res);
        console.log(res);
      });

    fetch(server_url + "/api/get_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: user_url }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPicUrl(res["url"]);
        console.log(res);
      });
    if (window.innerWidth < width_break) {
      setIsExpended(false);
    } else {
      setIsExpended(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < width_break) {
        setIsExpended(false);
      } else {
        setIsExpended(true);
      }
    });
  }, []);

  const classes = avatStyle();

  return (
    <>
      {is_expended && (
        <div
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
            paddingLeft: "1em",
            minWidth: "380px",
            maxWidth: "400px",
          }}
        >
          <Paper style={{ paddingRight: "1em", paddingTop: "1em" }}>
            <div style={{ display: "flex" }}>
              <div>
                {pic_url != "" && (
                  <Avatar
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href = server_url + "e/" + user_url;
                    }}
                    alt={user_pseudo}
                    src={pic_url}
                    className={classes.avatar}
                  ></Avatar>
                )}
                <div
                  style={{
                    padding: "2em",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <IconButton onClick={() => setIsExpended(false)}>
                    <ChevronLeftIcon></ChevronLeftIcon>
                  </IconButton>
                </div>
              </div>

              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h5" color="textPrimary">
                    {user_pseudo}
                  </Typography>
                  <UserReport id_user={id_user}></UserReport>
                </div>
                <Typography variant="body2" color="textSecondary">
                  {descr}
                </Typography>
                <div style={{ display: "flex" }}>
                  {a_networks.map((e, i) => {
                    if (e[1] != "") {
                      return (
                        <span>
                          <SocialNetwork
                            network={e[1]}
                            url={e[3]}
                          ></SocialNetwork>
                        </span>
                      );
                    }
                  })}
                </div>

                <div style={{ display: "flex" }}>
                  <IconButton disabled>
                    <EventIcon style={{ color: "gray" }}></EventIcon>
                  </IconButton>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" color="textSecondary">
                      {global.lang == "en" && "Joined "}
                      {global.lang == "fr" && "Depuis "}
                      {unixToVeryShortHuman(crea_date)}
                    </Typography>
                  </div>

                  <div style={{ display: "flex" }}>
                    <IconButton disabled>
                      <GroupIcon style={{ color: "gray" }}></GroupIcon>
                    </IconButton>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ paddingLeft: "0.4em", paddingTop: "0.17em" }}
                      >
                        {followers}
                      </Typography>
                    </div>
                  </div>
                </div>

                {typeof is_a_i_follow != "undefined" && (
                  <>
                    {!is_a_i_follow && (
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: "1em" }}
                        onClick={() => {
                          console.log("follow click 1");
                          console.log(is_logged);
                          if (is_logged == "1") {
                            doFollowDB(id_user);
                            setIFollow(true);
                          } else {
                            setOpenDialog(true);
                          }
                        }}
                      >
                        {global.lang == "en" ? "follow " : "suivre "}{" "}
                        {user_pseudo}
                      </Button>
                    )}
                    {is_a_i_follow && (
                      <Button
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={() => {
                          unFollowDB(id_user);
                          setIFollow(false);
                        }}
                      >
                        {global.lang == "en" ? "unfollow" : "ne plus suivre"}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </Paper>
        </div>
      )}

      {!is_expended && (
        <div
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
            minWidth: "130px",
            maxWidth: "150px",
          }}
        >
          <Paper
            style={{
              paddingTop: "1em",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                {pic_url != "" && (
                  <Avatar
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href = server_url + "e/" + user_url;
                    }}
                    alt={user_pseudo}
                    src={pic_url}
                    className={classes.avatar}
                  ></Avatar>
                )}
                <div
                  style={{
                    padding: "2em",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <IconButton onClick={() => setIsExpended(true)}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      )}

      {openDialog && (
        <SignUpSmallFull
          setClose={() => setOpenDialog(false)}
          callbackType="follow"
          callback={() => doFollowDB(id_user)}
        ></SignUpSmallFull>
      )}
    </>
  );
}

export function RUPresentation({
  id_user,
  user_pseudo,
  user_url,
  descr,
  followers,
  i_follow_number,
  crea_date,
  is_logged,
  width_break = 1420,
}) {
  const [is_a_i_follow, setIFollow] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [a_networks, setNetworks] = useState([]);
  const [pic_url, setPicUrl] = useState();

  const [is_expended, setIsExpended] = useState(false);

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => {
        window.location.reload();
      },
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };
  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  useEffect(() => {
    fetch(server_url + "/api/is_a_i_follow", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: id_user }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setIFollow(res["count"] == 1);
      });

    const data = { id_user: id_user };
    console.log(data);
    fetch(server_url + "/api/get_user_networks", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setNetworks(res);
        console.log(res);
      });

    fetch(server_url + "/api/get_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: user_url }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPicUrl(res["url"]);
        console.log(res);
      });
    if (window.innerWidth < width_break) {
      setIsExpended(false);
    } else {
      setIsExpended(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < width_break) {
        setIsExpended(false);
      } else {
        setIsExpended(true);
      }
    });
  }, []);

  const classes = shortAvatStyle();

  return (
    <>
      {is_expended && (
        <div
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
            paddingLeft: "1em",
            minWidth: "200px",
            maxWidth: "258px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <div>
                {pic_url != "" && (
                  <Avatar
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href = server_url + "e/" + user_url;
                    }}
                    alt={user_pseudo}
                    src={pic_url}
                    className={classes.avatar}
                  ></Avatar>
                )}
              </div>

              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h5" color="textPrimary">
                    {user_pseudo}
                  </Typography>
                </div>
                <div>
                  <div>
                    <Typography variant="body2" color="textSecondary">
                      {descr}
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <IconButton disabled>
                      <EventIcon style={{ color: "gray" }}></EventIcon>
                    </IconButton>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" color="textSecondary">
                        {global.lang == "en" && "Joined "}
                        {global.lang == "fr" && "Depuis "}
                        {unixToVeryShortHuman(crea_date)}
                      </Typography>
                    </div>

                   {/*  <div style={{ display: "flex" }}>
                      <IconButton disabled>
                        <GroupIcon style={{ color: "gray" }}></GroupIcon>
                      </IconButton>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ paddingLeft: "0.4em", paddingTop: "0.17em" }}
                        >
                          {followers}
                        </Typography>
                      </div>
                    </div> */}
                  </div>

                  {/*  {typeof is_a_i_follow != "undefined" && (
                <>
                  {!is_a_i_follow && (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ margin: "1em" }}
                      onClick={() => {
                        console.log("follow click 1");
                        console.log(is_logged);
                        if (is_logged == "1") {
                          doFollowDB(id_user);
                          setIFollow(true);
                        } else {
                          setOpenDialog(true);
                        }
                      }}
                    >
                      {global.lang == "en" ? "follow " : "suivre "}{" "}
                      {user_pseudo}
                    </Button>
                  )}
                  {is_a_i_follow && (
                    <Button
                      variant="contained"
                      style={{ margin: "1em" }}
                      onClick={() => {
                        unFollowDB(id_user);
                        setIFollow(false);
                      }}
                    >
                      {global.lang == "en" ? "unfollow" : "ne plus suivre"}
                    </Button>
                  )}
                </>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!is_expended && (
        <div
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
            minWidth: "130px",
            marginRight: "1em",
            marginLeft: "1em",
            /* maxWidth: "150px", */
          }}
        >
          <Paper
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {pic_url != "" && (
              <Avatar
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={() => {
                  window.location.href = server_url + "e/" + user_url;
                }}
                alt={user_pseudo}
                src={pic_url}
                className={classes.avatar}
              ></Avatar>
            )}
            <IconButton onClick={() => setIsExpended(true)}>
              <ChevronRightIcon></ChevronRightIcon>
            </IconButton>
          </Paper>
        </div>
      )}

      {openDialog && (
        <SignUpSmallFull
          setClose={() => setOpenDialog(false)}
          callbackType="follow"
          callback={() => doFollowDB(id_user)}
        ></SignUpSmallFull>
      )}
    </>
  );
}

export function BigUPresentation({
  id_user,
  user_pseudo,
  user_url,
  descr,
  followers,
  i_follow_number,
  crea_date,
  is_logged,
}) {
  const [is_a_i_follow, setIFollow] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [a_networks, setNetworks] = useState([]);
  const [pic_url, setPicUrl] = useState();

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const [openUpGDialog, setOpenUpGDialog] = useState(false);
  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => {
        window.location.reload();
      },
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };
  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  useEffect(() => {
    fetch(server_url + "/api/is_a_i_follow", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: id_user }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setIFollow(res["count"] == 1);
      });

    const data = { id_user: id_user };
    console.log(data);
    fetch(server_url + "/api/get_user_networks", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setNetworks(res);
        console.log(res);
      });

    fetch(server_url + "/api/get_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: user_url }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPicUrl(res["url"]);
        console.log(res);
      });
  }, []);

  const classes = avatStyle();

  return (
    <div
      style={{
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "1em",
        minWidth: "380px",
        maxWidth: "400px",
      }}
    >
      <Paper style={{ padding: "2em" }}>
        <div style={{ display: "flex" }}>
          {/*           {pic_url != "" && (
            <Avatar
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = server_url + "e/" + user_url;
              }}
              alt={user_pseudo}
              src={pic_url}
              className={classes.avatar}
            ></Avatar>
          )} */}

          <div>
            {
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <img
                  src={pic_url}
                  style={{
                    border: "solid rgba(0,0,0,0.48)",
                    borderRadius: "128px",
                    maxWidth: "256px",
                    maxHeight: "256px",
                    fontSize: "large",
                    padding: "4px",
                  }}
                ></img>
              </div>
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h3"
                color="textPrimary"
                style={{ marginTop: "1em" }}
              >
                {user_pseudo}
              </Typography>
              <UserReport id_user={id_user}></UserReport>
            </div>
            <Typography variant="body1" color="textSecondary">
              {descr}
            </Typography>
            <div style={{ display: "flex" }}>
              {a_networks.map((e, i) => {
                if (e[1] != "") {
                  return (
                    <BigSocialNetwork
                      network={e[1]}
                      url={e[3]}
                    ></BigSocialNetwork>
                  );
                }
              })}
            </div>

            <div style={{ display: "flex" }}>
              <IconButton disabled>
                <EventIcon style={{ color: "gray" }}></EventIcon>
              </IconButton>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="textSecondary">
                  {global.lang == "en" && "Joined "}
                  {global.lang == "fr" && "Depuis "}
                  {unixToVeryShortHuman(crea_date)}
                </Typography>
              </div>

              <div style={{ display: "flex" }}>
                <IconButton disabled>
                  <GroupIcon style={{ color: "gray" }}></GroupIcon>
                </IconButton>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ paddingLeft: "0.4em", paddingTop: "0.17em" }}
                  >
                    {followers}
                  </Typography>
                </div>
              </div>
            </div>

            {typeof is_a_i_follow != "undefined" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: "1em",
                }}
              >
                {!is_a_i_follow && (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: "1em" }}
                    onClick={() => {
                      if (is_logged == "1") {
                        doFollowDB(id_user);
                        setIFollow(true);
                      } else {
                        setOpenDialog(true);
                      }
                    }}
                  >
                    {global.lang == "en" ? "follow " : "suivre "} {user_pseudo}
                  </Button>
                )}
                {is_a_i_follow && (
                  <Button
                    variant="contained"
                    style={{ margin: "1em" }}
                    onClick={() => {
                      unFollowDB(id_user);
                      setIFollow(false);
                    }}
                  >
                    {global.lang == "en" ? "unfollow" : "ne plus suivre"}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {openDialog && (
          <SignUpSmallFull
            setClose={() => setOpenDialog(false)}
            callbackType="follow"
            callback={() => doFollowDB(id_user)}
          ></SignUpSmallFull>
        )}
      </Paper>
    </div>
  );
}

function SocialNetwork({ network, url }) {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const icon_url =
    server_url + "static/im/networks_icons/cb1_" + network + ".svg";

  return (
    <span>
      <img
        src={icon_url}
        style={{
          width: "18px",
          height: "18px",
          color: "gray",
          margin: "0.3em",
          cursor: "pointer",
          opacity: "0.6",
        }}
        onClick={() => {
          window.open(url, "_blank");
        }}
        target="_blank"
      />
    </span>
  );
}

function BigSocialNetwork({ network, url }) {
  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const icon_url =
    server_url + "static/im/networks_icons/cb1_" + network + ".svg";

  return (
    <span>
      <img
        src={icon_url}
        style={{
          width: "24px",
          height: "24px",
          color: "gray",
          margin: "0.3em",
          cursor: "pointer",
          opacity: "0.6",
        }}
        onClick={() => {
          window.open(url, "_blank");
        }}
        target="_blank"
      />
    </span>
  );
}
