import { createSlice } from "@reduxjs/toolkit";

export const wallSlice = createSlice({
  name: "global_wall",
  initialState: {
    h_users: {},
    h_pages: {},
    h_lists: {},
    h_questions: {},
  },
  reducers: {
    setReduxUser: (state, action) => {
      state['h_users'][action.payload.id_user] = action.payload.row;
    },
    setReduxPage: (state, action) => {
        state['h_pages'][action.payload.id_page] = action.payload.row;
    },
    setReduxList: (state, action) => {
        state['h_lists'][action.payload.id_list] = action.payload.row;
    },
    setReduxQuestion: (state, action) => {
      state['h_questions'][action.payload.id_question] = action.payload.row;
  },
  },
});

export const { setReduxUser, setReduxPage, setReduxList, setReduxQuestion } = wallSlice.actions;

export default wallSlice.reducer;
