import { createSlice } from "@reduxjs/toolkit";

export const listsSlice = createSlice({
  name: "global_lists",
  initialState: {
    a_own_lists: [],
    h_lists: {},
  },
  reducers: {
    setReduxListsMeta: (state, action) => {
        let h_lists = {}
        let a_own_lists = []
        action.payload.a_lists_meta.map((row)=>{
            h_lists[row[0]] = row;
            console.log(row);
            if (row[10]==1 && row[4]==1){
                a_own_lists.push(row);
            }
        });
      state['h_lists'] = h_lists;
      state['a_own_lists'] = a_own_lists;
      console.log(state['a_own_lists']);
      console.log(state['h_lists']);
    },
    addReduxListMeta: (state, action) => {
        state['h_lists'][action.payload.row[0]] = action.payload.row;
    },
    removeReduxListMeta: (state, action) => {
        delete state['h_lists'][action.payload.id_list];
        let splice_index = -1;
        let count = 0;
        state['a_own_lists'].map((row)=>{
            if ( row[0] == action.payload.id_list ){
                splice_index = count;
            }
            count++;
        });
        if (splice_index!=-1){
            state['a_own_lists'].splice(splice_index,1);
        }
    },
  },
});

export const { setReduxListsMeta, addReduxListMeta, removeReduxListMeta } = listsSlice.actions;

export default listsSlice.reducer;
