import { createSlice } from "@reduxjs/toolkit";

export const addListSlice = createSlice({
  name: "global_add_list",
  initialState: {
    id_list_sup1: 0,
    list_meta_sup1: [],
    id_list_sup2: 0,
    list_meta_sup2: [],
    id_list_sup3: 0,
    list_meta_sup3: []
  },
  reducers: {
    setListSup: (state, action) => {
        if (action.payload.list_number == 1 ){
            state['id_list_sup1'] = action.payload.id_a_list;
        }
        if (action.payload.list_number == 2 ){
            state['id_list_sup2'] = action.payload.id_a_list;
        }
        if (action.payload.list_number == 3 ){
            state['id_list_sup3'] = action.payload.id_a_list;
        }
    },
    setListMetaSup: (state, action) => {
        if (action.payload.list_number == 1 ){
            state['list_meta_sup1'] = action.payload.a_list_meta;
        }
        if (action.payload.list_number == 2 ){
            state['list_meta_sup2'] = action.payload.a_list_meta;
        }
        if (action.payload.list_number == 3 ){
            state['list_meta_sup3'] = action.payload.a_list_meta;
        }
    },
  },
});

export const { setListSup, setListMetaSup } = addListSlice.actions;

export default addListSlice.reducer;
