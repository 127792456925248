import React, { useState, useEffect } from "react";
import CHeader from "../MainComponents/CHeader";
import LTrain from "../List/LTrain";
import TestGen from "../Gen/TestGen";
import { MetPresentation, RMetPresentation } from "../User/UPresentation";
import Button from "@material-ui/core/Button";
import { saveListDB, addOneVisitDB, insertListVisitDB } from "../Generic/lists";
import { ScoreBullet } from "../SmallComponents/SmallComponents";
import CMenu from "../MainComponents/CMenu";
import { LPresentation } from "../List/UList";
import { Paper } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { CreaListDialog } from "../SmallComponents/CDialog";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import Link from "@mui/material/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { setReduxIsCffooterOpen } from "../redux/globalDialog";

import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import EventIcon from "@material-ui/icons/Event";
import { unixToShortHuman } from "../Generic/generic";
import Switch from "@mui/material/Switch";

const ColorButton = withStyles((theme) => ({
  root: {
    /* color: theme.palette.getContrastText(green[500]), */
    color: "white",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

export default function PList({ url_pseudo, url_name, is_logged = "1" }) {
  const [menuStatus, setMenuStatus] = useState("0");
  const [a_questions, setQuestions] = useState([]);
  const [user_pseudo, setUserPseudo] = useState("");
  const [current_id_question, setCurrentIdQuestion] = useState(-1);
  const [list_name, setListName] = useState("");
  const [list_descr, setListDescr] = useState("");
  const [list_views, setListViews] = useState("");
  const [list_crea_date, setListCreaDate] = useState("");
  const [list_followers, setListFollowers] = useState("");
  const [url_logged_pseudo, setUrlLoggedPseudo] = useState("");

  const [a_user_meta, setUserMeta] = useState();
  const [a_list_meta, setListMeta] = useState();
  const [id_user, setIdUser] = useState(-1);
  const [id_list, setIdList] = useState(-1);

  const [open_img_dial, setOpenImgDial] = useState(false);
  const [media_800, setMedia800] = useState();

  const [open_crea_list, setOpenCreaList] = useState(false);

  const [open_test_gen, setOpenTestGen] = useState(false);
  const [arg_a_questions, setArgAQuestions] = useState([]);

  const [cont_code, setContCode] = useState("");

  const isPCS = useMediaQuery({ query: "(max-width: 1300px)" });
  const is1100 = useMediaQuery({ query: "(max-width: 1100px)" });
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const [is_title_exp, setTitleExp] = useState(false);
  const [display_answers, setDisplayAnswers] = useState(false);

  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const setQuestionsForTestGen = () => {
    var temp_a_questions = [];
    a_questions.forEach((row) => {
      console.log(row);
      temp_a_questions.push(row[3]);
    });
    setArgAQuestions(temp_a_questions);
  };

  const setCurrentScore = (score) => {
    let a_questions_copy = [...a_questions];
    a_questions_copy.forEach((row, index) => {
      if (row[0] == current_id_question) {
        a_questions_copy[index][6] = score;
      }
    });
    setQuestions(a_questions_copy);
  };

  const saveList = () => {
    let a_scores = [];
    a_questions.forEach((row, index) => {
      a_scores.push(row[6]);
    });
    saveListDB(
      id_list,
      a_scores,
      server_url + "a/" + url_pseudo + "/" + url_name
    );
  };

  const server_url = "https://" + window.location.href.split(/[/?#]/)[2] + "/";
  const url =
    server_url +
    "u/" +
    window.location.href.split(/[/?#]/)[4] +
    "/" +
    window.location.href.split(/[/?#]/)[5];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setReduxIsCffooterOpen({ is_cffooter_open: 0 }));
    fetch(server_url + "api/get_user_data")
      .then((res) => res.json())
      .then((res) => {
        setUrlLoggedPseudo(res["url_user_name"]);
        console.log(res);
      });
    const data = { url_pseudo: url_pseudo, url_name: url_name };
    console.log(data);
    fetch("/api/pv_list", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setUserMeta(res[0]);
        setListMeta(res[1]);
        setUserPseudo(res[0][2]);
        setListName(res[1][2]);
        setListDescr(res[1][8]);
        setIdList(res[1][0]);
        addOneVisitDB(res[1][0]);
        insertListVisitDB(res[1][0]);
        setIdUser(res[0][0]);

        setListViews(res[1][17]);
        setListCreaDate(res[1][3]);
        setListFollowers(res[1][18]);

        fetch(server_url + "/api/get_questions", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ id_list: res[1][0] }),
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            res.forEach((row, index) => {
              row[6] = "0";
            });
            setQuestions(res);
            console.log("get_questions");
            console.log(res);
          });

        fetch(server_url + "/api/get_cont_code", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ id_content: res[1][0], content_type: "l" }),
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            setContCode(res["id_code"]);
          });
      });
  }, []);

  return (
    <div>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={true}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {menuStatus == "1" && <CMenu></CMenu>}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: isPhoneV ? "none" : "1650px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: isPhoneV ? "100%" : "auto",
              }}
            >
              {!isPCS && (
                <div>
                  <LPresentation
                    list_name={list_name}
                    list_descr={list_descr}
                    crea_date={list_crea_date}
                    views={list_views}
                    followers={list_followers}
                    mode={"visitor"}
                    is_public={true}
                  ></LPresentation>
                  {typeof a_user_meta != "undefined" && (
                    <RMetPresentation
                      a_meta={a_user_meta}
                      is_logged={is_logged}
                    ></RMetPresentation>
                  )}
                </div>
              )}

              <div style={{ width: isPhoneV ? "100%" : "auto" }}>
                {isPCS && (
                  <div style={{ margin: "1em" }}>
                    <div
                      style={
                        isPhoneV
                          ? {
                              display: "flex",
                              justifyContent: "space-between",
                            }
                          : {
                              display: "flex",
                              justifyContent: "space-between",
                              width: "746px",
                            }
                      }
                    >
                      <div></div>
                      <div style={{ maxWidth: "500px" }}>
                        <Typography variant="h3">{list_name}</Typography>
                      </div>

                      {is_title_exp ? (
                        <div>
                          <IconButton>
                            <ExpandLessIcon
                              onClick={() => setTitleExp(false)}
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <div>
                          <IconButton>
                            <ExpandMoreIcon onClick={() => setTitleExp(true)} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    {is_title_exp && (
                      <>
                        <div style={{ padding: "0.5em", marginTop: "0.5em" }}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: "large" }}
                          >
                            {list_descr}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <IconButton disabled>
                              <EventIcon style={{ color: "gray" }}></EventIcon>
                            </IconButton>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "1em",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {global.lang == "en" && "Created "}
                                {global.lang == "fr" && "Créée "}
                                {unixToShortHuman(list_crea_date)}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        {typeof a_user_meta != "undefined" && (
                          <RMetPresentation
                            a_meta={a_user_meta}
                            is_logged={is_logged}
                          ></RMetPresentation>
                        )}
                        {is1100 && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "1em",
                              padding: "1em",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  padding: "1em",
                                }}
                              >
                                <Link href={url} underline="hover">
                                  {global.lang == "fr"
                                    ? "voir mode édition"
                                    : "go to edition mode"}
                                </Link>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <Typography variant="h3" color="textSecondary">
                                  #{cont_code.toUpperCase()}
                                </Typography>
                              </div>

                              <div
                                id="id_url_tocopy"
                                style={{
                                  display: "flex",
                                  marginLeft: "-1em",
                                  transformOrigin: "center",
                                  transform: "scale(0.7)",
                                  width: "120%",
                                  paddingBottom: "1.2em",
                                }}
                              >
                                <TextField
                                  id="id_public_url"
                                  label="URL"
                                  value={url}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <LinkIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  style={{ width: "300px" }}
                                />
                                <IconButton
                                  aria-label={
                                    global.lang == "en"
                                      ? "copy to clipboard"
                                      : "copier dans le clipboard"
                                  }
                                  onClick={() => copyURL()}
                                  style={{
                                    transform: "rotate(90deg)",
                                    fontWeight: 400,
                                  }}
                                >
                                  <FilterNoneIcon />
                                </IconButton>
                              </div>
                            </div>

                            {a_questions.length >= 10 && (
                              <ColorButton
                                variant="contained"
                                style={{
                                  marginTop: "1em",
                                  borderRadius: "20px",
                                }}
                                onClick={() => {
                                  setQuestionsForTestGen();
                                  setOpenTestGen(true);
                                }}
                              >
                                {global.lang == "en"
                                  ? "paper test"
                                  : "interrogation écrite"}
                              </ColorButton>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <LTrain
                  a_questions={a_questions}
                  setScore={(x) => setCurrentScore(x)}
                  setCurrentId={setCurrentIdQuestion}
                  is_focused={true}
                  mode={"p"}
                  saveFunction={() => saveList()}
                ></LTrain>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "2em",
                  }}
                >
                  <Typography style={{ fontSize: "x-large" }}>
                    {global.lang === "fr"
                      ? "Afficher les réponses"
                      : "Display answers"}
                  </Typography>
                  <span style={{ transform: "scale(1.6)", marginLeft: "1em" }}>
                    <Switch
                      onChange={(e) => setDisplayAnswers(e.target.checked)}
                    ></Switch>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    /* maxWidth: "1650px", */
                    backgroundColor: "rgb(246,248,250)",
                    /* minWidth: "1192px", */
                  }}
                >
                  <Paper
                    style={{
                      border: isPhoneV ? "inherit" : "solid 1px lightgray",
                      backgroundColor: isPhoneV ? "rgb(246,248,250)" : "white",
                      marginBottom: "6em",
                    }}
                    elevation={0}
                  >
                    {a_questions.map((value, index) => {
                      return (
                        <>
                          <LQuestion
                            id_question={value[0]}
                            score={value[6]}
                            statement={value[3]}
                            answer={
                              current_id_question == value[0]
                                ? "1f9e"
                                : value[4]
                            }
                            has_pic={value[2] == "i"}
                            pic_url={value[2] == "i" ? value[8] : ""}
                            pic_url_800={value[9]}
                            setOpenImgDial={setOpenImgDial}
                            setMedia800={setMedia800}
                            display_answers={display_answers}
                          ></LQuestion>
                        </>
                      );
                    })}
                  </Paper>
                </div>
              </div>

              {!is1100 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1em",
                    padding: "1em",
                    maxWidth: "220px",
                  }}
                >
                  <div
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "1em",
                      }}
                    >
                      <Link href={url} underline="hover">
                        {global.lang == "fr"
                          ? "retour mode édition"
                          : "back to edition mode"}
                      </Link>
                    </div>
                    {window.innerWidth < 1400 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography variant="h5" color="textSecondary">
                          #{cont_code.toUpperCase()}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography variant="h3" color="textSecondary">
                          #{cont_code.toUpperCase()}
                        </Typography>
                      </div>
                    )}
                    <div
                      id="id_url_tocopy"
                      style={{
                        display: "flex",
                        marginLeft: "-1em",
                        transformOrigin: "center",
                        transform: "scale(0.7)",
                        width: "120%",
                        paddingBottom: "1.2em",
                      }}
                    >
                      <TextField
                        id="id_public_url"
                        label="URL"
                        value={url}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LinkIcon />
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: "300px", fontWeight: 400 }}
                      />
                      <IconButton
                        aria-label={
                          global.lang == "en"
                            ? "copy to clipboard"
                            : "copier dans le clipboard"
                        }
                        onClick={() => copyURL()}
                        style={{ transform: "rotate(90deg)" }}
                      >
                        <FilterNoneIcon />
                      </IconButton>
                    </div>
                  </div>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      saveList();
                      /* window.location.href = server_url+"a/" + url_logged_pseudo + "/" + url_name; */
                    }}
                    style={{
                      marginTop: "1em",
                      marginBottom: "1em",
                      maxWidth: "230px",
                      borderRadius: "36px",
                    }}
                    startIcon={
                      <SaveIcon
                        style={{ transform: "scale(2)", marginLeft: "8px" }}
                      />
                    }
                  >
                    {global.lang == "en"
                      ? "Save my results"
                      : "sauvegarder mes résultats"}
                  </Button>

                  {a_questions.length >= 10 && (
                    <ColorButton
                      variant="contained"
                      style={{
                        marginTop: "1em",
                        maxWidth: "230px",
                        borderRadius: "20px",
                      }}
                      onClick={() => {
                        setQuestionsForTestGen();
                        setOpenTestGen(true);
                      }}
                    >
                      {global.lang == "en"
                        ? "paper test"
                        : "interrogation écrite"}
                    </ColorButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open_img_dial}
        onClose={() => {
          setOpenImgDial(false);
        }}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2em",
          }}
        >
          <img src={media_800}></img>
        </div>
      </Dialog>
      <CreaListDialog
        open_crea_list={open_crea_list}
        setOpenCreaList={setOpenCreaList}
        url_pseudo={url_logged_pseudo}
      ></CreaListDialog>

      <Dialog
        open={open_test_gen}
        onClose={() => {
          setOpenTestGen(false);
        }}
        maxWidth="xl"
      >
        <div>
          <TestGen
            arg_a_questions={arg_a_questions}
            url_pseudo={url_pseudo}
            url_name={url_name}
            id_list={id_list}
          ></TestGen>
        </div>
      </Dialog>
    </div>
  );
}

function LQuestion({
  id_question,
  score,
  statement,
  answer,
  has_pic,
  pic_url,
  pic_url_800,
  setOpenImgDial,
  setMedia800,
  display_answers,
}) {
  const [show_answer, setShowAnswer] = useState(false);
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  useEffect(() => {
    setShowAnswer(display_answers);
  }, [display_answers]);

  return (
    <div>
      <div
        style={
          isPhoneV
            ? {
                display: "flex",
                flexDirection: "column",
                marginBottom: "1em",
                backgroundColor: "white",
                border: "1px solid lightgray",
              }
            : { display: "flex", justifyContent: "space-between" }
        }
      >
        <div
          style={
            isPhoneV
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }
              : { display: "flex", alignItems: "center" }
          }
        >
          <ScoreBullet score={score}></ScoreBullet>
        </div>

        <div
          style={
            isPhoneV
              ? {
                  padding: "12px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  width: "400px",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-around",
                }
          }
        >
          {has_pic ? (
            <div
              style={{
                /*                 width: "200px",
                maxHeight: "150px", */
                display: "flex",
                alignItems: "center",
                /* wordBreak: "break-all", */
                overflow: "auto",
                justifyContent: "space-around",
              }}
            >
              {statement.length < 17 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length >= 17 && statement.length <= 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "x-large" }
                      : { fontSize: "x-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length > 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "large" }
                      : { fontSize: "large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
            </div>
          ) : (
            <div
              style={{
                width: isPhoneV ? "auto" : "400px",
                /* maxHeight: "150px", */
                display: "flex",
                alignItems: "center",
                /* wordBreak: "break-all", */
                overflow: "auto",
                justifyContent: "space-around",
              }}
            >
              {statement.length < 17 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length >= 17 && statement.length <= 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "xx-large" }
                      : { fontSize: "xx-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
              {statement.length > 50 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={
                    isPhoneV
                      ? { fontSize: "x-large" }
                      : { fontSize: "x-large", marginRight: "0.5em" }
                  }
                >
                  {statement}
                </Typography>
              )}
            </div>
          )}
          {has_pic && (
            <img
              src={pic_url}
              style={{
                maxWidth: "300px",
                maxHeight: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                setMedia800(pic_url_800);
                setOpenImgDial(true);
              }}
            ></img>
          )}
        </div>
        {!isPhoneV && (
          <>
            {show_answer ? (
              <div
                style={{
                  width: "280px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowAnswer(false);
                }}
              >
                {answer === "1f9e" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontSize: "XXx-large", color: "red" }}
                    >
                      ?
                    </Typography>
                  </div>
                ) : (
                  <>
                    {answer.length < 17 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "xx-large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                    {answer.length >= 17 && answer.length <= 28 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "x-large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                    {answer.length > 28 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "large" }}
                      >
                        {answer}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "280px",
                }}
                onClick={() => {
                  setShowAnswer(true);
                }}
              >
                <div></div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "xx-large" }}
                >
                  {global.lang == "fr" ? "réponse" : "answer"}
                </Typography>
                <TouchAppIcon
                  style={{ color: "white", marginRight: "1em" }}
                  fontSize="large"
                ></TouchAppIcon>
              </div>
            )}
          </>
        )}
        {isPhoneV && (
          <>
            {show_answer ? (
              <>
                <div
                  style={
                    isPhoneV
                      ? {
                          padding: "12px",
                          textAlign: "center",
                          cursor: "pointer",
                        }
                      : {
                          width: "280px",
                          padding: "12px",
                          display: "flex",
                          justifyContent: "space-around",
                        }
                  }
                  onClick={() => {
                    setShowAnswer(false);
                  }}
                >
                  {answer === "1f9e" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ fontSize: "XXx-large", color: "red" }}
                      >
                        ?
                      </Typography>
                    </div>
                  ) : (
                    <>
                      {answer.length < 17 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "xx-large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                      {answer.length >= 17 && answer.length <= 28 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "x-large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                      {answer.length > 28 && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "large" }}
                        >
                          {answer}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  padding: "12px",
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowAnswer(true);
                }}
              >
                <div></div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "xx-large" }}
                >
                  {global.lang == "fr" ? "réponse" : "answer"}
                </Typography>
                <TouchAppIcon
                  style={{ color: "white", marginRight: "1em" }}
                  fontSize="large"
                ></TouchAppIcon>
              </div>
            )}
          </>
        )}
      </div>
      <Divider></Divider>
    </div>
  );
}
