import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CHeader from "../MainComponents/CHeader";
import TextField from "@material-ui/core/TextField";
import { setUserDescrDB } from "../Generic/lists";
import EditIcon from "@material-ui/icons/Edit";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Link from "@material-ui/core/Link";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RefreshIcon from "@material-ui/icons/Refresh";
import ImageIcon from "@material-ui/icons/Image";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinkIcon from "@material-ui/icons/Link";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default function CProfile() {
  let a_temp = [
    ["website", ""],
    ["discord", ""],
    ["facebook", ""],
    ["instagram", ""],
    ["linkedin", ""],
    ["pinterest", ""],
    ["reddit", ""],
    ["tumblr", ""],
    ["twitch", ""],
    ["twitter", ""],
    ["vimeo", ""],
    ["youtube", ""],
  ];

  let a_network_names = [];
  a_temp.forEach((e) => {
    a_network_names.push(e[0]);
  });
  const [menuStatus, setMenuStatus] = useState("0");
  const [a_networks, setNetworks] = useState([...a_temp]);
  const [is_edit_descr, setIsEditDescr] = useState(false);
  const [descr_clone, setDescrContent] = useState();
  const [display_delete_account, setDisplayDeleteAccount] = useState(false);
  const [id_user, setIdUser] = useState();
  const [url_pseudo, setUrlPseudo] = useState();
  const [pseudo, setPseudo] = useState();
  const [temp_descr, setTempDescr] = useState();

  console.log(id_user);
  console.log(typeof id_user);

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
  const url_download_data = server_url + "api/get_user_all_data";
  const public_url = server_url + "e/" + url_pseudo;
  const setDescr = () => {
    setUserDescrDB(id_user, temp_descr);
    setDescrContent(temp_descr);
    setIsEditDescr(false);
  };

  useEffect(() => {
    fetch("/api/get_user_meta", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_user: "session" }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setIdUser(res[0]);
        setPseudo(res[2]);
        setUrlPseudo(res[8]);
        setDescrContent(res[9]);

        const data = { id_user: res[0] };
        console.log(data);
        fetch("/api/get_user_networks", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            res.forEach((e) => {
              console.log(e);
              let a_a = [...a_networks];
              console.log(a_a);
              console.log(a_network_names.indexOf(e[1]));
              a_a[a_network_names.indexOf(e[1])][1] = e[3];
              setNetworks(a_a);
            });
            console.log(res);
          });
      });
  }, []);
  function copyURL() {
    var copyText = document.getElementById("id_public_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  return (
    <span>
      <div style={{ height: "69px" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "2em",
        }}
      >
        <Typography variant="h3" color="textSecondary">
          {pseudo}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "2em",
        }}
      >
        <div>
          <Typography variant="h6" color="textSecondary">
            <Link target="_blank" href={public_url}>
              {global.lang == "en" ? "preview" : "prévisualisation"}
            </Link>{" "}
            {global.lang == "en" ? "of your public profile" : "de votre profil"}
          </Typography>
          <TextField
            id="id_public_url"
            value={public_url}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            aria-label={
              global.lang == "en"
                ? "copy to clipboard"
                : "copier dans le clipboard"
            }
            onClick={() => copyURL()}
            style={{ transform: "rotate(90deg)" }}
          >
            <FilterNoneIcon />
          </IconButton>
        </div>
      </div>

      <Grid container spacing={3} justify="center">
        <Grid item xl={6} lg={12} justify="center">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              {typeof url_pseudo != "undefined" && (
                <DPicture url_pseudo={url_pseudo}></DPicture>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xl={6} lg={12} justify="center">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="h4"
                  style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                >
                  {global.lang == "en"
                    ? "Tell people about you"
                    : "Dites nous en plus sur vous"}
                </Typography>

                {!is_edit_descr && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      color="primary"
                      aria-label={global.lang == "en" ? "edit" : "modifier"}
                      onClick={() => setIsEditDescr(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </div>

              {is_edit_descr && (
                <div style={{ padding: "2em" }}>
                  <TextField
                    multiline
                    rows={4}
                    defaultValue={descr_clone}
                    variant="outlined"
                    onChange={(event) => {
                      setTempDescr(event.target.value);
                    }}
                    fullWidth
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "1em",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDescr()}
                    >
                      {global.lang == "en" ? "save" : "sauvegarder"}
                    </Button>
                  </div>
                </div>
              )}
              {!is_edit_descr && (
                <div style={{ padding: "2em" }}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {descr_clone}
                  </Typography>
                </div>
                /* <Typography style={{ whiteSpace: "pre-line" }}>{descr_clone}</Typography> */
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <Typography
        variant="h4"
        style={{ color: "gray", fontWeight: "300", padding: "1em" }}
      >
        {global.lang == "en" ? "Social networks" : "Réseaux sociaux"}
      </Typography>
      <Grid container spacing={3} style={{ padding: "2em" }}>
        {a_networks.map((e, i) => {
          return (
            <SocialNetwork
              network={e[0]}
              url={e[1]}
              id_user={id_user}
            ></SocialNetwork>
          );
        })}
      </Grid> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Link target="_blank" href={url_download_data}>
        {global.lang == "en"
          ? "download all my data"
          : "télécharger l'ensemble de mes données"}
      </Link>
      <br></br>
      <Link onClick={() => setDisplayDeleteAccount(true)}>
        {global.lang == "en" ? "delete account" : "supprimer le compte"}
      </Link>
      <DeleteDialog
        openCreaDialog={display_delete_account}
        setDisplayDeleteAccount={setDisplayDeleteAccount}
      ></DeleteDialog>
    </span>
  );
}

function DPicture({ url_pseudo }) {
  const [pic_number, setPicNumber] = useState(-1);
  const [image_file, setImageFile] = useState("");
  const [pic_url, setPicUrl] = useState("");
  const [picture_saved, setPictureSaved] = useState(true);

  const updatePicture = () => {
    console.log("updatePicture");
    var file_name = "";
    if (pic_number == -1) {
      file_name = image_file.fileToUpload.name;
    }
    const data = {
      url_pseudo: url_pseudo,
      pic_number: pic_number,
      file_name: file_name,
    };
    fetch("/api/update_profile_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (pic_number == -1) {
          axios({
            method: "PUT",
            url: res["presigned_url"],
            data: image_file.fileToUpload,
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((res) => {
              console.log("succeessss file upload");
            })
            .catch((err) => {
              console.log(
                "oh no something wen't wrong in the file uploading process"
              );
            });
        }
      });
  };

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
  useEffect(() => {
    fetch(server_url + "/api/get_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: url_pseudo }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPicUrl(res["url"]);
        console.log(res);
      });
    setImageFile("");
  }, []);
  const randProfilePic = () => {
    fetch(server_url + "/api/get_rand_profil_picture", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPicNumber(res["rand_int"]);
        setPicUrl(res["url"]);
        console.log("randProfilePic " + res["rand_int"] + " " + res["url"]);
      });
    setImageFile("");
    setPictureSaved(false);
  };
  const browseFiles = () => {
    var input = document.getElementById("id_hidden_file_browser");
    input.click();
  };
  return (
    <>
      <Typography
        variant="h4"
        style={{ color: "gray", fontWeight: "300", padding: "1em" }}
      >
        {global.lang == "en" ? "Profile picture" : "Image de profil"}
      </Typography>

      <div style={{ display: "flex" }}>
        <div style={{ padding: "2em" }}>
          <img
            src={
              image_file == ""
                ? pic_url
                : URL.createObjectURL(image_file.fileToUpload)
            }
            style={{
              border: "dashed rgba(0,0,0,0.12)",
              borderRadius: "128px",
              maxWidth: "256px",
              maxHeight: "256px",
              fontSize: "large",
            }}
          ></img>
        </div>
        <div style={{ padding: "1em", display: "flex", alignItems: "center" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body1" color="textSecondary">
                    {global.lang == "en"
                      ? "Upload profil picture"
                      : "Changer de photo de profil"}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton aria-label="icon" onClick={() => browseFiles()}>
                    <ImageIcon></ImageIcon>
                  </IconButton>
                </div>
              </div>
            </div>

            <input
              id="id_hidden_file_browser"
              type="file"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImageFile({
                  fileToUpload: e.target.files[0],
                });
                setPicNumber(-1);
                setPictureSaved(false);
              }}
              style={{ display: "none" }}
            ></input>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body1" color="textSecondary">
                    {global.lang == "en" ? "Random pick" : "Tirage aléatoire"}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    aria-label={
                      global.lang == "en"
                        ? "refresh icon"
                        : "rafraîchir l'icône"
                    }
                    onClick={() => randProfilePic()}
                  >
                    <RefreshIcon></RefreshIcon>
                  </IconButton>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {picture_saved ? (
                <Button disabled variant="contained">
                  {global.lang == "en" ? "save picture" : "sauvegarder l'image"}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    updatePicture();
                    setPictureSaved(true);
                  }}
                >
                  {global.lang == "en" ? "save picture" : "sauvegarder l'image"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SocialNetwork({ id_user, network, url }) {
  const [is_saved, setIsSaved] = useState(true);
  const [new_url, setNewURL] = useState(url);
  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
  const icon_url =
    server_url + "static/im/networks_icons/cb1_" + network + ".svg";
  const saveNetwork = () => {
    console.log("saveNetwork");
    const data = {
      id_user: id_user,
      network: network,
      url: new_url,
    };
    console.log(data);
    fetch("/api/add_user_network", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.log(JSON.stringify(res)));
    setIsSaved(true);
  };

  return (
    <Grid item xl={4} lg={6} md={12}>
      <Paper style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ display: "flex", alignItems: "center", padding: "1em" }}>
          {url == "" ? (
            <img
              src={icon_url}
              style={{ width: "36px", height: "36px", opacity: "0.4" }}
            />
          ) : (
            <img
              src={icon_url}
              style={{
                width: "36px",
                height: "36px",
                opacity: "0.7",
                backgroundColor: "dodgerblue",
                borderRadius: "10px",
              }}
            />
          )}
        </div>
        <TextField
          margin="dense"
          id={"id_net_" + network}
          label={network + " url"}
          placeholder={url == "" ? network + " url" : url}
          onChange={(event) => {
            setIsSaved(false);
            setNewURL(event.target.value);
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {is_saved ? (
            <Button disabled variant="contained">
              {global.lang == "en" ? "save" : "sauvegarder"}
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={() => saveNetwork()}
              variant="contained"
            >
              {global.lang == "en" ? "save" : "sauvegarder"}
            </Button>
          )}
        </div>
      </Paper>
    </Grid>
  );
}

function DeleteDialog({ openCreaDialog, setDisplayDeleteAccount }) {
  const [confirm, setConfirm] = useState("");

  return (
    <Dialog
      open={openCreaDialog}
      onClose={() => setDisplayDeleteAccount(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        {global.lang == "en"
          ? "This operation can't be undone"
          : "Cette opération ne peut être annulée"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {global.lang == "en"
            ? "To confirm deletion, type <i>delete</i> :"
            : "Pour confirmer la suppression, taper <i>delete</i> :"}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="id_crea_list_title"
          label="List title"
          fullWidth
          onChange={(e) => setConfirm(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDisplayDeleteAccount(false)} color="primary">
          {global.lang == "en" ? "Cancel" : "Annuler"}
        </Button>
        {confirm == "delete" ? (
          <Button
            onClick={() => {
              console.log(confirm);
              const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
              window.location.href = server_url + "/api/delete_user_account";
            }}
            color="primary"
          >
            {global.lang == "en" ? "delete" : "supprimer"}
          </Button>
        ) : (
          <Button disabled></Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
