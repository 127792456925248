import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { deletePageDB } from "../Generic/lists";
import { Typography } from "@material-ui/core";
import { unixToShortHuman, unixToDelay } from "../Generic/generic";
import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { CreaPageDialog } from "../SmallComponents/CDialog";

export default function CPages() {
  const [a_pages, setPages] = useState([]);
  const [url_user_name, setUrlUserName] = useState();
  const [url_pseudo, setUrlPseudo] = useState();
  const [openCreaPageDialog, setOpenCreaPageDialog] = useState(false);

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";

  useEffect(() => {
    fetch(server_url + "/api/get_pages")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPages(data);
      });

    fetch(server_url + "api/get_user_data")
      .then((res) => res.json())
      .then((res) => {
        setUrlPseudo(res["url_user_name"]);
        setUrlUserName(res["url_user_name"]);
      });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ height: "69px" }}></div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{display: "flex"}}>
          <Typography
            variant="h3"
            style={{ color: "gray", fontWeight: "300", padding: "1em" }}
          >
            Pages
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="primary"
              onClick={() => setOpenCreaPageDialog(true)}
            >
              <AddCircleOutlineIcon fontSize="large"></AddCircleOutlineIcon>
            </IconButton>
          </div>
        </div>
      </div>
      <div>
        {a_pages.map((value, index) => {
          const link_url = server_url + "b/" + url_user_name + "/" + value[7];
          return (
            <OnePage
              id_page={value[0]}
              title={value[2]}
              /* descr={value[6]} */
              descr={value[16]}
              doDelete={(x) => deletePageDB(x)}
              link_url={link_url}
              is_published={value[5]}
              crea_date={value[3]}
              pub_date={value[13]}
              lists={value[19]}
              views={value[9]}
            ></OnePage>
          );
        })}
      </div>
      <CreaPageDialog
        open_crea_page={openCreaPageDialog}
        setOpenCreaPage={setOpenCreaPageDialog}
        url_pseudo={url_pseudo}
      ></CreaPageDialog>
    </div>
  );
}

function OnePage({
  id_page,
  title,
  descr,
  doDelete,
  link_url,
  is_published,
  views,
  crea_date,
  pub_date,
  lists,
}) {
  const [open_del_dial, setOpenDelDial] = useState(false);
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Paper style={{ width: "500px", padding: "1em", margin: "1em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.4em",
          }}
        >
          <div style={{ fontWeight: "600", fontSize: "16px" }}>
            <Link href={link_url} style={{ marginRight: "0.5em" }}>
              {title}
            </Link>
          </div>
          {global.lang == "en" ? (
            <Typography variant="body2" color="textSecondary">
              {is_published == 1 ? "published" : "not published"}
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              {is_published == 1 ? "publiée" : "non publiée"}
            </Typography>
          )}
        </div>

        <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
          <div>
            <EventIcon fontSize="small"></EventIcon>
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: "14px",
              paddingLeft: "0.4em",
            }}
          >
            {global.lang == "en" && "Created "}
            {global.lang == "fr" && "Créée le "}
            {unixToShortHuman(crea_date)}
          </div>
          {pub_date != null && (
            <>
              <div style={{ paddingLeft: "0.6em" }}>
                <EventIcon fontSize="small"></EventIcon>
              </div>
              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {global.lang == "en" && "Last published "}
                {global.lang == "fr" && "Dernière publication "}
                {unixToShortHuman(pub_date)}
              </div>

              <div style={{ paddingLeft: "0.6em" }}>
                <VisibilityIcon fontSize="small"></VisibilityIcon>
              </div>

              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {" "}
                {views}
              </div>
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em",
          }}
        >
          <div>
            <Typography variant="body2" color="textSecondary">
              {descr}
            </Typography>
            {lists != "" && (
              <div style={{ paddingTop: "1em" }}>
                <Typography variant="body2" color="textSecondary">
                  Lists: {lists.slice(0, -2)}
                </Typography>
              </div>
            )}
          </div>
          <IconButton
            aria-label={
              global.lang == "en" ? "delete list" : "supprimer la liste"
            }
            style={{ transform: "scale(0.7)" }}
            onClick={() => setOpenDelDial(true)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </Paper>
      <Dialog
        open={open_del_dial}
        onClose={() => setOpenDelDial(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {global.lang == "en" && "Confirm you want to delete this page"}
          {global.lang == "fr" && "Confirmer la suppression de la page"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDelDial(false)} color="primary">
            {global.lang == "en" && "Cancel"}
            {global.lang == "fr" && "Annuler"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              doDelete(id_page);
              setOpenDelDial(false);
              window.location.reload();
            }}
            color="primary"
          >
            {global.lang == "en" && "I confirm"}
            {global.lang == "fr" && "Je confirme"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
