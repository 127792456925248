import { createSlice } from "@reduxjs/toolkit";

export const scoresSlice = createSlice({
  name: "global_scores",
  initialState: {
    mine_green: -1,
    mine_orange: 0,
    mine_red: 0,
    other_green: 0,
    other_orange: 0,
    other_red: 0,
    pages_visits: 0,
    lists_visits: 0,
    following_lists: 0,
    following_me: 0,
  },
  reducers: {
    setReduxScore: (state, action) => {
      state[action.payload.which_score] = action.payload.score;
    },
    setReduxShortStats: (state, action) => {
      state["mine_green"] = action.payload.mine_green;
      state["mine_orange"] = action.payload.mine_orange;
      state["mine_red"] = action.payload.mine_red;
      state["other_green"] = action.payload.other_green;
      state["other_orange"] = action.payload.other_orange;
      state["other_red"] = action.payload.other_red;
      state["pages_visits"] = action.payload.pages_visits;
      state["lists_visits"] = action.payload.lists_visits;
      state["following_lists"] = action.payload.following_lists;
      state["following_me"] = action.payload.following_me;
    },
  },
});

export const { setReduxScore, setReduxShortStats } = scoresSlice.actions;

export default scoresSlice.reducer;
