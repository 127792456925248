import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CHeader from "../MainComponents/CHeader";
import Dialog from "@material-ui/core/Dialog";
import { CreaListDialog, CreaPageDialog } from "../SmallComponents/CDialog";
import { unixToShortHuman, unixToDelay } from "../Generic/generic";
import { doFollowDB, unFollowDB } from "../Generic/lists";
import { Typography } from "@material-ui/core";
import CMenu from "../MainComponents/CMenu";
import { BigMetPresentation } from "../User/UPresentation";
import { SignIn, SignUp } from "../HomeNoSession/HomeNoSession";
import Link from "@material-ui/core/Link";

import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GroupIcon from "@material-ui/icons/Group";
import Paper from "@material-ui/core/Paper";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LinkIcon from "@material-ui/icons/Link";
import ListAltIcon from "@material-ui/icons/ListAlt";

export default function EProfile({
  id_user,
  url_pseudo,
  pseudo,
  descr,
  is_logged,
}) {
  const a_temp = [
    ["website", ""],
    ["discord", ""],
    ["facebook", ""],
    ["instagram", ""],
    ["linkedin", ""],
    ["pinterest", ""],
    ["reddit", ""],
    ["tumblr", ""],
    ["twitch", ""],
    ["twitter", ""],
    ["vimeo", ""],
    ["youtube", ""],
    ["other", ""],
  ];
  let a_network_names = [];
  a_temp.forEach((e) => {
    a_network_names.push(e[0]);
  });
  const [menuStatus, setMenuStatus] = useState("0");
  const [a_user_profile_meta, setUserProfileMeta] = useState();
  const [a_networks, setNetworks] = useState([...a_temp]);
  const [a_lists, setLists] = useState([]);
  const [a_pages, setPages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreaListDialog, setOpenCreaListDialog] = useState(false);
  const [openCreaPageDialog, setOpenCreaPageDialog] = useState(false);

  console.log(id_user);
  console.log(typeof id_user);

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";
  const public_url = server_url + "e/" + url_pseudo;

  useEffect(() => {
    const data = { id_user: id_user };
    console.log(data);

    let api_url = server_url + "api/meta_from_url_pseudo";

    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: url_pseudo }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setUserProfileMeta(res);
      });

    fetch("/api/get_user_profile_data", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        /* setUserProfileMeta(res[0]); */
        res[1].forEach((e) => {
          console.log(e);
          let a_a = [...a_networks];
          console.log(a_a);
          console.log(a_network_names.indexOf(e[1]));
          a_a[a_network_names.indexOf(e[1])][1] = e[3];
          setNetworks(a_a);
        });
        setLists(res[2]);
        setPages(res[3]);
      });
  }, []);

  return (
    <>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={is_logged}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          maxWidth: "1650px",
        }}
      >
        {menuStatus == "1" && <CMenu></CMenu>}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            {typeof a_user_profile_meta != "undefined" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BigMetPresentation
                  a_meta={a_user_profile_meta}
                  is_logged={is_logged}
                ></BigMetPresentation>
              </div>
            )}
          </div>
          <div style={{ display: "flex", padding: "2em" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography
                  variant="h3"
                  style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                >
                  {global.lang == "en" ? "Lists" : "Listes"}
                </Typography>
              </div>
              {a_lists.map((value, i) => {
                console.log(value);
                return (
                  <EList
                    id_list={value[0]}
                    title={value[1]}
                    descr={value[3]}
                    url_pseudo={url_pseudo}
                    crea_date={value[2]}
                    visits={value[5]}
                    followers={value[6]}
                    url_name={value[4]}
                    questions_number={value[7]}
                    last_question_added={value[8]}
                  ></EList>
                );
              })}
            </div>

            <div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography
                  variant="h3"
                  style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                >
                  Pages
                </Typography>
              </div>
              {a_pages.map((value, i) => {
                console.log(value);
                let link_url = server_url + "c/" + url_pseudo + "/" + value[7];
                return (
                  <EPage
                    id_page={value[0]}
                    title={value[2]}
                    descr={value[16]}
                    link_url={link_url}
                    is_published={value[5]}
                    crea_date={value[3]}
                    pub_date={value[13]}
                    lists={value[19]}
                    views={value[9]}
                    lists_number={value[19]}
                    files_number={value[20]}
                    links_number={value[21]}
                  ></EPage>
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "1em" }}
                onClick={() => {
                  if (is_logged == "1") {
                    setOpenCreaListDialog(true);
                  } else {
                    setOpenDialog(true);
                  }
                }}
                fullWidth
              >
                {global.lang == "en" ? "create a list" : "créer une liste"}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "1em" }}
                onClick={() => {
                  if (is_logged == "1") {
                    setOpenCreaPageDialog(true);
                  } else {
                    setOpenDialog(true);
                  }
                }}
                fullWidth
              >
                {global.lang == "en" ? "create a page" : "créer une page"}
              </Button>
            </div>
          </div>
        </div>
        <CreaListDialog
          open_crea_list={openCreaListDialog}
          setOpenCreaList={setOpenCreaListDialog}
          url_pseudo={url_pseudo}
        ></CreaListDialog>

        <CreaPageDialog
          open_crea_page={openCreaPageDialog}
          setOpenCreaPage={setOpenCreaPageDialog}
          url_pseudo={url_pseudo}
        ></CreaPageDialog>
        <Dialog
          onClose={() => setOpenDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          maxWidth="lg"
        >
          <div style={{ display: "flex" }}>
            <SignIn></SignIn>
            <SignUp></SignUp>
          </div>
          <Button
            autoFocus
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            {global.lang == "en" ? "cancel" : "annuler"}
          </Button>
        </Dialog>
      </div>
    </>
  );
}

function EPage({
  id_page,
  title,
  descr,
  link_url,
  is_published,
  crea_date,
  pub_date,
  lists,
  views,
  lists_number,
  files_number,
  links_number,
}) {
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Paper style={{ width: "300px", padding: "1em", margin: "1em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.4em",
          }}
        >
          <div style={{ fontWeight: "600", fontSize: "16px" }}>
            <Link href={link_url} style={{ marginRight: "0.5em" }}>
              {title}
            </Link>
          </div>
        </div>

        <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
          <div>
            <EventIcon fontSize="small"></EventIcon>
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "14px",
              paddingLeft: "0.4em",
            }}
          >
            {global.lang == "en" && "Created "}
            {global.lang == "fr" && "Créée le "}
            {unixToShortHuman(crea_date)}
          </div>
          {pub_date != null && (
            <>
              <div style={{ paddingLeft: "0.6em" }}>
                <VisibilityIcon fontSize="small"></VisibilityIcon>
              </div>

              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {" "}
                {views}
              </div>
            </>
          )}
        </div>

        <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
          <div>
            <EventIcon fontSize="small"></EventIcon>
          </div>
          <div
            style={{
              paddingLeft: "0.4em",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {global.lang == "en" && "Last published "}
            {global.lang == "fr" && "Dernière publication "}
            {unixToShortHuman(pub_date)}
          </div>
        </div>

        <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
          <div style={{ display: "flex" }}>
            <ListAltIcon fontSize="small"></ListAltIcon>
            <div
              style={{
                paddingLeft: "0.4em",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {lists_number}
              {global.lang == "en" && " lists"}
              {global.lang == "fr" && " listes"}
            </div>
          </div>
        </div>
        {(files_number != 0 ||
          links_number != 0) && (
            <div style={{ display: "flex", color: "rgb(156,158,160)"  }}>
              {files_number != 0 && (
                <div style={{ display: "flex", paddingRight:"0.4em" }}>
                  <InsertDriveFileIcon fontSize="small"></InsertDriveFileIcon>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {files_number}
                    {global.lang == "en" && " files"}
                    {global.lang == "fr" && " fichiers"}
                  </div>
                </div>
              )}

              {files_number != 0 && (
                <div style={{ display: "flex", color: "rgb(156,158,160)"  }}>
                  <LinkIcon fontSize="small"></LinkIcon>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {links_number}
                    {global.lang == "en" && " links"}
                    {global.lang == "fr" && " liens"}
                  </div>
                </div>
              )}
            </div>
          )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em",
          }}
        >
          <div>
            <Typography variant="body2" color="textSecondary">
              {descr}
            </Typography>
            {/*               {lists != "" && (
                <div style={{ paddingTop: "1em" }}>
                  <Typography variant="body2" color="textSecondary">
                    Lists: {lists.slice(0, -2)}
                  </Typography>
                </div>
              )} */}
          </div>
        </div>
      </Paper>
    </div>
  );
}

function EList({
  id_list,
  title,
  descr,
  url_pseudo,
  crea_date,
  visits,
  followers,
  url_name,
  questions_number,
  last_question_added,
}) {
  const server_url =
    "https://"+window.location.href.split(/[/?#]/)[2]+"/" + "p/" + url_pseudo + "/" + url_name;
  let curr_time = Math.round(new Date().getTime() / 1000);
  return (
    <Paper style={{ width: "300px", margin: "1em" }}>
      <div
        style={{
          display: "flex",
          padding: "1em",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: "600", fontSize: "16px" }}>
            <Link href={server_url} style={{ marginRight: "0.5em" }}>
              {title}
            </Link>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "0.2em",
              color: "rgb(156,158,160)",
            }}
          >
            <EventIcon fontSize="small"></EventIcon>
            <div
              style={{
                paddingLeft: "0.4em",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {global.lang == "en" && "Created "}
              {global.lang == "fr" && "Créée le "}
              {unixToShortHuman(crea_date)}
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: "0.6em" }}>
                <VisibilityIcon fontSize="small"></VisibilityIcon>
              </div>
              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {" "}
                {visits}
              </div>
              <div style={{ paddingLeft: "0.6em" }}>
                <GroupIcon fontSize="small"></GroupIcon>
              </div>
              <div
                style={{
                  paddingLeft: "0.4em",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {" "}
                {followers}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
            <div>
              <QuestionAnswerIcon fontSize="small"></QuestionAnswerIcon>
            </div>

            <div
              style={{
                paddingLeft: "0.4em",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {questions_number} questions
            </div>
          </div>
          <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
            <div>
              <EventIcon fontSize="small"></EventIcon>
            </div>

            <div
              style={{
                paddingLeft: "0.4em",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {global.lang == "en" && "Last question added "}
              {global.lang == "fr" && "Dernière question ajoutée "}
              {unixToDelay(curr_time - last_question_added)}
            </div>
          </div>

          <div style={{ paddingTop: "1em" }}>
            <Typography variant="body2" color="textSecondary">
              {descr}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
}
