import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CHeader from "../MainComponents/CHeader";
import { MetPresentation } from "../User/UPresentation";
import Dialog from "@material-ui/core/Dialog";
import { CreaListDialog, CreaPageDialog } from "../SmallComponents/CDialog";
import { addOnePageVisitDB, insertPageVisitDB } from "../Generic/lists";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography } from "@material-ui/core";
import MMenu from "../MonoPages/MMenu";
import { unixToShortHuman, unixToDelay } from "../Generic/generic";
import EventIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GroupIcon from "@material-ui/icons/Group";
import { SignIn, SignUp, SignUpG } from "../HomeNoSession/HomeNoSession";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { signInGoogle } from "../Generic/google_signin";
import { GoogleLogin } from "react-google-login";

export default function CPage({
  id_user,
  id_page,
  url_pseudo,
  url_name,
  pseudo,
  page_name,
  is_logged,
  c_or_f = "c",
}) {
  const [a_lists, setLists] = useState([]);
  const [a_links, setLinks] = useState([]);
  const [menuStatus, setMenuStatus] = useState("0");
  const [page_meta, setPageMeta] = useState([]);
  const [user_meta, setUserMeta] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreaListDialog, setOpenCreaListDialog] = useState(false);
  const [openCreaPageDialog, setOpenCreaPageDialog] = useState(false);
  const [files_meta, setFilesMeta] = useState([]);
  const [url_logged_pseudo, setUrlLoggedPseudo] = useState("");

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");
  const [openUpGDialog, setOpenUpGDialog] = useState(false);

  const [cont_code, setContCode] = useState("");

  const server_url = "https://"+window.location.href.split(/[/?#]/)[2]+"/";

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handleGoogleButtonCreaList = (e) => {
    signInGoogle(
      e,
      () => window.location.href = server_url,
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };

  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  useEffect(() => {
    let api_url = server_url + "api/meta_from_url_pseudo";
    console.log("url_pseudo ",url_pseudo);
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ url_pseudo: url_pseudo }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setUserMeta(res);
      });

    api_url = server_url + "api/get_page_meta";
    if (c_or_f == "c") {
      api_url = server_url + "api/get_published_page_meta";
    }
    const data = { id_page: id_page };
    console.log(data);
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setPageMeta(res);
      });

    api_url = server_url + "api/get_page_lists_full";
    if (c_or_f == "f") {
      api_url = server_url + "api/get_preview_page_lists_full";
    }
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLists(res);
      });

    api_url = server_url + "api/get_page_links";
    if (c_or_f == "c") {
      api_url = server_url + "api/get_published_page_links";
    }
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLinks(res);
      });

    api_url = server_url + "api/get_page_files_meta";
    if (c_or_f == "c") {
      api_url = server_url + "api/get_published_page_files_meta";
    }
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id_page: id_page }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setFilesMeta(res);
      });
    if (c_or_f == "c") {
      console.log(c_or_f);
      insertPageVisitDB(id_page);
      addOnePageVisitDB(id_page);
    }
    if (is_logged == "1") {
      fetch(server_url + "api/get_user_data")
        .then((res) => res.json())
        .then((res) => {
          setUrlLoggedPseudo(res["url_user_name"]);
          console.log(res);
        });
    }

    fetch(server_url + "/api/get_cont_code", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({"id_content":id_page, "content_type":"p"}),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setContCode(res["id_code"]);
      });
  }, []);

  return (
    <span>
      <CHeader
        menuStatus={menuStatus}
        setMenuStatus={(status) => setMenuStatus(status)}
        is_logged={is_logged}
      ></CHeader>
      <div style={{ height: "69px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {menuStatus == "1" && <MMenu></MMenu>}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "1650px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {typeof user_meta != "undefined" && (
              <MetPresentation
                a_meta={user_meta}
                is_logged={is_logged}
              ></MetPresentation>
            )}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "3em",
                }}
              >
                <Typography variant="h3" color="textSecondary">
                  {page_name}
                </Typography>
              </div>

              <div
                style={{
                  whiteSpace: "pre-line",
                  textAlign: "center",
                  paddingBottom: "2em",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  display:"flex",
                  justifyContent:"space-around"
                }}
              >
                <div style={{width: "66%"}}>
                <Typography variant="body1" color="textSecondary">
                  {page_meta[6]}
                </Typography>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginLeft:"4em", marginRight:"2em" }}>
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                  >
                    {global.lang == "en" ? "Files" : "Pièces jointes"}
                  </Typography>

                  <ul style={{ marginLeft: "2em" }}>
                    {files_meta.length == 0 ? (
                      <Typography variant="body1" color="textPrimary">
                        {global.lang == "en"
                          ? "no files"
                          : "pas de pièce jointe"}
                      </Typography>
                    ) : (
                      <>
                        {files_meta.map((value, index) => {
                          return (
                            <li>
                              <Link target="_blank" href={value[5]}>
                                {value[2]}
                                <IconButton>
                                  <GetAppIcon></GetAppIcon>
                                </IconButton>
                              </Link>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  <Typography
                    variant="h4"
                    style={{ color: "gray", fontWeight: "300", padding: "1em" }}
                  >
                    {global.lang == "en" ? "External links" : "Liens externes"}
                  </Typography>
                  <ul style={{ marginLeft: "2em" }}>
                    {a_links.length == 0 ? (
                      <Typography variant="body1" color="textPrimary">
                        {global.lang == "en"
                          ? "no external links"
                          : "pas de lien externe"}
                      </Typography>
                    ) : (
                      <>
                        {a_links.map((value, index) => {
                          return (
                            <li>
                              <OneLink
                                id_link={value[0]}
                                name={value[2]}
                                url={value[3]}
                                descr={value[5]}
                              ></OneLink>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
                <div style={{display:"hidden"}}>
                </div>
              </div>
              <Typography
                variant="h4"
                style={{ color: "gray", fontWeight: "300", padding: "1em", marginLeft:"2em" }}
              >
                {global.lang == "en" ? "Lists" : "Listes"}
              </Typography>
              <ul>
                {a_lists.map((value, index) => {
                  const link_url =
                    server_url + "p/" + value[21] + "/" + value[9];
                  console.log("a_lists_loop");
                  console.log(value[0]);

                  return (
                    <OneList
                      id_list={value[0]}
                      id_page={id_page}
                      title={value[2]}
                      descr={value[8]}
                      link_url={link_url}
                      crea_date={value[3]}
                      views={value[17]}
                      followers={value[22]}
                      questions_number={value[23]}
                    ></OneList>
                  );
                })}
              </ul>

              <br></br>
            </div>
            <div>
            
            {window.innerWidth<1400 ?
            <div style={{display:"flex", justifyContent:"space-around"}}><Typography variant="h5" color="textSecondary">#{cont_code.toUpperCase()}</Typography></div>:
            <div style={{display:"flex", justifyContent:"space-around"}}><Typography variant="h3" color="textSecondary">#{cont_code.toUpperCase()}</Typography></div>}
              <div style={{ width: "160px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: "1em" }}
                  onClick={() => {
                    if (is_logged == "1") {
                      setOpenCreaListDialog(true);
                    } else {
                      setOpenDialog(true);
                    }
                  }}
                  fullWidth
                >
                  {global.lang == "en" ? "create a list" : "créer une liste"}
                </Button>
              </div>

              <div style={{ width: "160px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "1em" }}
                  onClick={() => {
                    if (is_logged == "1") {
                      setOpenCreaPageDialog(true);
                    } else {
                      setOpenDialog(true);
                    }
                  }}
                  fullWidth
                >
                  {global.lang == "en" ? "create a page" : "créer une page"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="lg"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <Typography variant="h3" color="textPrimary">
            Sign In with google
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <GoogleLogin
            clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={(e) => handleGoogleButtonCreaList(e)}
            onFailure={() => {
              alert(
                "Problem with the sign in method, please choose another one"
              );
            }}
            cookiePolicy={"single_host_origin"}
            style={{ width: "400px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "1em",
          }}
        >
          <Typography variant="h4" color="textPrimary">
            Or
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <SignIn></SignIn>
          <SignUp></SignUp>
        </div>
        <Button autoFocus onClick={closeDialog} color="primary">
          {global.lang == "en" ? "cancel" : "annuler"}
        </Button>
      </Dialog>

      <CreaListDialog
        open_crea_list={openCreaListDialog}
        setOpenCreaList={setOpenCreaListDialog}
        url_pseudo={url_logged_pseudo}
      ></CreaListDialog>

      <CreaPageDialog
        open_crea_page={openCreaPageDialog}
        setOpenCreaPage={setOpenCreaPageDialog}
        url_pseudo={url_logged_pseudo}
      ></CreaPageDialog>

      <Dialog
        onClose={() => setOpenUpGDialog(false)}
        open={openUpGDialog}
        maxWidth="lg"
      >
        <div style={{ display: "flex" }}>
          <SignUpG
            email={g_email}
            pseudo={g_pseudo}
            google_profile_pic={g_picture}
            callbackType="home"
          ></SignUpG>
        </div>
      </Dialog>
    </span>
  );
}

function OneLink({ id_link, name, url, descr }) {
  return (
    <Link target="_blank" href={url}>
      {name}{" "}
      <IconButton style={{ opacity: "0" }}>
        <GetAppIcon></GetAppIcon>
      </IconButton>
    </Link>
  );
}

function OneList({
  id_list,
  id_page,
  title,
  descr,
  link_url,
  crea_date,
  views,
  followers,
  questions_number,
}) {
  return (
    <Paper style={{ width: "450px", padding: "1em", margin: "0.5em", marginLeft:"4em" }}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Link target="_blank" href={link_url}>
              {title}
            </Link>
            <div>
              <div
                style={{
                  display: "flex",
                  marginTop: "0.2em",
                  color: "rgb(156,158,160)",
                }}
              >
                <EventIcon fontSize="small"></EventIcon>
                <div
                  style={{
                    paddingLeft: "0.4em",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  {global.lang == "en" && "Created "}
                  {global.lang == "fr" && "Créée le "}
                  {unixToShortHuman(crea_date)}
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ paddingLeft: "0.6em" }}>
                    <VisibilityIcon fontSize="small"></VisibilityIcon>
                  </div>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {views}
                  </div>
                  <div style={{ paddingLeft: "0.6em" }}>
                    <GroupIcon fontSize="small"></GroupIcon>
                  </div>
                  <div
                    style={{
                      paddingLeft: "0.4em",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {followers}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", color: "rgb(156,158,160)" }}>
                <div>
                  <QuestionAnswerIcon fontSize="small"></QuestionAnswerIcon>
                </div>

                <div
                  style={{
                    paddingLeft: "0.4em",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  {questions_number} questions
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", paddingTop: "1em" }}>
        <Typography variant="body2" color="textSecondary">
          {descr}
        </Typography>
      </div>
    </Paper>
  );
}
