import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SignIn, SignUp, SignUpG } from "../HomeNoSession/HomeNoSession";
import { signInGoogle } from "../Generic/google_signin";
import { GoogleLogin } from "react-google-login";
import Grow from "@mui/material/Grow";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GoogleIcon from "@mui/icons-material/Google";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { setReduxIsCffooterOpen } from "../redux/globalDialog";
import {
  setReduxRegister,
  setReduxDefaultDisplay,
} from "../redux/globalDialog";

export default function CFFooter({}) {
  const isPC = useMediaQuery({ query: "(min-width: 1000px)" });
  const isPhoneV = useMediaQuery({ query: "(max-width: 800px)" });

  const [openUpGDialog, setOpenUpGDialog] = useState(false);

  const [g_email, setGEmail] = useState("");
  const [g_pseudo, setGPseudo] = useState("");
  const [g_picture, setGPicture] = useState("");

  const dispatch = useDispatch();
  const h_global_dialog = useSelector((state) => state.global_dialog);

  const handleGoogleButton = (e) => {
    signInGoogle(
      e,
      () => window.location.reload(),
      (email, pseudo, picture) => handleSignUpG(email, pseudo, picture)
    );
  };

  const handleSignUpG = (email, pseudo, picture) => {
    setGEmail(email);
    setGPseudo(pseudo);
    setGPicture(picture);
    setOpenUpGDialog(true);
  };

  return (
    <>
      {h_global_dialog["is_cffooter_open"] != 0 && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            bottom: 0,
            right: 0,
            alignItems: "center",
            backgroundColor: "white",
            padding: "0.5em 1em",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px",
            borderBottomLeftRadius: "36px",
            borderTopLeftRadius: "36px",
            transition: "all 300ms ease-in-out",
          }}
        >
          {h_global_dialog["is_cffooter_open"] == 2 ? (
            <>
              <Grow in={true}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("id_invisible_link").click();
                    dispatch(
                      setReduxDefaultDisplay({ default_display: "signup" })
                    );
                    dispatch(setReduxRegister({ register: true }));
                  }}
                  style={{ margin: "1em", fontFamily:"Nunito", fontWeight: 900 }}
                >
                  {global.lang == "en" ? "Sign up" : "créer un compte"}
                </Button>
              </Grow>

              <Grow in={true} timeout={500}>
                <Button
                  variant="contained"
                  style={{ margin: "1em", fontFamily:"Nunito", fontWeight: 900 }}
                  onClick={() => {
                    document.getElementById("id_invisible_link").click();
                    dispatch(
                      setReduxDefaultDisplay({ default_display: "signin" })
                    );
                    dispatch(setReduxRegister({ register: true }));
                  }}
                >
                  {global.lang == "en" ? "Log in" : "se connecter"}
                </Button>
              </Grow>
              {!isPhoneV && (
                <Grow in={true} timeout={1000}>
                  <span style={{ marginLeft: "1em", marginRight: "2em" }}>
                    <GoogleLogin
                      clientId="301441581088-b5lugdhh9hm3b2r7mta7g3ube6e4u9i4.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={handleGoogleButton}
                      onFailure={() => {
                        alert(
                          "Problem with the sign in method, please choose another one"
                        );
                      }}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          style={
                            isPC
                              ? {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontFamily:"Nunito",
                                  fontWeight: 900
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontFamily:"Nunito",
                                  fontWeight: 900
                                }
                          }
                          startIcon={<GoogleIcon />}
                          endIcon={<></>}
                          variant="outlined"
                        >
                          {global.lang == "fr"
                            ? "Se connecter avec Google"
                            : "Login with Google"}
                        </Button>
                      )}
                      cookiePolicy={"single_host_origin"}
                    />
                  </span>
                </Grow>
              )}

              <IconButton
                aria-label="close"
                onClick={() =>
                  dispatch(setReduxIsCffooterOpen({ is_cffooter_open: 1 }))
                }
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              aria-label="expand"
              onClick={() =>
                dispatch(setReduxIsCffooterOpen({ is_cffooter_open: 2 }))
              }
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
}
